<ion-header class="custom-header">
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="navigateHome()">
        <ion-icon slot="icon-only" color="primary" name="home"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title mode="ios">{{"reservation-app.more"|translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="item-list">
  <ion-grid
    *ngIf="!groupApp || !loginState || loginState ==='login_loading' || !windowDimensions"
  >
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid
    *ngIf="groupApp && loginState && loginState !=='login_loading' && windowDimensions"
  >
    <ion-item lines="none" style="margin-top: 20px" (click)="changeChannel()">
      <img
        alt="logo"
        [class]="
        windowDimensions &&
        windowDimensions.width &&
        windowDimensions.width > 450
          ? 'imgLogoBrowser'
          : 'imgLogoMobile'
      "
        alt="{{ groupApp.group }}"
        src="{{ rootDir }}{{ groupApp.group }}/logos/{{ selectedLogoFileName }}"
      />
    </ion-item>
    <ion-item lines="none" class="ion-text-center">
      <ion-label>
        <ion-button
          fill="clear"
          size="medium"
          (click)="openBrowserURL(groupApp.facebookUrl)"
        >
          <ion-icon
            style="position: absolute"
            slot="icon-only"
            name="logo-facebook"
          ></ion-icon>
        </ion-button>
        <ion-button
          fill="clear"
          size="medium"
          (click)="openBrowserURL(groupApp.instagramUrl)"
        >
          <ion-icon
            style="position: absolute"
            slot="icon-only"
            name="logo-instagram"
          ></ion-icon>
        </ion-button>
      </ion-label>
    </ion-item>
    <ion-list>
      <ion-item
        style="
          margin-left: 10px;
          margin-right: 10px;
          border-radius: 10px;
          margin-top: 10px;
          cursor: pointer;
        "
        lines="none"
        color="light"
        (click)="presentModal()"
        *ngIf="!currentUser"
      >
        <ion-icon size="small" name="person"></ion-icon>
        <ion-label style="margin-left: 10px"
          >{{"reservation-app.login"|translate}}</ion-label
        >
        <ion-icon
          color="primary"
          slot="end"
          name="chevron-forward-outline"
        ></ion-icon>
      </ion-item>
      <ion-item
        style="
          margin-left: 10px;
          margin-right: 10px;
          border-radius: 10px;
          margin-top: 10px;
          cursor: pointer;
        "
        lines="none"
        color="light"
        (click)="openUserInfo()"
        *ngIf="currentUser"
      >
        <ion-icon size="small" name="person"></ion-icon>
        <ion-label style="margin-left: 10px"
          >{{"reservation-app.profile"|translate}}</ion-label
        >
        <ion-icon
          color="primary"
          slot="end"
          name="chevron-forward-outline"
        ></ion-icon>
      </ion-item>

      <ion-item
        style="
          margin-left: 10px;
          margin-right: 10px;
          border-radius: 10px;
          margin-top: 10px;
          cursor: pointer;
        "
        lines="none"
        color="light"
        (click)="navigateCouponsPage()"
        *ngIf="currentUser && groupApp && groupApp.pointsSystem"
      >
        <ion-icon size="small" name="ticket"></ion-icon>
        <ion-label style="margin-left: 10px"
          >{{"reservation-app.user-coupons"|translate}}</ion-label
        >
        <ion-icon
          color="primary"
          slot="end"
          name="chevron-forward-outline"
        ></ion-icon>
      </ion-item>
      <!-- <ion-item
        style="
          margin-left: 10px;
          margin-right: 10px;
          border-radius: 10px;
          margin-top: 10px;
          cursor: pointer;
        "
        lines="none"
        color="light"
      >
        <ion-icon size="small" name="business"></ion-icon>
        <ion-label style="margin-left: 10px"
          >{{"reservation-app.whoWeAre"|translate}}</ion-label
        >
        <ion-icon
          color="primary"
          slot="end"
          name="chevron-forward-outline"
        ></ion-icon>
      </ion-item> -->
      <ion-item
        style="
          margin-left: 10px;
          margin-right: 10px;
          border-radius: 10px;
          margin-top: 10px;
          cursor: pointer;
        "
        lines="none"
        color="light"
        (click)="openGroupInfo()"
        class="clickable-item"
      >
        <ion-icon size="small" name="call"></ion-icon>
        <ion-label style="margin-left: 10px"
          >{{"reservation-app.contact"|translate}}</ion-label
        >
        <ion-icon
          color="primary"
          slot="end"
          name="chevron-forward-outline"
        ></ion-icon>
      </ion-item>
      <ion-item
        style="
          margin-left: 10px;
          margin-right: 10px;
          border-radius: 10px;
          margin-top: 10px;
          cursor: pointer;
        "
        lines="none"
        color="light"
        (click)="openTerms()"
      >
        <ion-icon size="small" name="document-text"></ion-icon>
        <ion-label style="margin-left: 10px"
          >{{"reservation-app.terms"|translate}}</ion-label
        >
        <ion-icon
          color="primary"
          slot="end"
          name="chevron-forward-outline"
        ></ion-icon>
      </ion-item>
      <ion-item
        style="
          margin-left: 10px;
          margin-right: 10px;
          border-radius: 10px;
          margin-top: 10px;
          cursor: pointer;
        "
        lines="none"
        color="light"
        *ngIf="currentUser"
        (click)="logout()"
      >
        <ion-icon size="small" name="log-out"></ion-icon>
        <ion-label style="margin-left: 10px"
          >{{"reservation-app.logout"|translate}}</ion-label
        >
        <ion-icon
          color="primary"
          slot="end"
          name="chevron-forward-outline"
        ></ion-icon>
      </ion-item>
    </ion-list>
    <ion-item
      (click)="openBrowserURL('kioskpos.gr')"
      button="true"
      detail="false"
      lines="none"
      class="ion-text-center"
      style="margin-top: 15px"
    >
      <ion-label
        class="ion-text-wrap"
        style="font-weight: bold; font-size: small; line-height: 1.2"
        >Powered by kioskpos.gr
        <div>
          <span>v{{ version }} © DM SOFT IKE</span>
        </div>
      </ion-label>
    </ion-item>
  </ion-grid>
</ion-content>
