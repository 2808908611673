<ion-card>
  <ion-item-divider mode="ios">
    <ion-label>
      {{ "cart.cart-components.cart-list.order-list" | translate }}
    </ion-label>
  </ion-item-divider>

  <app-cart-prices></app-cart-prices>

  <ion-item lines="full" *ngIf="amountToMinimumOrder">
    <ion-label class="ion-text-wrap ion-text-center">
      <div>
        <ion-text color="danger">
          {{ "cart.cart-components.cart-list.you-need" | translate }}
          {{ amountToMinimumOrder }}€
          {{
            "cart.cart-components.cart-list.in-order-to-reach-the-min-order"
              | translate
          }}
        </ion-text>
      </div>
      <ion-progress-bar
        *ngIf="amountToMinimumOrderProgressValue"
        color="primary"
        [value]="amountToMinimumOrderProgressValue / 100"
      >
      </ion-progress-bar>
    </ion-label>
  </ion-item>

  <ion-row *ngIf="cartProducts && !cartProducts.length" style="width: 100%">
    <ion-col size="12">
      <ion-item lines="none" style="min-height: 0px">
        <ion-label class="ion-text-wrap ion-text-center">
          <ion-text color="dark" style="font-size: medium; font-weight: bold">
            {{
              "cart.cart-components.cart-list.your-cart-is-empty" | translate
            }}</ion-text
          >
        </ion-label>
      </ion-item>
    </ion-col>
  </ion-row>

  <ion-row style="width: 100%" class="ion-align-items-center">
    <ion-col size="12" class="ion-text-center">
      <ion-button
        style="min-width: 240px"
        [disabled]="
          finalPriceLoading ||
          checkAddressLoading ||
          manualFinalPriceLoading ||
          preorderDaysLoading ||
          checkLocationDineInLoading
        "
        (click)="completeOrder()"
        >{{
          !finalPriceLoading &&
          !checkAddressLoading &&
          !manualFinalPriceLoading &&
          !preorderDaysLoading &&
          !checkLocationDineInLoading
            ? ("cart.cart-components.cart-list.checkout" | translate)
            : ""
        }}
        <ion-spinner
          *ngIf="
            finalPriceLoading ||
            checkAddressLoading ||
            manualFinalPriceLoading ||
            preorderDaysLoading ||
            checkLocationDineInLoading
          "
        ></ion-spinner>
      </ion-button>
    </ion-col>
  </ion-row>

  <ion-row style="width: 100%">
    <ion-col
      size="12"
      class="ion-text-center"
      *ngIf="
        cartInfoMessagesGroup &&
        cartInfoMessagesGroup.inline &&
        (cartInfoMessagesGroup.inline
          | filterInlineCartInfoMessages : cartPrices : 'cart-list') &&
        (
          cartInfoMessagesGroup.inline
          | filterInlineCartInfoMessages : cartPrices : 'cart-list'
        ).length &&
        groupApp &&
        selectedLang
      "
    >
      <ion-label
        *ngFor="
          let infoMessage of cartInfoMessagesGroup.inline
            | filterInlineCartInfoMessages : cartPrices : 'cart-list'
        "
        class="ion-text-wrap"
      >
        <div
          [innerHTML]="
            infoMessage.title[selectedLang]
              ? infoMessage.title[selectedLang]
              : infoMessage.title[mo_base]
              ? infoMessage.title[mo_base]
              : infoMessage.title['en']
          "
          *ngIf="infoMessage && infoMessage.title"
        ></div>
        <p
          [innerHTML]="
            infoMessage.description[selectedLang]
              ? infoMessage.description[selectedLang]
              : infoMessage.description[mo_base]
              ? infoMessage.description[mo_base]
              : infoMessage.description['en']
          "
          style="margin: 0"
          *ngIf="infoMessage && infoMessage.description"
        ></p>
      </ion-label>
    </ion-col>
    <ion-col
      size="12"
      class="ion-text-center"
      *ngIf="
        cartInfoMessagesStore &&
        cartInfoMessagesStore.inline &&
        (cartInfoMessagesStore.inline
          | filterInlineCartInfoMessages : cartPrices : 'cart-list') &&
        (
          cartInfoMessagesStore.inline
          | filterInlineCartInfoMessages : cartPrices : 'cart-list'
        ).length &&
        groupApp &&
        selectedLang
      "
    >
      <ion-label
        *ngFor="
          let infoMessage of cartInfoMessagesStore.inline
            | filterInlineCartInfoMessages : cartPrices : 'cart-list'
        "
        class="ion-text-wrap"
      >
        <div
          [innerHTML]="
            infoMessage.title[selectedLang]
              ? infoMessage.title[selectedLang]
              : infoMessage.title[mo_base]
              ? infoMessage.title[mo_base]
              : infoMessage.title['en']
          "
          *ngIf="infoMessage && infoMessage.title"
        ></div>
        <p
          [innerHTML]="
            infoMessage.description[selectedLang]
              ? infoMessage.description[selectedLang]
              : infoMessage.description[mo_base]
              ? infoMessage.description[mo_base]
              : infoMessage.description['en']
          "
          style="margin: 0"
          *ngIf="infoMessage && infoMessage.description"
        ></p>
      </ion-label>
    </ion-col>
  </ion-row>

  <app-cart-list-product-item
    *ngFor="let product of cartProducts; trackBy: trackFnc"
    [cartProduct]="product"
    [selectedAddress]="selectedAddress"
  >
  </app-cart-list-product-item>

  <ion-row
    style="width: 100%"
    *ngIf="
      (cartProducts && cartProducts.length) ||
      (storeInfo && storeInfo.staticPagesAllergensActive) ||
      (!storeInfo.staticPagesAllergensActive &&
        storeInfo.allergensDownloadFileActive)
    "
  >
    <ion-col *ngIf="cartProducts && cartProducts.length > 0">
      <ion-item class="buttonItem" lines="none">
        <ion-row style="width: 100%" class="ion-no-padding ion-no-margin">
          <ion-col class="ion-text-center ion-no-padding ion-no-margin">
            <ion-button (click)="clearCart()" color="danger" fill="clear">
              {{ "cart.cart-components.cart-list.clear-cart" | translate }}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-item>
    </ion-col>

    <ion-col
      *ngIf="
        storeInfo &&
        (storeInfo.staticPagesAllergensActive ||
          storeInfo.allergensDownloadFileActive)
      "
    >
      <ion-item lines="none" class="buttonItem">
        <ion-row style="width: 100%" class="ion-no-padding ion-no-margin">
          <ion-col class="ion-text-center ion-no-padding ion-no-margin">
            <ion-button
              (click)="showAllergensModal()"
              size="small"
              fill="clear"
            >
              <ion-icon
                style="
                  vertical-align: middle;
                  font-size: 18px;
                  margin-right: 3px;
                "
                src="assets/ionicons/information-circle-outline.svg"
              >
              </ion-icon>
              <ion-text>
                {{
                  "cart.cart-components.cart-list.allergens" | translate
                }}</ion-text
              >
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-item>
    </ion-col>
  </ion-row>
</ion-card>
