import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Subscription } from "rxjs";
import {
  auditTime,
  debounceTime,
  distinctUntilChanged,
  first,
} from "rxjs/operators";
import * as selectors from "../../../../../store/app.selectors";
import * as _ from "lodash";
import * as fromApp from "../../../../../store/app.reducer";
import { Store } from "@ngrx/store";

import { ModalController, Platform } from "@ionic/angular";
import { BundleOptionRadioClosedChoicesModalComponent } from "./bundle-option-radio-closed-choices-modal/bundle-option-radio-closed-choices-modal.component";
import { ProductDetailsComponent } from "src/app/product-details/product-details.component";
import * as ProductOptionsValidationActions from "../../../../store/productOptionsValidation/productOptionsValidation.actions";
import { ProductDetailsOperationsService } from "src/app/product-details/product-details-operations.service";
declare let window: any;

@Component({
  selector: "app-bundle-option-radio-closed-choices",
  templateUrl: "./bundle-option-radio-closed-choices.component.html",
  styleUrls: ["./bundle-option-radio-closed-choices.component.scss"],
})
export class BundleOptionRadioClosedChoicesComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() selectedProductId: any;
  @Input() product_id: any;
  @Input() bundleMode: any;
  @Input() option_id;
  @Input() selectedProductPromo: any;
  @Input() selectedProductCategory: any;
  @Input() selectedProductFreeParentOption: any;
  @Output() optionChange = new EventEmitter();
  @Input() selectedProductParentDiscount;
  @Input() optionValidationObject;
  @Input() rootProductParentBundleChoice;
  @Input() groupStoreData;

  openProductModalBundleChoice;
  public initialOpenSelection = true; //this variable is used for preselected choices that is preselected from the system!
  public option: any;
  public selectedChoice: any;
  public orderPickup;
  public dineIn;
  public selectedLanguage: any;
  public bundleOptionLoading = false;

  private optionValidations: any;
  private lastValidTimestamp: any = null;
  private bundleChoicesModal: any;
  private unsubscribeBackEvent: Subscription;
  private subscriptions: Subscription[] = [];
  private optionSubscription: Subscription;
  private productDetailsModal: any;
  bundleOptionLoadingArray;
  constructor(
    private store: Store<fromApp.AppState>,
    private modalCtrl: ModalController,
    private productDetailsOperationsService: ProductDetailsOperationsService,
    private platform: Platform
  ) {}

  selectOption() {
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
    this.optionSubscription = this.store
      .select(
        selectors.getOptionByOptionId(this.selectedProductId, this.option_id)
      )
      .pipe(distinctUntilChanged())
      .pipe(auditTime(50))
      .subscribe((state) => {
        if (!_.isEqual(this.option, state)) {
          let firstTime = false;

          if (!this.option) {
            firstTime = true;
          }
          this.option = _.cloneDeep(state);

          if (
            firstTime &&
            this.option &&
            this.option.choices &&
            this.initialOpenSelection &&
            _.find(this.option.choices, (ch) => {
              if (ch.is_preselected && _.isEmpty(ch.bundled_product)) {
                return true;
              }
            })
          ) {
            this.store.dispatch(
              new ProductOptionsValidationActions.AddBundleOptionLoading(
                _.cloneDeep(this.option.product_id_option_id)
              )
            );

            //Just for safety
            setTimeout(() => {
              this.store.dispatch(
                new ProductOptionsValidationActions.RemoveBundleOptionLoading(
                  _.cloneDeep(this.option.product_id_option_id)
                )
              );
            }, 6000);
            if (!this.bundleChoicesModal) {
              this.bundleChoicesModal = true;
              this.openSelectionModal(this.option.option_id);
            }
          }

          if (this.option && this.option.choices) {
            const preselectedCh = _.find(this.option.choices, {
              is_preselected: true,
            });
            this.selectedChoice = null;

            if (preselectedCh) {
              this.selectedChoice = _.cloneDeep(preselectedCh);
            }
          }
        }
      });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (
      simpleChanges &&
      simpleChanges.option_id &&
      simpleChanges.option_id.currentValue !==
        simpleChanges.option_id.previousValue
    ) {
      this.selectOption();
    }
  }

  ngOnInit() {
    console.log("bundle closed choices on init");
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.modalCtrl.dismiss();
        }
      }
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLanguage, state.selectedLangugage)
          ) {
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")

        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(this.dineIn, state.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }
          if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("productOptionsValidation")
        .pipe(distinctUntilChanged())
        .pipe(debounceTime(300))
        .subscribe((state) => {
          if (
            state &&
            state.lastValidTimestampBySelectedProductId &&
            state.lastValidTimestampBySelectedProductId[
              this.selectedProductId
            ] &&
            !_.isEqual(
              state.lastValidTimestampBySelectedProductId[
                this.selectedProductId
              ],
              this.lastValidTimestamp
            ) &&
            !_.isEmpty(
              state.optionValidationsBySelectedProductId[this.selectedProductId]
            )
          ) {
            this.lastValidTimestamp = _.cloneDeep(
              state.lastValidTimestampBySelectedProductId[
                this.selectedProductId
              ]
            );
            if (
              !_.isEmpty(
                state.optionValidationsBySelectedProductId[
                  this.selectedProductId
                ]
              )
            ) {
              this.optionValidations = _.cloneDeep(
                state.optionValidationsBySelectedProductId[
                  this.selectedProductId
                ]
              );

              let foundOptionToOpen = _.find(this.optionValidations, {
                optionToOpen: true,
                bundle_option_id: null,
              });
              console.log(
                "last valid timestamp changed",
                this.optionValidations,
                foundOptionToOpen
              );
              if (
                foundOptionToOpen &&
                this.option &&
                _.isEqual(foundOptionToOpen.option_id, this.option.option_id)
              ) {
                if (!this.bundleChoicesModal) {
                  this.bundleChoicesModal = true;
                  this.openSelectionModalDebounce();
                }
              }
            }
          }
          if (
            state &&
            state.openProductModalBundleChoice &&
            !_.isEqual(
              this.openProductModalBundleChoice,
              state.openProductModalBundleChoice
            )
          ) {
            this.openProductModalBundleChoice = _.cloneDeep(
              state.openProductModalBundleChoice
            );
            console.log(
              "inside openProductmodalbundle subscription",
              this.openProductModalBundleChoice
            );
            if (this.openProductModalBundleChoice.choice_id) {
              const bundleChoice = _.find(this.option.choices, {
                choice_id: this.openProductModalBundleChoice.choice_id,
              });
              console.log(
                "inside openProductmodalbundle subscription",
                this.openProductModalBundleChoice,
                bundleChoice
              );
              if (bundleChoice) {
                this.store
                  .select(
                    selectors.getSelectedProductBySelectedProductId(
                      this.selectedProductId
                    )
                  )
                  .pipe(first())
                  .subscribe((parentProduct) => {
                    this.showProductDetailsModal(
                      bundleChoice,
                      parentProduct,
                      _.cloneDeep(this.openProductModalBundleChoice.product),
                      bundleChoice.bundle_options,
                      _.cloneDeep(this.option),
                      false
                    );
                  });

                this.store.dispatch(
                  new ProductOptionsValidationActions.SetOpenProductModalBundleChoice(
                    { choice_id: null, product: null }
                  )
                );
              }
            }
          }

          if (
            state &&
            state.bundleOptionLoadingArray &&
            !_.isEqual(
              this.bundleOptionLoadingArray,
              state.bundleOptionLoadingArray
            )
          ) {
            this.bundleOptionLoadingArray = _.cloneDeep(
              state.bundleOptionLoadingArray
            );

            this.bundleOptionLoading = _.find(this.bundleOptionLoadingArray, {
              product_id_option_id: this.option.product_id_option_id,
            });
          }
        })
    );
  }

  optionChanged(opt) {
    console.log(opt);
    this.optionChange.emit(opt);
  }

  async openSelectionModal(option_id) {
    let cssClass = "radioClosedChoicesSelectionModal";
    if (
      this.initialOpenSelection &&
      _.size(_.filter(this.option.choices || [], { is_preselected: true })) ===
        1
    ) {
      cssClass = "display-none-product-details";
    }

    this.bundleChoicesModal = await this.modalCtrl.create({
      id: this.option.product_id_option_id,
      component: BundleOptionRadioClosedChoicesModalComponent,
      cssClass: cssClass,
      backdropDismiss: false,
      animated: true,

      componentProps: {
        option_id: option_id,
        selectedProductCategory: this.selectedProductCategory,
        selectedProductPromo: this.selectedProductPromo,
        bundleMode: this.bundleMode,
        selectedProductId: this.selectedProductId,
        groupStoreData: this.groupStoreData,
        initialOpenSelection: this.initialOpenSelection,
        modalId: this.option.product_id_option_id,
      },
    });

    await this.bundleChoicesModal.present();
    this.bundleChoicesModal.onDidDismiss().then((data) => {
      this.bundleChoicesModal = null;

      if (data && data.data) {
        if (
          !(
            this.initialOpenSelection &&
            _.size(
              _.filter(this.option.choices || [], { is_preselected: true })
            ) === 1
          )
        ) {
          //dalay to open the modal in order to play the closing animation of previous modal
          setTimeout(() => {
            this.openProductDetailsModal(_.cloneDeep(data.data));
          }, 200);
        } else {
          this.store.dispatch(
            new ProductOptionsValidationActions.RemoveBundleOptionLoading(
              _.cloneDeep(data.data.product_id_option_id)
            )
          );
        }
        this.initialOpenSelection = false;
      }
    });
  }

  openProductDetailsModal(newOption) {
    let selectedChoice = _.find(newOption.choices, { is_preselected: true });
    if (selectedChoice && selectedChoice.bundled_product) {
      this.store
        .select(
          selectors.getSelectedProductBySelectedProductId(
            this.selectedProductId
          )
        )
        .pipe(first())
        .subscribe((parentProduct) => {
          this.showProductDetailsModal(
            _.cloneDeep(selectedChoice),
            parentProduct,
            _.cloneDeep(selectedChoice.bundled_product),
            _.cloneDeep(selectedChoice.bundle_options),
            newOption,
            true
          );
        });
    }
  }

  calculateDetailsCssClass(product) {
    return this.productDetailsOperationsService.getProductDetailsModalCssClass(
      _.cloneDeep(product),
      _.cloneDeep({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    );
  }

  showProductDetailsModal(
    parentChoice,
    parentProduct,
    product,
    bundle_options,
    newOption,
    openingFromBundleChoicesSelection
  ) {
    let detailsCssClass = "productDetailsModal";
    console.log("product from show pr details mod", product);
    if (!this.productDetailsModal) {
      this.productDetailsModal = true;
      this.store
        .select(
          selectors.getSelectedProductBySelectedProductId(
            this.selectedProductId
          )
        )
        .pipe(first())
        .subscribe((parentProduct) => {
          this.presentProductDetailsModal(
            parentChoice,
            parentProduct,
            product,
            bundle_options,
            detailsCssClass,
            newOption,
            openingFromBundleChoicesSelection,
            null
          );
        });
    }
  }

  async presentProductDetailsModal(
    parentChoice,
    parentProduct,
    product,
    bundle_options,
    detailsCssClass,
    newOption,
    openingFromBundleChoicesSelection,
    add_bundle_product_to_cart
  ) {
    if (
      (product && product.num_of_options && product.num_of_options <= 1) ||
      (this.option &&
        this.option.bundle_options &&
        this.option.bundle_options.selectBundledProductInstant &&
        !add_bundle_product_to_cart)
    ) {
      //this is the case that we add a product to cart without showing the user product details modal
      //because we do not have options
      detailsCssClass = "display-none-product-details";
    }
    console.log("prd det comp");
    this.productDetailsModal = await this.modalCtrl.create({
      component: ProductDetailsComponent,
      cssClass: detailsCssClass,
      animated: true,
      componentProps: {
        bundleMode: "add",
        product_id: product.product_id,
        bundleOptions: bundle_options,
        parentBundleChoice: parentChoice,
        parentProduct: parentProduct,
        cart_id: null,
        openingFromBundleChoicesSelection: openingFromBundleChoicesSelection,
        parentBundleOption: _.cloneDeep(this.option),
        add_bundle_product_to_cart:
          add_bundle_product_to_cart === "no"
            ? false
            : (product &&
                product.num_of_options &&
                product.num_of_options <= 1) ||
              (this.option &&
                this.option.bundle_options &&
                this.option.bundle_options.selectBundledProductInstant)
            ? true
            : false,

        bundle_product_for_cart:
          product && product.num_of_options && product.num_of_options <= 1
            ? product
            : null,
      },
      backdropDismiss: false,
    });

    await this.productDetailsModal.present();
    await this.productDetailsModal.onDidDismiss().then((data) => {
      if (data && data.data && data.data === "option_required_error") {
        //we have an a bundle option that puts the choice products instant without open product details modal
        //but there is a required option that not have a preselected choice
        console.log("asdasdoption_required_error");
        detailsCssClass = this.calculateDetailsCssClass(product);
        this.presentProductDetailsModal(
          parentChoice,
          parentProduct,
          product,
          bundle_options,
          detailsCssClass,
          newOption,
          openingFromBundleChoicesSelection,
          "no"
        );
        return false;
      }

      if (data && data.data && data.data.product) {
        product = _.cloneDeep(data.data.product);
        product.productTotal = _.cloneDeep(data.data.price);
        const chIndex = _.findIndex(newOption.choices, {
          is_preselected: true,
        });
        if (
          chIndex !== -1 &&
          newOption.choices[chIndex] &&
          newOption.choices[chIndex].bundled_product &&
          newOption.choices[chIndex].bundled_product.product_id ===
            product.product_id
        ) {
          newOption.choices[chIndex].bundled_product = _.cloneDeep(product);
        }
      }
      console.log("newOption on dismiss", newOption);
      this.optionChanged(newOption);
      this.productDetailsModal = null;
    });
  }

  openSelectionModalDebounce = _.debounce(
    () => {
      this.openSelectionModal(this.option.option_id);
    },

    100
  );

  toFloat(num) {
    return parseFloat(num.toString());
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.bundleChoicesModal) {
      this.bundleChoicesModal.dismiss();
      this.bundleChoicesModal = null;
    }
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
  }
}
