import {
  AfterContentInit,
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import * as selectors from "../../../../store/app.selectors";
import * as _ from "lodash";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import { ModalController } from "@ionic/angular";
import { ProductDetailsComponent } from "src/app/product-details/product-details.component";
import { Subscription } from "rxjs";
import { ProductDetailsOperationsService } from "src/app/product-details/product-details-operations.service";
declare let window: any;

@Component({
  selector: "app-product-item-browser-overflow-image",
  templateUrl: "./product-item-browser-overflow-image.component.html",
  styleUrls: ["./product-item-browser-overflow-image.component.scss"],
})
export class ProductItemBrowserOverflowImageComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() cartProductIds: any;
  @Input() product_id;
  @Input() category_id;
  @Input() orderPickup: any;
  @Input() dineIn: any;
  @Input() hyperCategories: any;
  @Input() groupStoreData: any;
  @Input() favoriteProduct: boolean;
  @Input() selectedDineInTable;
  @Input() selectedPreorderDate;

  public product: any;
  public browserPlatform: any;
  public productInCart: boolean = false;
  public productInCartCounter: number = 0;
  public imageUrl;

  private productSub: Subscription;
  private productDetailsModal: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private productDetailsOperationsService: ProductDetailsOperationsService,
    private modalCtrl: ModalController
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.product_id &&
      changes.product_id.currentValue !== changes.product_id.previousValue &&
      changes.product_id.previousValue
    ) {
      if (this.productSub) {
        this.productSub.unsubscribe();
      }
      if (this.hyperCategories && this.hyperCategories.length > 0) {
        this.productSub = this.store
          .select(
            selectors.getProductItemByCategoryIdProductIdForHyperCategories(
              this.category_id,
              this.product_id
            )
          )
          .pipe(distinctUntilChanged())

          .subscribe((prd) => {
            if (prd && !_.isEqual(prd, this.product)) {
              this.product = _.cloneDeep(prd);
              this.imageUrl =
                "https://images.deliverymanager.gr/" +
                this.product.image.true["4x3"].path +
                (this.groupStoreData.webp
                  ? ".webp"
                  : this.product.image.true["4x3"].type || ".jpg");
              this.unsubscibeSelectorDebounced();
            }
          });
      } else {
        this.productSub = this.store
          .select(
            !this.favoriteProduct
              ? selectors.getProductItemByCategoryIdProductId(
                  this.category_id,
                  this.product_id
                )
              : selectors.getProductItemAllCategoriesByCategoryIdProductId(
                  this.category_id,
                  this.product_id
                )
          )
          .pipe(distinctUntilChanged())

          .subscribe((prd) => {
            if (prd && !_.isEqual(prd, this.product)) {
              this.product = _.cloneDeep(prd);
              this.imageUrl =
                "https://images.deliverymanager.gr/" +
                this.product.image.true["4x3"].path +
                (this.groupStoreData.webp
                  ? ".webp"
                  : this.product.image.true["4x3"].type || ".jpg");
              this.unsubscibeSelectorDebounced();
            }
          });
      }
    }
    if (
      changes &&
      changes.cartProductIds &&
      changes.cartProductIds.currentValue !==
        changes.cartProductIds.previousValue &&
      changes.cartProductIds.previousValue
    ) {
      this.checkIfProductIsAddedToCart();
    }
  }

  ngOnInit() {
    if (this.hyperCategories && this.hyperCategories.length > 0) {
      this.productSub = this.store
        .select(
          selectors.getProductItemByCategoryIdProductIdForHyperCategories(
            this.category_id,
            this.product_id
          )
        )
        .pipe(distinctUntilChanged())

        .subscribe((prd) => {
          if (prd && !_.isEqual(prd, this.product)) {
            this.product = _.cloneDeep(prd);
            this.imageUrl =
              "https://images.deliverymanager.gr/" +
              this.product.image.true["4x3"].path +
              (this.groupStoreData.webp
                ? ".webp"
                : this.product.image.true["4x3"].type || ".jpg");
            this.unsubscibeSelectorDebounced();
          }
        });
    } else {
      this.productSub = this.store
        .select(
          !this.favoriteProduct
            ? selectors.getProductItemByCategoryIdProductId(
                this.category_id,
                this.product_id
              )
            : selectors.getProductItemAllCategoriesByCategoryIdProductId(
                this.category_id,
                this.product_id
              )
        )
        .pipe(distinctUntilChanged())

        .subscribe((prd) => {
          if (prd && !_.isEqual(prd, this.product)) {
            this.product = _.cloneDeep(prd);

            this.imageUrl =
              "https://images.deliverymanager.gr/" +
              this.product.image.true["4x3"].path +
              (this.groupStoreData.webp
                ? ".webp"
                : this.product.image.true["4x3"].type || ".jpg");
            this.unsubscibeSelectorDebounced();
          }
        });
    }

    this.checkIfProductIsAddedToCart();
  }

  checkIfProductIsAddedToCart() {
    this.productInCartCounter = 0;
    if (this.cartProductIds && this.product) {
      this.productInCart = _.includes(
        this.cartProductIds,
        this.product.product_id
      );

      this.productInCartCounter = _.filter(this.cartProductIds, (id) => {
        if (id === this.product.product_id) {
          return id;
        }
      }).length;
    }
  }

  private unsubscibeSelectorDebounced = _.debounce(() => {
    this.unsubscribeSelector();
  }, 1000);

  unsubscribeSelector() {
    if (this.productSub) {
      this.productSub.unsubscribe();
    }
  }

  prdWithoutImage(product) {
    return (
      !product.image ||
      (product.image && !product.image.true) ||
      (product.image && !product.image.true["16x9"]) ||
      (product.image &&
        product.image.true["16x9"] &&
        !product.image.true["16x9"].path)
    );
  }

  showProductDetailsModal(product) {
    const detailsCssClass =
      this.productDetailsOperationsService.getProductDetailsModalCssClass(
        _.cloneDeep(product),
        _.cloneDeep({
          height: window.innerHeight,
          width: window.innerWidth,
        })
      );
    if (!this.productDetailsModal) {
      this.productDetailsModal = true;
      this.presentProductDetailsModal(product, detailsCssClass);
    }
  }

  async presentProductDetailsModal(product, detailsCssClass) {
    let product_id: string;
    let cart_id: string;
    if (product && product.product_id) {
      product_id = product.product_id;
    }

    if (product && product.cart_id) {
      cart_id = product.product_id;
    }

    this.productDetailsModal = await this.modalCtrl.create({
      component: ProductDetailsComponent,
      cssClass: detailsCssClass,
      animated: true,
      componentProps: {
        product_id: product_id,
        cart_id: cart_id,
      },
      backdropDismiss: false,
    });

    await this.productDetailsModal.present();
    this.productDetailsModal.onDidDismiss().then(() => {
      this.productDetailsModal = null;
    });
  }

  trackFc(index, item) {
    return index;
  }

  ngOnDestroy() {
    if (this.productDetailsModal) {
      this.productDetailsModal.dismiss();
      this.productDetailsModal = null;
    }
    this.product = null;
    this.imageUrl = null;
    if (this.productSub) {
      this.productSub.unsubscribe();
    }
  }
}
