import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import * as _ from "lodash";
import * as selectors from "../../../../../../store/app.selectors";
import * as fromApp from "../../../../../../store/app.reducer";
import {
  auditTime,
  debounceTime,
  distinctUntilChanged,
  first,
} from "rxjs/operators";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { NavigationEnd, Router } from "@angular/router";
import { KeyValue } from "@angular/common";

@Component({
  selector: "app-bundle-option-radio-choices",
  templateUrl: "./bundle-option-radio-choices.component.html",
  styleUrls: ["./bundle-option-radio-choices.component.scss"],
})
export class BundleOptionRadioChoicesComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() selectedProductId: any;
  @Input() bundleMode: any;
  @Input() option_id;
  @Input() selectedProductPromo: any;
  @Input() selectedProductCategory: any;
  @Input() groupStoreData: any;
  @Output() choicesChanged = new EventEmitter();
  @Input() autoSelectChoice: any;
  @Input() choicePreselectedId: any;

  public option: any;
  public loading: boolean = false;
  public browserPlatform: boolean;
  public imagePath;
  public selectedChoiceId;
  public productDetailsModalDimensions: any;
  public colSize: string;
  public categories: any;
  public categoriesByCategoryIdWithProducts: any = {};
  public categoriesByCategoryIdWithProductsArray: any;
  private hyperCategories: any;
  private calculateCategoryArraysNow = false;
  private Subscriptions: Subscription[] = [];
  private optionSubscription: Subscription;
  private hyperCategoriesSub: Subscription;

  constructor(private store: Store<fromApp.AppState>, private router: Router) {}

  selectOption() {
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
    this.optionSubscription = this.store
      .select(
        selectors.getOptionByOptionId(this.selectedProductId, this.option_id)
      )
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (!_.isEqual(this.option, state)) {
          this.option = _.cloneDeep(state);

          if (this.option.choices) {
            this.option.choices = _.orderBy(
              _.cloneDeep(this.option.choices),
              ["category_id"],
              "asc"
            );
          }
          if (this.categories) {
            this.calculateCategoryArrays(true);
          } else if (this.calculateCategoryArraysNow) {
            this.calculateCategoryArraysNow = false;
            this.calculateCategoryArrays(false);
          }

          if (!this.selectedChoiceId) {
            this.checkRadioValue();
          }
        }
      });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (
      simpleChanges &&
      simpleChanges.option_id &&
      simpleChanges.option_id.currentValue !==
        simpleChanges.option_id.previousValue
    ) {
      this.selectOption();
    }
  }

  ngOnInit() {
    this.checkIfWeAreInCatalog();
    this.selectOption();
    this.Subscriptions.push(
      this.store
        .select("platformInfo")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.browserPlatform, this.browserPlatform)
          ) {
            this.browserPlatform = _.cloneDeep(state.browserPlatform);
          }
        })
    );

    this.Subscriptions.push(
      this.store
        .select(
          selectors.getSelectedProductModalDimensionsBySelectedProductId(
            this.selectedProductId
          )
        )
        .pipe(distinctUntilChanged())

        .subscribe((productDetailsModalDimensions) => {
          if (
            productDetailsModalDimensions &&
            !_.isEqual(
              this.productDetailsModalDimensions,
              productDetailsModalDimensions
            )
          ) {
            this.productDetailsModalDimensions = _.cloneDeep(
              productDetailsModalDimensions
            );
            this.calculateColumnsSize();
          }
        })
    );
  }
  checkForImage(choice) {
    if (
      choice.image &&
      choice.image[_.toString(this.browserPlatform)] &&
      choice.image[_.toString(this.browserPlatform)]["4x3"] &&
      choice.image[_.toString(this.browserPlatform)]["4x3"].path
    ) {
      this.imagePath =
        "https://images.deliverymanager.gr/" +
        choice.image[_.toString(this.browserPlatform)]["4x3"].path +
        (true
          ? ".webp"
          : choice.image[_.toString(this.browserPlatform)]["4x3"].type ||
            ".jpg");

      return true;
    } else {
      return false;
    }
  }

  checkIfWeAreInCatalog() {
    // Hide progress spinner or progress bar

    if (this.router.url.includes("catalog")) {
      this.hyperCategoriesSub = this.store
        .select("hyperCategories")
        .pipe(auditTime(200))
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.hyperCategories, this.hyperCategories)
          ) {
            this.hyperCategories = _.cloneDeep(state.hyperCategories);
            if (this.hyperCategories && !this.hyperCategories.length) {
              this.subscribeToCategories(false);
            } else if (this.hyperCategories && this.hyperCategories.length) {
              this.subscribeToCategories(true);
            }
          }
        });
    } else {
      //calculate categories for bundle products
      if (this.option) {
        this.calculateCategoryArrays(false);
      } else {
        this.calculateCategoryArraysNow = true;
      }
    }
  }

  subscribeToCategories(hyperCategoriesActive) {
    if (this.hyperCategoriesSub) {
      this.hyperCategoriesSub.unsubscribe();
    }
    if (hyperCategoriesActive) {
      this.Subscriptions.push(
        this.store
          .select("catalog")
          .pipe(distinctUntilChanged())
          .subscribe((state) => {
            if (
              state &&
              state.allCategories &&
              !_.isEqual(this.categories, state.allCategories)
            ) {
              this.categories = _.cloneDeep(state.allCategories);
              if (this.option) {
                this.calculateCategoryArrays(true);
              }
            }
          })
      );
    } else {
      this.Subscriptions.push(
        this.store
          .select("catalog")
          .pipe(distinctUntilChanged())
          .subscribe((state) => {
            if (
              state &&
              state.catalog &&
              !_.isEqual(this.categories, state.catalog)
            ) {
              this.categories = _.cloneDeep(state.catalog);
              if (this.option) {
                this.calculateCategoryArrays(true);
              }
            }
          })
      );
    }
  }

  calculateCategoryArrays(categoriesExistsInState) {
    let allCategories;
    if (categoriesExistsInState) {
      allCategories = _.cloneDeep(this.categories);
    } else {
      allCategories = [];
      _.each(this.option.choices, (choice) => {
        if (choice && choice.bundle && choice.bundled_product) {
          allCategories.push(_.cloneDeep(choice.bundled_product_category));
        }
      });
    }

    _.each(this.option.choices, (choice) => {
      if (choice && choice.bundle && choice.bundled_product) {
        if (
          !this.categoriesByCategoryIdWithProducts[
            choice.bundled_product.category_id
          ]
        ) {
          this.categoriesByCategoryIdWithProducts[
            choice.bundled_product.category_id
          ] = {};
        }
        let category = _.find(allCategories, {
          category_id: choice.bundled_product.category_id,
        });
        if (category) {
          this.categoriesByCategoryIdWithProducts[
            choice.bundled_product.category_id
          ].name = _.cloneDeep(category.name);
        }

        if (
          !this.categoriesByCategoryIdWithProducts[
            choice.bundled_product.category_id
          ].choices
        ) {
          this.categoriesByCategoryIdWithProducts[
            choice.bundled_product.category_id
          ].choices = [];
        }
        if (
          _.findIndex(
            this.categoriesByCategoryIdWithProducts[
              choice.bundled_product.category_id
            ].choices,
            { choice_id: choice.choice_id }
          ) === -1
        ) {
          this.categoriesByCategoryIdWithProducts[
            choice.bundled_product.category_id
          ].choices.push(_.cloneDeep(choice));
        }
      }
    });

    if (
      this.option.bundle_options &&
      this.option.bundle_options.load_category_choice_products &&
      this.option.bundle_options.choiceCategoriesProducts &&
      this.option.bundle_options.choiceCategoriesProducts.length
    ) {
      _.each(
        this.option.bundle_options.choiceCategoriesProducts,
        (categoryChoiceProduct, key) => {
          if (
            this.categoriesByCategoryIdWithProducts &&
            !_.isEmpty(this.categoriesByCategoryIdWithProducts) &&
            this.categoriesByCategoryIdWithProducts[
              categoryChoiceProduct.category_id
            ]
          ) {
            this.categoriesByCategoryIdWithProducts[
              categoryChoiceProduct.category_id
            ].position = key;
          }
        }
      );
    }

    if (
      this.categoriesByCategoryIdWithProducts &&
      !_.isEmpty(this.categoriesByCategoryIdWithProducts)
    ) {
      this.categoriesByCategoryIdWithProductsArray = [];
      for (const property in this.categoriesByCategoryIdWithProducts) {
        this.categoriesByCategoryIdWithProductsArray.push(
          this.categoriesByCategoryIdWithProducts[property]
        );
      }
      if (
        this.categoriesByCategoryIdWithProductsArray &&
        this.categoriesByCategoryIdWithProductsArray.length
      ) {
        this.categoriesByCategoryIdWithProductsArray = _.cloneDeep(
          _.orderBy(
            this.categoriesByCategoryIdWithProductsArray,
            ["position"],
            "asc"
          )
        );
      }
    }

    console.log("calcualtaions", this.categoriesByCategoryIdWithProductsArray);
  }

  onChoiceSelectedChange(ev) {
    this.selectedChoiceId = ev;
    if (this.selectedChoiceId) {
      let choice_id = _.cloneDeep(this.selectedChoiceId);

      let changedChoiceIndex = _.findIndex(this.option.choices, {
        choice_id: choice_id,
      });
      console.log("changedChoiceIndex", changedChoiceIndex);
      if (changedChoiceIndex !== -1) {
        if (
          this.option.choices[changedChoiceIndex].activeTotal &&
          this.option.choices[changedChoiceIndex].activeTotal
        ) {
          _.each(this.option.choices, (ch) => {
            if (ch.is_preselected) {
              ch.is_preselected = false;
            }
          });
          this.option.choices[changedChoiceIndex].is_preselected = true;

          this.sendOptionChangedChoicesDebounce();
        } else {
          console.log("something went wrong");
        }
      }
    } else {
      _.each(this.option.choices, (ch) => {
        if (ch.is_preselected) {
          ch.is_preselected = false;
        }
      });

      this.sendOptionChangedChoicesDebounce();
    }
  }

  private sendOptionChangedChoicesDebounce = _.debounce(() => {
    console.log("send Option Changed");
    this.sendOptionChangedChoices(this.option);
  }, 400);

  sendOptionChangedChoices(opt) {
    this.choicesChanged.emit(_.cloneDeep(opt));
  }

  trackCategories(index, item) {
    return item.category_id;
  }

  trackFn(index, choice) {
    return choice.option_id_choice_id;
  }

  checkRadioValue() {
    if (this.option) {
      this.option.choices.forEach((choice) => {
        if (choice.is_preselected) {
          console.log("choice id frim checking radio", choice.choice_id);

          this.selectedChoiceId = _.cloneDeep(choice.choice_id);
        }
      });
    }
  }

  calculateColumnsSize() {
    console.log("calc columns size called", this.productDetailsModalDimensions);
    if (this.option && this.option.choices && this.option.choices.length > 1) {
      let maxChoiceTitleChars = 0;
      let choiceImage = false;
      _.each(this.option.choices, (ch) => {
        if (ch.title && ch.title.length) {
          if (maxChoiceTitleChars < ch.title.length) {
            maxChoiceTitleChars = ch.title.length;
          }
        }
        if (this.checkForImage(ch)) {
          choiceImage = true;
        }
      });
      console.log("maxChoiceChars", maxChoiceTitleChars);

      if (this.productDetailsModalDimensions.width <= 400) {
        this.colSize = "12";
        //console.log("colsize", this.colSize);
      } else if (
        this.productDetailsModalDimensions.width > 400 &&
        this.productDetailsModalDimensions.width < 600
      ) {
        this.colSize = "6";
        //console.log("colsize", this.colSize);
      } else if (
        this.productDetailsModalDimensions.width >= 600 &&
        maxChoiceTitleChars < 15 &&
        !choiceImage
      ) {
        this.colSize = "4";
        //console.log("colsize", this.colSize);
      } else if (
        this.productDetailsModalDimensions.width >= 600 &&
        maxChoiceTitleChars > 15
      ) {
        this.colSize = "6";
        //console.log("colsize", this.colSize);
      } else {
        this.colSize = "6";
      }
    }
  }

  ngOnDestroy() {
    if (this.Subscriptions && this.Subscriptions.length > 0) {
      this.Subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
    if (this.hyperCategoriesSub) {
      this.hyperCategoriesSub.unsubscribe();
    }
  }
}
