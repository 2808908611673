import { Component, OnDestroy, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import {
  auditTime,
  debounceTime,
  distinctUntilChanged,
  first,
  retry,
} from "rxjs/operators";
import { Capacitor } from "@capacitor/core";

import { DataStorageService } from "../services/data-storage/data-storage.service";
import * as fromApp from "../store/app.reducer";
import * as CatalogActions from "./../catalog/store/catalog.actions";
import * as selectors from "../store/app.selectors";
import { ActivatedRoute, Router } from "@angular/router";
import * as CurrentStoreActions from "../store/current-store/current-store.actions";
import { TranslateService } from "@ngx-translate/core";
import * as HyperCategoriesActions from "../store/hyperCategories/hyperCategories.actions";
import * as DeliveryMethodAddressActions from "../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import { AnimationOptions } from "ngx-lottie";
import * as FavoriteProductsActions from "../store/favorite-products/favoriteProducts.actions";
import { InvoiceListModalComponent } from "../cart/cart-components/invoice-list-modal/invoice-list-modal.component";
import { AnalyticsServiceService } from "../services/analitycsService/analytics-service.service";
declare let window: any;
@Component({
  selector: "app-favorite-products",
  templateUrl: "./favorite-products.page.html",
  styleUrls: ["./favorite-products.page.scss"],
})
export class FavoriteProductsPage implements OnInit, OnDestroy {
  public windowDimensions: any;
  public mobilePlatform: any = null;
  public loading: boolean = false;
  public favoriteProducts: any;
  public hyperCategories: any;
  public groupApp: any;
  public store_info: any;
  public stores: any;
  public selectedStoreId: any;
  public finalPriceLoading: boolean = false;
  public previousProductsInCart: any;
  public cartLength: any;
  public customerPricelist: any;
  public cartProducts: any;
  public isIosPlatform: any;
  public cartProductsNumber: number;
  public languagePath: any;
  public menuMinWidthToShow: any;
  public migration_customer_id: any;
  public loginState;
  public allCategoriesMarketCardsDesign;
  public selectedInvoice: any;
  public currentUser: any;

  private changedFromUser: boolean = false;
  private currentStoreId: any;
  private catalog: any;
  private categories: any;
  private subCategoriesById: any;
  private selectedHyperCategory: any;

  private catalogZeroPricesMode: boolean = false;
  private selectedPreorderDate: any;
  private selectedAddress: any;
  private migrationCustomerIdSubscription: Subscription;
  private subscriptions: Subscription[] = [];
  private unsubscribeBackEvent: Subscription;
  private currentStoreSub: Subscription;
  private pricelistsSub: Subscription;
  private loginStateSub: Subscription;
  private selectedInvoiceModal: any;

  AnimationNoOrderoptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/no-favorites-animation.json",
  };

  constructor(
    private dataStorageService: DataStorageService,
    private store: Store<fromApp.AppState>,
    private translateService: TranslateService,
    private toastController: ToastController,
    private modalCtrl: ModalController,
    private analyticsService: AnalyticsServiceService,
    private platform: Platform,
    private router: Router,
    private alertController: AlertController
  ) {}

  ionViewDidEnter() {
    if (
      this.platform.is("ios") ||
      this.platform.is("iphone") ||
      this.platform.is("ipad")
    ) {
      this.isIosPlatform = true;
      // console.log("isIos cart icon");
    }
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      100,
      () => {
        this.navigateToHome();
      }
    );

    this.analyticsService.fbqTrackPageView();
    this.analyticsService.setCurrentScreenFirebase("favorites");
    this.analyticsService.tikTokPixelPageView("favorites");

    this.loginStateSub = this.store
      .select("auth")
      .pipe(distinctUntilChanged())
      .subscribe((auth) => {
        if (
          auth &&
          auth.loginState &&
          !_.isEqual(auth.loginState, this.loginState)
        ) {
          this.loginState = _.cloneDeep(auth.loginState);

          if (this.loginState === "login_completed") {
            this.store
              .select(selectors.getStoreInfo)
              .pipe(first())
              .subscribe((currentStore) => {
                if (
                  currentStore &&
                  currentStore.vatBasedCustomerProductsActive
                ) {
                  this.subscribeToMigrationCustomerId(true);
                } else {
                  this.fetchFavorites(null, null);
                }
              });
          } else if (this.loginState === "no_login") {
            this.router.navigateByUrl("/homepage", {
              replaceUrl: true,
            });
          }
        }
      });
  }

  initActions() {
    console.log("initActions");
    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);

            this.menuMinWidthToShow = getComputedStyle(
              document.documentElement
            ).getPropertyValue("--menuMinWidthToShow");
            if (this.menuMinWidthToShow) {
              this.menuMinWidthToShow = parseInt(this.menuMinWidthToShow);
            }

            if (this.windowDimensions && this.windowDimensions.width) {
              if (this.windowDimensions.width < 850) {
                this.mobilePlatform = true;
              } else {
                this.mobilePlatform = false;
              }
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("selectedInvoice")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.selectedInvoice, state.selectedInvoice)
          ) {
            this.selectedInvoice = _.cloneDeep(state.selectedInvoice);
          }
        })
    );

    console.log("favorites sub");
    this.subscriptions.push(
      this.store
        .select("favoriteProducts")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.favoriteProducts, state.favoriteProducts)
          ) {
            this.favoriteProducts = _.cloneDeep(state.favoriteProducts);
            // console.log("favorites prodducts", this.favoriteProducts);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("hyperCategories")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.currentHyperCategory &&
            !_.isEqual(
              this.selectedHyperCategory,
              state.currentHyperCategory
            ) &&
            state.allCategories &&
            state.allCategories.length > 0
          ) {
            this.selectedHyperCategory = _.cloneDeep(
              state.currentHyperCategory
            );
            this.hyperCategoriesCalculations(state.allCategories);
          }
        })
    );
    console.log("will enter called", this.currentStoreId, this.languagePath);
    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }

          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.languagePath, state.selectedLangugage)
          ) {
            this.languagePath = _.cloneDeep(state.selectedLangugage);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((store_info) => {
          if (store_info && !_.isEqual(store_info, this.store_info)) {
            this.store_info = _.cloneDeep(store_info);
            this.selectedStoreId = _.cloneDeep(store_info.store_id);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(this.stores, state.stores)) {
            this.stores = _.cloneDeep(state.stores);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((auth) => {
          if (auth && !_.isEqual(this.currentUser, auth.user)) {
            this.currentUser = _.cloneDeep(auth.user);
          }
          //console.log("curr user", this.loginState, auth.loginState);
          if (
            auth &&
            auth.loginState &&
            !_.isEqual(this.loginState, auth.loginState)
          ) {
            this.loginState = _.cloneDeep(auth.loginState);
            // console.log("login state", this.loginState);
            if (
              this.loginState === "login_completed" ||
              this.loginState === "no_login"
            ) {
              if (this.currentStoreSub) {
                this.currentStoreSub.unsubscribe();
              }

              this.currentStoreSub = this.store
                .select("currentStore")
                .pipe(distinctUntilChanged())
                .pipe(auditTime(200))
                .subscribe((state) => {
                  if (
                    state &&
                    state.currentStoreId &&
                    !_.isEqual(this.currentStoreId, state.currentStoreId)
                  ) {
                    this.currentStoreId = _.cloneDeep(state.currentStoreId);
                    console.log("currentStoreId", this.currentStoreId);

                    this.store
                      .select(selectors.getStoreInfo)
                      .pipe(auditTime(200))
                      .pipe(first())
                      .subscribe((storeInfo) => {
                        this.catalogZeroPricesMode =
                          (!!storeInfo.catalogZeroPricesMode ||
                            !!auth?.user?.catalogZeroPricesMode) &&
                          !!!auth?.user?.skipCatalogZeroPricesMode;
                        if (
                          storeInfo &&
                          storeInfo.enable_customer_pricelists_mobileorder &&
                          storeInfo.enable_customer_pricelists &&
                          this.loginState === "login_completed"
                        ) {
                          this.subscribeToCatalogForPricelist();
                        } else {
                          this.fetchCatalog(false, false);
                          this.subscribeToCatalogForPricelist();
                        }
                      });
                  }
                });
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.selectedAddress, this.selectedAddress)
          ) {
            this.selectedAddress = _.cloneDeep(state.selectedAddress);
          }

          if (
            state &&
            !_.isEqual(state.selectedPreorderDate, this.selectedPreorderDate)
          ) {
            this.selectedPreorderDate = _.cloneDeep(state.selectedPreorderDate);
            if (
              this.selectedPreorderDate &&
              this.selectedPreorderDate.date &&
              this.selectedPreorderDate.hour
            ) {
              //TO_DO YOU HAVE TO CHECK FOR PRICELISTS
              this.fetchCatalog(true, false);
            }
          }
        })
    );

    this.subscriptions.push(
      this.store.select("disableFinalPrice").subscribe((state) => {
        if (
          state &&
          !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
        ) {
          this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
        }
      })
    );
    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (state && state.products) {
            this.previousProductsInCart = _.cloneDeep(this.cartProducts);
            this.cartLength = _.cloneDeep(state.products.length);

            this.cartProducts = _.cloneDeep(state.products);
            this.cartProductsNumber = 0;
            _.each(this.cartProducts, (product) => {
              if (
                product.quantity &&
                !product.disable_qty &&
                (!product.enable_quantity_step || !product.quantity_step)
              ) {
                this.cartProductsNumber =
                  this.cartProductsNumber + product.quantity;
              } else {
                this.cartProductsNumber = this.cartProductsNumber + 1;
              }
            });

            if (navigator.cookieEnabled) {
              window.localStorage.setItem(
                "cart",
                JSON.stringify(this.cartProducts)
              );
            }
          }
        })
    );
  }

  subscribeToMigrationCustomerId(customerPricelistsEnabled) {
    console.log(
      "fetchVatCustomerPricelistsAndSubToMigrationCustomerId",
      this.migration_customer_id,
      customerPricelistsEnabled
    );
    if (this.migrationCustomerIdSubscription) {
      this.migrationCustomerIdSubscription.unsubscribe();
    }
    this.migrationCustomerIdSubscription = this.store
      .select("auth")
      .pipe(distinctUntilChanged())
      .subscribe((authState) => {
        if (
          authState &&
          authState.migration_customer_id &&
          !_.isEqual(
            this.migration_customer_id,
            authState.migration_customer_id
          )
        ) {
          if (!this.migration_customer_id) {
            this.fetchFavorites(
              authState.migration_customer_id,
              customerPricelistsEnabled
            );
          } else {
            this.ionViewDidLeave();
            this.ngOnDestroy();

            setTimeout(() => {
              this.migration_customer_id = null;
              this.ionViewDidEnter();
              this.ngOnInit();
            }, 300);
          }

          this.migration_customer_id = _.cloneDeep(
            authState.migration_customer_id
          );
        }
      });
  }

  fetchFavorites(migration_customer_id, customerPricelistsEnabled) {
    if (this.loginStateSub) {
      this.loginStateSub.unsubscribe();
    }
    this.loginState = null;
    this.store
      .select("auth")
      .pipe(first())
      .subscribe((authState) => {
        if (authState && authState.mo_token) {
          this.store
            .select(selectors.getStoreInfo)
            .pipe(first())
            .subscribe((storeInfo) => {
              if (storeInfo && storeInfo.store_id) {
                this.fetchCustomerFavoriteProducts(
                  storeInfo,
                  authState.mo_token,
                  migration_customer_id,
                  customerPricelistsEnabled,
                  authState.user
                );
              }
            });
        }
      })
      .unsubscribe();
  }

  async presentSelectInvoiceModal() {
    this.selectedInvoiceModal = await this.modalCtrl.create({
      component: InvoiceListModalComponent,
      cssClass: "invoiceListModal",
      backdropDismiss: false,
    });

    await this.selectedInvoiceModal.present();
    this.selectedInvoiceModal.onDidDismiss().then((invoice) => {
      console.log("on dismiss", invoice);
      this.selectedInvoiceModal = null;
    });
  }

  fetchCustomerFavoriteProducts(
    store,
    mo_token,
    migration_customer_id,
    customerPricelistsEnabled,
    currentUser
  ) {
    console.log("fetchCustomerFavoriteProducts");
    this.dataStorageService
      .fetchCustomerFavoriteProducts(
        store.store_id,
        mo_token,
        migration_customer_id
      )

      .subscribe({
        next: async (res: any) => {
          if (res && res.success) {
            if (migration_customer_id && customerPricelistsEnabled) {
              this.store.dispatch(
                new FavoriteProductsActions.SetFavoriteProducts(res.products)
              );
              this.fetchCustomerPricelistsMigrationProductId(
                migration_customer_id,
                store,
                mo_token,
                currentUser
              );
            } else {
              this.store.dispatch(
                new FavoriteProductsActions.SetFavoriteProducts(res.products)
              );
              this.initActions();
            }
          } else if (res && !res.success) {
            if (res && res.comment_id) {
              this.presentErrorToast(
                this.translateService.instant(
                  "errorMessages." + res.comment_id
                ),
                "danger"
              );
            } else if (res && res.comments) {
              this.presentErrorToast(
                this.translateService.instant(res.comments),
                "danger"
              );
            }
          } else if (!res) {
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message: this.translateService.instant(
                "errorMessages.problem_reaching_server"
              ),

              buttons: ["OK"],
            });
            await alert.present();
          }
        },
        error: async (error) => {
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.translateService.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          return error;
        },
      });
  }

  fetchCustomerPricelistsMigrationProductIdPromise(
    migration_customer_id,
    store,
    mo_token,
    currentUser
  ) {
    return new Promise((resolve) => {
      if (
        ((store && store.catalogZeroPricesMode) ||
          (currentUser && currentUser.catalogZeroPricesMode)) &&
        !!!currentUser?.skipCatalogZeroPricesMode
      ) {
        this.store.dispatch(new CatalogActions.SetCustomerPricelist([]));

        resolve({ sucess: true, products: [] });
      }

      this.dataStorageService
        .getUserPricelist(mo_token, store.store_id, migration_customer_id)
        .subscribe({
          next: async (res: any) => {
            if (!res || !res.success) {
              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message:
                  res && res.comment_id
                    ? this.translateService.instant(res.comment_id)
                    : this.translateService.instant(
                        "errorMessages.general_error"
                      ),
                backdropDismiss: false,
                buttons: ["OK"],
              });

              await alert.present();
            } else {
              this.store.dispatch(
                new CatalogActions.SetCustomerPricelist(res.products)
              );
            }
            resolve(res);
          },
          error: async (err) => {
            resolve({ success: false });
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message: this.translateService.instant(
                "errorMessages.problem_reaching_server"
              ),

              buttons: ["OK"],
            });
            await alert.present();
            return err;
          },
        });
    });
  }

  fetchCustomerPricelistsMigrationProductId(
    migration_customer_id,
    store,
    mo_token,
    currentUser
  ) {
    if (
      ((store && store.catalogZeroPricesMode) ||
        (currentUser && currentUser.catalogZeroPricesMode)) &&
      !!!currentUser?.skipCatalogZeroPricesMode
    ) {
      this.store.dispatch(new CatalogActions.SetCustomerPricelist([]));
      this.initActions();
      return;
    }
    this.dataStorageService
      .getUserPricelist(mo_token, store.store_id, migration_customer_id)
      .subscribe({
        next: async (res: any) => {
          if (!res || !res.success) {
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message:
                res && res.comment_id
                  ? this.translateService.instant(res.comment_id)
                  : this.translateService.instant(
                      "errorMessages.general_error"
                    ),
              backdropDismiss: false,
              buttons: ["OK"],
            });

            await alert.present();
          } else {
            this.store.dispatch(
              new CatalogActions.SetCustomerPricelist(res.products)
            );

            this.initActions();
          }
        },
        error: async (err) => {
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.translateService.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          return err;
        },
      });
  }

  private navigateToHome() {
    if (this.platform.is("android")) {
      this.router.navigateByUrl("/homepage", {
        replaceUrl: true,
      });
    }
  }

  navigateToCatalog() {
    this.router.navigateByUrl("/catalog", {
      replaceUrl: true,
    });
  }

  navigateToCart() {
    this.router.navigateByUrl("/cart", {
      replaceUrl: true,
    });
  }

  changeStore() {
    if (!this.changedFromUser) {
      this.store
        .select("cart")
        .pipe(first())
        .subscribe(async (state) => {
          if (
            state &&
            state.products &&
            state.products.length > 0 &&
            !this.selectedAddress &&
            !_.isEqual(this.currentStoreId, this.selectedStoreId)
          ) {
            const alert = await this.alertController.create({
              cssClass: "my-custom-class",
              header: this.translateService.instant("alert"),
              message: this.translateService.instant(
                "favoriteProductsPage.if-you-change-store-your-cart-products-will-be-deleted-do-you-want-to-continue"
              ),
              buttons: [
                {
                  text: this.translateService.instant("cancel"),
                  role: "cancel",
                  cssClass: "secondary",
                  handler: (blah) => {
                    this.changedFromUser = true;
                    this.selectedStoreId = _.cloneDeep(
                      this.store_info.store_id
                    );
                  },
                },
                {
                  text: this.translateService.instant("yes"),
                  handler: () => {
                    if (this.selectedStoreId) {
                      this.store.dispatch(
                        new CurrentStoreActions.SetCurrentStoreId(
                          _.cloneDeep(this.selectedStoreId)
                        )
                      );
                    }
                  },
                },
              ],
            });
            await alert.present();
          } else if (
            state &&
            state.products &&
            state.products.length > 0 &&
            this.selectedAddress &&
            !_.isEqual(this.currentStoreId, this.selectedStoreId)
          ) {
            const alert = await this.alertController.create({
              cssClass: "my-custom-class",
              header: this.translateService.instant("alert"),
              message: this.translateService.instant(
                "favoriteProductsPage.if-you-change-store-your-cart-products-and-your-address-will-be-deleted-do-you-want-to-continue"
              ),
              buttons: [
                {
                  text: this.translateService.instant("cancel"),
                  role: "cancel",
                  cssClass: "secondary",
                  handler: (blah) => {
                    this.changedFromUser = true;

                    this.selectedStoreId = _.cloneDeep(
                      this.store_info.store_id
                    );
                  },
                },
                {
                  text: this.translateService.instant("yes"),
                  handler: () => {
                    if (this.selectedStoreId) {
                      console.log(
                        "set store id favorites page",
                        this.selectedStoreId
                      );
                      this.deleteSelectedAddress();

                      this.store.dispatch(
                        new CurrentStoreActions.SetCurrentStoreId(
                          _.cloneDeep(this.selectedStoreId)
                        )
                      );
                    }
                  },
                },
              ],
            });
            await alert.present();
          } else if (
            state &&
            (!state.products ||
              (state.products && state.products.length === 0)) &&
            !_.isEqual(this.currentStoreId, this.selectedStoreId) &&
            !this.selectedAddress
          ) {
            if (this.selectedStoreId) {
              // console.log("set store id favorites page", this.selectedStoreId);
              this.store.dispatch(
                new CurrentStoreActions.SetCurrentStoreId(
                  _.cloneDeep(this.selectedStoreId)
                )
              );
            }
          } else if (
            state &&
            (!state.products ||
              (state.products && state.products.length === 0)) &&
            !_.isEqual(this.currentStoreId, this.selectedStoreId) &&
            this.selectedAddress
          ) {
            if (this.selectedStoreId) {
              const alert = await this.alertController.create({
                cssClass: "my-custom-class",
                header: this.translateService.instant("alert"),
                message: this.translateService.instant(
                  "favoriteProductsPage.if-you-change-store-your-address-will-be-deleted-do-you-want-to-continue"
                ),
                buttons: [
                  {
                    text: this.translateService.instant("cancel"),
                    role: "cancel",
                    cssClass: "secondary",
                    handler: (blah) => {
                      this.changedFromUser = true;

                      this.selectedStoreId = _.cloneDeep(
                        this.store_info.store_id
                      );
                    },
                  },
                  {
                    text: this.translateService.instant("yes"),
                    handler: () => {
                      if (this.selectedStoreId) {
                        console.log(
                          "set store id favorites page",
                          this.selectedStoreId
                        );
                        console.log(
                          "set store id favorites page",
                          this.selectedStoreId
                        );
                        this.deleteSelectedAddress();

                        this.store.dispatch(
                          new CurrentStoreActions.SetCurrentStoreId(
                            _.cloneDeep(this.selectedStoreId)
                          )
                        );
                      }
                    },
                  },
                ],
              });
              await alert.present();
            }
          }
        })
        .unsubscribe();
    } else {
      this.changedFromUser = false;
    }
  }

  subscribeToCatalogForPricelist() {
    if (this.pricelistsSub) {
      this.pricelistsSub.unsubscribe();
      this.customerPricelist = null;
    }

    this.pricelistsSub = this.store
      .select("catalog")
      .pipe(distinctUntilChanged())
      .subscribe((catalogState) => {
        if (
          catalogState &&
          !_.isEqual(catalogState.customerPricelist, this.customerPricelist)
        ) {
          this.customerPricelist = _.cloneDeep(catalogState.customerPricelist);
          console.log("catalog pricelists called", this.customerPricelist);

          if (
            this.customerPricelist &&
            this.customerPricelist &&
            this.customerPricelist.length > 0
          ) {
            this.fetchCatalog(false, true);
          } else if (
            this.customerPricelist &&
            this.customerPricelist &&
            this.customerPricelist.length === 0
          ) {
            this.fetchCatalog(false, false);
          }
        }
      });
  }

  ngOnInit() {}

  deleteSelectedAddress() {
    this.store.dispatch(new DeliveryMethodAddressActions.setValidStores(null));
    this.store.dispatch(
      new DeliveryMethodAddressActions.setValidDeliveryAreas(null)
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setCheckSelectedAddress(null)
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setSelectedAddress(null)
    );
    this.store.dispatch(new DeliveryMethodAddressActions.setOrderPickup(null));
    this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(null));
    this.store.dispatch(
      new DeliveryMethodAddressActions.setSelectedDineInTable(_.cloneDeep(null))
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setValidDeliveryAreas(null)
    );

    this.store.dispatch(
      new DeliveryMethodAddressActions.setCarTakeawayPlateNumber(null)
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setCarTakewayComments(null)
    );

    if (navigator.cookieEnabled) {
      let deliveryMethodAddressObj: any = localStorage.getItem(
        "deliveryMethodAddress"
      );
      if (deliveryMethodAddressObj) {
        deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
        deliveryMethodAddressObj.selectedAddress = null;
        deliveryMethodAddressObj.orderPickup = null;
        deliveryMethodAddressObj.dineIn = null;
        deliveryMethodAddressObj.carTakeAwayComments = null;
        deliveryMethodAddressObj.carTakeAwayPlateNumber = null;

        localStorage.setItem(
          "deliveryMethodAddress",
          JSON.stringify(deliveryMethodAddressObj)
        );
      }
    }
  }

  hyperCategoriesCalculations(allCategories) {
    // console.log("first hyper category", this.selectedHyperCategory);
    let categories: any = [];
    // console.log("hyper categories calculations", categories, allCategories);
    this.store.dispatch(new CatalogActions.SetCatalog(_.cloneDeep(null)));
    _.each(allCategories, (category) => {
      if (category.hyper_category_id === this.selectedHyperCategory) {
        categories.push(category);
      }
    });

    // console.log("after each loop hyper categories", categories);

    this.writeCategoriesToStateForRender(categories);
    //this.favoritesSubscription(true, categories);
  }

  deleteAllCatalogPrices(catalog) {
    _.each(catalog, (category, cat_index) => {
      if (category.products) {
        _.each(category.products, (product, prd_index) => {
          if (catalog[cat_index].products[prd_index].price) {
            catalog[cat_index].products[prd_index].price = null;
          }

          if (catalog[cat_index].products[prd_index].specialPrice) {
            catalog[cat_index].products[prd_index].specialPrice = null;
          }
          if (catalog[cat_index].products[prd_index].price_takeaway) {
            catalog[cat_index].products[prd_index].price_takeaway = null;
          }
          if (catalog[cat_index].products[prd_index].price_dinein) {
            catalog[cat_index].products[prd_index].price_dinein = null;
          }
          if (catalog[cat_index].products[prd_index].price_alt_dinein) {
            catalog[cat_index].products[prd_index].price_alt_dinein = null;
          }
        });
      }
    });

    return _.cloneDeep(catalog);
  }

  fetchCatalog(fetchForPreorder, customerPricelist) {
    this.loading = true;
    console.log("fetch catalog called");
    if (!fetchForPreorder) {
      this.dataStorageService
        .getCatalog(this.currentStoreId, this.languagePath)
        .subscribe({
          next: (res: any) => {
            if (res && res.success && res.catalog) {
              this.loading = false;
              //console.log("get catalog", res.catalog);

              if (this.catalogZeroPricesMode) {
                res.catalog = this.deleteAllCatalogPrices(
                  _.cloneDeep(res.catalog)
                );
                console.log("ressss", res.catalog);
              }

              this.catalog = res.catalog;

              const unfilteredCatalogWithoutProducts = _.map(
                _.cloneDeep(res.catalog),
                (cat) => {
                  return _.omit(cat, ["products"]);
                }
              );
              this.store.dispatch(
                new CatalogActions.SetCatalogUnfiltered(
                  unfilteredCatalogWithoutProducts
                )
              );
              this.hyperCategories = res.hyper_categories;

              this.filterCatalogProducts(customerPricelist);

              if (this.hyperCategories && this.hyperCategories.length > 0) {
                this.hyperCategories = _.filter(
                  this.hyperCategories,
                  (hyperCategory) => {
                    if (
                      _.find(this.catalog, (category) => {
                        if (
                          category.hyper_category_id ===
                            hyperCategory.hyper_category_id &&
                          category.activeTotal &&
                          category.products &&
                          category.products.length > 0
                        ) {
                          return category;
                        }
                      })
                    ) {
                      return hyperCategory;
                    }
                  }
                );

                let createOthersHyperCategory = false;
                _.each(this.catalog, (category) => {
                  if (
                    !_.find(this.hyperCategories, {
                      hyper_category_id: category.hyper_category_id,
                    })
                  ) {
                    if (
                      category &&
                      category.activeTotal &&
                      category.products &&
                      category.products.length > 0
                    ) {
                      category.hyper_category_id = "others";
                      createOthersHyperCategory = true;
                    }
                  }
                });
                if (createOthersHyperCategory) {
                  this.hyperCategories.push({
                    name: "others",
                    hyper_category_id: "others",
                  });
                }

                _.each(this.hyperCategories, (hyperCategory) => {
                  _.each(this.catalog, (category) => {
                    if (
                      category.hyper_category_id ===
                      hyperCategory.hyper_category_id
                    ) {
                      if (hyperCategory.num_of_categories) {
                        hyperCategory.num_of_categories =
                          hyperCategory.num_of_categories + 1;
                      } else {
                        hyperCategory.num_of_categories = 1;
                      }
                    }
                  });
                });
              }

              this.store.dispatch(
                new HyperCategoriesActions.SetHyperCategories(
                  _.cloneDeep(this.hyperCategories ? this.hyperCategories : [])
                )
              );
              this.getCatalogSuccessFunction(customerPricelist);
            } else {
              //TO_DO ERROR HANDLING
              console.log("error");
              this.loading = false;
            }
          },
          error: (err) => {
            this.loading = false;
            let errMsg = this.translateService.instant(
              "errorMessages.problem_reaching_server"
            );

            this.presentErrorToast(errMsg, "danger");
          },
        });
    } else {
      this.loading = false;

      if (this.hyperCategories && this.hyperCategories.length === 0) {
        this.store
          .select("catalog")
          .pipe(first())
          .subscribe((state) => {
            if (state && state.catalog) {
              let catalog = _.cloneDeep(state.catalog);
              if (catalog && catalog.preorderNeedsCalculations) {
                //TO_DO FETCH FROM PREORDER API products and categories needs calculations
              } else {
                //this.fetchCatalog(false);
              }
            }
          })
          .unsubscribe();
      } else if (this.hyperCategories && this.hyperCategories.length > 0) {
        this.loading = false;
        this.store
          .select("hyperCategories")
          .pipe(first())
          .subscribe((state) => {
            if (state && state.allCategories) {
              let catalog = _.cloneDeep(state.allCategories);
              if (catalog && catalog.preorderNeedsCalculations) {
                //TO_DO FETCH FROM PREORDER API products and categories needs calculations
              } else {
                // this.fetchCatalog(false);
              }
            }
          })
          .unsubscribe();
      } else {
        this.loading = false;
      }
    }
  }

  getCatalogSuccessFunction(thereAreCustomerPricelist) {
    this.subCategoriesById = {};
    if (
      this.catalog &&
      (!this.hyperCategories ||
        (this.hyperCategories && this.hyperCategories.length === 0))
    ) {
      this.filterCatalogProducts(thereAreCustomerPricelist);

      this.categories = _.filter(this.catalog, (category) => {
        if (
          category.activeTotal &&
          !category.hidden &&
          !category.showOnlyInQRCatalog &&
          !_.isEmpty(category.products) &&
          (!Capacitor.isNativePlatform() ||
            (Capacitor.isNativePlatform() && !this.platform.is("ios")) ||
            (Capacitor.isNativePlatform() &&
              this.platform.is("ios") &&
              !category.ios_hide_category))
        ) {
          return category;
        }
      });

      this.allCategoriesMarketCardsDesign = true;

      _.each(this.categories, (category, k) => {
        if (
          category.subCategoriesActive &&
          category.subCategories &&
          !_.isEmpty(category.subCategories)
        ) {
          _.each(category.subCategories, (subCategory) => {
            this.subCategoriesById[subCategory.subCategoryId] = subCategory;
          });
        }
        if (category && !category.marketCardsDesign) {
          this.allCategoriesMarketCardsDesign = false;
        }

        if (
          category &&
          category.promotions &&
          category.hideCategoryPromotions
        ) {
          category.promotions = [];
        }
      });

      this.store.dispatch(
        new CatalogActions.SetAllCategoriesMarketCardsDesign(
          _.cloneDeep(this.allCategoriesMarketCardsDesign)
        )
      );
      //console.log("subcategories", this.subCategoriesById);

      this.writeCategoriesToStateForRender(this.categories);
    } else if (
      this.catalog &&
      this.hyperCategories &&
      this.hyperCategories.length > 0
    ) {
      //console.log("hyper categories calculations");
      this.hyperCategoriesCatalog(thereAreCustomerPricelist);
    }
  }

  writeCategoriesToStateForRender(categories) {
    this.store.dispatch(new CatalogActions.SetCatalog(_.cloneDeep(categories)));
    this.store.dispatch(
      new CatalogActions.SetAllCategories(_.cloneDeep(categories))
    );
  }

  filterCatalogProducts(thereAreCustomerPricelist) {
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe((groupState) => {
        this.store
          .select(selectors.getStoreInfo)
          .pipe(first())
          .subscribe((store_info) => {
            if (thereAreCustomerPricelist) {
              this.store
                .select("catalog")
                .pipe(first())
                .subscribe((state) => {
                  if (state && state.customerPricelist) {
                    this.filterCatalogProductsActions(
                      _.cloneDeep(state.customerPricelist),
                      store_info,
                      groupState.groupApp
                    );
                  }
                });
            } else {
              this.filterCatalogProductsActions(
                null,
                store_info,
                groupState.groupApp
              );
            }
          })
          .unsubscribe();
      })
      .unsubscribe();
  }

  calculateProductPoints(price, order_rule) {
    if (
      order_rule &&
      order_rule.points_ratio &&
      order_rule.amount_ratio &&
      price
    ) {
      price = parseFloat(
        parseFloat(_.cloneDeep(price).toString().replace("€", "")).toFixed(2)
      );
      return parseInt(
        parseFloat(
          ((price * order_rule.points_ratio) / order_rule.amount_ratio)
            .toFixed(2)
            .toString()
        )
          .toFixed()
          .toString()
      );
    } else {
      return null;
    }
  }

  checkOriginalCategoryIsPromo(product) {
    const original_category = _.find(this.catalog, {
      category_id: product.category_id,
    });
    if (_.has(product, ["originalCategoryIsPromo"])) {
      delete product.originalCategoryIsPromo;
    }
    if (original_category && original_category.promo && !product.promo) {
      product.originalCategoryIsPromo = true;
    }
  }

  filterCatalogProductsActions(
    customerPricelistProducts,
    store_info,
    groupApp
  ) {
    //console.log("customerPricelistProducts", customerPricelistProducts);
    _.each(this.catalog, (cat, c, l) => {
      this.catalog[c].products = _.filter(cat.products, (product, key) => {
        this.checkOriginalCategoryIsPromo(product);

        if (
          groupApp &&
          groupApp.pointsSystem &&
          store_info &&
          store_info.design_settings &&
          store_info.design_settings.showPointRulesOnProductItems
        ) {
          if (store_info.overridePromoItemsForPointsSystem) {
            this.calculateProductShowOptionInDescriptionObject(product, false);
          } else {
            this.calculateShowPointRulesOnProductItem(product);
            this.calculateProductShowOptionInDescriptionObject(product, true);
          }
        } else {
          this.calculateProductShowOptionInDescriptionObject(product, false);
        }
        //TODO this is to be deleted as the subCategoryId will not exist in the product if the subCategories of the category
        //This was found in Prunas
        // This problem exists when the product is a copy
        if (
          product.subCategoryId &&
          (!cat.subCategoriesActive ||
            !_.find(cat.subCategories, {
              subCategoryId: product.subCategoryId,
            }))
        ) {
          //console.log("product with an invalid subCategoryId", product);
          cat.products[key].subCategoryId = null;
        }
        if (
          product.image &&
          product.image.true &&
          product.image.true["4x3"] &&
          product.image.true["4x3"].path
        ) {
          product.thumb = true;
        }

        this.checkProductsCategoriesAvailabilityActions(product, cat);

        if (
          !(
            cat.availableTotal &&
            !product.availableTotal &&
            product.available &&
            product.avpc
          )
        ) {
          cat.avpc = null;
        }
        if (
          (!product.available && !product.availableTotal) ||
          !cat.availableTotal
        ) {
          product.lineThrough = true;
        }
        if (
          !product.availableTotal ||
          !cat.availableTotal ||
          product.initial_price
        ) {
          product.priceWidthUnavailable = true;
        }
        if (
          product.availableTotal &&
          cat.availableTotal &&
          !product.initial_price
        ) {
          product.priceWidthAvailable = true;
        }

        //all the products that has avalabilityForPreorder

        //this is for the preorder only category for user to have the ability to order this products only with preorder
        if (
          product &&
          !product.availableTotal &&
          store_info.preorderActive &&
          product.tags &&
          product.tags.availableForPreorder &&
          cat &&
          cat.promoCategory &&
          cat.availableTotal &&
          cat.activeTotal &&
          cat.tags &&
          cat.tags.availableForPreorder
        ) {
          product.availableTotal = true;
          product.avpc = null;
          product.hideAvailableForPreorderTag = false;
        } else if (
          product &&
          product.tags &&
          product.tags.availableForPreorder &&
          product.availableTotal &&
          cat &&
          (!cat.promoCategory ||
            (cat.promoCategory &&
              (!cat.tags || !cat.tags.availableForPreorder)))
        ) {
          product.hideAvailableForPreorderTag = true;
        } else if (
          product &&
          product.tags &&
          product.tags.availableForPreorder &&
          !product.availableTotal &&
          !cat.promoCategory
        ) {
          let foundProductInPromoCategory;
          _.each(this.catalog, (categ) => {
            if (
              categ &&
              categ.promoCategory &&
              categ.availableTotal &&
              categ.activeTotal &&
              categ.tags &&
              categ.tags.availableForPreorder
            ) {
              _.each(categ.products, (categ_product) => {
                if (categ_product.product_id === product.product_id) {
                  foundProductInPromoCategory = true;
                }
              });
            }
          });

          if (!foundProductInPromoCategory) {
            product.hideAvailableForPreorderTag = true;
          }
        }

        if (
          product.product_categories_active_check &&
          product.product_categories_availability_check &&
          (!cat.promoCategory || !cat.tags || !cat.tags.availableForPreorder) &&
          //TO-DO check the above condition
          // (!cat.promoCategory ||
          //   (cat.promoCategory &&
          //     !cat.check_promo_category_products_availability)) &&
          //
          !product.availableTotal &&
          !product.activePeriod &&
          !product.availablePeriod &&
          product.availableInitializationActive &&
          product.availableInitializationTime &&
          (!product.preorder_availability_stock_enabled ||
            product.preorder_availability_stock > 0) &&
          store_info &&
          store_info.preorderActive &&
          (((!store_info.preorderDaysStart ||
            store_info.preorderDaysStart === 0) &&
            store_info.preorderDaysAhead > 1) ||
            store_info.preorderDaysStart > 0) &&
          (!product.tags || !product.tags.availableForPreorder)
        ) {
          if (!product.tags) {
            product.tags = {};
          }
          product.tags.initializationActivePreorder = true;
          product.availableTotal = true;
        }

        if (customerPricelistProducts && customerPricelistProducts.length) {
          _.each(customerPricelistProducts, (customerProduct) => {
            if (customerProduct.product_id === product.product_id) {
              if (customerProduct.price) {
                let price = _.cloneDeep(
                  parseFloat(customerProduct.price.toString()).toFixed(
                    store_info && store_info.decimalPlacesPrices
                      ? store_info.decimalPlacesPrices
                      : 2
                  )
                );
                product.price = price.toString() + "€";
              }

              if (customerProduct.price_takeaway) {
                let price_takeaway = _.cloneDeep(
                  parseFloat(customerProduct.price_takeaway.toString()).toFixed(
                    store_info && store_info.decimalPlacesPrices
                      ? store_info.decimalPlacesPrices
                      : 2
                  )
                );
                price_takeaway = price_takeaway.toString() + "€";

                product.price_takeaway = price_takeaway;
              }
            }
          });
        }

        return (
          product.activeTotal &&
          !product.hidden &&
          !product.showOnlyInQRCatalog &&
          product.product_categories_active_check
        );
      });
    });
  }

  calculateShowPointRulesOnProductItem(product) {
    if (product && !product.promo) {
      if (product.price) {
        product.points_to_earn = this.calculateProductPoints(
          _.cloneDeep(product.price),
          _.find(this.groupApp.points.rules, { type: "order" })
        );
      }
      if (product.price_takeaway) {
        product.points_to_earn_takeaway = this.calculateProductPoints(
          _.cloneDeep(product.price_takeaway),
          _.find(this.groupApp.points.rules, { type: "order" })
        );
      }
      if (product.price_dinein) {
        product.points_to_earn_dinein = this.calculateProductPoints(
          _.cloneDeep(product.price_dinein),
          _.find(this.groupApp.points.rules, { type: "order" })
        );
      }
      if (product.price_alt_dinein) {
        product.points_to_earn_alt_dinein = this.calculateProductPoints(
          _.cloneDeep(product.price_alt_dinein),
          _.find(this.groupApp.points.rules, { type: "order" })
        );
      }
    }
  }

  calculateProductShowOptionInDescriptionObject(product, withPoints) {
    if (
      product.showOptionInDescriptionObject &&
      product.showOptionInDescriptionObject.choices &&
      product.showOptionInDescriptionObject.choices.length
    ) {
      _.each(product.showOptionInDescriptionObject.choices, (ch, indx) => {
        product.showOptionInDescriptionObject.choices[indx] = {
          title: _.cloneDeep(ch.title),
          price: _.cloneDeep(ch.price),
          points:
            withPoints && !product.promo && !product.gift
              ? this.calculateProductPoints(
                  _.cloneDeep(ch.price),
                  _.find(this.groupApp.points.rules, { type: "order" })
                )
              : null,
        };
      });
    }
    if (
      product.showOptionInDescriptionObject_takeaway &&
      product.showOptionInDescriptionObject_takeaway.choices &&
      product.showOptionInDescriptionObject_takeaway.choices.length
    ) {
      _.each(
        product.showOptionInDescriptionObject_takeaway.choices,
        (ch, indx) => {
          product.showOptionInDescriptionObject_takeaway.choices[indx] = {
            title: _.cloneDeep(ch.title),
            price: _.cloneDeep(ch.price),
            points:
              withPoints && !product.promo && !product.gift
                ? this.calculateProductPoints(
                    _.cloneDeep(ch.price),
                    _.find(this.groupApp.points.rules, { type: "order" })
                  )
                : null,
          };
        }
      );
    }
    if (
      product.showOptionInDescriptionObject_dinein &&
      product.showOptionInDescriptionObject_dinein.choices &&
      product.showOptionInDescriptionObject_dinein.choices.length
    ) {
      _.each(
        product.showOptionInDescriptionObject_dinein.choices,
        (ch, indx) => {
          product.showOptionInDescriptionObject_dinein.choices[indx] = {
            title: _.cloneDeep(ch.title),
            price: _.cloneDeep(ch.price),
            points:
              withPoints && !product.promo && !product.gift
                ? this.calculateProductPoints(
                    _.cloneDeep(ch.price),
                    _.find(this.groupApp.points.rules, { type: "order" })
                  )
                : null,
          };
        }
      );
    }
    if (
      product.showOptionInDescriptionObject_alt_dinein &&
      product.showOptionInDescriptionObject_alt_dinein.choices &&
      product.showOptionInDescriptionObject_alt_dinein.choices.length
    ) {
      _.each(
        product.showOptionInDescriptionObject_alt_dinein.choices,
        (ch, indx) => {
          product.showOptionInDescriptionObject_alt_dinein.choices[indx] = {
            title: _.cloneDeep(ch.title),
            price: _.cloneDeep(ch.price),
            points:
              withPoints && !product.promo && !product.gift
                ? this.calculateProductPoints(
                    _.cloneDeep(ch.price),
                    _.find(this.groupApp.points.rules, { type: "order" })
                  )
                : null,
          };
        }
      );
    }
  }

  checkProductsCategoriesAvailabilityActions(product, cat) {
    if (product && product.options) {
      _.each(product.options, (opt) => {
        if (opt && opt.choices) {
          _.each(opt.choices, (ch) => {
            if (ch.bundle === true && ch.bundled_product) {
              this.checkProductsCategoriesAvailabilityActions(
                ch.bundled_product,
                cat
              );
            }
          });
        }
      });
    }

    product = this.checkProductCategoriesAvailabilityActions(product, cat);
  }

  checkProductCategoriesAvailabilityActions(product, cat) {
    //check for categories copy products availability and active
    if (product.categories && product.categories.length) {
      const original_category = _.find(this.catalog, {
        category_id: product.category_id,
      });

      if (
        original_category &&
        (!original_category.availableTotal || !original_category.activeTotal)
      ) {
        if (
          cat.promoCategory &&
          cat.check_promo_category_products_availability
        ) {
          const productCategories = _.filter(product.categories, (ct) => {
            return ct.category_id !== cat.category_id;
          });
          let productCategoryActiveTotal, productCategoryAvailableTotal;
          _.each(productCategories, (product_category) => {
            const pr_cat = _.find(this.catalog, {
              category_id: product_category.category_id,
            });

            if (pr_cat.availableTotal) {
              productCategoryAvailableTotal = true;
            }

            if (pr_cat.activeTotal) {
              productCategoryActiveTotal = true;
              //console.log("pr categoruyasd", pr_cat);
            }
          });
          if (productCategoryActiveTotal) {
            product.product_categories_active_check = true;
          }

          if (productCategoryAvailableTotal) {
            product.product_categories_availability_check = true;
          }
        } else if (
          cat.promoCategory &&
          !cat.check_promo_category_products_availability
        ) {
          let productCategoryActiveTotal, productCategoryAvailableTotal;

          _.each(product.categories, (product_category) => {
            const pr_cat = _.find(this.catalog, {
              category_id: product_category.category_id,
            });

            if (pr_cat.availableTotal) {
              productCategoryAvailableTotal = true;
            }

            if (pr_cat.activeTotal) {
              productCategoryActiveTotal = true;
            }
          });
          if (productCategoryActiveTotal) {
            product.product_categories_active_check = true;
          }

          if (productCategoryAvailableTotal) {
            product.product_categories_availability_check = true;
          }
        } else if (!cat.promoCategory) {
          let productCategoryActiveTotal, productCategoryAvailableTotal;
          if (cat.availableTotal) {
            productCategoryAvailableTotal = true;
          }

          if (cat.activeTotal) {
            productCategoryActiveTotal = true;
          }

          if (!cat.availableTotal || !cat.activeTotal) {
            _.each(product.categories, (product_category) => {
              const pr_cat = _.find(this.catalog, {
                category_id: product_category.category_id,
              });

              if (pr_cat.availableTotal) {
                productCategoryAvailableTotal = true;
              }

              if (pr_cat.activeTotal) {
                productCategoryActiveTotal = true;
              }
            });
          }

          if (productCategoryActiveTotal) {
            product.product_categories_active_check = true;
          }

          if (productCategoryAvailableTotal) {
            product.product_categories_availability_check = true;
          }
        }
      } else {
        product.product_categories_availability_check = _.cloneDeep(
          original_category.availableTotal
        );
        product.product_categories_active_check = _.cloneDeep(
          original_category.activeTotal
        );
      }
    } else {
      product.product_categories_availability_check = _.cloneDeep(
        cat.availableTotal
      );
      product.product_categories_active_check = _.cloneDeep(cat.activeTotal);
    }
  }

  hyperCategoriesCatalog(thereAreCustomerPricelist) {
    if (!this.categories || !_.isArray(this.categories)) {
      this.categories = [];
    }

    this.filterCatalogProducts(thereAreCustomerPricelist);

    this.categories = _.filter(this.catalog, (category) => {
      if (
        category.activeTotal &&
        !category.hidden &&
        !category.showOnlyInQRCatalog &&
        !_.isEmpty(category.products) &&
        (!Capacitor.isNativePlatform() ||
          (Capacitor.isNativePlatform() && !this.platform.is("ios")) ||
          (Capacitor.isNativePlatform() &&
            this.platform.is("ios") &&
            !category.ios_hide_category))
      ) {
        return category;
      }
    });

    this.allCategoriesMarketCardsDesign = true;

    _.each(this.categories, (category, k) => {
      if (
        category.subCategoriesActive &&
        category.subCategories &&
        !_.isEmpty(category.subCategories)
      ) {
        _.each(category.subCategories, (subCategory) => {
          this.subCategoriesById[subCategory.subCategoryId] = subCategory;
        });
      }
      if (category && !category.marketCardsDesign) {
        this.allCategoriesMarketCardsDesign = false;
      }
    });

    this.store.dispatch(
      new CatalogActions.SetAllCategoriesMarketCardsDesign(
        _.cloneDeep(this.allCategoriesMarketCardsDesign)
      )
    );
    // console.log("subcategories", this.subCategoriesById);

    this.store.dispatch(
      new HyperCategoriesActions.SetAllCategories(_.cloneDeep(this.categories))
    );

    if (navigator.cookieEnabled) {
      let selectedHyperCateogryId = localStorage.getItem(
        "selectedHyperCategory"
      );
      if (
        selectedHyperCateogryId &&
        _.find(this.hyperCategories, {
          hyper_category_id: selectedHyperCateogryId,
        })
      ) {
        this.store.dispatch(
          new HyperCategoriesActions.SetCurrentHyperCategory(
            _.cloneDeep(selectedHyperCateogryId)
          )
        );
      } else {
        if (selectedHyperCateogryId) {
          localStorage.removeItem("selectedHyperCategory");
        }
        this.store.dispatch(
          new HyperCategoriesActions.SetCurrentHyperCategory(
            this.hyperCategories[0].hyper_category_id
          )
        );
      }
    } else {
      this.store.dispatch(
        new HyperCategoriesActions.SetCurrentHyperCategory(
          this.hyperCategories[0].hyper_category_id
        )
      );
    }

    // this.favoritesSubscription();
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  ionViewDidLeave() {
    console.log("favorites page did leave");
    if (this.currentStoreSub) {
      this.currentStoreSub.unsubscribe();
    }
    if (this.pricelistsSub) {
      this.pricelistsSub.unsubscribe();
    }

    if (this.migrationCustomerIdSubscription) {
      this.migrationCustomerIdSubscription.unsubscribe();
    }
    if (this.loginStateSub) {
      this.loginStateSub.unsubscribe();
    }

    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }

    if (this.migrationCustomerIdSubscription) {
      this.migrationCustomerIdSubscription.unsubscribe();
    }

    this.migration_customer_id = null;
    this.windowDimensions = null;
    this.mobilePlatform = null;
    this.loading = null;
    this.favoriteProducts = null;
    this.hyperCategories = null;
    this.groupApp = null;
    this.store_info = null;
    this.stores = null;
    this.selectedStoreId = null;
    this.finalPriceLoading = null;
    this.previousProductsInCart = null;
    this.cartLength = null;
    this.customerPricelist = null;
    this.cartProducts = null;
    this.isIosPlatform = null;
    this.cartProductsNumber = null;
    this.languagePath = null;
    this.menuMinWidthToShow = null;
    this.migration_customer_id = null;
    this.changedFromUser = null;
    this.currentStoreId = null;
    this.catalog = null;
    this.categories = null;
    this.subCategoriesById = null;
    this.loginState = null;
    this.selectedHyperCategory = null;
    this.currentUser = null;
    this.selectedPreorderDate = null;
    this.selectedAddress = null;

    this.store.dispatch(new CatalogActions.ClearCatalog());
    this.store.dispatch(new HyperCategoriesActions.ClearAllCategories());
    this.store.dispatch(new HyperCategoriesActions.ClearHyperCategories());
    this.store.dispatch(new CatalogActions.SetAllCategories(null));
  }

  ngOnDestroy(): void {
    if (this.loginStateSub) {
      this.loginStateSub.unsubscribe();
    }

    if (this.migrationCustomerIdSubscription) {
      this.migrationCustomerIdSubscription.unsubscribe();
    }

    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
