<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      {{ option && option.title ? option.title : "" }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()" color="dark" fill="clear">
        <ion-icon src="assets/ionicons/close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="windowDimensions && windowDimensions.width < 600 && option">
  <ion-grid
    *ngIf="
      bundleChoiceProductsLoading ||
      (option.choices && option.choices.length && option.choices.length === 1)
    "
  >
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-spinner> </ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>

  <app-bundle-option-radio-choices
    *ngIf="
      option &&
      option.choices &&
      option.choices.length &&
      !bundleChoiceProductsLoading
    "
    [option_id]="option_id"
    [bundleMode]="bundleMode"
    [selectedProductPromo]="selectedProductPromo"
    [selectedProductCategory]="selectedProductCategory"
    [selectedProductId]="selectedProductId"
    [groupStoreData]="groupStoreData"
    [autoSelectChoice]="autoSelectChoice"
    [choicePreselectedId]="choicePreselectedId"
    [hidden]="option.choices.length === 1"
    (choicesChanged)="optionChanged($event)"
  ></app-bundle-option-radio-choices>
</ion-content>

<div
  class="inner-content"
  *ngIf="windowDimensions && windowDimensions.width >= 600 && option"
>
  <ion-grid
    *ngIf="
      bundleChoiceProductsLoading ||
      (option.choices && option.choices.length && option.choices.length === 1)
    "
  >
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-spinner color="dark"> </ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>

  <app-bundle-option-radio-choices
    *ngIf="
      option &&
      option.choices &&
      option.choices.length &&
      !bundleChoiceProductsLoading
    "
    [option_id]="option_id"
    [selectedProductPromo]="selectedProductPromo"
    [bundleMode]="bundleMode"
    [selectedProductCategory]="selectedProductCategory"
    [selectedProductId]="selectedProductId"
    [groupStoreData]="groupStoreData"
    [autoSelectChoice]="autoSelectChoice"
    [choicePreselectedId]="choicePreselectedId"
    [hidden]="option.choices.length === 1"
    (choicesChanged)="optionChanged($event)"
  ></app-bundle-option-radio-choices>
</div>

<!-- <ion-footer>
  <ion-grid>
    <ion-row style="width: 100%">
      <ion-col size="12" class="ion-text-center">
        <ion-button> OK </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer> -->
