<ion-footer color="customSideMenuBackground">
  <ion-toolbar
    [color]="
      appChannel && appChannel === 'mastercapacitor'
        ? 'notValid'
        : 'customSideMenuBackground'
    "
  >
    <ion-item
      *ngIf="
        !(
          groupApp &&
          groupApp.design_settings &&
          groupApp.design_settings.disable_app_darkmode
        )
      "
      [color]="
        appChannel && appChannel === 'mastercapacitor'
          ? 'notValid'
          : 'customSideMenuBackground'
      "
      class="ion-no-padding ion-no-margin"
      lines="none"
    >
      <ion-button
        color="changeDarkModeButton"
        fill="clear"
        slot="end"
        (click)="darkMode()"
      >
        <ion-icon
          slot="icon-only"
          *ngIf="!darkTheme"
          src="assets/ionicons/moon-outline.svg"
        >
        </ion-icon>
        <ion-icon
          slot="icon-only"
          *ngIf="darkTheme"
          src="assets/ionicons/sunny-outline.svg"
        >
        </ion-icon>
      </ion-button>
    </ion-item>
    <ion-item
      [color]="
        appChannel && appChannel === 'mastercapacitor'
          ? 'notValid'
          : 'customSideMenuBackground'
      "
      lines="none"
      *ngIf="
        stores &&
        stores.length === 1 &&
        currentStore &&
        currentStore.paymentGateways &&
        currentStore.paymentGateways.nexi &&
        currentStore.paymentGateways.nexi.active
      "
    >
      <ion-label style="font-size: small; line-height: 1.1">
        <ion-text color="medium">
          <!-- {{
            currentStore.store_name_mobileorder &&
            currentStore.store_name_mobileorder[selectedLanguage]
              ? currentStore.store_name_mobileorder[selectedLanguage]
              : currentStore.store_name_langs &&
                currentStore.store_name_langs[selectedLanguage]
              ? currentStore.store_name_langs[selectedLanguage]
              : currentStore.store_name_langs &&
                currentStore.store_name_langs.el
              ? currentStore.store_name_langs.el
              : currentStore.store_name
          }} -->

          {{ "catalog.cart-catalog.vatNumber" | translate }}:
          {{ currentStore.vatNumber }} <br />

          {{ currentStore.address }}
          <br />

          {{ currentStore.vatEmailStore ? currentStore.vatEmailStore : "" }}

          {{ currentStore.telephone ? currentStore.telephone : "" }}
          <br />

          <br />
        </ion-text>
      </ion-label>
    </ion-item>

    <ion-item
      [color]="
        appChannel && appChannel === 'mastercapacitor'
          ? 'notValid'
          : 'customSideMenuBackground'
      "
      class="creditsItemPaddings"
      lines="none"
      detail="false"
      [button]="
        groupApp &&
        groupApp.design_settings &&
        groupApp.design_settings.menuFooterTabs &&
        windowDimensions &&
        windowDimensions.width < 500
          ? false
          : true
      "
      (click)="
        groupApp &&
        groupApp.design_settings &&
        groupApp.design_settings.menuFooterTabs &&
        windowDimensions &&
        windowDimensions.width < 500
          ? ''
          : openLink('https://www.deliverymanager.gr')
      "
    >
      <!-- <ion-icon style="font-size: 80px;" slot="start" src="assets/deliveryManagerLogo/deliveryManager.svg">

      </ion-icon>
      <ion-thumbnail slot="start">
     
      </ion-thumbnail> -->
      <ion-avatar slot="start">
        <img
          *ngIf="!animationPlaying"
          [src]="
            darkTheme || showWhiteLogoDeliveryManager
              ? 'assets/deliveryManagerLogo/deliveryManagerWhite.png'
              : 'assets/deliveryManagerLogo/deliveryManager.svg'
          "
          alt="delivery-manager"
        />
        <ng-lottie
          *ngIf="startAnimation"
          (animationCreated)="animationCreated($event)"
          (domLoaded)="animStartPlaying()"
          (loopComplete)="animationLoopCompleted($event)"
          (error)="animationError($event)"
          style="z-index: 100"
          [options]="animationDeliveryManagerOptions"
        >
        </ng-lottie>
      </ion-avatar>
      <ion-label>
        <ion-text style="font-size: small" color="poweredByTextColor"
          >Powered by deliverymanager.gr</ion-text
        >
        <p>
          <ion-text color="poweredByTextColor">
            v{{ version }} © DM SOFT IKE</ion-text
          >
        </p>
      </ion-label>
    </ion-item>
  </ion-toolbar>
</ion-footer>
