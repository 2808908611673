import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged, first, Subscription } from "rxjs";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import { PaymentGatewaysService } from "src/app/services/payment-gateways/payment-gateways.service";
import * as selectors from "../../store/app.selectors";
import { VERSION } from "../../../environments/version";
import {
  AlertController,
  IonContent,
  LoadingController,
  ModalController,
  Platform,
} from "@ionic/angular";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";
import * as CartActions from "../../cart/store/cart.actions";
import * as OrderCommentsActions from "../../cart/cart-components/order-comments/store/orderComments.actions";
import * as DisableFinalPriceActions from "../../store/disable-final-price/disable-final-price.actions";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import * as selectedInvoiceActions from "../../cart/cart-components/invoice/store/selectedInvoice.actions";
import * as DeliveryMethodAddressActions from "../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import * as CartCustomerOptionsActions from "../../../app/cart/cart-components/cart-custom-options/store/cart-customer-options/cart-customer-options.actions";
import * as CartCustomerOptionsSelectionsActions from "../../../app/cart/cart-components/cart-custom-options/store/cart-custom-options-selections/cart-custom-options-selections.actions";
import { AnimationOptions } from "ngx-lottie";
import { CartPageAlertsService } from "src/app/services/cartPageAlerts/cart-page-alerts.service";
import { CreateOrderFailedPreorderModalComponent } from "../create-order-failed-preorder-modal/create-order-failed-preorder-modal.component";
import * as SelectedPaymentMethodIDActions from "../../cart/cart-components/payment-methods/store/selectePaymentMethod.actions";
import * as AuthActions from "../../auth/store/auth.actions";
import { Capacitor } from "@capacitor/core";

declare let window: any;

@Component({
  selector: "app-send-order-pay-popup",
  templateUrl: "./send-order-pay-popup.component.html",
  styleUrls: ["./send-order-pay-popup.component.scss"],
})
export class SendOrderPayPopupComponent implements OnInit, OnDestroy {
  @ViewChild("content", { static: false }) content: IonContent;
  @Input() existedOrderItem;
  @Input() existingOrderSelectedPaymentMethodID;
  public dineIn: any;
  public selectedAddress: any;
  public currentUser: any;
  public currentStoreId: any;
  public groupApp: any;
  public cartPrices: any;
  public checkBoxPromotions: any;
  public cartCustomOptions: any;
  public cart: any;
  public versionNumber: any;
  public customerInvoice: any;
  public migration_customer_id: any;
  public coupons: any;
  public orderComments: any;
  public generalComments: any;
  public orderPickup: any;
  public selectedPaymentMethod: any;
  public mo_token: any;
  public loading: boolean;
  public store_info: any;
  public version = VERSION.version;
  public cards: any;
  public selectedPaymentMethodID: any;
  public selectedLanguage: string;
  public newCard: any = { selected: false };
  public selectedPreorderDate: any;
  public ionLoading: any;
  public checkStoreOnlineInfo: any;
  public pickUpTimeIsNumber: boolean;
  public deliveryTimeIsNumber: boolean;
  public selectedDineInTable: any;
  public currentOrderPoints: any;
  public createOrderLoadingAnimation: boolean = false;
  public createOrderLoadingAnimationInstance: any;
  public selectCardModal: any;
  public carTakeAwayPlateNumber: any;
  public carTakeAwayComments: any;
  private developerMode: boolean;
  private subscription: Subscription;
  private storeInfoSubscription: Subscription;
  private storesSub: Subscription;
  private createOrderRetried = false;
  private unsubscribeBackEvent: Subscription;

  private userSubscription: Subscription;
  animationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/create-order-animation.json",
  };

  constructor(
    private modalController: ModalController,
    private platform: Platform,
    private store: Store<fromApp.AppState>,
    private paymentGatewaysService: PaymentGatewaysService,
    private dataStorageService: DataStorageService,
    private analyticsService: AnalyticsServiceService,
    private router: Router,
    private alertController: AlertController,
    private translate: TranslateService,
    private loadingController: LoadingController,
    private cartPageAlertsService: CartPageAlertsService
  ) {}

  ngOnInit() {
    // this.dataStorageService.triggerReconnectSocket();

    console.log("existed order item ", this.existedOrderItem);
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    this.storeInfoSubscription = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((storeInfo) => {
        if (storeInfo && !_.isEqual(storeInfo, this.store_info)) {
          this.store_info = _.cloneDeep(storeInfo);
          console.log("store Info from cart options", this.store_info);
        }
      });

    this.storesSub = this.store
      .select("stores")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(50))
      .subscribe((state) => {
        if (
          state &&
          state.checkStoreOnlineInfo &&
          !_.isEqual(state.checkStoreOnlineInfo, this.checkStoreOnlineInfo)
        ) {
          this.checkStoreOnlineInfo = _.cloneDeep(state.checkStoreOnlineInfo);

          if (
            this.checkStoreOnlineInfo &&
            this.checkStoreOnlineInfo.deliveryTime &&
            this.checkStoreOnlineInfo.deliveryTime
              .toString()
              .match(/^[0-9]+$/) != null
          ) {
            this.deliveryTimeIsNumber = true;
          } else {
            this.deliveryTimeIsNumber = false;
          }
          if (
            this.checkStoreOnlineInfo &&
            this.checkStoreOnlineInfo.pickupDeliveryTime &&
            this.checkStoreOnlineInfo.pickupDeliveryTime
              .toString()
              .match(/^[0-9]+$/) != null
          ) {
            this.pickUpTimeIsNumber = true;
          } else {
            this.pickUpTimeIsNumber = false;
          }
        }
      });

    this.subscription = this.store
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.auth &&
          state.auth.mo_token &&
          !_.isEqual(state.auth.mo_token, this.mo_token)
        ) {
          this.mo_token = _.cloneDeep(state.auth.mo_token);
        }
        if (
          state &&
          state.auth &&
          state.auth.migration_customer_id &&
          !_.isEqual(
            state.auth.migration_customer_id,
            this.migration_customer_id
          )
        ) {
          this.migration_customer_id = _.cloneDeep(
            state.auth.migration_customer_id
          );
        }
        if (
          state &&
          state.cartCustomerOptions &&
          state.cartCustomerOptions.cartCustomerOptions &&
          !_.isEqual(
            state.cartCustomerOptions.cartCustomerOptions,
            this.cartCustomOptions
          )
        ) {
          this.cartCustomOptions = _.cloneDeep(
            state.cartCustomerOptions.cartCustomerOptions
          );
        }
        if (
          state &&
          state.deliveryMethodAddress &&
          !_.isEqual(state.deliveryMethodAddress.orderPickup, this.orderPickup)
        ) {
          this.orderPickup = _.cloneDeep(
            state.deliveryMethodAddress.orderPickup
          );
        }

        if (
          state &&
          state.deliveryMethodAddress &&
          !_.isEqual(
            state.deliveryMethodAddress.carTakeAwayComments,
            this.carTakeAwayComments
          )
        ) {
          this.carTakeAwayComments = _.cloneDeep(
            state.deliveryMethodAddress.carTakeAwayComments
          );
        }

        if (
          state &&
          state.deliveryMethodAddress &&
          !_.isEqual(
            state.deliveryMethodAddress.carTakeAwayPlateNumber,
            this.carTakeAwayPlateNumber
          )
        ) {
          this.carTakeAwayPlateNumber = _.cloneDeep(
            state.deliveryMethodAddress.carTakeAwayPlateNumber
          );
        }

        if (
          state &&
          state.deliveryMethodAddress &&
          !_.isEqual(state.deliveryMethodAddress.dineIn, this.dineIn)
        ) {
          this.dineIn = _.cloneDeep(state.deliveryMethodAddress.dineIn);
        }
        if (
          state &&
          state.deliveryMethodAddress &&
          !_.isEqual(
            state.deliveryMethodAddress.selectedDineInTable,
            this.selectedDineInTable
          )
        ) {
          this.selectedDineInTable = _.cloneDeep(
            state.deliveryMethodAddress.selectedDineInTable
          );
        }
        if (
          state &&
          state.currentStore &&
          state.currentStore.currentStoreId &&
          !_.isEqual(state.currentStore.currentStoreId, this.currentStoreId)
        ) {
          this.currentStoreId = _.cloneDeep(state.currentStore.currentStoreId);
        }
        if (
          state &&
          state.deliveryMethodAddress &&
          state.deliveryMethodAddress.selectedAddress &&
          !_.isEqual(
            state.deliveryMethodAddress.selectedAddress,
            this.selectedAddress
          )
        ) {
          this.selectedAddress = _.cloneDeep(
            state.deliveryMethodAddress.selectedAddress
          );
        }
        if (
          state &&
          state.groupApp &&
          state.groupApp.groupApp &&
          !_.isEqual(state.groupApp.groupApp, this.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp.groupApp);

          if (this.groupApp.design_settings) {
            if (this.groupApp.design_settings.animation_loader) {
              this.animationOptions = {
                ...this.animationOptions,
                path:
                  "https://data.deliverymanager.gr/animations/" +
                  this.groupApp.design_settings.animation_loader,
              };
            } else {
              this.animationOptions = {
                ...this.animationOptions,
                path: "https://data.deliverymanager.gr/animations/spinner-default-1.json",
              };
            }
          } else {
            this.animationOptions = {
              ...this.animationOptions,
              path: "https://data.deliverymanager.gr/animations/spinner-default-1.json",
            };
          }
        }
        if (
          state &&
          state.groupApp &&
          state.groupApp.selectedLangugage &&
          !_.isEqual(state.groupApp.selectedLangugage, this.selectedLanguage)
        ) {
          this.selectedLanguage = _.cloneDeep(state.groupApp.selectedLangugage);
        }
        if (
          state &&
          state.checkboxPromotions &&
          state.checkboxPromotions.checkboxPromotions &&
          !_.isEqual(
            state.checkboxPromotions.checkboxPromotions,
            this.checkBoxPromotions
          )
        ) {
          this.checkBoxPromotions = _.cloneDeep(
            state.checkboxPromotions.checkboxPromotions
          );
        }
        if (
          state &&
          state.cartPrices &&
          state.cartPrices.cartPrices &&
          !_.isEqual(state.cartPrices.cartPrices, this.cartPrices)
        ) {
          this.cartPrices = _.cloneDeep(state.cartPrices.cartPrices);
          if (this.cartPrices && this.cartPrices.currentOrderPoints) {
            this.currentOrderPoints = _.cloneDeep(
              this.cartPrices.currentOrderPoints
            );
          }
          if (this.cart && this.cartPrices) {
            this.analyticsService.fbqTrackInitiateCheckout(
              _.cloneDeep(this.cart)
            );
            this.analyticsService.tikTokPixelInitiateCheckout(
              _.cloneDeep(this.cart),
              this.cartPrices.grand_total
            );
          }
        }
        if (
          state &&
          state.cart &&
          state.cart.products &&
          !_.isEqual(state.cart.products, this.cart)
        ) {
          this.cart = _.cloneDeep(state.cart.products);

          if (this.cart && this.cartPrices) {
            this.analyticsService.fbqTrackInitiateCheckout(
              _.cloneDeep(this.cart)
            );
            this.analyticsService.tikTokPixelInitiateCheckout(
              _.cloneDeep(this.cart),
              this.cartPrices.grand_total
            );
          }
        }
        if (
          state &&
          state.orderComments &&
          state.orderComments.generalComments &&
          !_.isEqual(state.orderComments.generalComments, this.orderComments)
        ) {
          this.orderComments = _.cloneDeep(state.orderComments.generalComments);
        }
        if (
          state &&
          state.selectedInvoice &&
          state.selectedInvoice.selectedInvoice &&
          !_.isEqual(state.selectedInvoice, this.customerInvoice)
        ) {
          this.customerInvoice = _.cloneDeep(
            state.selectedInvoice.selectedInvoice
          );
        }
        if (
          state &&
          state.cartActiveCoupons &&
          state.cartActiveCoupons.activeCoupons &&
          !_.isEqual(state.cartActiveCoupons.activeCoupons, this.coupons)
        ) {
          this.coupons = _.cloneDeep(state.cartActiveCoupons.activeCoupons);
        }
        if (
          state.selectedPaymentMethodID &&
          state.selectedPaymentMethodID.selectedPaymentMethodID &&
          !this.existedOrderItem &&
          !_.isEqual(
            state.selectedPaymentMethodID.selectedPaymentMethodID,
            this.selectedPaymentMethod
          )
        ) {
          this.selectedPaymentMethod = _.cloneDeep(
            state.selectedPaymentMethodID.selectedPaymentMethodID
          );
        }
        if (
          state.deliveryMethodAddress &&
          state.deliveryMethodAddress.selectedPreorderDate &&
          !_.isEqual(
            state.deliveryMethodAddress.selectedPreorderDate,
            this.selectedPreorderDate
          )
        ) {
          this.selectedPreorderDate = _.cloneDeep(
            state.deliveryMethodAddress.selectedPreorderDate
          );
          console.log("selected preorder date", this.selectedPreorderDate);
        }

        if (
          state.developerMode &&
          state.developerMode.developerMode &&
          !_.isEqual(state.developerMode.developerMode, this.developerMode)
        ) {
          this.developerMode = _.cloneDeep(state.developerMode.developerMode);
        }
      });

    this.userSubscription = this.store
      .select("auth")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(state.user, this.currentUser)) {
          this.currentUser = _.cloneDeep(state.user);
          // this.currentUser.cards = this.testUserCards;
          if (!this.existedOrderItem) {
            this.initializeUserCards(null);
          } else {
            this.initializeUserCards(true);
          }
        }
      });

    console.log("selected payment method", this.selectedPaymentMethodID);
  }

  initializeUserCards(existingOrder) {
    if (!existingOrder) {
      console.log("initializeUserCards");
      this.store
        .select("selectedPaymentMethodID")
        .pipe(first())
        .subscribe((state) => {
          if (state && state.selectedPaymentMethodID) {
            console.log(
              "selectedPaymentMethodID",
              state.selectedPaymentMethodID
            );
            this.selectedPaymentMethodID = _.cloneDeep(
              state.selectedPaymentMethodID
            );
            this.store
              .select("stores")
              .pipe(first())
              .subscribe((stores) => {
                if (stores && stores.stores) {
                  console.log("stores", stores.stores);
                  this.store
                    .select("currentStore")
                    .pipe(first())
                    .subscribe((currentStore) => {
                      if (currentStore && currentStore.currentStoreId) {
                        console.log(
                          "current store",
                          currentStore.currentStoreId
                        );
                        this.store_info = _.cloneDeep(
                          _.find(stores.stores, {
                            store_id: currentStore.currentStoreId,
                          })
                        );

                        console.log("store info ", this.store_info);
                        if (
                          this.store_info &&
                          this.store_info.paymentGateways &&
                          this.selectedPaymentMethodID &&
                          this.selectedPaymentMethodID !== "viva" &&
                          this.store_info.paymentGateways[
                            this.selectedPaymentMethodID
                          ].tokenization
                        ) {
                          const assigned_cards = _.cloneDeep(
                            this.currentUser.cards
                          );
                          //select the already selected card before assign cards
                          if (
                            this.cards &&
                            this.cards.length &&
                            assigned_cards &&
                            assigned_cards.length
                          ) {
                            _.each(this.cards, (card) => {
                              if (card && card.selected) {
                                const index = _.findIndex(assigned_cards, {
                                  timestamp: card.timestamp,
                                });
                                if (index !== -1) {
                                  this.newCard.selected = false;
                                  assigned_cards[index].selected = true;

                                  return false;
                                }
                              }
                            });
                          }

                          this.cards = assigned_cards;
                        } else {
                          this.cards = [];
                        }

                        this.newCard.tokenization = _.cloneDeep(
                          this.store_info.paymentGateways[
                            this.selectedPaymentMethodID
                          ].tokenization
                        );
                        this.cards = _.filter(
                          _.cloneDeep(this.cards),
                          (card) => {
                            return (
                              card.paymentGateway ===
                                this.selectedPaymentMethodID.replace(
                                  "-masterpass",
                                  ""
                                ) &&
                              _.find(card.stores, (store) => {
                                return (
                                  store.store_id === this.store_info.store_id &&
                                  store.publicKey ===
                                    this.paymentGatewaysService.fetchUniquePaymentGatewayAttr(
                                      this.store_info.store_id,
                                      card.paymentGateway
                                    ).value
                                );
                              })
                            );
                          }
                        );
                        if (this.cards && this.cards.length > 0) {
                          if (
                            !_.find(this.cards, {
                              selected: true,
                            }) &&
                            !this.newCard.selected
                          ) {
                            this.cards[0].selected = true;
                            this.newCard.selected = false;
                          }
                        }

                        if (_.isEmpty(this.cards)) {
                          this.newCard.selected = true;
                        }
                      }
                    })
                    .unsubscribe();
                }
              })
              .unsubscribe();
          }
        })
        .unsubscribe();
    } else {
      console.log("initializeUserCards");
      if (this.existingOrderSelectedPaymentMethodID) {
        this.selectedPaymentMethodID = _.cloneDeep(
          this.existingOrderSelectedPaymentMethodID
        );
      }

      this.store
        .select("stores")
        .pipe(first())
        .subscribe((stores) => {
          if (stores && stores.stores) {
            console.log("stores", stores.stores);
            this.store
              .select("currentStore")
              .pipe(first())
              .subscribe((currentStore) => {
                if (currentStore && currentStore.currentStoreId) {
                  console.log("current store", currentStore.currentStoreId);
                  this.store_info = _.cloneDeep(
                    _.find(stores.stores, {
                      store_id: currentStore.currentStoreId,
                    })
                  );

                  console.log("store info ", this.store_info);
                  this.cards = this.currentUser.cards;
                  if (
                    this.store_info &&
                    this.selectedPaymentMethodID &&
                    this.selectedPaymentMethodID !== "viva" &&
                    this.store_info.paymentGateways &&
                    this.store_info.paymentGateways[
                      this.selectedPaymentMethodID
                    ].tokenization
                  ) {
                    const assigned_cards = _.cloneDeep(this.currentUser.cards);
                    //select the already selected card before assign cards
                    if (
                      this.cards &&
                      this.cards.length &&
                      assigned_cards &&
                      assigned_cards.length
                    ) {
                      _.each(this.cards, (card) => {
                        if (card && card.selected) {
                          const index = _.findIndex(assigned_cards, {
                            timestamp: card.timestamp,
                          });
                          if (index !== -1) {
                            this.newCard.selected = false;
                            assigned_cards[index].selected = true;

                            return false;
                          }
                        }
                      });
                    }

                    this.cards = assigned_cards;
                  } else {
                    this.cards = [];
                  }
                  this.newCard.tokenization = _.cloneDeep(
                    this.store_info.paymentGateways[
                      this.selectedPaymentMethodID
                    ].tokenization
                  );
                  this.cards = _.filter(_.cloneDeep(this.cards), (card) => {
                    return (
                      card.paymentGateway ===
                        this.selectedPaymentMethodID.replace(
                          "-masterpass",
                          ""
                        ) &&
                      _.find(card.stores, (store) => {
                        return (
                          store.store_id === this.store_info.store_id &&
                          store.publicKey ===
                            this.paymentGatewaysService.fetchUniquePaymentGatewayAttr(
                              this.store_info.store_id,
                              card.paymentGateway
                            ).value
                        );
                      })
                    );
                  });
                  if (this.cards && this.cards.length > 0) {
                    if (
                      !_.find(this.cards, {
                        selected: true,
                      }) &&
                      !this.newCard.selected
                    ) {
                      this.newCard.selected = false;
                      this.cards[0].selected = true;
                    }
                  }

                  if (_.isEmpty(this.cards)) {
                    this.newCard.selected = true;
                  }
                }
              })
              .unsubscribe();
          }
        })
        .unsubscribe();
    }
  }

  trackCards(index) {
    return index;
  }

  addNewCard() {
    this.newCard.selected = true;
    _.each(this.cards, (card, key) => {
      this.cards[key].selected = false;
    });
    this.scrollToBottom();
  }

  async scrollToBottom() {
    await this.content.scrollToPoint(
      0,
      (
        await this.content.getScrollElement()
      ).scrollHeight,
      300
    );
  }

  selectCard(index) {
    this.newCard.selected = false;

    _.each(this.cards, (card, key) => {
      if (index !== key) {
        this.cards[key].selected = false;
      } else {
        this.cards[key].selected = true;
      }
      //TO_DO CHECK THIS
      // $rootScope.billingInfo = card.billingInfo || {};
    });
  }

  checkForSendOrder() {
    var selectedCard = _.find(this.cards, {
      selected: true,
    });

    console.log("selectedCard", selectedCard, this.newCard);
    if (selectedCard && selectedCard.invalid) {
      this.errorAlert(
        this.translate.instant(
          "popups.send-order-and-pay-popup.the-selected-card-should-be-saved-again"
        )
      );
    } else if (
      this.newCard &&
      this.newCard.selected &&
      !this.newCard.acceptedTerms
    ) {
      this.errorAlert(
        this.translate.instant(
          "Θα πρέπει να αποδεχτείτε πρώτα τους όρους χρήσης και την πολιτική επιστροφής!"
        )
      );

      //This appears only when adding the card the first time not with recurring payments
    } else {
      this.sendOrder();
    }
  }

  payChangedOrderPaymentMethod() {
    if (
      this.selectedPaymentMethodID !== "cash" &&
      this.selectedPaymentMethodID !== "mobilePOS"
    ) {
      if (
        this.store_info.paymentGateways[
          this.existingOrderSelectedPaymentMethodID
        ].tokenization
      ) {
        var selectedCard = _.find(this.cards, {
          selected: true,
        });
        console.log("selectedCard", selectedCard);

        var selectedCardStore;

        if (selectedCard) {
          selectedCardStore = _.find(selectedCard.stores, {
            store_id: this.existedOrderItem.store_id,
          });
        }

        if (selectedCard && selectedCard.invalid) {
          this.errorAlert(
            this.translate.instant(
              "popups.send-order-and-pay-popup.the-selected-card-should-be-saved-again"
            )
          );
        } else if (
          this.newCard &&
          this.newCard.selected &&
          !this.newCard.acceptedTerms
        ) {
          this.errorAlert(
            this.translate.instant(
              "Θα πρέπει να αποδεχτείτε πρώτα τους όρους χρήσης και την πολιτική επιστροφής!"
            )
          );

          //This appears only when adding the card the first time not with recurring payments
        } else {
          if (
            selectedCardStore &&
            this.selectedPaymentMethodID !== "eurobank" &&
            this.selectedPaymentMethodID !== "piraeus" &&
            this.selectedPaymentMethodID !== "nexi" &&
            this.selectedPaymentMethodID !== "alpha"
          ) {
            console.log("selectedCardStore", selectedCardStore);
            //recurring payment
            console.log("requrring payment proccess", selectedCardStore);
            this.startRecurringPaymentProcess(
              this.existedOrderItem.selectedPaymentMethodID,
              this.existedOrderItem.order_id,
              this.existedOrderItem.store_id,
              selectedCardStore.token
                ? selectedCardStore.token
                : selectedCardStore.transaction_id,
              selectedCard.number
            );
            this.closeModal();
          } else {
            console.log("else selected method", this.selectedPaymentMethodID);
            let PaymentProcess = {
              orderItem: this.existedOrderItem,
            };
            let save_card_from_order =
              this.newCard && this.newCard.selected && this.newCard.save_card
                ? "scfo"
                : "dnsc";

            this.store_info.paymentGateways[this.selectedPaymentMethodID] =
              _.extend(
                this.store_info.paymentGateways[this.selectedPaymentMethodID],
                {
                  store_id: this.store_info.store_id,
                  id: this.existingOrderSelectedPaymentMethodID,
                }
              );

            this.paymentGatewaysService.selectedPaymentGateway(
              parseFloat(this.existedOrderItem.grand_total),
              this.store_info.paymentGateways[
                this.existingOrderSelectedPaymentMethodID
              ],
              "order",
              selectedCardStore,
              this.existedOrderItem.order_id,
              this.existedOrderItem.store_id,
              selectedCard ? selectedCard.number : null,
              selectedCard ? selectedCard.cardType : null,
              this.existedOrderItem.formatted_address,
              save_card_from_order,
              PaymentProcess,
              !!this.existedOrderItem.fixed_address_id
            );
            this.closeModal();
          }
        }
      } else if (
        this.store_info.paymentGateways[
          this.existingOrderSelectedPaymentMethodID
        ] &&
        !this.store_info.paymentGateways[
          this.existingOrderSelectedPaymentMethodID
        ].tokenization
      ) {
        if (
          this.newCard &&
          this.newCard.selected &&
          !this.newCard.acceptedTerms
        ) {
          this.errorAlert(
            this.translate.instant(
              "Θα πρέπει να αποδεχτείτε πρώτα τους όρους χρήσης και την πολιτική επιστροφής!"
            )
          );
        } else {
          console.log("else selected method", this.selectedPaymentMethodID);
          let PaymentProcess = {
            orderItem: this.existedOrderItem,
          };

          this.store_info.paymentGateways[this.selectedPaymentMethodID] =
            _.extend(
              this.store_info.paymentGateways[this.selectedPaymentMethodID],
              {
                store_id: this.store_info.store_id,
                id: this.existingOrderSelectedPaymentMethodID,
              }
            );

          this.paymentGatewaysService.selectedPaymentGateway(
            parseFloat(this.existedOrderItem.grand_total),
            this.store_info.paymentGateways[
              this.existingOrderSelectedPaymentMethodID
            ],
            "order",
            null,
            this.existedOrderItem.order_id,
            this.existedOrderItem.store_id,
            null,
            null,
            this.existedOrderItem.formatted_address,
            "dnsc",
            PaymentProcess,
            !!this.existedOrderItem.fixed_address_id
          );
          this.closeModal();
          console.log("no tokenazations");
        }
      }
    }
  }

  sendOrder() {
    let platform = "browser";
    if (!Capacitor.isNativePlatform() && this.inIframe()) {
      platform = "facebook";
    } else if (Capacitor.isNativePlatform()) {
      platform = Capacitor.getPlatform();
    }
    var selectedCoupons = _.filter(this.coupons, {
      couponCheckbox: true,
    });
    this.loading = true;
    this.createOrderLoadingAnimation = true;
    const langs = _.cloneDeep(this.groupApp.languages);
    let baseLanguage;
    const moBaseLanguage = _.find(langs, { mo_base: true });
    if (moBaseLanguage && moBaseLanguage.code) {
      baseLanguage = moBaseLanguage.code;
    }

    this.dataStorageService

      .createOrder(
        {
          store_id: this.currentStoreId,
          firstName: this.currentUser.firstName,
          lastName: this.currentUser.lastName,
          group: this.currentUser.group,
          selectedLanguage: this.selectedLanguage,
          baseLanguage: baseLanguage || "el",
          generalComments: this.orderComments, //Comments for ORDER
          pickup: this.orderPickup,
          dinein: this.dineIn,
          dinein_table_id:
            this.selectedDineInTable && this.selectedDineInTable.table_id
              ? this.selectedDineInTable.table_id
              : null,
          dinein_table_name:
            this.selectedDineInTable && this.selectedDineInTable.name
              ? this.selectedDineInTable.name
              : null,
          deliveryCost: this.cartPrices.deliveryCost,
          versionNumber: this.version,
          checkboxPromotions: this.checkBoxPromotions,
          cartCustomOptions: this.cartCustomOptions
            ? this.cartCustomOptions
            : [],
          coupons: selectedCoupons,
          cart: this.cart,
          customerInvoice:
            this.store_info.customerInvoice && this.customerInvoice
              ? this.customerInvoice
              : {},
          selectedPaymentMethodID: this.selectedPaymentMethod,
          paymentTimestamp: "",
          address_id:
            this.orderPickup || this.dineIn
              ? ""
              : this.selectedAddress.address_id,
          address_unverified:
            this.orderPickup || this.dineIn
              ? false
              : this.selectedAddress.unverified,
          floor:
            this.orderPickup || this.dineIn ? "" : this.selectedAddress.floor,
          company:
            this.orderPickup || this.dineIn ? "" : this.selectedAddress.company,
          company_manager:
            this.orderPickup || this.dineIn
              ? ""
              : this.selectedAddress.company_manager,
          comments:
            this.orderPickup || this.dineIn
              ? ""
              : this.selectedAddress.comments,
          userLat:
            this.orderPickup || this.dineIn ? "" : this.selectedAddress.userLat,
          userLng:
            this.orderPickup || this.dineIn ? "" : this.selectedAddress.userLng,
          formatted_address:
            this.orderPickup || this.dineIn
              ? ""
              : this.selectedAddress.formatted_address,
          postCode:
            this.orderPickup || this.dineIn
              ? ""
              : this.selectedAddress &&
                this.selectedAddress.address_components &&
                this.selectedAddress.address_components.postal_code
              ? this.selectedAddress.address_components.postal_code
              : null,
          doorbellname:
            this.orderPickup || this.dineIn
              ? ""
              : this.selectedAddress.doorbellname,
          altPhone:
            this.orderPickup || this.dineIn
              ? ""
              : this.selectedAddress.altPhone,
          platform: platform,
          timezone: this.store_info.timezone || "Europe/Athens",
          preorder: this.selectedPreorderDate ? true : false,
          preorder_day:
            this.selectedPreorderDate && this.selectedPreorderDate.date
              ? this.selectedPreorderDate.date
              : "",
          preorder_time:
            this.selectedPreorderDate && this.selectedPreorderDate.hour
              ? this.selectedPreorderDate.hour
              : "",
          preorderAcceptDisableDeliveryTime:
            this.selectedPreorderDate &&
            this.selectedPreorderDate.preorderAcceptDisableDeliveryTime
              ? true
              : false,
          preorderAcceptDisablePickupTime:
            this.selectedPreorderDate &&
            this.selectedPreorderDate.preorderAcceptDisablePickupTime
              ? true
              : false,

          migration_customer_id:
            this.store_info && this.store_info.vatBasedCustomerProductsActive
              ? this.migration_customer_id
              : null,
          channel: this.developerMode ? "mastercapacitor" : "Capacitor",
          carTakeAwayPlateNumber:
            this.orderPickup && !!this.carTakeAwayPlateNumber
              ? this.carTakeAwayPlateNumber
              : null,
          carTakeAwayComments:
            this.orderPickup && !!this.carTakeAwayComments
              ? this.carTakeAwayComments
              : null,
        },
        this.mo_token
      )
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            this.analyticsService.fbqTrackPurchase(
              this.cartPrices.grand_total,
              this.cart
            );

            this.analyticsService.tikTokPixelPurchase(
              this.cart,
              this.cartPrices.grand_total
            );

            this.analyticsService.logEventPurchase(
              res.orderItem.order_id,
              res.orderItem.grand_total
            );
            this.analyticsService.dmAnalyticsMessage(
              "complete_order_success",
              null,
              null,
              null,
              null,
              res.orderItem.order_id,
              null,
              null,
              null,
              null,
              null
            );

            this.createOrderRetried = false;
            this.loading = false;
            this.createOrderLoadingAnimation = false;

            this.cartPageAlertsService.clearPendingOrderAlertAlreadyShowedInCompleteOrder();
            this.store.dispatch(new CartActions.ClearCart());

            this.store.dispatch(
              new selectedInvoiceActions.ClearSelectedInvoice()
            );
            this.store.dispatch(
              new DeliveryMethodAddressActions.setSelectedPreorderDate(null)
            );
            this.store.dispatch(
              new OrderCommentsActions.SetOrderComments(null)
            );

            if (navigator.cookieEnabled) {
              window.localStorage.removeItem("cart");
              window.localStorage.removeItem("cartCustomOptions");
              if (localStorage.getItem("orderComments")) {
                localStorage.removeItem("orderComments");
              }
            }

            this.store.dispatch(
              new CartCustomerOptionsActions.ClearCartCustomerOptions()
            );

            this.store.dispatch(
              new CartCustomerOptionsSelectionsActions.ClearCartCustomOptionsSelections()
            );
            if (
              this.store_info &&
              this.store_info.cartCustomOptions &&
              this.store_info.cartCustomOptions.length > 0
            ) {
              let customOptions = _.cloneDeep(
                this.store_info.cartCustomOptions
              );
              customOptions.forEach((option) => {
                this.store.dispatch(
                  new CartCustomerOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
                    option
                  )
                );
              });
            }

            if (
              res &&
              res.orderItem &&
              res.orderItem.selectedPaymentMethodID &&
              !_.isEqual(
                res.orderItem.selectedPaymentMethodID,
                this.selectedPaymentMethodID
              )
            ) {
              this.selectedPaymentMethodID = _.cloneDeep(
                res.orderItem.selectedPaymentMethodID
              );
              this.store.dispatch(
                new SelectedPaymentMethodIDActions.SetSelectedPaymentMethodID(
                  _.cloneDeep(res.orderItem.selectedPaymentMethodID)
                )
              );
            }

            if (res.updatedCustomer && !_.isEmpty(res.updatedCustomer)) {
              this.store.dispatch(
                new AuthActions.SetUser(_.cloneDeep(res.updatedCustomer))
              );
            }

            if (
              this.selectedPaymentMethodID !== "cash" &&
              this.selectedPaymentMethodID !== "mobilePOS" &&
              this.store_info &&
              this.store_info.paymentGateways[this.selectedPaymentMethodID]
                .active
            ) {
              var selectedCard = _.find(this.cards, {
                selected: true,
              });
              console.log("selectedCard", selectedCard);

              var selectedCardStore;

              if (selectedCard) {
                selectedCardStore = _.find(selectedCard.stores, {
                  store_id: res.orderItem.store_id,
                });
              }

              if (
                selectedCardStore &&
                this.selectedPaymentMethodID !== "eurobank" &&
                this.selectedPaymentMethodID !== "nexi" &&
                this.selectedPaymentMethodID !== "piraeus" &&
                this.selectedPaymentMethodID !== "alpha"
              ) {
                console.log("selectedCardStore", selectedCardStore);
                //recurring payment
                this.startRecurringPaymentProcess(
                  res.orderItem.selectedPaymentMethodID,
                  res.orderItem.order_id,
                  res.orderItem.store_id,
                  selectedCardStore.token
                    ? selectedCardStore.token
                    : selectedCardStore.transaction_id,
                  selectedCard.number
                );
              } else {
                let PaymentProcess = {
                  orderItem: res.orderItem,
                };
                let save_card_from_order =
                  this.newCard &&
                  this.newCard.selected &&
                  this.newCard.save_card
                    ? "scfo"
                    : "dnsc";

                this.store_info.paymentGateways[this.selectedPaymentMethodID] =
                  _.extend(
                    this.store_info.paymentGateways[
                      this.selectedPaymentMethodID
                    ],
                    {
                      store_id: this.store_info.store_id,
                      id: this.selectedPaymentMethodID,
                    }
                  );

                this.paymentGatewaysService.selectedPaymentGateway(
                  parseFloat(res.orderItem.grand_total),
                  this.store_info.paymentGateways[this.selectedPaymentMethodID],
                  "order",
                  selectedCardStore,
                  res.orderItem.order_id,
                  res.orderItem.store_id,
                  selectedCard ? selectedCard.number : null,
                  selectedCard ? selectedCard.cardType : null,
                  this.orderPickup || this.dineIn
                    ? ""
                    : this.selectedAddress?.formatted_address,
                  save_card_from_order,
                  PaymentProcess,
                  !!res.orderItem.fixed_address_id
                );

                if (
                  (!this.orderPickup &&
                    !this.dineIn &&
                    this.selectedAddress &&
                    this.selectedAddress.fixed_address_id) ||
                  (this.orderPickup === false && this.dineIn === true)
                ) {
                  //clear delivery method state on fixed address OR on dine in order!!!
                  setTimeout(() => {
                    if (!this.currentUser?.uposMoUser) {
                      this.clearDeliveryMethod();
                    }
                  }, 600);
                }
              }
            } else {
              //TO_DO CHECK IF YOU WANT TO PRINT COMMENT_ID
              // Toast.show((res.comment_id ? (this.languages[res.comment_id] ? this.languages[res.comment_id][this.selectedLanguage] : res.comment_id) : res.comments));
            }

            this.closeModal();
            //this.presentOrderCompletedAlert();

            this.router.navigateByUrl("/orders", {
              replaceUrl: true,
            });
          } else if (!res.success && res.retry && !this.createOrderRetried) {
            this.analyticsService.dmAnalyticsMessage(
              "complete_order_failed",
              res.comment_id,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null
            );
            this.createOrderRetried = true;
            setTimeout(() => {
              this.sendOrder();
            }, 20000);
          } else {
            this.loading = false;
            this.createOrderLoadingAnimation = false;

            this.createOrderRetried = false;
            this.store.dispatch(
              new DisableFinalPriceActions.SetTriggerFinalPriceTimestamp(
                Date.now().toString()
              )
            );

            if (res.comment_id === "prompt_for_preorder") {
              this.modalController.dismiss();
              this.presentPreorderInfoModal();
            } else {
              if (res.comment_id) {
                this.presentErrorAlert(res.comment_id);
              } else {
                this.presentErrorAlert(res.comments);
              }
            }

            this.analyticsService.dmAnalyticsMessage(
              "complete_order_failed",
              res.comment_id,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null
            );
          }
        },
        error: (error) => {
          console.log("Error on sending order: ", error);

          if (!this.createOrderRetried) {
            this.createOrderRetried = true;
            this.analyticsService.dmAnalyticsMessage(
              "complete_order_failed",
              "create_order_failed_will_retry",
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null
            );
            setTimeout(() => {
              this.sendOrder();
            }, 5000);
          } else {
            this.analyticsService.dmAnalyticsMessage(
              "complete_order_failed",
              "create_order_failed_after_retry",
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null
            );
            this.createOrderRetried = false;
            this.loading = false;
            this.createOrderLoadingAnimation = false;
            this.presentErrorAlert(
              "errorMessages.sending-you-order-failed-please-try-again"
            );
          }
        },
      });
  }

  async presentPreorderInfoModal() {
    const modal = await this.modalController.create({
      component: CreateOrderFailedPreorderModalComponent,
      cssClass: "create-order-failed-preorder-modal",
      backdropDismiss: false,
    });
    await modal.present();
  }

  animationCreated(ev) {
    this.createOrderLoadingAnimationInstance = ev;
    this.createOrderLoadingAnimationInstance.setSpeed(1);
  }

  inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  async loadingSimulation() {
    await this.presentLoading();
    setTimeout(async () => {
      if (this.ionLoading) {
        await this.ionLoading.dismiss();
        this.ionLoading = null;
      }
    }, 1500);
  }

  async presentLoading() {
    console.log("present loading from send order pay popup");
    let message = this.translate.instant("loading-please-wait");
    this.ionLoading = await this.loadingController.create({
      duration: 20000,
      message: message,
      cssClass: "ionicLoadingCss",
      keyboardClose: true,
    });
    return await this.ionLoading.present();
  }

  async startRecurringPaymentProcess(
    selectedPaymentMethodID,
    order_id,
    store_id,
    token,
    card_number
  ) {
    await this.presentLoading();
    this.dataStorageService
      .createCardRecurringTransaction(
        {
          selectedPaymentMethodID: selectedPaymentMethodID,
          order_id: order_id,
          languageCode: this.selectedLanguage,
          store_id: store_id,
          viva3ds: false,
          card_number: card_number,
          token: token,
        },
        this.mo_token
      )
      .subscribe({
        next: async (res: any) => {
          if (res && res.success) {
            if (this.ionLoading) {
              await this.ionLoading.dismiss();
              this.ionLoading = null;
            }
            setTimeout(async () => {
              if (this.ionLoading) {
                await this.ionLoading.dismiss();
                this.ionLoading = null;
              }
              const alert = await this.alertController.create({
                header: this.translate.instant("alert"),
                message: res.comment_id
                  ? this.translate.instant(res.comment_id)
                  : this.translate.instant(res.comments),

                buttons: ["OK"],
              });
              await alert.present();
            }, 1500);
          } else {
            if (this.ionLoading) {
              await this.ionLoading.dismiss();
              this.ionLoading = null;
            }

            if (res) {
              setTimeout(async () => {
                const alert = await this.alertController.create({
                  header: this.translate.instant("alert"),
                  message: res.comment_id
                    ? this.translate.instant(res.comment_id)
                    : this.translate.instant(res.comments),

                  buttons: ["OK"],
                });
                await alert.present();
              }, 1500);
            }
          }
        },
        error: async (err) => {
          if (this.ionLoading) {
            await this.ionLoading.dismiss();
            this.ionLoading = null;
          }
          const alert = await this.alertController.create({
            header: this.translate.instant("alert"),
            message: this.translate.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          return err;
        },
      });
  }

  closeModal() {
    this.modalController.dismiss();
  }

  async errorAlert(message) {
    const alert = await this.alertController.create({
      header: this.translate.instant("alert"),

      message: this.translate.instant(message),
      buttons: ["OK"],
    });

    await alert.present();
  }

  async presentErrorAlert(message) {
    const alert = await this.alertController.create({
      cssClass: "errorOnOrderCompletedAlert",
      header: this.translate.instant("errorMessages.alert"),

      message: this.translate.instant(message),
      buttons: ["OK"],
    });

    await alert.present();
  }

  clearDeliveryMethod() {
    this.store.dispatch(
      new DeliveryMethodAddressActions.setSelectedAddress(_.cloneDeep(null))
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setValidStores(_.cloneDeep(null))
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setValidDeliveryAreas(_.cloneDeep(null))
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setOpenNotValidAddressModal(false)
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setOpenSelectStorePopupDelivery(false)
    );
    this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(null));
    this.store.dispatch(new DeliveryMethodAddressActions.setOrderPickup(null));
    this.store.dispatch(
      new DeliveryMethodAddressActions.setSelectedDineInTable(null)
    );

    this.store.dispatch(
      new DeliveryMethodAddressActions.setCarTakeawayPlateNumber(null)
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setCarTakewayComments(null)
    );

    if (navigator.cookieEnabled) {
      let deliveryMethodAddressObj: any = localStorage.getItem(
        "deliveryMethodAddress"
      );
      if (deliveryMethodAddressObj) {
        deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
        deliveryMethodAddressObj.dineIn = null;
        deliveryMethodAddressObj.orderPickup = null;
        deliveryMethodAddressObj.selectedAddress = null;
        deliveryMethodAddressObj.selectedDineInTable = null;
        deliveryMethodAddressObj.carTakeAwayComments = null;
        deliveryMethodAddressObj.carTakeAwayPlateNumber = null;

        localStorage.setItem(
          "deliveryMethodAddress",
          JSON.stringify(deliveryMethodAddressObj)
        );
      }
    }
  }

  ngOnDestroy() {
    if (this.selectCardModal) {
      this.selectCardModal.dismiss();
      this.selectCardModal = null;
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.storeInfoSubscription) {
      this.storeInfoSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
