<div *ngIf="hyperCategories" scrollY="true" class="scrollHeight">
  <ion-list class="ion-no-margin">
    <span *ngFor="let hyperCategory of hyperCategories; trackBy: trackFn">
      <ion-item
        detail="false"
        button
        lines="none"
        detail="false"
        [ngClass]="{
          buttonBold: hyperCategory.hyper_category_id === selectedHyperCategory
        }"
        (click)="HyperCategorySelection(hyperCategory)"
      >
        <ion-icon
          *ngIf="
            icons_hyper_categories_design &&
            hyperCategory.mobileorder_hyper_category_icon
          "
          [color]="
            hyperCategory.hyper_category_id === selectedHyperCategory
              ? 'selected'
              : 'verticalCategories'
          "
          style="
            font-size: 32px;
            margin-right: 10px;
            margin-bottom: 5px;
            margin-top: 5px;
          "
          slot="start"
          [src]="
            hyperCategoryIconsLink +
            hyperCategory.mobileorder_hyper_category_icon
          "
        >
        </ion-icon>

        <ion-label
          style="line-height: 1.1"
          class="ion-text-wrap paddingNameLabel"
          ><ion-text style="font-size: large; line-height: 0" color="primary">
            <ion-text
              [color]="
                hyperCategory.hyper_category_id === selectedHyperCategory
                  ? 'selected'
                  : 'verticalCategories'
              "
            >
              {{
                hyperCategory.name !== "others"
                  ? hyperCategory.name
                  : ("others" | translate)
              }}
            </ion-text>
          </ion-text></ion-label
        >
        <ion-icon
          style="margin-left: 5px; font-size: 20px"
          color="primary"
          *ngIf="
            hyperCategory.hyper_category_id !== selectedHyperCategory ||
            (hyperCategory.num_of_categories &&
              hyperCategory.num_of_categories === 1)
          "
          slot="end"
          src="assets/ionicons/chevron-forward-outline.svg"
        >
        </ion-icon>
        <ion-icon
          style="margin-left: 5px; font-size: 20px"
          color="primary"
          *ngIf="
            hyperCategory.hyper_category_id === selectedHyperCategory &&
            hyperCategory.num_of_categories &&
            hyperCategory.num_of_categories > 1
          "
          slot="end"
          src="assets/ionicons/chevron-down-outline.svg"
        >
        </ion-icon
      ></ion-item>

      <div
        *ngIf="
          hyperCategory.hyper_category_id === selectedHyperCategory &&
          hyperCategory.num_of_categories &&
          hyperCategory.num_of_categories > 1
        "
        [class]="
          icons_hyper_categories_design &&
          hyperCategory.mobileorder_hyper_category_icon
            ? 'marginCategoriesIcons'
            : 'marginCategoriesNoIcons'
        "
      >
        <app-vertical-categories
          *ngIf="
            hyperCategory.num_of_categories &&
            hyperCategory.num_of_categories > 1
          "
        ></app-vertical-categories>
      </div>
    </span>
  </ion-list>
</div>
