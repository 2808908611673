<ion-header>
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="navigateHome()">
        <ion-icon slot="icon-only" color="primary" name="home"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title mode="ios"> {{"reservation-app.myDates" |translate}}</ion-title>

    <ion-buttons *ngIf="currentUser" slot="end">
      <ion-button (click)="fetchReservations()">
        <ion-icon slot="icon-only" color="primary" name="refresh"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-grid
  *ngIf="animationLogin"
  style="width: 100%; height: 100%; position: absolute; top: 0; z-index: 1000"
>
  <ion-row
    style="width: 100%; height: 100%"
    class="ion-align-items-center ion-justify-content-center"
  >
    <ion-col class="ion-align-self-center ion-text-center">
      <ion-grid style="max-width: 300px">
        <ng-lottie
          (animationCreated)="animationCreated($event)"
          (loopComplete)="animationComplete($event)"
          [options]="options"
        >
        </ng-lottie>
      </ion-grid>
    </ion-col>
  </ion-row>
</ion-grid>
<ion-content
  class="item-list"
  *ngIf="(loginState && loginState === 'login_loading')||loading"
>
  <ion-grid>
    <ion-item lines="none" class="ion-text-center">
      <ion-label>
        <ion-spinner></ion-spinner>
      </ion-label>
    </ion-item>
  </ion-grid>
</ion-content>
<ion-content *ngIf="currentUser&&!loading">
  <ion-grid>
    <ion-item
      lines="none"
      class="ion-text-center"
      style="height: 8%; max-width: 500px; margin: auto"
    >
      <ion-label>
        <ion-button
          shape="round"
          style="width: 45%; height: 35px"
          [color]="isCurrentsEnabled ? 'primary' : 'medium'"
          (click)="enableCurrents()"
        >
          {{"reservation-app.current" |translate}}
        </ion-button>
        <ion-button
          shape="round"
          style="width: 45%; height: 35px"
          [color]="isCompletedEnabled ? 'primary' : 'medium'"
          (click)="enableCompleted()"
        >
          {{"reservation-app.done" |translate}}
        </ion-button>
      </ion-label>
    </ion-item>
    <ion-row
      *ngIf="windowDimensions &&isCurrentsEnabled"
      style="max-width: 1100px; margin: auto"
    >
      <ion-col [size]="colSize" *ngFor="let item of activeReservations; trackBy: reservationKey">
        <ion-grid class="card-grid">
          <ion-card
            button="true"
            (click)="openDate(item)"
            style="border-radius: 20px; max-width: 500px; margin: auto"
          >
            <ion-badge
              style="position: absolute; top: 10px; left: 10px"
              lines="none"
              *ngIf="item.order_id"
            >
              #{{ item.order_id }}
            </ion-badge>
            <img
              *ngIf="(item &&
              item.product_image )||(item&&storesByStoreId[item.store_id]&&storesByStoreId[item.store_id].reservation_assets&&storesByStoreId[item.store_id].reservation_assets.reservationHomeImages&&storesByStoreId[item.store_id].reservation_assets.reservationHomeImages[0])"
              style="width: 100%"
              src="{{ item &&
                item.product_image ? 'https://images.deliverymanager.gr/'+ item.store_id+'/products/16x9/'+ item.product_image:
                'https://s3-eu-west-1.amazonaws.com/storegroupseditedbgimages/' + storesByStoreId[item.store_id].store_id + '/reservation_assets/reservationHomeImages/' + storesByStoreId[item.store_id].reservation_assets.reservationHomeImages[0].fileName }}"
              alt="Item Image"
            />

            <ion-item
              lines="none"
              *ngIf="item && storesByStoreId && storesByStoreId[item.store_id]"
            >
              <ion-icon name="location-sharp" slot="start"></ion-icon>
              <ion-label class="ion-text-wrap">
                <ion-text style="font-weight: bold"
                  >{{ groupApp.app_name }}</ion-text
                >
                <ion-text *ngIf="storesByStoreId[item.store_id].address">
                  | {{ storesByStoreId[item.store_id].address }}</ion-text
                >
              </ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-icon slot="start" name="calendar"></ion-icon>
              <ion-label>
                <span style="font-weight: bold"
                  >{{ item.date | dateDetails }}</span
                >
              </ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-icon slot="start" name="person-circle"></ion-icon>
              <ion-label
                ><span style="font-weight: bold">{{item?.product_name}}</span>
                {{"reservation-app.with"|translate}}
                {{item?.table_name?.el}}</ion-label
              >
            </ion-item>
            <ion-icon
              name="information-circle"
              class="information-circle"
            ></ion-icon> </ion-card
        ></ion-grid>
      </ion-col>

      <ion-col
        size="12"
        *ngIf="activeReservations && !activeReservations.length"
      >
        <ion-grid style="max-width: 200px">
          <ng-lottie
            (animationCreated)="animationCreated($event)"
            [options]="noReservationOptions"
          ></ng-lottie>
        </ion-grid>
        <ion-item lines="none">
          <ion-label class="ion-text-center ion-text-wrap"
            >{{"reservation-app.active-reservations-not-found" | translate}}
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row
      *ngIf="windowDimensions&&!isCurrentsEnabled"
      style="max-width: 1100px; margin: auto"
    >
      <ion-col [size]="colSize" *ngFor="let item of completedReservations; trackBy: reservationKey">
        <ion-grid class="card-grid">
          <ion-card
            button="true"
            (click)="completedDateInfo(item)"
            style="border-radius: 20px; max-width: 500px; margin: auto"
          >
            <ion-badge
              style="position: absolute; top: 10px; left: 10px"
              lines="none"
              *ngIf="item.order_id"
            >
              #{{ item.order_id }}
            </ion-badge>
            <img
              *ngIf="(item &&
          item.product_image )||(item&&storesByStoreId[item.store_id]&&storesByStoreId[item.store_id].reservation_assets&&storesByStoreId[item.store_id].reservation_assets.reservationHomeImages&&storesByStoreId[item.store_id].reservation_assets.reservationHomeImages[0])"
              style="width: 100%"
              src="{{ item &&
            item.product_image ? 'https://images.deliverymanager.gr/'+ item.store_id+'/products/16x9/'+ item.product_image:
            'https://s3-eu-west-1.amazonaws.com/storegroupseditedbgimages/' + storesByStoreId[item.store_id].store_id + '/reservation_assets/reservationHomeImages/' + storesByStoreId[item.store_id].reservation_assets.reservationHomeImages[0].fileName }}"
              alt="Item Image"
            />

            <ion-item lines="none">
              <ion-icon slot="start" name="location-sharp"></ion-icon>
              <ion-label class="ion-text-wrap">
                <ion-text style="font-weight: bold"
                  >{{ groupApp.app_name }}</ion-text
                >
                <ion-text *ngIf="storesByStoreId[item.store_id].address">
                  | {{ storesByStoreId[item.store_id].address }}</ion-text
                >
              </ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-icon slot="start" name="calendar"></ion-icon>
              <ion-label>
                <span style="font-weight: bold"
                  >{{ item?.date | dateDetails }}</span
                >
              </ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-icon name="person-circle"></ion-icon>
              <ion-label
                ><span style="font-weight: bold">{{item?.product_name}}</span>
                {{"reservation-app.with"|translate}}
                {{item?.table_name?.el}}</ion-label
              >
            </ion-item>
            <ion-icon
              *ngIf="item?.res_status!=='canceled' && item?.res_status !== 'rejected'"
              name="checkmark-circle"
              class="green-checkmark"
            ></ion-icon>
            <ion-icon
              *ngIf="item?.res_status==='canceled' || item?.res_status === 'rejected'"
              name="close"
              class="red-close"
            ></ion-icon>
          </ion-card>
        </ion-grid>
      </ion-col>
      <ion-col
        size="12"
        *ngIf="completedReservations && !completedReservations.length"
      >
        <ion-grid style="max-width: 200px">
          <ng-lottie
            (animationCreated)="animationCreated($event)"
            [options]="noReservationOptions"
          ></ng-lottie>
        </ion-grid>
        <ion-item lines="none">
          <ion-label class="ion-text-center ion-text-wrap"
            >{{"reservation-app.completed-reservations-not-found" | translate}}
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-content
  class="item-list"
  *ngIf="!currentUser && loginState && loginState === 'no_login'"
>
  <ion-grid>
    <ion-item lines="none">
      <ng-lottie
        (animationCreated)="animationCreated($event)"
        [options]="options2"
        style="margin: auto; height: 200px"
      ></ng-lottie>
    </ion-item>
    <ion-item style="height: 53px" lines="none" class="ion-text-center">
      <ion-label>
        {{"reservation-app.loginToSeeYourDates" |translate}}
      </ion-label>
    </ion-item>
    <ion-item lines="none">
      <ion-button
        shape="round"
        style="width: 80%; height: 35px; margin-left: 10%"
        (click)="presentModal()"
        >{{"reservation-app.login" |translate}}</ion-button
      >
    </ion-item>
  </ion-grid>
</ion-content>
