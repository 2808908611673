import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import * as _ from "lodash";
import * as selectors from "../../../../../../../store/app.selectors";
import * as fromApp from "../../../../../../../store/app.reducer";
import {
  auditTime,
  debounceTime,
  distinctUntilChanged,
  first,
} from "rxjs/operators";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

@Component({
  selector: "app-bundle-radio-choice",
  templateUrl: "./bundle-radio-choice.component.html",
  styleUrls: ["./bundle-radio-choice.component.scss"],
})
export class BundleRadioChoiceComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() selectedProductId: any;
  @Input() bundleMode: any;
  @Input() option_id;
  @Input() choice_id;
  @Input() selectedProductPromo: any;
  @Input() selectedProductCategory: any;
  @Input() selectedChoiceId: any;
  @Input() choicePreselectedId: any;
  @Input() groupStoreData: any;
  @Input() autoSelectChoice: any;
  public choice: any;
  public option: any;
  public dineIn;
  public orderPickup;
  public selectedPreorderDate;
  public image: any = {};
  public disableForDeliveryMethodAddress: boolean = false;
  private optionSubscription: Subscription;
  private subscription: Subscription;
  @Output() selectChoiceEv = new EventEmitter();

  constructor(
    private store: Store<fromApp.AppState>,
    private changeDetector: ChangeDetectorRef
  ) {}

  selectChoice(choice_id) {
    this.selectChoiceEv.emit(choice_id);
  }

  selectOption() {
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
    this.optionSubscription = this.store
      .select(
        selectors.getOptionByOptionId(this.selectedProductId, this.option_id)
      )
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (!_.isEqual(this.option, state)) {
          this.option = _.cloneDeep(state);

          if (this.option && this.option.choices && this.choice_id) {
            this.choice = _.cloneDeep(
              _.find(this.option.choices, { choice_id: this.choice_id })
            );
            this.store
              .select("deliveryMethodAddress")
              .pipe(first())
              .subscribe((deliveryMethodState) => {
                if (deliveryMethodState) {
                  this.checkDisableForDeliveryMethodAddress(
                    deliveryMethodState.orderPickup,
                    deliveryMethodState.dineIn
                  );
                }
              })
              .unsubscribe();
            console.log("choicesssssss bundle", this.option);
            this.store
              .select("groupApp")
              .pipe(first())
              .subscribe((state) => {
                if (state) {
                  if (
                    this.choice &&
                    this.choice.bundled_product &&
                    this.choice.bundled_product.image &&
                    this.choice.bundled_product.image.true &&
                    this.choice.bundled_product.image.true["4x3"] &&
                    this.choice.bundled_product.image.true["4x3"].path &&
                    state.groupApp &&
                    (!state.groupApp.disableCatalogPhoto ||
                      state.groupApp.disableCatalogPhoto)
                  ) {
                    this.image.path =
                      "https://images.deliverymanager.gr/" +
                      this.choice.bundled_product.image.true["4x3"].path +
                      (state.webp
                        ? ".webp"
                        : this.choice.bundled_product.image.true["4x3"].type ||
                          ".jpg");
                  }
                }
              })
              .unsubscribe();
            this.checkChoiceAutoSelect();
          }
        }
      });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (
      simpleChanges &&
      simpleChanges.option_id &&
      simpleChanges.option_id.currentValue !==
        simpleChanges.option_id.previousValue
    ) {
      this.selectOption();
    }
  }
  //this is the case tha we have one choice only and we want to auto select it
  checkChoiceAutoSelect() {
    if (
      (this.autoSelectChoice ||
        this.choice.choice_id === this.choicePreselectedId) &&
      this.option &&
      this.choice &&
      this.choice.bundled_product &&
      this.choice.bundled_product.product_id
    ) {
      setTimeout(() => {
        this.selectChoice(_.cloneDeep(this.choice.choice_id));
        this.changeDetector.detectChanges();
      }, 250);
    }
  }

  ngOnInit() {
    console.log("bundle radio choice on init", this.groupStoreData);
    this.subscription = this.store
      .select("deliveryMethodAddress")

      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(this.dineIn, state.dineIn)) {
          this.dineIn = _.cloneDeep(state.dineIn);
          this.checkDisableForDeliveryMethodAddress(
            state.orderPickup,
            state.dineIn
          );
        }
        if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
          this.orderPickup = _.cloneDeep(state.orderPickup);
          this.checkDisableForDeliveryMethodAddress(
            state.orderPickup,
            state.dineIn
          );
        }
        if (
          state &&
          !_.isEqual(this.selectedPreorderDate, state.selectedPreorderDate)
        ) {
          this.selectedPreorderDate = _.cloneDeep(state.selectedPreorderDate);
        }
      });

    this.selectOption();
  }

  checkDisableForDeliveryMethodAddress(orderPickup, dineIn) {
    if (this.choice) {
      this.disableForDeliveryMethodAddress = false;
      if (orderPickup === false && dineIn === false) {
        if (
          this.choice.bundled_product &&
          !this.choice.bundled_product.delivery &&
          (this.choice.bundled_product.pickup ||
            this.choice.bundled_product.dinein)
        ) {
          this.disableForDeliveryMethodAddress = true;
        }
      } else if (orderPickup && dineIn === false) {
        if (
          this.choice.bundled_product &&
          !this.choice.bundled_product.pickup &&
          (this.choice.bundled_product.dinein ||
            this.choice.bundled_product.delivery)
        ) {
          this.disableForDeliveryMethodAddress = true;
        }
      } else if (orderPickup === false && dineIn) {
        if (
          this.choice.bundled_product &&
          !this.choice.bundled_product.dinein &&
          (this.choice.bundled_product.delivery ||
            this.choice.bundled_product.pickup)
        ) {
          this.disableForDeliveryMethodAddress = true;
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
  }
}
