import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { AnalyticsServiceService } from "../../services/analitycsService/analytics-service.service";
import * as fromApp from "../../store/app.reducer";
import * as CartActions from "../../cart/store/cart.actions";
import * as SelectedPaymentMethodIDActions from "../../cart/cart-components/payment-methods/store/selectePaymentMethod.actions";
import * as cartCustomOptionsSelectionsActions from "../../cart/cart-components/cart-custom-options/store/cart-custom-options-selections/cart-custom-options-selections.actions";
import * as CartCustomerOptionsActions from "../../cart/cart-components/cart-custom-options/store/cart-customer-options/cart-customer-options.actions";
import * as CheckboxPromotionsActions from "../../cart/cart-components/checkbox-promotions/store/checkbox-promotions.actions";
import * as DeliveryMethodAddressActions from "../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import * as AuthActions from "../../auth/store/auth.actions";
import * as CurrentStoreActions from "../../store/current-store/current-store.actions";
import * as orderCommentsActions from "../../cart/cart-components/order-comments/store/orderComments.actions";
import * as SelectedInvoiceActions from "../../cart/cart-components/invoice/store/selectedInvoice.actions";
import * as GroupAppActions from "../../store/group-app/groupApp.actions";
@Injectable({
  providedIn: "root",
})
export class LocalStorageDataService {
  constructor(
    private store: Store<fromApp.AppState>,
    private analyticsService: AnalyticsServiceService
  ) {}

  checkForIonicUpdateErrorFlag() {
    if (
      navigator.cookieEnabled &&
      localStorage.getItem("ionicUpdateErrorFlag")
    ) {
      const comment_id = localStorage.getItem("ionicUpdateErrorFlag");
      //send footprint
      this.analyticsService.dmAnalyticsMessage(
        "ionic_update_error",
        comment_id,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
      localStorage.removeItem("ionicUpdateErrorFlag");
    }
  }

  loadPaymentMethod() {
    if (navigator.cookieEnabled) {
      this.store.dispatch(
        new SelectedPaymentMethodIDActions.SetSelectedPaymentMethodID(
          window.localStorage.getItem("selectedPaymentMethodID")
            ? JSON.parse(window.localStorage.getItem("selectedPaymentMethodID"))
            : null
        )
      );
    }
  }

  loadThemeJSON(themeJSON, group, disable_app_darkmode) {
    if (
      navigator.cookieEnabled &&
      localStorage.getItem("darkTheme") &&
      localStorage.getItem("darkTheme") === "yes" &&
      !disable_app_darkmode
    ) {
      if (group === "battlenet") {
        document.body.classList.toggle("battlenet-dark", true);
      } else if (
        themeJSON &&
        themeJSON["darkThemeMode"] &&
        disable_app_darkmode
      ) {
        //no class here theme is already dark
      } else {
        document.body.classList.toggle("dark", true);
      }
    }
    if (themeJSON) {
      document.documentElement.style.setProperty(
        "--ion-color-primary",
        themeJSON["--ion-color-primary"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-primary-rgb",
        themeJSON["--ion-color-primary-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-primary-contrast",
        themeJSON["--ion-color-primary-contrast"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-primary-contrast-rgb",
        themeJSON["--ion-color-primary-contrast-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-primary-shade",
        themeJSON["--ion-color-primary-shade"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-primary-tint",
        themeJSON["--ion-color-primary-tint"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-secondary",
        themeJSON["--ion-color-secondary"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-secondary-rgb",
        themeJSON["--ion-color-secondary-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-secondary-contrast",
        themeJSON["--ion-color-secondary-contrast"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-secondary-contrast-rgb",
        themeJSON["--ion-color-secondary-contrast-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-secondary-shade",
        themeJSON["--ion-color-secondary-shade"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-secondary-tint",
        themeJSON["--ion-color-secondary-tint"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-tertiary",
        themeJSON["--ion-color-tertiary"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-tertiary-rgb",
        themeJSON["--ion-color-tertiary-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-tertiary-contrast",
        themeJSON["--ion-color-tertiary-contrast"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-tertiary-contrast-rgb",
        themeJSON["--ion-color-tertiary-contrast-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-tertiary-shade",
        themeJSON["--ion-color-tertiary-shade"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-tertiary-tint",
        themeJSON["--ion-color-tertiary-tint"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-success",
        themeJSON["--ion-color-success"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-success-rgb",
        themeJSON["--ion-color-success-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-success-contrast",
        themeJSON["--ion-color-success-contrast"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-success-contrast-rgb",
        themeJSON["--ion-color-success-contrast-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-success-shade",
        themeJSON["--ion-color-success-shade"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-success-tint",
        themeJSON["--ion-color-success-tint"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-warning",
        themeJSON["--ion-color-warning"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-warning-rgb",
        themeJSON["--ion-color-warning-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-warning-contrast",
        themeJSON["--ion-color-warning-contrast"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-warning-contrast-rgb",
        themeJSON["--ion-color-warning-contrast-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-warning-shade",
        themeJSON["--ion-color-warning-shade"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-warning-tint",
        themeJSON["--ion-color-warning-tint"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-danger",
        themeJSON["--ion-color-danger"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-danger-rgb",
        themeJSON["--ion-color-danger-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-danger-contrast",
        themeJSON["--ion-color-danger-contrast"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-danger-contrast-rgb",
        themeJSON["--ion-color-danger-contrast-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-danger-shade",
        themeJSON["--ion-color-danger-shade"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-danger-tint",
        themeJSON["--ion-color-danger-tint"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-dark",
        themeJSON["--ion-color-dark"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-dark-rgb",
        themeJSON["--ion-color-dark-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-dark-contrast",
        themeJSON["--ion-color-dark-contrast"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-dark-contrast-rgb",
        themeJSON["--ion-color-dark-contrast-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-dark-shade",
        themeJSON["--ion-color-dark-shade"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-dark-tint",
        themeJSON["--ion-color-dark-tint"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-medium",
        themeJSON["--ion-color-medium"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-medium-rgb",
        themeJSON["--ion-color-medium-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-medium-contrast",
        themeJSON["--ion-color-medium-contrast"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-medium-contrast-rgb",
        themeJSON["--ion-color-medium-contrast-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-medium-shade",
        themeJSON["--ion-color-medium-shade"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-dark-tint",
        themeJSON["--ion-color-dark-tint"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-light",
        themeJSON["--ion-color-light"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-light-rgb",
        themeJSON["--ion-color-light-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-light-contrast",
        themeJSON["--ion-color-light-contrast"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-light-contrast-rgb",
        themeJSON["--ion-color-light-contrast-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-light-shade",
        themeJSON["--ion-color-light-shade"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-light-tint",
        themeJSON["--ion-color-light-tint"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-grey",
        themeJSON["--ion-color-grey"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-grey-rgb",
        themeJSON["--ion-color-grey-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-grey-contrast",
        themeJSON["--ion-color-grey-contrast"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-grey-contrast-rgb",
        themeJSON["--ion-color-grey-contrast-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-grey-shade",
        themeJSON["--ion-color-grey-shade"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-grey-tint",
        themeJSON["--ion-color-grey-tint"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-completeorder",
        themeJSON["--ion-color-completeorder"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-completeorder-rgb",
        themeJSON["--ion-color-completeorder-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-completeorder-contrast",
        themeJSON["--ion-color-completeorder-contrast"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-completeorder-contrast-rgb",
        themeJSON["--ion-color-completeorder-contrast-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-completeorder-shade",
        themeJSON["--ion-color-completeorder-shade"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-completeorder-tint",
        themeJSON["--ion-color-completeorder-tint"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-ordererror",
        themeJSON["--ion-color-ordererror"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-ordererror-rgb",
        themeJSON["--ion-color-ordererror-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-ordererror-contrast",
        themeJSON["--ion-color-ordererror-contrast"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-ordererror-contrast-rgb",
        themeJSON["--ion-color-ordererror-contrast-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-ordererror-shade",
        themeJSON["--ion-color-ordererror-shade"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-ordererror-tint",
        themeJSON["--ion-color-ordererror-tint"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-selected",
        themeJSON["--ion-color-selected"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-selected-rgb",
        themeJSON["--ion-color-selected-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-selected-contrast",
        themeJSON["--ion-color-selected-contrast"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-selected-contrast-rgb",
        themeJSON["--ion-color-selected-contrast-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-selected-shade",
        themeJSON["--ion-color-selected-shade"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-selected-tint",
        themeJSON["--ion-color-selected-tint"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-black",
        themeJSON["--ion-color-black"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-black-rgb",
        themeJSON["--ion-color-black-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-black-contrast",
        themeJSON["--ion-color-black-contrast"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-black-contrast-rgb",
        themeJSON["--ion-color-black-contrast-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-black-shade",
        themeJSON["--ion-color-black-shade"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-black-tint",
        themeJSON["--ion-color-black-tint"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-homePageMotoColor",
        themeJSON["--ion-color-homePageMotoColor"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-homePageMotoColor-rgb",
        themeJSON["--ion-color-homePageMotoColor-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-homePageMotoColor-contrast",
        themeJSON["--ion-color-homePageMotoColor-contrast"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-homePageMotoColor-contrast-rgb",
        themeJSON["--ion-color-homePageMotoColor-contrast-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-homePageMotoColor-shade",
        themeJSON["--ion-color-homePageMotoColor-shade"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-homePageMotoColor-tint",
        themeJSON["--ion-color-homePageMotoColor-tint"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-deliveryMethodAddressButtons",
        themeJSON["--ion-color-deliveryMethodAddressButtons"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-deliveryMethodAddressButtons-rgb",
        themeJSON["--ion-color-deliveryMethodAddressButtons-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-deliveryMethodAddressButtons-contrast",
        themeJSON["--ion-color-deliveryMethodAddressButtons-contrast"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-deliveryMethodAddressButtons-contrast-rgb",
        themeJSON["--ion-color-deliveryMethodAddressButtons-contrast-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-deliveryMethodAddressButtons-shade",
        themeJSON["--ion-color-deliveryMethodAddressButtons-shade"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-deliveryMethodAddressButtons-tint",
        themeJSON["--ion-color-deliveryMethodAddressButtons-tint"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-homepageBackground",
        themeJSON["--ion-color-homepageBackground"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-homepageBackground-rgb",
        themeJSON["--ion-color-homepageBackground-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-homepageBackground-contrast",
        themeJSON["--ion-color-homepageBackground-contrast"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-homepageBackground-contrast-rgb",
        themeJSON["--ion-color-homepageBackground-contrast-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-homepageBackground-shade",
        themeJSON["--ion-color-homepageBackground-shade"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-homepageBackground-tint",
        themeJSON["--ion-color-homepageBackground-tint"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-homepageButtons",
        themeJSON["--ion-color-homepageButtons"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-homepageButtons-rgb",
        themeJSON["--ion-color-homepageButtons-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-homepageButtons-contrast",
        themeJSON["--ion-color-homepageButtons-contrast"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-homepageButtons-contrast-rgb",
        themeJSON["--ion-color-homepageButtons-contrast-rgb"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-homepageButtons-shade",
        themeJSON["--ion-color-homepageButtons-shade"]
      );
      document.documentElement.style.setProperty(
        "--ion-color-homepageButtons-tint",
        themeJSON["--ion-color-homepageButtons-tint"]
      );

      if (themeJSON["--ion-color-changeDarkModeButton"]) {
        document.documentElement.style.setProperty(
          "--ion-color-changeDarkModeButton",
          themeJSON["--ion-color-changeDarkModeButton"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-changeDarkModeButton",
          themeJSON["--ion-color-primary"]
        );
      }

      if (themeJSON["--ion-color-changeDarkModeButton-rgb"]) {
        document.documentElement.style.setProperty(
          "--ion-color-changeDarkModeButton-rgb",
          themeJSON["--ion-color-changeDarkModeButton-rgb"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-changeDarkModeButton-rgb",
          themeJSON["--ion-color-primary-rgb"]
        );
      }

      if (themeJSON["--ion-color-changeDarkModeButton-contrast"]) {
        document.documentElement.style.setProperty(
          "--ion-color-changeDarkModeButton-contrast",
          themeJSON["--ion-color-changeDarkModeButton-contrast"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-changeDarkModeButton-contrast",
          themeJSON["--ion-color-primary-contrast"]
        );
      }

      if (themeJSON["--ion-color-changeDarkModeButton-contrast-rgb"]) {
        document.documentElement.style.setProperty(
          "--ion-color-changeDarkModeButton-contrast-rgb",
          themeJSON["--ion-color-changeDarkModeButton-contrast-rgb"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-changeDarkModeButton-contrast-rgb",
          themeJSON["--ion-color-primary-contrast-rgb"]
        );
      }

      if (themeJSON["--ion-color-changeDarkModeButton-shade"]) {
        document.documentElement.style.setProperty(
          "--ion-color-changeDarkModeButton-shade",
          themeJSON["--ion-color-changeDarkModeButton-shade"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-changeDarkModeButton-shade",
          themeJSON["--ion-color-primary-shade"]
        );
      }

      if (themeJSON["--ion-color-changeDarkModeButton-tint"]) {
        document.documentElement.style.setProperty(
          "--ion-color-changeDarkModeButton-tint",
          themeJSON["--ion-color-changeDarkModeButton-tint"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-changeDarkModeButton-tint",
          themeJSON["--ion-color-primary-tint"]
        );
      }

      // PoweredByTextColor

      if (themeJSON["--ion-color-poweredByTextColor"]) {
        document.documentElement.style.setProperty(
          "--ion-color-poweredByTextColor",
          themeJSON["--ion-color-poweredByTextColor"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-poweredByTextColor",
          themeJSON["--ion-color-dark"]
        );
      }

      if (themeJSON["--ion-color-poweredByTextColor-rgb"]) {
        document.documentElement.style.setProperty(
          "--ion-color-poweredByTextColor-rgb",
          themeJSON["--ion-color-poweredByTextColor-rgb"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-poweredByTextColor-rgb",
          themeJSON["--ion-color-dark-rgb"]
        );
      }

      if (themeJSON["--ion-color-poweredByTextColor-contrast"]) {
        document.documentElement.style.setProperty(
          "--ion-color-poweredByTextColor-contrast",
          themeJSON["--ion-color-poweredByTextColor-contrast"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-poweredByTextColor-contrast",
          themeJSON["--ion-color-dark-contrast"]
        );
      }

      if (themeJSON["--ion-color-poweredByTextColor-contrast-rgb"]) {
        document.documentElement.style.setProperty(
          "--ion-color-poweredByTextColor-contrast-rgb",
          themeJSON["--ion-color-poweredByTextColor-contrast-rgb"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-poweredByTextColor-contrast-rgb",
          themeJSON["--ion-color-dark-contrast-rgb"]
        );
      }

      if (themeJSON["--ion-color-poweredByTextColor-shade"]) {
        document.documentElement.style.setProperty(
          "--ion-color-poweredByTextColor-shade",
          themeJSON["--ion-color-poweredByTextColor-shade"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-poweredByTextColor-shade",
          themeJSON["--ion-color-dark-shade"]
        );
      }

      if (themeJSON["--ion-color-poweredByTextColor-tint"]) {
        document.documentElement.style.setProperty(
          "--ion-color-poweredByTextColor-tint",
          themeJSON["--ion-color-poweredByTextColor-tint"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-poweredByTextColor-tint",
          themeJSON["--ion-color-dark-tint"]
        );
      }

      if (themeJSON["--ion-color-verticalCategories"]) {
        document.documentElement.style.setProperty(
          "--ion-color-verticalCategories",
          themeJSON["--ion-color-verticalCategories"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-verticalCategories",
          themeJSON["--ion-color-primary"]
        );
      }

      if (themeJSON["--ion-color-verticalCategories-rgb"]) {
        document.documentElement.style.setProperty(
          "--ion-color-verticalCategories-rgb",
          themeJSON["--ion-color-verticalCategories-rgb"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-verticalCategories-rgb",
          themeJSON["--ion-color-primary-rgb"]
        );
      }

      if (themeJSON["--ion-color-verticalCategories-contrast"]) {
        document.documentElement.style.setProperty(
          "--ion-color-verticalCategories-contrast",
          themeJSON["--ion-color-verticalCategories-contrast"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-verticalCategories-contrast",
          themeJSON["--ion-color-primary-contrast"]
        );
      }

      if (themeJSON["--ion-color-verticalCategories-contrast-rgb"]) {
        document.documentElement.style.setProperty(
          "--ion-color-verticalCategories-contrast-rgb",
          themeJSON["--ion-color-verticalCategories-contrast-rgb"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-verticalCategories-contrast-rgb",
          themeJSON["--ion-color-primary-contrast-rgb"]
        );
      }

      if (themeJSON["--ion-color-verticalCategories-shade"]) {
        document.documentElement.style.setProperty(
          "--ion-color-verticalCategories-shade",
          themeJSON["--ion-color-verticalCategories-shade"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-verticalCategories-shade",
          themeJSON["--ion-color-primary-shade"]
        );
      }

      if (themeJSON["--ion-color-verticalCategories-tint"]) {
        document.documentElement.style.setProperty(
          "--ion-color-verticalCategories-tint",
          themeJSON["--ion-color-verticalCategories-tint"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-verticalCategories-tint",
          themeJSON["--ion-color-primary-tint"]
        );
      }

      if (themeJSON["--ion-color-horizontalCategories"]) {
        document.documentElement.style.setProperty(
          "--ion-color-horizontalCategories",
          themeJSON["--ion-color-horizontalCategories"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-horizontalCategories",
          themeJSON["--ion-color-primary"]
        );
      }

      if (themeJSON["--ion-color-horizontalCategories-rgb"]) {
        document.documentElement.style.setProperty(
          "--ion-color-horizontalCategories-rgb",
          themeJSON["--ion-color-horizontalCategories-rgb"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-horizontalCategories-rgb",
          themeJSON["--ion-color-primary-rgb"]
        );
      }

      if (themeJSON["--ion-color-horizontalCategories-contrast"]) {
        document.documentElement.style.setProperty(
          "--ion-color-horizontalCategories-contrast",
          themeJSON["--ion-color-horizontalCategories-contrast"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-horizontalCategories-contrast",
          themeJSON["--ion-color-primary-contrast"]
        );
      }

      if (themeJSON["--ion-color-horizontalCategories-contrast-rgb"]) {
        document.documentElement.style.setProperty(
          "--ion-color-horizontalCategories-contrast-rgb",
          themeJSON["--ion-color-horizontalCategories-contrast-rgb"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-horizontalCategories-contrast-rgb",
          themeJSON["--ion-color-primary-contrast-rgb"]
        );
      }

      if (themeJSON["--ion-color-horizontalCategories-shade"]) {
        document.documentElement.style.setProperty(
          "--ion-color-horizontalCategories-shade",
          themeJSON["--ion-color-verticalCategories-shade"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-horizontalCategories-shade",
          themeJSON["--ion-color-primary-shade"]
        );
      }

      if (themeJSON["--ion-color-horizontalCategories-tint"]) {
        document.documentElement.style.setProperty(
          "--ion-color-horizontalCategories-tint",
          themeJSON["--ion-color-horizontalCategories-tint"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-horizontalCategories-tint",
          themeJSON["--ion-color-primary-tint"]
        );
      }

      if (themeJSON["--ion-color-kioskMemberCardColor"]) {
        document.documentElement.style.setProperty(
          "--ion-color-kioskMemberCardColor",
          themeJSON["--ion-color-kioskMemberCardColor"]
        );
      }

      if (themeJSON["--ion-color-kioskMemberCardColor-rgb"]) {
        document.documentElement.style.setProperty(
          "--ion-color-kioskMemberCardColor-rgb",
          themeJSON["--ion-color-kioskMemberCardColor-rgb"]
        );
      }

      if (themeJSON["--ion-color-kioskMemberCardColor-contrast"]) {
        document.documentElement.style.setProperty(
          "--ion-color-kioskMemberCardColor-contrast",
          themeJSON["--ion-color-kioskMemberCardColor-contrast"]
        );
      }

      if (themeJSON["--ion-color-kioskMemberCardColor-contrast-rgb"]) {
        document.documentElement.style.setProperty(
          "--ion-color-kioskMemberCardColor-contrast-rgb",
          themeJSON["--ion-color-kioskMemberCardColor-contrast-rgb"]
        );
      }

      if (themeJSON["--ion-color-kioskMemberCardColor-shade"]) {
        document.documentElement.style.setProperty(
          "--ion-color-kioskMemberCardColor-shade",
          themeJSON["--ion-color-kioskMemberCardColor-shade"]
        );
      }

      if (themeJSON["--ion-color-kioskMemberCardColor-tint"]) {
        document.documentElement.style.setProperty(
          "--ion-color-kioskMemberCardColor-tint",
          themeJSON["--ion-color-kioskMemberCardColor-tint"]
        );
      }

      if (themeJSON["--ion-item-background"]) {
        document.documentElement.style.setProperty(
          "--ion-item-background",
          themeJSON["--ion-item-background"]
        );
      }

      if (themeJSON["--ion-card-background"]) {
        document.documentElement.style.setProperty(
          "--ion-card-background",
          themeJSON["--ion-card-background"]
        );
      }

      if (themeJSON["--ion-tab-bar-background"]) {
        document.documentElement.style.setProperty(
          "--ion-tab-bar-background",
          themeJSON["--ion-tab-bar-background"]
        );
      }

      if (themeJSON["--ion-border-color"]) {
        document.documentElement.style.setProperty(
          "--ion-border-color",
          themeJSON["--ion-border-color"]
        );
      }

      if (themeJSON["--homepageBrightnessSlider"]) {
        document.documentElement.style.setProperty(
          "--homepageBrightnessSlider",
          themeJSON["--homepageBrightnessSlider"]
        );
      }

      if (themeJSON["--ion-menu-selected-item-font-color"]) {
        document.documentElement.style.setProperty(
          "--ion-menu-selected-item-font-color",
          themeJSON["--ion-menu-selected-item-font-color"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-menu-selected-item-font-color",
          themeJSON["--ion-color-primary"]
        );
      }

      if (themeJSON["--customSideMenuBackground"]) {
        document.documentElement.style.setProperty(
          "--customSideMenuBackground",
          themeJSON["--customSideMenuBackground"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--customSideMenuBackground",
          themeJSON["--ion-color-background"]
        );
      }

      if (themeJSON["--showWhiteLogoDeliveryManager"]) {
        document.documentElement.style.setProperty(
          "--showWhiteLogoDeliveryManager",
          themeJSON["--showWhiteLogoDeliveryManager"]
        );
      }

      if (themeJSON["--sideMenuIconsColor"]) {
        document.documentElement.style.setProperty(
          "--sideMenuIconsColor",
          themeJSON["--sideMenuIconsColor"]
        );
      }
      if (themeJSON["--ion-color-customSideMenuItemBackground"]) {
        document.documentElement.style.setProperty(
          "--ion-color-customSideMenuItemBackground",
          themeJSON["--ion-color-customSideMenuItemBackground"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-customSideMenuItemBackground",
          themeJSON["--ion-color-background"]
        );
      }

      if (themeJSON["--ion-color-customSideMenuItemBackground-contrast"]) {
        document.documentElement.style.setProperty(
          "--ion-color-customSideMenuItemBackground-contrast",
          themeJSON["--ion-color-customSideMenuItemBackground-contrast"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-customSideMenuItemBackground-contrast",
          themeJSON["--ion-color-text-contrast"]
        );
      }

      if (themeJSON["--ion-color-ionMenuSelectedColor"]) {
        document.documentElement.style.setProperty(
          "--ion-color-ionMenuSelectedColor",
          themeJSON["--ion-color-ionMenuSelectedColor"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-ionMenuSelectedColor",
          themeJSON["--ion-color-primary"]
        );
      }

      if (themeJSON["--ion-color-ionMenuSelectedColor-rgb"]) {
        document.documentElement.style.setProperty(
          "--ion-color-ionMenuSelectedColor-rgb",
          themeJSON["--ion-color-ionMenuSelectedColor-rgb"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-ionMenuSelectedColor-rgb",
          themeJSON["--ion-color-primary-rgb"]
        );
      }

      if (themeJSON["--ion-color-ionMenuSelectedColor-contrast"]) {
        document.documentElement.style.setProperty(
          "--ion-color-ionMenuSelectedColor-contrast",
          themeJSON["--ion-color-ionMenuSelectedColor-contrast"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-ionMenuSelectedColor-contrast",
          themeJSON["--ion-color-primary-contrast"]
        );
      }

      if (themeJSON["--ion-color-ionMenuSelectedColor-contrast-rgb"]) {
        document.documentElement.style.setProperty(
          "--ion-color-ionMenuSelectedColor-contrast-rgb",
          themeJSON["--ion-color-ionMenuSelectedColor-contrast-rgb"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-ionMenuSelectedColor-contrast-rgb",
          themeJSON["--ion-color-primary-contrast-rgb"]
        );
      }

      if (themeJSON["--ion-color-ionMenuSelectedColor-shade"]) {
        document.documentElement.style.setProperty(
          "--ion-color-ionMenuSelectedColor-shade",
          themeJSON["--ion-color-ionMenuSelectedColor-shade"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-ionMenuSelectedColor-shade",
          themeJSON["--ion-color-primary-shade"]
        );
      }

      if (themeJSON["--ion-color-ionMenuSelectedColor-tint"]) {
        document.documentElement.style.setProperty(
          "--ion-color-ionMenuSelectedColor-tint",
          themeJSON["--ion-color-ionMenuSelectedColor-tint"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-ionMenuSelectedColor-tint",
          themeJSON["--ion-color-primary-tint"]
        );
      }

      // HomeBurgerButton
      if (themeJSON["--ion-color-homeBurgerButton"]) {
        document.documentElement.style.setProperty(
          "--ion-color-homeBurgerButton",
          themeJSON["--ion-color-homeBurgerButton"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-homeBurgerButton",
          themeJSON["--ion-color-homepageButtons"]
        );
      }

      if (themeJSON["--ion-color-homeBurgerButton-rgb"]) {
        document.documentElement.style.setProperty(
          "--ion-color-homeBurgerButton-rgb",
          themeJSON["--ion-color-homeBurgerButton-rgb"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-homeBurgerButton-rgb",
          themeJSON["--ion-color-homepageButtons-rgb"]
        );
      }

      if (themeJSON["--ion-color-homeBurgerButton-contrast"]) {
        document.documentElement.style.setProperty(
          "--ion-color-homeBurgerButton-contrast",
          themeJSON["--ion-color-homeBurgerButton-contrast"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-homeBurgerButton-contrast",
          themeJSON["--ion-color-homepageButtons-contrast"]
        );
      }

      if (themeJSON["--ion-color-homeBurgerButton-contrast-rgb"]) {
        document.documentElement.style.setProperty(
          "--ion-color-homeBurgerButton-contrast-rgb",
          themeJSON["--ion-color-homeBurgerButton-contrast-rgb"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-homeBurgerButton-contrast-rgb",
          themeJSON["--ion-color-homepageButtons-contrast-rgb"]
        );
      }

      if (themeJSON["--ion-color-homeBurgerButton-shade"]) {
        document.documentElement.style.setProperty(
          "--ion-color-homeBurgerButton-shade",
          themeJSON["--ion-color-homeBurgerButton-shade"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-homeBurgerButton-shade",
          themeJSON["--ion-color-homepageButtons-shade"]
        );
      }

      if (themeJSON["--ion-color-homeBurgerButton-tint"]) {
        document.documentElement.style.setProperty(
          "--ion-color-homeBurgerButton-tint",
          themeJSON["--ion-color-homeBurgerButton-tint"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-homeBurgerButton-tint",
          themeJSON["--ion-color-homepageButtons-tint"]
        );
      }

      // HomeLoginButton
      if (themeJSON["--ion-color-homeLoginButton"]) {
        document.documentElement.style.setProperty(
          "--ion-color-homeLoginButton",
          themeJSON["--ion-color-homeLoginButton"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-homeLoginButton",
          themeJSON["--ion-color-homepageButtons"]
        );
      }

      if (themeJSON["--ion-color-homeLoginButton-rgb"]) {
        document.documentElement.style.setProperty(
          "--ion-color-homeLoginButton-rgb",
          themeJSON["--ion-color-homeLoginButton-rgb"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-homeLoginButton-rgb",
          themeJSON["--ion-color-homepageButtons-rgb"]
        );
      }

      if (themeJSON["--ion-color-homeLoginButton-contrast"]) {
        document.documentElement.style.setProperty(
          "--ion-color-homeLoginButton-contrast",
          themeJSON["--ion-color-homeLoginButton-contrast"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-homeLoginButton-contrast",
          themeJSON["--ion-color-homepageButtons-contrast"]
        );
      }

      if (themeJSON["--ion-color-homeLoginButton-contrast-rgb"]) {
        document.documentElement.style.setProperty(
          "--ion-color-homeLoginButton-contrast-rgb",
          themeJSON["--ion-color-homeLoginButton-contrast-rgb"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-homeLoginButton-contrast-rgb",
          themeJSON["--ion-color-homepageButtons-contrast-rgb"]
        );
      }

      if (themeJSON["--ion-color-homeLoginButton-shade"]) {
        document.documentElement.style.setProperty(
          "--ion-color-homeLoginButton-shade",
          themeJSON["--ion-color-homeLoginButton-shade"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-homeLoginButton-shade",
          themeJSON["--ion-color-homepageButtons-shade"]
        );
      }

      if (themeJSON["--ion-color-homeLoginButton-tint"]) {
        document.documentElement.style.setProperty(
          "--ion-color-homeLoginButton-tint",
          themeJSON["--ion-color-homeLoginButton-tint"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-homeLoginButton-tint",
          themeJSON["--ion-color-homepageButtons-tint"]
        );
      }

      // ionMenuBadgesColor
      if (themeJSON["--ion-color-ionMenuBadgesColor"]) {
        document.documentElement.style.setProperty(
          "--ion-color-ionMenuBadgesColor",
          themeJSON["--ion-color-ionMenuBadgesColor"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-ionMenuBadgesColor",
          themeJSON["--ion-color-primary"]
        );
      }

      if (themeJSON["--ion-color-ionMenuBadgesColor-rgb"]) {
        document.documentElement.style.setProperty(
          "--ion-color-ionMenuBadgesColor-rgb",
          themeJSON["--ion-color-ionMenuBadgesColor-rgb"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-ionMenuBadgesColor-rgb",
          themeJSON["--ion-color-primary-rgb"]
        );
      }

      if (themeJSON["--ion-color-ionMenuBadgesColor-contrast"]) {
        document.documentElement.style.setProperty(
          "--ion-color-ionMenuBadgesColor-contrast",
          themeJSON["--ion-color-ionMenuBadgesColor-contrast"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-ionMenuBadgesColor-contrast",
          themeJSON["--ion-color-primary-contrast"]
        );
      }

      if (themeJSON["--ion-color-ionMenuBadgesColor-contrast-rgb"]) {
        document.documentElement.style.setProperty(
          "--ion-color-ionMenuBadgesColor-contrast-rgb",
          themeJSON["--ion-color-ionMenuBadgesColor-contrast-rgb"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-ionMenuBadgesColor-contrast-rgb",
          themeJSON["--ion-color-primary-contrast-rgb"]
        );
      }

      if (themeJSON["--ion-color-ionMenuBadgesColor-shade"]) {
        document.documentElement.style.setProperty(
          "--ion-color-ionMenuBadgesColor-shade",
          themeJSON["--ion-color-ionMenuBadgesColor-shade"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-ionMenuBadgesColor-shade",
          themeJSON["--ion-color-primary-shade"]
        );
      }

      if (themeJSON["--ion-color-ionMenuBadgesColor-tint"]) {
        document.documentElement.style.setProperty(
          "--ion-color-ionMenuBadgesColor-tint",
          themeJSON["--ion-color-ionMenuBadgesColor-tint"]
        );
      } else {
        document.documentElement.style.setProperty(
          "--ion-color-ionMenuBadgesColor-tint",
          themeJSON["--ion-color-primary-tint"]
        );
      }

      document.documentElement.style.setProperty(
        "--ion-color-background",
        themeJSON["--ion-color-background"]
      );

      document.documentElement.style.setProperty(
        "--ion-background-color",
        themeJSON["--ion-background-color"]
      );

      document.documentElement.style.setProperty(
        "--ion-background-color-rgb",
        themeJSON["--ion-background-color-rgb"]
      );

      document.documentElement.style.setProperty(
        "--ion-text-color",
        themeJSON["--ion-text-color"]
      );

      document.documentElement.style.setProperty(
        "--ion-text-color-rgb",
        themeJSON["--ion-text-color-rgb"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-step-50",
        themeJSON["--ion-color-step-50"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-step-100",
        themeJSON["--ion-color-step-100"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-step-150",
        themeJSON["--ion-color-step-150"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-step-200",
        themeJSON["--ion-color-step-200"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-step-250",
        themeJSON["--ion-color-step-250"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-step-300",
        themeJSON["--ion-color-step-300"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-step-350",
        themeJSON["--ion-color-step-350"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-step-400",
        themeJSON["--ion-color-step-400"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-step-450",
        themeJSON["--ion-color-step-450"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-step-500",
        themeJSON["--ion-color-step-500"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-step-550",
        themeJSON["--ion-color-step-550"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-step-600",
        themeJSON["--ion-color-step-600"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-step-650",
        themeJSON["--ion-color-step-650"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-step-700",
        themeJSON["--ion-color-step-700"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-step-750",
        themeJSON["--ion-color-step-750"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-step-800",
        themeJSON["--ion-color-step-800"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-step-850",
        themeJSON["--ion-color-step-850"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-step-900",
        themeJSON["--ion-color-step-900"]
      );

      document.documentElement.style.setProperty(
        "--ion-color-step-950",
        themeJSON["--ion-color-step-950"]
      );

      document.documentElement.style.setProperty(
        "--ion-toolbar-background",
        themeJSON["--ion-toolbar-background"]
      );
      if (themeJSON["--homepageBrightnessSlider"]) {
        document.documentElement.style.setProperty(
          "--homepageBrightnessSlider",
          themeJSON["--homepageBrightnessSlider"]
        );
      }

      if (themeJSON["--ion-menu-selected-opacity"]) {
        document.documentElement.style.setProperty(
          "--ion-menu-selected-opacity",
          themeJSON["--ion-menu-selected-opacity"]
        );
      }
    }

    if (themeJSON) {
      if (themeJSON["--sideMenuCloseButtonCustomColor"]) {
        document.documentElement.style.setProperty(
          "--sideMenuCloseButtonCustomColor",
          themeJSON["--sideMenuCloseButtonCustomColor"]
        );
      }
    }

    if (themeJSON && themeJSON["darkThemeMode"] && disable_app_darkmode) {
      //already dark dont load any class
      this.store.dispatch(new GroupAppActions.SetSelectedTheme("dark"));
      localStorage.setItem("darkTheme", "yes");
    }

    if (themeJSON["ion-modal-border"]) {
      const styleElement = document.createElement("style");

      const dynamicStyle = `
       ion-modal::part(content) {
          border: 1px solid #ffffff8f !important;
        }

        ion-modal.fullscreen::part(content) {
           border: none !important;
          }
      `;

      styleElement.textContent = dynamicStyle;

      document.head.appendChild(styleElement);
    }

    if (themeJSON["ion-alert-border"]) {
      const styleElement = document.createElement("style");

      const dynamicStyle = `ion-alert {.alert-wrapper {border: 1px solid #ffffff8f !important;}}`;

      styleElement.textContent = dynamicStyle;

      document.head.appendChild(styleElement);
    }

    if (themeJSON["points-card-border"]) {
      const styleElement = document.createElement("style");

      const dynamicStyle = `
      .points-card-border {
          border: 1px solid #ffffff8f !important;
          background-color: var(--ion-card-background) !important;
      }
    `;

      styleElement.textContent = dynamicStyle;

      document.head.appendChild(styleElement);
    }
  }

  // loadTheme(brandTheme) {
  //   if (navigator.cookieEnabled) {
  //     let darkTheme = window.localStorage.getItem("darkTheme");
  //     if (darkTheme === "yes") {
  //       document.body.classList.toggle(brandTheme, false);
  //       document.body.classList.toggle("dark", true);
  //     } else {
  //       document.body.classList.toggle(brandTheme, true);
  //     }
  //   } else {
  //     document.body.classList.toggle(brandTheme, true);
  //   }
  // }

  loadLanguage() {
    if (navigator.cookieEnabled) {
      let defaultLang = window.localStorage.getItem("default_language");
      console.log("def lang", defaultLang);
      if (defaultLang) {
        return defaultLang;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  loadOrderComments() {
    if (navigator.cookieEnabled) {
      if (
        window.localStorage.getItem("orderComments") &&
        window.localStorage.getItem("orderComments") !== "" &&
        window.localStorage.getItem("orderComments") !== "{}"
      ) {
        let orderComments = JSON.parse(
          window.localStorage.getItem("orderComments")
        );

        this.store.dispatch(
          new orderCommentsActions.SetOrderComments(orderComments)
        );
      }
    }
  }

  loadCart() {
    if (navigator.cookieEnabled) {
      if (
        window.localStorage.getItem("cart") &&
        window.localStorage.getItem("cart") !== "" &&
        window.localStorage.getItem("cart") !== "{}"
      ) {
        console.log("getting from local storage");
        let cartProducts = JSON.parse(window.localStorage.getItem("cart"));

        this.store.dispatch(new CartActions.SetCartProducts(cartProducts));
      }
    }
  }

  loadMigrationCustomerId(currentUser, store) {
    console.log("loadMigrationCustomerId", currentUser, store);
    if (navigator.cookieEnabled) {
      if (
        window.localStorage.getItem("migration_customer_id") &&
        window.localStorage.getItem("migration_customer_id") !== "" &&
        window.localStorage.getItem("migration_customer_id") !== "{}"
      ) {
        const migration_customer_id = window.localStorage.getItem(
          "migration_customer_id"
        );

        if (
          currentUser &&
          store &&
          store.vatBasedCustomerProductsActive &&
          currentUser.invoiceData &&
          !_.isEmpty(currentUser.invoiceData) &&
          _.find(currentUser.invoiceData, {
            migration_customer_id: migration_customer_id,
          })
        ) {
          this.store.dispatch(
            new AuthActions.SetMigrationCustomerId(migration_customer_id)
          );

          const selectedInvoice = _.find(currentUser.invoiceData, {
            migration_customer_id: migration_customer_id,
          });

          if (selectedInvoice && !_.isEmpty(selectedInvoice)) {
            selectedInvoice.selected = true;
          }

          this.store.dispatch(
            new SelectedInvoiceActions.SetSelectedInvoice(
              _.cloneDeep(selectedInvoice)
            )
          );
        } else {
          window.localStorage.removeItem("migration_customer_id");

          this.store.dispatch(
            new SelectedInvoiceActions.SetSelectedInvoice(null)
          );
        }
      }
    }
  }

  loadMoToken() {
    if (navigator.cookieEnabled) {
      if (
        window.localStorage.getItem("mo_token") &&
        window.localStorage.getItem("mo_token") !== ""
      ) {
        let mo_token = window.localStorage.getItem("mo_token");
        if (!_.isEmpty(mo_token)) {
          this.store.dispatch(
            new AuthActions.SetMoToken(_.cloneDeep(mo_token))
          );
        } else {
          this.store.dispatch(
            new AuthActions.SetLoginLoading(_.cloneDeep("not-loading"))
          );
        }
      } else {
        this.store.dispatch(
          new AuthActions.SetLoginLoading(_.cloneDeep("not-loading"))
        );
      }
    } else {
      this.store.dispatch(
        new AuthActions.SetLoginLoading(_.cloneDeep("not-loading"))
      );
    }
  }

  loadDeliveryMethodAddress(store) {
    if (navigator.cookieEnabled) {
      console.log("load delivery method address variables");
      if (
        window.localStorage.getItem("deliveryMethodAddress") &&
        window.localStorage.getItem("deliveryMethodAddress") !== "{}" &&
        window.localStorage.getItem("deliveryMethodAddress") !== ""
      ) {
        console.log("cookie Enabled and deliveryMethodAddress Object exists");
        let deliveryMethodAddress = JSON.parse(
          window.localStorage.getItem("deliveryMethodAddress")
        );
        console.log(
          "deliveryMethodObjectFromLocalStorage",
          deliveryMethodAddress
        );
        if (
          deliveryMethodAddress &&
          deliveryMethodAddress.orderPickup != null
        ) {
          console.log(
            "set order pickup from local storage",
            deliveryMethodAddress.orderPickup,
            store.disableOrderPickup
          );
          if (store.disableOrderPickup && deliveryMethodAddress.orderPickup) {
            this.store.dispatch(
              new DeliveryMethodAddressActions.setOrderPickup(null)
            );
            this.store.dispatch(
              new DeliveryMethodAddressActions.setDineIn(null)
            );
            this.store.dispatch(
              new DeliveryMethodAddressActions.setValidDeliveryAreas(null)
            );

            this.store.dispatch(
              new DeliveryMethodAddressActions.setCarTakeawayPlateNumber(null)
            );
            this.store.dispatch(
              new DeliveryMethodAddressActions.setCarTakewayComments(null)
            );

            return false;
          } else {
            console.log("set order picup", deliveryMethodAddress.orderPickup);
            this.store.dispatch(
              new DeliveryMethodAddressActions.setOrderPickup(
                deliveryMethodAddress.orderPickup
              )
            );

            if (
              deliveryMethodAddress.carTakeAwayPlateNumber &&
              store.carTakeawayActive
            ) {
              this.store.dispatch(
                new DeliveryMethodAddressActions.setCarTakeawayPlateNumber(
                  deliveryMethodAddress.carTakeAwayPlateNumber
                )
              );
              this.store.dispatch(
                new DeliveryMethodAddressActions.setCarTakewayComments(
                  deliveryMethodAddress.carTakeAwayComments || null
                )
              );
            }
          }
        } else {
          this.store.dispatch(
            new DeliveryMethodAddressActions.setOrderPickup(null)
          );
          this.store.dispatch(
            new DeliveryMethodAddressActions.setValidDeliveryAreas(null)
          );

          this.store.dispatch(
            new DeliveryMethodAddressActions.setCarTakeawayPlateNumber(null)
          );
          this.store.dispatch(
            new DeliveryMethodAddressActions.setCarTakewayComments(null)
          );
        }
        if (deliveryMethodAddress && deliveryMethodAddress.dineIn != null) {
          if (deliveryMethodAddress.dineIn && !store.dinein) {
            this.store.dispatch(
              new DeliveryMethodAddressActions.setDineIn(null)
            );
            this.store.dispatch(
              new DeliveryMethodAddressActions.setOrderPickup(null)
            );

            this.store.dispatch(
              new DeliveryMethodAddressActions.setCarTakeawayPlateNumber(null)
            );
            this.store.dispatch(
              new DeliveryMethodAddressActions.setCarTakewayComments(null)
            );

            this.store.dispatch(
              new DeliveryMethodAddressActions.setSelectedAddress(null)
            );
            this.store.dispatch(
              new DeliveryMethodAddressActions.setValidDeliveryAreas(null)
            );
            this.store.dispatch(
              new DeliveryMethodAddressActions.setSelectedPreorderDate(null)
            );

            this.store.dispatch(
              new DeliveryMethodAddressActions.setSelectedDineInTable(null)
            );
            deliveryMethodAddress = {
              dineIn: null,
              orderPickup: null,
            };

            localStorage.setItem(
              "deliveryMethodAddress",
              JSON.stringify(deliveryMethodAddress)
            );

            return false;
          }
          this.store.dispatch(
            new DeliveryMethodAddressActions.setDineIn(
              deliveryMethodAddress.dineIn
            )
          );
          if (deliveryMethodAddress.selectedDineInTable && store.dinein) {
            this.store.dispatch(
              new DeliveryMethodAddressActions.setSelectedDineInTable(
                deliveryMethodAddress.selectedDineInTable
              )
            );

            this.store.dispatch(
              new DeliveryMethodAddressActions.setDineInOrderStartTimestamp(
                deliveryMethodAddress.dineInOrderStartTimestamp || null
              )
            );
          }
        } else {
          this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(null));
          this.store.dispatch(
            new DeliveryMethodAddressActions.setOrderPickup(null)
          );
          this.store.dispatch(
            new DeliveryMethodAddressActions.setValidDeliveryAreas(null)
          );
          this.store.dispatch(
            new DeliveryMethodAddressActions.setSelectedDineInTable(null)
          );

          this.store.dispatch(
            new DeliveryMethodAddressActions.setCarTakeawayPlateNumber(null)
          );
          this.store.dispatch(
            new DeliveryMethodAddressActions.setCarTakewayComments(null)
          );
          return false;
        }
        if (
          deliveryMethodAddress &&
          deliveryMethodAddress.selectedAddress &&
          store.disableOrderDelivery
        ) {
          this.store.dispatch(
            new DeliveryMethodAddressActions.setCheckSelectedAddress(null)
          );
          this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(null));
          this.store.dispatch(
            new DeliveryMethodAddressActions.setOrderPickup(null)
          );
          this.store.dispatch(
            new DeliveryMethodAddressActions.setValidDeliveryAreas(null)
          );
          this.store.dispatch(
            new DeliveryMethodAddressActions.setSelectedDineInTable(null)
          );

          this.store.dispatch(
            new DeliveryMethodAddressActions.setCarTakeawayPlateNumber(null)
          );
          this.store.dispatch(
            new DeliveryMethodAddressActions.setCarTakewayComments(null)
          );
        } else if (
          deliveryMethodAddress &&
          deliveryMethodAddress.selectedAddress &&
          !store.disableOrderDelivery
        ) {
          this.store.dispatch(
            new DeliveryMethodAddressActions.setCheckSelectedAddress(
              deliveryMethodAddress.selectedAddress
            )
          );
        }

        if (
          deliveryMethodAddress &&
          deliveryMethodAddress.selectedPreorderDate
        ) {
          if (!deliveryMethodAddress.dineIn) {
            this.store.dispatch(
              new DeliveryMethodAddressActions.setSelectedPreorderDate(
                deliveryMethodAddress.selectedPreorderDate
              )
            );
          } else {
            //clear peroder date beacause of dine in

            if (deliveryMethodAddress) {
              deliveryMethodAddress.selectedPreorderDate = null;
              console.log(
                "before write to localstorage",
                deliveryMethodAddress
              );
              localStorage.setItem(
                "deliveryMethodAddress",
                JSON.stringify(deliveryMethodAddress)
              );
            }

            this.store.dispatch(
              new DeliveryMethodAddressActions.setSelectedPreorderDate(null)
            );
          }
        }
      } else {
        this.store.dispatch(
          new DeliveryMethodAddressActions.setOrderPickup(null)
        );
        this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(null));

        this.store.dispatch(
          new DeliveryMethodAddressActions.setCarTakeawayPlateNumber(null)
        );
        this.store.dispatch(
          new DeliveryMethodAddressActions.setCarTakewayComments(null)
        );
      }
    } else {
      this.store.dispatch(
        new DeliveryMethodAddressActions.setOrderPickup(null)
      );
      this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(null));
      this.store.dispatch(
        new DeliveryMethodAddressActions.setValidDeliveryAreas(null)
      );

      this.store.dispatch(
        new DeliveryMethodAddressActions.setCarTakeawayPlateNumber(null)
      );
      this.store.dispatch(
        new DeliveryMethodAddressActions.setCarTakewayComments(null)
      );
    }
  }

  loadCheckboxPromotions() {
    if (navigator.cookieEnabled) {
      let checkboxPromotions = window.localStorage.getItem("checkboxPromotions")
        ? JSON.parse(window.localStorage.getItem("checkboxPromotions"))
        : [];
      if (!_.isEmpty(checkboxPromotions)) {
        this.store.dispatch(
          new CheckboxPromotionsActions.SetCheckboxPromotions(
            checkboxPromotions
          )
        );
      }
    }
  }

  loadCurrentStore() {
    console.log("load current store from local storage");
    if (navigator.cookieEnabled) {
      let current_store_id = window.localStorage.getItem("store_id")
        ? JSON.parse(window.localStorage.getItem("store_id"))
        : null;
      if (!_.isEmpty(current_store_id)) {
        console.log("set store id select local storage service");
        this.store.dispatch(
          new CurrentStoreActions.SetCurrentStoreId(current_store_id)
        );
      }
    }
  }

  loadCustomOptions(store_info) {
    if (store_info && store_info.cartCustomOptionsActive) {
      let cartCustomOptions: any = [];

      let lastStoreInfo = _.cloneDeep(store_info);
      if (
        navigator.cookieEnabled &&
        window.localStorage.getItem("cartCustomOptions")
      ) {
        cartCustomOptions = JSON.parse(
          window.localStorage.getItem("cartCustomOptions")
        );
      }

      if (
        cartCustomOptions &&
        cartCustomOptions.length > 0 &&
        _.isArray(cartCustomOptions)
      ) {
        _.each(cartCustomOptions, (option, key) => {
          var customOptionIndex = _.findIndex(lastStoreInfo.cartCustomOptions, {
            timestamp: option.timestamp,
          });

          console.log("customOption", customOptionIndex);

          if (
            customOptionIndex !== -1 &&
            lastStoreInfo.cartCustomOptions[customOptionIndex].active
          ) {
            _.each(
              lastStoreInfo.cartCustomOptions[customOptionIndex].choices,
              (choice, index) => {
                //All the choices have qty. The qtyEnabled options just have a way to increase it!
                var choiceIndex = _.findIndex(option.choices, {
                  timestamp: choice.timestamp,
                });
                console.log(
                  option.title,
                  index,
                  choice,
                  option.choices,
                  choiceIndex
                );

                console.log(
                  "asdasdasd",
                  choiceIndex,
                  lastStoreInfo.cartCustomOptions[customOptionIndex]
                );
                if (
                  choiceIndex !== -1 &&
                  lastStoreInfo.cartCustomOptions[customOptionIndex].type ===
                    "text" &&
                  option.choices[choiceIndex].text
                ) {
                  lastStoreInfo.cartCustomOptions[customOptionIndex].choices[
                    index
                  ].text = option.choices[choiceIndex].text;
                }

                if (choiceIndex !== -1) {
                  lastStoreInfo.cartCustomOptions[customOptionIndex].choices[
                    index
                  ].qty = option.choices[choiceIndex].qty
                    ? option.choices[choiceIndex].qty
                    : "";
                  lastStoreInfo.cartCustomOptions[customOptionIndex].choices[
                    index
                  ].price = option.choices[choiceIndex].price
                    ? option.choices[choiceIndex].price
                    : "";
                  lastStoreInfo.cartCustomOptions[customOptionIndex].choices[
                    index
                  ].selected = option.choices[choiceIndex].selected
                    ? option.choices[choiceIndex].selected
                    : false;
                } else {
                }

                if (choice.price) {
                  if (
                    typeof lastStoreInfo.cartCustomOptions[customOptionIndex]
                      .choices[index].qty !== "number" ||
                    typeof lastStoreInfo.cartCustomOptions[customOptionIndex]
                      .choices[index].price !== "number"
                  ) {
                    lastStoreInfo.cartCustomOptions[customOptionIndex].choices[
                      index
                    ].qty = parseFloat(
                      lastStoreInfo.cartCustomOptions[customOptionIndex]
                        .choices[index].qty
                    );
                    lastStoreInfo.cartCustomOptions[customOptionIndex].choices[
                      index
                    ].price = parseFloat(
                      lastStoreInfo.cartCustomOptions[customOptionIndex]
                        .choices[index].price
                    );
                  }
                  lastStoreInfo.cartCustomOptions[customOptionIndex].choices[
                    index
                  ].totalPrice =
                    lastStoreInfo.cartCustomOptions[customOptionIndex].choices[
                      index
                    ].qty *
                    lastStoreInfo.cartCustomOptions[customOptionIndex].choices[
                      index
                    ].price.toFixed(2);
                  if (
                    typeof lastStoreInfo.cartCustomOptions[customOptionIndex]
                      .choices[index].totalPrice !== "number"
                  ) {
                    lastStoreInfo.cartCustomOptions[customOptionIndex].choices[
                      index
                    ].totalPrice = parseFloat(
                      lastStoreInfo.cartCustomOptions[customOptionIndex]
                        .choices[index].totalPrice
                    );
                  }
                } else {
                  lastStoreInfo.cartCustomOptions[customOptionIndex].choices[
                    index
                  ].totalPrice = 0;
                }
              }
            );
          }
        });
      }
      let selectedCustomOptions;
      if (
        lastStoreInfo &&
        lastStoreInfo.cartCustomOptions &&
        lastStoreInfo.cartCustomOptions.length > 0
      ) {
        selectedCustomOptions = _.cloneDeep(lastStoreInfo.cartCustomOptions);
      }

      console.log("selected custom options", selectedCustomOptions);
      if (
        selectedCustomOptions &&
        selectedCustomOptions &&
        selectedCustomOptions.length > 0
      ) {
        console.log("dispathcing");
        // console.log(
        //   "SELECTED OPTIONS FROM LOADDDDDDDDDDDDDDDDDDDDDDDDDDDDDD",
        //   selectedCustomOptions
        // );
        selectedCustomOptions.forEach((option) => {
          this.store.dispatch(
            new cartCustomOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
              option
            )
          );
        });
      }

      // this.calculateCustomOptions(
      //   this.store_info,
      //   this.selectePaymentMethodID,
      //   this.orderPickup,
      //   selectedCustomOptions
      // );
    } else {
      console.log("clear local storage from load");
      let customOptions = [];
      this.store.dispatch(
        new CartCustomerOptionsActions.ClearCartCustomerOptions()
      );
      this.store.dispatch(
        new cartCustomOptionsSelectionsActions.ClearCartCustomOptionsSelections()
      );
      if (navigator.cookieEnabled) {
        window.localStorage.setItem(
          "cartCustomOptions",
          JSON.stringify(customOptions)
        );
      }
    }
  }
}
