import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  AlertController,
  LoadingController,
  ModalController,
  Platform,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AnimationOptions } from "ngx-lottie";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { Geolocation } from "@capacitor/geolocation";
import { getDistance } from "geolib";
import * as DeliveryMethodAddressActions from "../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import { CheckLocationDistanceService } from "src/app/services/checkLocationDistance/check-location-distance.service";
import { DineinValidationsService } from "src/app/services/dineInValidations/dinein-validations.service";

@Component({
  selector: "app-no-location-dine-in-modal",
  templateUrl: "./no-location-dine-in-modal.component.html",
  styleUrls: ["./no-location-dine-in-modal.component.scss"],
})
export class NoLocationDineInModalComponent implements OnInit, OnDestroy {
  @Input() orderNowModal: boolean;
  @Input() message: any;
  @Input() completeOrder: boolean;
  @Input() appComponentCheck: boolean;
  public ionLoading: any;
  public disableButton: boolean;

  public storeInfo: any;
  options: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/location-service-off.json",
  };

  public onDismissClearDeliveryMethod: boolean = false;
  private subscription: Subscription;
  private unsubscribeBackEvent: Subscription;
  constructor(
    private modalController: ModalController,
    private translateService: TranslateService,
    private loadingController: LoadingController,
    private dineInValidationsService: DineinValidationsService,
    private alertController: AlertController,
    private checkLocationDistanceService: CheckLocationDistanceService,
    private platform: Platform,
    private store: Store<fromApp.AppState>
  ) {}

  closeModal() {
    if (this.onDismissClearDeliveryMethod && !this.orderNowModal) {
      //clear dine in
      this.clearDineIn();

      this.modalController.dismiss();
    } else {
      this.modalController.dismiss();
    }
  }

  async presentIonLoading() {
    let message = this.translateService.instant("loading-please-wait");
    this.ionLoading = await this.loadingController.create({
      duration: 30000,
      message: message,
      cssClass: "ionicLoadingCss",
      keyboardClose: true,
    });
    return await this.ionLoading.present();
  }

  dismissIonLoading() {
    console.log("Loading dismissed!", this.ionLoading);
    if (this.ionLoading) {
      this.ionLoading.dismiss();
      this.ionLoading = null;
    }
  }

  ngOnInit() {
    this.dineInValidationsService.dismissDineInOrderStopAlert();
    this.store.dispatch(
      new DeliveryMethodAddressActions.setNoLocationDineInModalOpen("opened")
    );

    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    if (!this.orderNowModal) {
      this.onDismissClearDeliveryMethod = true;
    }
    this.subscription = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .pipe(auditTime(50))
      .subscribe((storeInfo) => {
        if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
          this.storeInfo = _.cloneDeep(storeInfo);
        }
      });
  }

  clearDineIn() {
    this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(null));
    this.store.dispatch(new DeliveryMethodAddressActions.setOrderPickup(null));
    this.store.dispatch(
      new DeliveryMethodAddressActions.setSelectedDineInTable(null)
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setValidDeliveryAreas(null)
    );

    this.store.dispatch(
      new DeliveryMethodAddressActions.setCarTakeawayPlateNumber(null)
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setCarTakewayComments(null)
    );
    if (navigator.cookieEnabled) {
      if (
        window.localStorage.getItem("deliveryMethodAddress") &&
        window.localStorage.getItem("deliveryMethodAddress") !== "{}" &&
        window.localStorage.getItem("deliveryMethodAddress") !== ""
      ) {
        console.log("cookie Enabled and deliveryMethodAddress Object exists");
        let deliveryMethodAddress = JSON.parse(
          window.localStorage.getItem("deliveryMethodAddress")
        );
        deliveryMethodAddress.dineIn = null;
        deliveryMethodAddress.orderPickup = null;
        window.localStorage.setItem(
          "deliveryMethodAddress",
          JSON.stringify(deliveryMethodAddress)
        );
      }
    }
  }

  async checkLocationAgain() {
    if (this.orderNowModal) {
      this.modalController.dismiss("check-location-again");
    } else {
      this.disableButton = true;
      await this.presentIonLoading();
      let distance: any;
      const distance_object: any =
        await this.checkLocationDistanceService.calculateDistanceFromGps(
          {
            lat: this.storeInfo.mapCenterStoreLat,
            lng: this.storeInfo.mapCenterStoreLng,
          },
          false
        );
      this.dismissIonLoading();
      this.disableButton = false;
      if (distance_object && distance_object.distance) {
        distance = distance_object.distance;
      } else if (!distance_object || distance_object.error) {
        console.log("error calculating distance", distance_object);
        this.onDismissClearDeliveryMethod = true;
      }

      if (distance) {
        if (distance <= 300) {
          if (!this.orderNowModal) {
            this.onDismissClearDeliveryMethod = false;
          }
          if (this.completeOrder) {
            this.modalController.dismiss("success_complete_order");
          }
          if (this.appComponentCheck) {
            this.modalController.dismiss();
          }
        } else {
          if (!this.orderNowModal) {
            this.onDismissClearDeliveryMethod = true;
          }

          this.message = this.translateService.instant(
            "popups.no-location-dine-in-modal.you-are-out-of-store"
          );
        }
      } else {
        if (!distance_object || !distance_object.error) {
          this.message = this.translateService.instant(
            "popups.no-location-dine-in-modal.something-went-wrong-try-again"
          );
        } else if (distance_object && distance_object.error) {
          if (distance_object.error === "no-gps-enabled") {
            this.message = this.translateService.instant(
              "popups.no-location-dine-in-modal.no-gps-enabled"
            );
          } else if (distance_object.error === "no-coordinates") {
            this.message = this.translateService.instant(
              "popups.no-location-dine-in-modal.no-coordinates"
            );
          } else if (distance_object.error) {
            this.message = this.translateService.instant(
              this.translateService.instant(distance_object.error)
            );
          } else {
            console.log("GPS no error");
          }
        }
      }
    }
  }

  animationCreated(ev) {}

  ngOnDestroy(): void {
    this.store.dispatch(
      new DeliveryMethodAddressActions.setNoLocationDineInModalOpen("closed")
    );

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
