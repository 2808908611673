import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  AlertController,
  LoadingController,
  ModalController,
  ToastController,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import moment from "moment";
import { Subscription } from "rxjs";
import { auditTime, distinctUntilChanged, first } from "rxjs/operators";
import { SelectPaymentMethodPopupComponent } from "src/app/popups/select-payment-method-popup/select-payment-method-popup.component";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as fromApp from "../../store/app.reducer";
import { OrderDetailsComponent } from "../order-details/order-details.component";
import { OrderRatingModalComponent } from "../order-rating-modal/order-rating-modal.component";
import { OrderItemDetailsComponent } from "./order-item-details/order-item-details.component";
import * as OrdersActions from "../store/orders.actions";
import { TranslateService } from "@ngx-translate/core";
import { SendOrderPayPopupComponent } from "src/app/popups/send-order-pay-popup/send-order-pay-popup.component";
import * as selectors from "../../store/app.selectors";
import { PreviousOrderModalComponent } from "src/app/catalog/previous-order/previous-order-modal/previous-order-modal.component";
import * as PreviousOrdersActions from "../../catalog/previous-order/store/previousOrders.actions";
import * as CurrentStoreActions from "../../store/current-store/current-store.actions";
import * as DeliveryMethodAddressActions from "../../store/deliveryMethodAddress/deliveryMethodAddress.actions";

@Component({
  selector: "app-order-item",
  templateUrl: "./order-item.component.html",
  styleUrls: ["./order-item.component.scss"],
})
export class OrderItemComponent implements OnInit, OnChanges, OnDestroy {
  @Input() order: any;
  @Input() openOrderChangePaymentMethodModalForOrderId;
  @Output() openedChangePaymentMethodPopup = new EventEmitter();
  @Input() orderForReview;
  @Input() orderIndex;
  public stores: any;
  public selectedLanguage: any;
  public mo_token: any;
  public storesByStoreId: any;
  public loading = true;
  public renderedOrder: any;
  public orderPickup;
  public dineIn;
  public currentUser: any;
  public groupApp: any;
  public selectedPreorderDate: any;
  public selectedDineInTable: any;
  public storeInfo;
  public selectedAddress;
  public piraeusPendingPaymentOrder;
  public ds_status_color: string;

  private migration_customer_id: any;
  private reviewOrderPresented = false;
  private currentStoreId: any;
  private subscription: Subscription;
  private orderDetailsModal: any;
  private reviewOrderModal: any;
  private selectPaymentMethodModal;
  private groupSub: Subscription;
  private authSub: Subscription;
  private selectedStoreSub: Subscription;
  private deliveryMethodAddressSub: Subscription;
  private storeInfoSub: Subscription;
  private ordersSub: Subscription;
  private ionLoading: any;
  private sendOrderAndPayModal: any;
  private previousOrderModal: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private modalCtrl: ModalController,
    private dataStorageService: DataStorageService,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private translate: TranslateService,
    private toastController: ToastController
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.order &&
      changes.order.currentValue !== changes.order.previousValue &&
      changes.order.previousValue
    ) {
      if (this.order && this.order.timestamp) {
        const timeSent = moment(parseInt(_.cloneDeep(this.order.timestamp)))
          .set("hour", 0)
          .set("minutes", 0)
          .set("seconds", 0);
        const beforeOneMonth = moment()
          .subtract(1, "month")
          .set("hour", 0)
          .set("minutes", 0)
          .set("seconds", 0);

        let isAfter = timeSent.isBefore(beforeOneMonth);
        this.order.oldOrder = isAfter;
      }
      this.renderedOrder = _.cloneDeep(this.order);
      if (this.orderForReview && this.orderIndex === 0) {
        this.checkToPresentOrderReviewModal();
      }
      this.caclulateDsStatusColor();
    }

    if (
      changes &&
      changes.openOrderChangePaymentMethodModalForOrderId &&
      !changes.openOrderChangePaymentMethodModalForOrderId.previousValue &&
      changes.openOrderChangePaymentMethodModalForOrderId.currentValue &&
      changes.openOrderChangePaymentMethodModalForOrderId.currentValue ===
        this.order.order_id
    ) {
      this.openSelectPaymentMethodPopup(_.cloneDeep(this.order));
      this.openedChangePaymentMethodPopup.emit("opened");
    }
  }

  isNum(val) {
    return /^\d+$/.test(val);
  }

  ngOnInit() {
    this.ordersSub = this.store
      .select("orders")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(200))
      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(
            state.piraeusPendingPaymentOrder,
            this.piraeusPendingPaymentOrder
          )
        ) {
          this.piraeusPendingPaymentOrder = _.cloneDeep(
            state.piraeusPendingPaymentOrder
          );
        }
      });

    if (this.order && this.order.timestamp) {
      const timeSent = moment(parseInt(_.cloneDeep(this.order.timestamp)))
        .set("hour", 0)
        .set("minutes", 0)
        .set("seconds", 0);
      const beforeOneMonth = moment()
        .subtract(1, "month")
        .set("hour", 0)
        .set("minutes", 0)
        .set("seconds", 0);

      let isAfter = timeSent.isBefore(beforeOneMonth);
      this.order.oldOrder = isAfter;

      //this attr is for orders that is in panding payment status more than 30 minutes because there is a bug that not canceling order sometimes!
      this.order.orderMustBeCanceled = !!(
        this.order &&
        this.order.status === "pendingPayment" &&
        this.order.timestamp &&
        moment().diff(
          moment(parseInt(this.order.timestamp.toString())),
          "minutes"
        ) > 35
      );
    }
    this.renderedOrder = _.cloneDeep(this.order);
    if (this.orderForReview && this.orderIndex === 0) {
      this.checkToPresentOrderReviewModal();
    }
    this.caclulateDsStatusColor();

    this.storeInfoSub = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((storeInfo) => {
        if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
          this.storeInfo = _.cloneDeep(storeInfo);
        }
      });

    this.deliveryMethodAddressSub = this.store
      .select("deliveryMethodAddress")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (state && !_.isEqual(state.orderPickup, this.orderPickup)) {
          this.orderPickup = _.cloneDeep(state.orderPickup);
        }
        if (state && !_.isEqual(state.dineIn, this.dineIn)) {
          this.dineIn = _.cloneDeep(state.dineIn);
        }
        if (state && !_.isEqual(state.selectedAddress, this.selectedAddress)) {
          this.selectedAddress = _.cloneDeep(state.selectedAddress);
        }
        if (
          state &&
          !_.isEqual(state.selectedDineInTable, this.selectedDineInTable)
        ) {
          this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
        }
        if (!_.isEqual(state.selectedPreorderDate, this.selectedPreorderDate)) {
          this.selectedPreorderDate = _.cloneDeep(state.selectedPreorderDate);
          let preorderData;
          if (
            this.selectedPreorderDate &&
            this.selectedPreorderDate.date &&
            this.selectedPreorderDate.hour
          ) {
            preorderData = {
              preorder: true,
              preorder_day: this.selectedPreorderDate.date,
              preorder_time: this.selectedPreorderDate.hour,
            };
          } else {
            preorderData = null;
          }
          this.store
            .select("previousOrders")
            .pipe(first())
            .subscribe((prevOrderState) => {
              if (
                (prevOrderState &&
                  prevOrderState.previousOrderCart &&
                  prevOrderState.previousOrders &&
                  prevOrderState.previousOrders.length &&
                  prevOrderState.onPreorderChangeFetchAgain &&
                  this.previousOrderModal) ||
                (prevOrderState &&
                  prevOrderState.previousOrderCart &&
                  this.previousOrderModal &&
                  this.hasProductWithAvailablePreorderTag(
                    _.cloneDeep(prevOrderState.previousOrderCart)
                  ))
              ) {
                this.fetchPreviousOrderProductsForPreorder(
                  _.cloneDeep(prevOrderState.previousOrderCart)
                );
              }
            })
            .unsubscribe();
        }
      });

    console.log(this.order);
    this.authSub = this.store
      .select("auth")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          state.mo_token &&
          !_.isEqual(state.mo_token, this.mo_token)
        ) {
          this.mo_token = _.cloneDeep(state.mo_token);
        }
        if (state && state.user && !_.isEqual(state.user, this.currentUser)) {
          this.currentUser = _.cloneDeep(state.user);
        }

        if (
          state &&
          !_.isEqual(state.migration_customer_id, this.migration_customer_id)
        ) {
          this.migration_customer_id = _.cloneDeep(state.migration_customer_id);
        }
      });
    this.groupSub = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(state.selectedLangugage, this.selectedLanguage)
        ) {
          this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
        }
        if (
          state &&
          state.groupApp &&
          !_.isEqual(state.groupApp, this.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
        }
      });
    this.subscription = this.store
      .select("stores")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
          this.stores = _.cloneDeep(state.stores);
          if (this.stores) {
            this.storesByStoreId = _.groupBy(this.stores, "store_id");

            _.each(this.storesByStoreId, (store, key, list) => {
              this.storesByStoreId[key] = store[0];
            });
          }
        }
      });

    this.selectedStoreSub = this.store
      .select("currentStore")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.currentStoreId &&
          !_.isEqual(this.currentStoreId, state.currentStoreId)
        ) {
          this.currentStoreId = _.cloneDeep(state.currentStoreId);
        }
      });
  }

  checkToPresentOrderReviewModal() {
    setTimeout(() => {
      if (
        this.storesByStoreId &&
        this.storesByStoreId[this.renderedOrder.store_id] &&
        this.storesByStoreId[this.renderedOrder.store_id]
          .reviewSystemActivated &&
        !this.renderedOrder.oldOrder &&
        !this.renderedOrder.rated &&
        this.renderedOrder.status !== "pendingPayment" &&
        this.renderedOrder.status !== "processingPayment" &&
        (this.renderedOrder.status === "complete" ||
          this.renderedOrder.status === "canceled") &&
        !this.reviewOrderPresented
      ) {
        this.reviewOrderPresented = true;
        this.presentReviewOrderModal(this.renderedOrder);
      }
    }, 2000);
  }

  openSelectPaymentMethodPopup(order) {
    if (order && order.store_id && order.store_id !== this.currentStoreId) {
      this.store.dispatch(
        new CurrentStoreActions.SetCurrentStoreId(_.cloneDeep(order.store_id))
      );
    }
    this.ShowSelectPaymentMethodPopup(order);
  }

  hasProductWithAvailablePreorderTag(previousOrderCart) {
    return !!_.find(previousOrderCart, (product) => {
      if (
        (product && product.tags && product.tags.availableForPreorder) ||
        product.preorder_availability_stock_enabled
      ) {
        return true;
      }
    });
  }

  caclulateDsStatusColor() {
    if (this.renderedOrder) {
      if (this.renderedOrder.ds_status === "order_received") {
        this.ds_status_color = "processing";
      } else if (this.renderedOrder.ds_status === "order_picked_up") {
        this.ds_status_color = "processing";
      } else if (this.renderedOrder.ds_status === "order_delivered") {
        this.ds_status_color = "complete";
      } else if (this.renderedOrder.ds_status === "order_rejected") {
        this.ds_status_color = "canceled";
      }
    }
  }

  async ShowSelectPaymentMethodPopup(order) {
    if (!this.selectPaymentMethodModal) {
      this.selectPaymentMethodModal = true;
      this.selectPaymentMethodModal = await this.modalCtrl.create({
        component: SelectPaymentMethodPopupComponent,
        componentProps: {
          order: _.cloneDeep(order),
        },
        cssClass: "selectPaymentMethodPopup",
        backdropDismiss: true,
      });

      await this.selectPaymentMethodModal.present();
      this.selectPaymentMethodModal.onDidDismiss().then((data) => {
        this.selectPaymentMethodModal = null;
        if (data && data.data) {
          console.log("selected pyament method changed", data.data);
          this.changeOrderPaymentMethod(data.data);
        }
      });
    }
  }

  async changeOrderPaymentMethod(paymentMethod) {
    if (paymentMethod === "cash" || paymentMethod === "mobilePOS") {
      const alert = await this.alertController.create({
        header: this.translate.instant("orders.changed-order-payment-method"),
        message:
          this.translate.instant("orders.do-you-want-to-send-order-with") +
          " " +
          this.translate.instant(
            "cart.cart-components.payment-methods." + paymentMethod
          ) +
          "?",
        buttons: [
          {
            text: this.translate.instant("cancel"),
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              console.log("Confirm Cancel: blah");
            },
          },
          {
            text: this.translate.instant("yes"),
            handler: () => {
              this.changeOrderPaymentMethodActions(paymentMethod);
            },
          },
        ],
      });

      await alert.present();
    } else {
      this.changeOrderPaymentMethodActions(paymentMethod);
    }
  }

  changeOrderPaymentMethodActions(paymentMethod) {
    this.presentLoading();
    this.dataStorageService
      .changeOrderPaymentMethod(
        {
          order_id: this.order.order_id,
          store_id: this.order.store_id,
          language_code: this.selectedLanguage,
          selectedPaymentMethodID: paymentMethod,
        },
        this.mo_token
      )
      .subscribe({
        next: async (res: any) => {
          if (res && res.success) {
            if (this.ionLoading) {
              this.ionLoading.dismiss();
            }

            let updatedOrder = _.cloneDeep(this.order);
            updatedOrder.selectedPaymentMethodID = paymentMethod;
            this.store.dispatch(
              new OrdersActions.AddUpdateOrderFromSocket(
                _.cloneDeep(updatedOrder)
              )
            );
            if (paymentMethod !== "cash" && paymentMethod !== "mobilePOS") {
              this.presentSendOrderAndPayModal(paymentMethod, this.currentUser);
            }
          } else if (res && !res.success) {
            if (this.ionLoading) {
              this.ionLoading.dismiss();
            }
            if (res.comment_id) {
              const alert = await this.alertController.create({
                header: this.translate.instant("alert"),
                message: this.translate.instant(
                  "errorMessages." + res.comment_id
                ),

                buttons: ["OK"],
              });
              alert.present();
            } else {
              const alert = await this.alertController.create({
                header: this.translate.instant("alert"),
                message: this.translate.instant(res.comments),

                buttons: ["OK"],
              });
              alert.present();
            }
          } else {
            if (this.ionLoading) {
              this.ionLoading.dismiss();
            }

            const alert = await this.alertController.create({
              header: this.translate.instant("alert"),
              message: this.translate.instant(
                "errorMessages.problem_reaching_server"
              ),

              buttons: ["OK"],
            });
            alert.present();
          }
        },
        error: async (err) => {
          if (this.ionLoading) {
            this.ionLoading.dismiss();
          }
          const alert = await this.alertController.create({
            header: this.translate.instant("alert"),
            message: this.translate.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          return err;
        },
      });
  }

  askForStoreChanging() {
    if (
      this.stores &&
      this.stores.length > 1 &&
      this.storeInfo &&
      this.order.store_id !== this.storeInfo.store_id
    ) {
      this.changeStoreAlert();
    } else {
      this.reorderOrder();
    }
  }

  showOnMap(lat, lng) {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;

    window.open(googleMapsUrl, "_system");
  }

  changeStoreAlert() {
    this.store
      .select("cart")
      .pipe(first())
      .subscribe(async (state) => {
        if (
          state &&
          state.products &&
          state.products.length > 0 &&
          !this.selectedAddress
        ) {
          const alert = await this.alertController.create({
            cssClass: "my-custom-class",
            header: this.translate.instant("alert"),
            message: this.translate.instant(
              "orders.for-reorder-if-you-change-store-your-cart-products-will-be-deleted-do-you-want-to-continue"
            ),
            buttons: [
              {
                text: this.translate.instant("cancel"),
                role: "cancel",
                cssClass: "secondary",
                handler: (blah) => {},
              },
              {
                text: this.translate.instant("yes"),
                handler: () => {
                  this.store.dispatch(
                    new CurrentStoreActions.SetCurrentStoreId(
                      _.cloneDeep(this.order.store_id)
                    )
                  );
                  this.reorderOrder();
                },
              },
            ],
          });
          await alert.present();
        } else if (
          state &&
          state.products &&
          state.products.length > 0 &&
          this.selectedAddress
        ) {
          const alert = await this.alertController.create({
            cssClass: "my-custom-class",
            header: this.translate.instant("alert"),
            message: this.translate.instant(
              "orders.for-reorder-if-you-change-store-your-cart-products-and-your-address-will-be-deleted-do-you-want-to-continue"
            ),
            buttons: [
              {
                text: this.translate.instant("cancel"),
                role: "cancel",
                cssClass: "secondary",
                handler: (blah) => {},
              },
              {
                text: this.translate.instant("yes"),
                handler: () => {
                  this.deleteSelectedAddress();

                  this.store.dispatch(
                    new CurrentStoreActions.SetCurrentStoreId(
                      _.cloneDeep(this.order.store_id)
                    )
                  );
                  this.reorderOrder();
                },
              },
            ],
          });
          await alert.present();
        } else if (
          state &&
          state.products &&
          state.products.length === 0 &&
          this.selectedAddress
        ) {
          const alert = await this.alertController.create({
            cssClass: "my-custom-class",
            header: this.translate.instant("alert"),
            message: this.translate.instant(
              "orders.for-reorder-if-you-change-store-your-address-will-be-deleted-do-you-want-to-continue"
            ),
            buttons: [
              {
                text: this.translate.instant("cancel"),
                role: "cancel",
                cssClass: "secondary",
                handler: (blah) => {},
              },
              {
                text: this.translate.instant("yes"),
                handler: () => {
                  this.deleteSelectedAddress();

                  this.store.dispatch(
                    new CurrentStoreActions.SetCurrentStoreId(
                      _.cloneDeep(this.order.store_id)
                    )
                  );
                  this.reorderOrder();
                },
              },
            ],
          });
          await alert.present();
        } else if (
          state &&
          (!state.products ||
            (state.products && state.products.length === 0)) &&
          !this.selectedAddress
        ) {
          this.store.dispatch(
            new CurrentStoreActions.SetCurrentStoreId(
              _.cloneDeep(this.order.store_id)
            )
          );
          this.reorderOrder();
        }
      });
  }

  deleteSelectedAddress() {
    this.store.dispatch(new DeliveryMethodAddressActions.setValidStores(null));
    this.store.dispatch(
      new DeliveryMethodAddressActions.setValidDeliveryAreas(null)
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setCheckSelectedAddress(null)
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setSelectedAddress(null)
    );
    this.store.dispatch(new DeliveryMethodAddressActions.setOrderPickup(null));
    this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(null));
    this.store.dispatch(
      new DeliveryMethodAddressActions.setSelectedDineInTable(_.cloneDeep(null))
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setValidDeliveryAreas(null)
    );

    this.store.dispatch(
      new DeliveryMethodAddressActions.setCarTakeawayPlateNumber(null)
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setCarTakewayComments(null)
    );

    if (navigator.cookieEnabled) {
      let deliveryMethodAddressObj: any = localStorage.getItem(
        "deliveryMethodAddress"
      );
      if (deliveryMethodAddressObj) {
        deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
        deliveryMethodAddressObj.selectedAddress = null;
        deliveryMethodAddressObj.orderPickup = null;
        deliveryMethodAddressObj.dineIn = null;
        deliveryMethodAddressObj.cartTakeAwayPlateNumber = null;
        deliveryMethodAddressObj.carTakeAwayComments = null;

        localStorage.setItem(
          "deliveryMethodAddress",
          JSON.stringify(deliveryMethodAddressObj)
        );
      }
    }
  }

  reorderOrder() {
    this.showPreviousOrderModal(this.order);
    let preorderData;
    let dinein_table_id;
    if (
      this.selectedPreorderDate &&
      this.selectedPreorderDate.date &&
      this.selectedPreorderDate.hour
    ) {
      preorderData = {
        preorder: true,
        preorder_day: this.selectedPreorderDate.date,
        preorder_time: this.selectedPreorderDate.hour,
      };
    }

    if (this.dineIn && this.selectedDineInTable) {
      dinein_table_id = this.selectedDineInTable.table_id;
    }
    this.dataStorageService
      .fetchPreviousOrderProducts(
        this.order.store_id,
        this.order.order_id,
        this.selectedLanguage,
        this.groupApp.languages,
        this.mo_token,
        this.orderPickup,
        this.dineIn,
        preorderData,
        dinein_table_id,
        this.storeInfo.vatBasedCustomerProductsActive,
        this.migration_customer_id
      )
      .subscribe({
        next: (res: any) => {
          if (res && res.success) {
            console.log("response", res);
            this.store.dispatch(
              new PreviousOrdersActions.SetPreviousOrderCart(
                _.cloneDeep(res.products)
              )
            );

            // this.store.dispatch(
            //   new PreviousOrdersActions.SetPreviousOrders(res.orders)
            // );
          } else if (res && !res.success) {
            if (res && res.comment_id) {
              this.presentErrorToast(
                this.translate.instant("errorMessages." + res.comment_id),
                "danger"
              );
            } else if (res && res.comments) {
              this.presentErrorToast(
                this.translate.instant(res.comments),
                "danger"
              );
            }
          } else if (!res) {
          }
        },
        error: (error) => {
          this.presentErrorToast(
            this.translate.instant("errorMessages.problem_reaching_server"),
            "danger"
          );
          return error;
        },
      });
  }

  fetchPreviousOrderProductsForPreorder(previousOrderIntitialCart) {
    let preorderData;
    let dinein_table_id;
    if (
      this.selectedPreorderDate &&
      this.selectedPreorderDate.date &&
      this.selectedPreorderDate.hour
    ) {
      preorderData = {
        preorder: true,
        preorder_day: this.selectedPreorderDate.date,
        preorder_time: this.selectedPreorderDate.hour,
      };
    }

    if (this.dineIn && this.selectedDineInTable) {
      dinein_table_id = this.selectedDineInTable.table_id;
    }
    this.dataStorageService
      .fetchPreviousOrderProducts(
        this.order.store_id,
        this.order.order_id,
        this.selectedLanguage,
        this.groupApp.languages,
        this.mo_token,
        this.orderPickup,
        this.dineIn,
        preorderData,
        dinein_table_id,
        this.storeInfo.vatBasedCustomerProductsActive,
        this.migration_customer_id
      )
      .subscribe({
        next: (res: any) => {
          if (res && res.success) {
            console.log("response", res);
            const newProducts = res.products;

            if (
              newProducts &&
              newProducts.length &&
              !_.isEqual(newProducts, previousOrderIntitialCart)
            ) {
              _.each(newProducts, (orderProduct) => {
                if (
                  orderProduct &&
                  ((orderProduct.tags &&
                    orderProduct.tags.availableForPreorder) ||
                    orderProduct.preorder_availability_stock_enabled)
                ) {
                  const indexInPreviousOrderCart = _.findIndex(
                    previousOrderIntitialCart,
                    { cart_id: orderProduct.cart_id }
                  );

                  if (indexInPreviousOrderCart !== -1) {
                    _.assign(
                      previousOrderIntitialCart[indexInPreviousOrderCart],
                      _.pick(orderProduct, [
                        "needsEdit",
                        "available",
                        "availableTotal",
                        "tags",
                        "needsEdit",
                        "available",
                        "availableTotal",
                        "tags",
                        "availability_stock",
                        "availability_stock_enabled",
                        "availableInitializationActive",
                        "availableInitializationTime",
                        "preorder_availability_stock",
                        "preorder_availability_stock_enabled",
                      ])
                    );
                  }
                }
              });
            }

            this.store.dispatch(
              new PreviousOrdersActions.SetPreviousOrderCart(
                _.cloneDeep(previousOrderIntitialCart)
              )
            );

            // this.store.dispatch(
            //   new PreviousOrdersActions.SetPreviousOrders(res.orders)
            // );
          } else if (res && !res.success) {
            if (res && res.comment_id) {
              this.presentErrorToast(
                this.translate.instant("errorMessages." + res.comment_id),
                "danger"
              );
            } else if (res && res.comments) {
              this.presentErrorToast(
                this.translate.instant(res.comments),
                "danger"
              );
            }
          } else if (!res) {
          }
        },
        error: (error) => {
          this.presentErrorToast(
            this.translate.instant("errorMessages.problem_reaching_server"),
            "danger"
          );
          return error;
        },
      });
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  showPreviousOrderModal(order) {
    this.previousOrderModal = true;
    this.presentPreviousOrderModal(order);
  }

  async presentPreviousOrderModal(order) {
    this.previousOrderModal = await this.modalCtrl.create({
      component: PreviousOrderModalComponent,
      cssClass: "previousOrderModal",
      animated: true,
      componentProps: { previousOrderFromOrderPage: order },
      backdropDismiss: false,
    });

    await this.previousOrderModal.present();
    this.previousOrderModal.onDidDismiss().then(() => {
      this.previousOrderModal = null;
    });
  }

  async presentSendOrderAndPayModal(paymentMethodId, currentUser) {
    this.sendOrderAndPayModal = await this.modalCtrl.create({
      component: SendOrderPayPopupComponent,
      cssClass:
        currentUser && currentUser.cards && currentUser.cards.length > 0
          ? "sendOrderAndPayModal"
          : "sendOrderAndPayModalSmall",
      componentProps: {
        existedOrderItem: this.order,
        existingOrderSelectedPaymentMethodID: paymentMethodId,
      },
      backdropDismiss: false,
    });
    await this.sendOrderAndPayModal.present();
    this.sendOrderAndPayModal.onDidDismiss().then(() => {
      this.sendOrderAndPayModal = null;
    });
  }

  async presentOrderDetailsModal(order) {
    this.orderDetailsModal = await this.modalCtrl.create({
      component: OrderItemDetailsComponent,
      componentProps: { order_id: order.order_id, store_id: order.store_id },
      backdropDismiss: false,
    });

    await this.orderDetailsModal.present();
    this.orderDetailsModal.onDidDismiss().then(() => {
      this.orderDetailsModal = null;
    });
  }

  async presentReviewOrderModal(order) {
    this.reviewOrderModal = await this.modalCtrl.create({
      component: OrderRatingModalComponent,
      componentProps: { order_id: order.order_id, store_id: order.store_id },
      backdropDismiss: false,
    });

    await this.reviewOrderModal.present();
    this.reviewOrderModal.onDidDismiss().then(() => {
      this.reviewOrderModal = null;
    });
  }

  async presentLoading() {
    let message = this.translate.instant("loading-please-wait");
    this.ionLoading = await this.loadingController.create({
      duration: 30000,
      message: message,
      cssClass: "ionicLoadingCss",
      keyboardClose: true,
    });
    this.ionLoading.present();
    this.ionLoading.onDidDismiss().then(() => {
      this.ionLoading = null;
    });

    console.log("Loading dismissed!");
  }

  ngOnDestroy() {
    this.openOrderChangePaymentMethodModalForOrderId = null;

    if (this.ordersSub) {
      this.ordersSub.unsubscribe();
    }

    if (this.sendOrderAndPayModal) {
      this.sendOrderAndPayModal.dismiss();
      this.sendOrderAndPayModal = null;
    }

    if (this.deliveryMethodAddressSub) {
      this.deliveryMethodAddressSub.unsubscribe();
    }

    if (this.selectedStoreSub) {
      this.selectedStoreSub.unsubscribe();
    }

    if (this.previousOrderModal) {
      this.previousOrderModal.dismiss();
      this.previousOrderModal = null;
    }

    if (this.storeInfoSub) {
      this.storeInfoSub.unsubscribe();
    }

    if (this.authSub) {
      this.authSub.unsubscribe();
    }

    if (this.groupSub) {
      this.groupSub.unsubscribe();
    }

    if (this.reviewOrderModal) {
      this.reviewOrderModal.dismiss();
      this.reviewOrderModal = null;
    }
    if (this.orderDetailsModal) {
      this.orderDetailsModal.dismiss();
      this.orderDetailsModal = null;
    }

    if (this.selectPaymentMethodModal) {
      this.selectPaymentMethodModal.dismiss();
      this.selectPaymentMethodModal = null;
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
