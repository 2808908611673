import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AlertController, ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import * as DeliveryMethodAddressActions from "../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import * as fromApp from "../../store/app.reducer";
import { auditTime, distinctUntilChanged, first, Subscription } from "rxjs";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import { TranslateService } from "@ngx-translate/core";
import { OrderNowModalPopupsService } from "src/app/services/orderNowModalPopupsService/orderNowModalPopups.service";
import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";

@Component({
  selector: "app-preorder-popup",
  templateUrl: "./preorder-popup.component.html",
  styleUrls: ["./preorder-popup.component.scss"],
})
export class PreorderPopupComponent implements OnInit, OnDestroy {
  @Input() showNotValidDateToast: any;
  @Input() fromCartButtons: any;
  @Input() fromProductDetails: any;
  public preorderMode: boolean;
  public segment: string = "orderNow";
  public preorderDeliveryDays: any;
  public preorderPickupDays: any;
  public loading = true;
  public preorderDineinDays: any;
  public availableHours: any;
  public selectedDateSchedule: any;
  public checkStoreOnlineInfo: any;
  public deliveryTimeIsNumber: boolean;
  public pickUpTimeIsNumber: boolean;
  public preorderAcceptDisableDeliveryTime: boolean;
  public preorderAcceptDisablePickupTime: boolean;

  preorderDeliveryMethod: string;

  public selectedDate: any;
  public selectedHour: any;
  public selectedPreorderDate: any;
  public orderPickup: any;
  public dineIn: any;
  public sliderOptions = {
    allowTouchMove: true,
    slidesPerView: "auto",
    direction: "horizontal",
    slideToClickedSlide: true,
    mousewheel: true,
    freeMode: true,
    freeModeMomentumBounce: false,
    centeredSlides: true,
    spaceBetween: 5,
    watchSlidesVisibility: true,
  };

  private subscription: Subscription[] = [];
  private unsubscribeBackEvent: Subscription;
  private stores: any;
  public showDeliveryButton;
  public showPickupButton;
  public showDineInButton;
  public selectedAddress: any;
  private changeDeliveryMethodAlert: any;
  private infoShowDeliveryButton;

  private checkSelectedAddress: any;
  //public addressLoading: boolean = false;
  // public waitForAddressToCloseModal: boolean = false;
  constructor(
    private modalController: ModalController,
    private store: Store<fromApp.AppState>,
    private platform: Platform,
    private dataStorageService: DataStorageService,
    private alertController: AlertController,
    private translateService: TranslateService,
    private orderNowModalPopupsService: OrderNowModalPopupsService,
    private analyticsService: AnalyticsServiceService
  ) {}

  ngOnInit() {
    this.analyticsService.dmAnalyticsMessage(
      "preorder_modal_loaded",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );

    this.getPreorderDays();
    if (this.showNotValidDateToast) {
      setTimeout(() => {
        this.presentNotValidDateToast();
      }, 1000);
    }
    this.subscription.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())

        .subscribe(async (state) => {
          if (state && state.stores && !_.isEqual(this.stores, state.stores)) {
            this.stores = _.cloneDeep(state.stores);
            this.showDeliveryButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (!store.disableOrderDelivery) {
                  return store;
                }
              })
            );

            if (!this.showDeliveryButton) {
              this.infoShowDeliveryButton =
                await this.orderNowModalPopupsService.checkToShowInfoDeliveryButton();

              this.infoShowDeliveryButton = !!this.infoShowDeliveryButton;
            }

            this.showPickupButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (!store.disableOrderPickup) {
                  return store;
                }
              })
            );
            console.log("pickup button", this.showPickupButton);

            this.showDineInButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (store.dinein) {
                  return store;
                }
              })
            );
            console.log("dine in butto");
          }

          if (
            state &&
            state.checkStoreOnlineInfo &&
            !_.isEqual(state.checkStoreOnlineInfo, this.checkStoreOnlineInfo)
          ) {
            this.checkStoreOnlineInfo = _.cloneDeep(state.checkStoreOnlineInfo);

            if (
              this.checkStoreOnlineInfo &&
              this.checkStoreOnlineInfo.deliveryTime &&
              this.checkStoreOnlineInfo.deliveryTime
                .toString()
                .match(/^[0-9]+$/) != null
            ) {
              this.deliveryTimeIsNumber = true;
            } else {
              this.deliveryTimeIsNumber = false;
            }
            if (
              this.checkStoreOnlineInfo &&
              this.checkStoreOnlineInfo.pickupDeliveryTime &&
              this.checkStoreOnlineInfo.pickupDeliveryTime
                .toString()
                .match(/^[0-9]+$/) != null
            ) {
              this.pickUpTimeIsNumber = true;
            } else {
              this.pickUpTimeIsNumber = false;
            }
          }
        })
    );
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    this.subscription.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(auditTime(200))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.selectedPreorderDate, state.selectedPreorderDate)
          ) {
            this.selectedPreorderDate = _.cloneDeep(state.selectedPreorderDate);
            if (
              this.selectedPreorderDate &&
              this.selectedPreorderDate.hour &&
              this.selectedPreorderDate.date
            ) {
              if (!this.fromCartButtons) {
                this.preorderMode = true;
                this.segment = "preOrder";
              } else if (
                this.fromCartButtons &&
                this.fromCartButtons === "orderNow"
              ) {
                this.preorderMode = false;
                this.segment = "orderNow";
              } else {
                this.preorderMode = true;
                this.segment = "preOrder";
              }

              console.log(
                "selected preorder hour",
                this.selectedPreorderDate.hour
              );
              this.selectedHour = _.cloneDeep(this.selectedPreorderDate.hour);
              this.selectedDate = _.cloneDeep(this.selectedPreorderDate.date);

              console.log("selecteddate", this.selectedDate);
            } else {
              if (this.fromCartButtons && this.fromCartButtons === "preOrder") {
                this.preorderMode = true;
                this.segment = "preOrder";
              }
            }
          }
          if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }
          if (state && !_.isEqual(this.dineIn, state.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }
          // if (
          //   state &&
          //   !_.isEqual(state.checkSelectedAddress, this.checkSelectedAddress) &&
          //   this.waitForAddressToCloseModal
          // ) {
          //   this.checkSelectedAddress = _.cloneDeep(state.checkSelectedAddress);
          //   this.addressLoading = true;
          // }
          if (
            state &&
            !_.isEqual(state.selectedAddress, this.selectedAddress)
          ) {
            this.selectedAddress = _.cloneDeep(state.selectedAddress);
            // console.log("selecteddAddress changed", this.selectedAddress);
            // console.log("wait to close modal", this.waitForAddressToCloseModal);
            // if (this.selectedAddress && this.waitForAddressToCloseModal) {
            //   this.waitForAddressToCloseModal = false;
            //   console.log("call preorderSetActions");
            //   this.preorderSetActions();
            // }
          }
        })
    );

    if (this.fromProductDetails && this.preorderMode === false) {
      setTimeout(() => {
        this.segment = "preOrder";
        this.preorderMode = true;
      }, 200);
    }
  }

  onSegmentChange(ev) {
    console.log("pre order segment", this.segment);
    if (this.segment && this.segment === "orderNow") {
      this.preorderMode = false;
    } else if (this.segment && this.segment === "preOrder") {
      this.preorderMode = true;
    }
  }

  validateExistingDate() {
    console.log(
      "validate existing date",
      this.selectedDate,
      this.selectedHour,
      this.preorderDeliveryMethod,
      this.preorderDeliveryDays
    );
    if (
      this.preorderDeliveryMethod === "delivery" &&
      this.selectedDate &&
      this.selectedHour
    ) {
      let selectedDay = _.find(this.preorderDeliveryDays, {
        day: this.selectedDate,
      });

      if (
        selectedDay &&
        selectedDay.time_slots &&
        selectedDay.time_slots.length
      ) {
        //console.log("date found");
        let findHour = _.includes(selectedDay.time_slots, this.selectedHour);
        //console.log("find selected hour", findHour);

        if (!findHour) {
          this.store.dispatch(
            new DeliveryMethodAddressActions.setSelectedPreorderDate(null)
          );
          this.store.dispatch(
            new DeliveryMethodAddressActions.setPreorderResponse(null)
          );

          if (navigator.cookieEnabled) {
            let deliveryMethodAddressObj: any = localStorage.getItem(
              "deliveryMethodAddress"
            );
            if (deliveryMethodAddressObj) {
              deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
              deliveryMethodAddressObj.selectedPreorderDate = null;

              localStorage.setItem(
                "deliveryMethodAddress",
                JSON.stringify(deliveryMethodAddressObj)
              );
            }
          }
          this.presentNotValidDateToast();
          //console.log("make selected hour null");
          this.selectedDate = null;
          this.selectedHour = null;
        }
      } else {
        this.store.dispatch(
          new DeliveryMethodAddressActions.setSelectedPreorderDate(null)
        );
        this.store.dispatch(
          new DeliveryMethodAddressActions.setPreorderResponse(null)
        );

        if (navigator.cookieEnabled) {
          let deliveryMethodAddressObj: any = localStorage.getItem(
            "deliveryMethodAddress"
          );
          if (deliveryMethodAddressObj) {
            deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
            deliveryMethodAddressObj.selectedPreorderDate = null;

            localStorage.setItem(
              "deliveryMethodAddress",
              JSON.stringify(deliveryMethodAddressObj)
            );
          }
        }
        this.presentNotValidDateToast();
        // console.log("make selected hour null");
        this.selectedDate = null;
        this.selectedHour = null;
      }
    } else if (
      this.preorderDeliveryMethod === "takeaway" &&
      this.selectedHour &&
      this.selectedDate
    ) {
      let selectedDay = _.find(this.preorderPickupDays, {
        day: this.selectedDate,
      });

      if (
        selectedDay &&
        selectedDay.time_slots &&
        selectedDay.time_slots.length
      ) {
        let findHour = _.includes(selectedDay.time_slots, this.selectedHour);
        console.log("find selected hour", findHour);

        if (!findHour) {
          this.store.dispatch(
            new DeliveryMethodAddressActions.setSelectedPreorderDate(null)
          );
          this.store.dispatch(
            new DeliveryMethodAddressActions.setPreorderResponse(null)
          );

          if (navigator.cookieEnabled) {
            let deliveryMethodAddressObj: any = localStorage.getItem(
              "deliveryMethodAddress"
            );
            if (deliveryMethodAddressObj) {
              deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
              deliveryMethodAddressObj.selectedPreorderDate = null;

              localStorage.setItem(
                "deliveryMethodAddress",
                JSON.stringify(deliveryMethodAddressObj)
              );
            }
          }
          this.presentNotValidDateToast();
          // console.log("make selected hour null");
          this.selectedDate = null;
          this.selectedHour = null;
        }
      } else {
        this.store.dispatch(
          new DeliveryMethodAddressActions.setSelectedPreorderDate(null)
        );
        this.store.dispatch(
          new DeliveryMethodAddressActions.setPreorderResponse(null)
        );

        if (navigator.cookieEnabled) {
          let deliveryMethodAddressObj: any = localStorage.getItem(
            "deliveryMethodAddress"
          );
          if (deliveryMethodAddressObj) {
            deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
            deliveryMethodAddressObj.selectedPreorderDate = null;

            localStorage.setItem(
              "deliveryMethodAddress",
              JSON.stringify(deliveryMethodAddressObj)
            );
          }
        }
        this.presentNotValidDateToast();
        // console.log("make selected hour null");
        this.selectedDate = null;
        this.selectedHour = null;
      }
    }
  }

  async presentNotValidDateToast() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: this.translateService.instant("alert"),
      message: this.translateService.instant(
        "popups.pre-order-popup.the-date-for-preorder-is-not-longer-available-please-select-another-date"
      ),
      buttons: ["OK"],
    });

    await alert.present();
  }

  getPreorderDays() {
    this.store
      .select("currentStore")
      .pipe(first())
      .subscribe((currrentStore) => {
        if (currrentStore && currrentStore.currentStoreId) {
          this.store
            .select("groupApp")
            .pipe(first())
            .subscribe((groupApp) => {
              if (groupApp && groupApp.groupApp && groupApp.selectedLangugage) {
                this.dataStorageService
                  .getPreorderDays(
                    currrentStore.currentStoreId,
                    groupApp.groupApp.group,
                    groupApp.selectedLangugage
                  )
                  .subscribe({
                    next: async (res: any) => {
                      this.loading = false;
                      if (!res || !res.success) {
                        const alert = await this.alertController.create({
                          header: this.translateService.instant("alert"),
                          message:
                            res && res.comment_id
                              ? this.translateService.instant(res.comment_id)
                              : this.translateService.instant(
                                  "errorMessages.general_error"
                                ),
                          backdropDismiss: false,
                          buttons: ["OK"],
                        });

                        await alert.present();
                      } else {
                        console.log("preorder days res", res);
                        if (
                          res.store_schedule_status &&
                          !res.store_schedule_status.preorder_status &&
                          res.store_schedule_status.schedule_comments
                        ) {
                          console.log("present alert for chedule");
                          this.presentAlertForSchedule(
                            res.store_schedule_status.schedule_comments
                          );
                        }
                        this.preorderDeliveryDays = res.preorder_delivery_days;
                        this.preorderPickupDays = res.preorder_pickup_days;
                        this.preorderAcceptDisableDeliveryTime =
                          res.preorderAcceptDisableDeliveryTime;
                        this.preorderAcceptDisablePickupTime =
                          res.preorderAcceptDisablePickupTime;

                        this.store.dispatch(
                          new DeliveryMethodAddressActions.setPreorderResponse({
                            preorder_delivery_days: res.preorder_delivery_days,
                            preorder_pickup_days: res.preorder_pickup_days,
                          })
                        );

                        this.store
                          .select("deliveryMethodAddress")
                          .pipe(first())
                          .subscribe((state) => {
                            if (state) {
                              this.calculatePreorderDeliveryMethod(
                                state.orderPickup,
                                state.dineIn
                              );
                              this.initializeAvailableTimeSlotsAndSchedule();
                            }
                          })
                          .unsubscribe();
                        this.validateExistingDate();
                      }
                    },
                    error: async (error) => {
                      this.loading = false;
                      const alert = await this.alertController.create({
                        header: this.translateService.instant("alert"),
                        message: this.translateService.instant(
                          "errorMessages.problem_reaching_server"
                        ),

                        buttons: ["OK"],
                      });
                      await alert.present();
                      return error;
                    },
                  });
              }
            })
            .unsubscribe();
        }
      })
      .unsubscribe();
  }

  async presentAlertForSchedule(message) {
    const alert = await this.alertController.create({
      header: this.translateService.instant("alert"),
      message: message,
      buttons: ["OK"],
    });
    await alert.present();
  }

  changeMethod() {
    console.log("make selected hour null");
    this.selectedDate = null;
    this.selectedHour = null;
    if (
      this.preorderDeliveryDays &&
      this.preorderDeliveryDays.length &&
      this.preorderDeliveryDays.length === 1
    ) {
      this.selectedDate = _.cloneDeep(this.preorderDeliveryDays[0].day);
      this.selectDate();
    }
  }

  selectDate() {
    console.log("selected date", this.selectedDate);
    console.log("make selected hour null");

    this.selectedHour = null;
    this.selectedDateSchedule = null;

    this.initializeAvailableTimeSlotsAndSchedule();
  }

  initializeAvailableTimeSlotsAndSchedule() {
    this.availableHours = null;
    console.log(
      "initializeAvailableTimeSlotsAndSchedule called",
      this.selectedDate,
      this.preorderDeliveryMethod,
      this.preorderDeliveryDays
    );
    if (
      this.selectedDate &&
      this.preorderDeliveryMethod === "delivery" &&
      this.preorderDeliveryDays
    ) {
      let dayObject = _.find(this.preorderDeliveryDays, {
        day: this.selectedDate,
      });
      console.log("initialize times day obj", dayObject);
      if (dayObject && !dayObject.preorderAcceptDeliveryDefaultTime) {
        this.availableHours = dayObject.time_slots;
        this.selectedDateSchedule = dayObject.day_schedule;
      } else if (dayObject && dayObject.preorderAcceptDeliveryDefaultTime) {
        console.log("not found");
        this.selectedDateSchedule = dayObject.day_schedule;
        console.log("set selected hour");
        this.selectedHour = _.cloneDeep(
          dayObject.preorderAcceptDeliveryDefaultTime
        );
      }
    } else if (
      this.selectedDate &&
      this.preorderDeliveryMethod === "takeaway" &&
      this.preorderPickupDays
    ) {
      let dayObject = _.find(this.preorderPickupDays, {
        day: this.selectedDate,
      });
      if (dayObject && !dayObject.preorderAcceptPickupDefaultTime) {
        this.availableHours = dayObject.time_slots;
        this.selectedDateSchedule = dayObject.day_schedule;
      } else if (dayObject && dayObject.preorderAcceptPickupDefaultTime) {
        this.selectedDateSchedule = dayObject.day_schedule;
        console.log("set selected hour");
        this.selectedHour = _.cloneDeep(
          dayObject.preorderAcceptPickupDefaultTime
        );
      }
    } else if (
      this.selectedDate &&
      this.preorderDeliveryMethod === "dinein" &&
      this.preorderDineinDays
    ) {
      let dayObject = _.find(this.preorderDineinDays, {
        day: this.selectedDate,
      });
      if (dayObject) {
        this.availableHours = dayObject.time_slots;
        this.selectedDateSchedule = dayObject.day_schedule;
      }
    }
  }

  calculatePreorderDeliveryMethod(orderPickup, dineIn) {
    console.log("calculate preorder delivery method", dineIn, orderPickup);
    if (
      this.showDeliveryButton &&
      !this.showDineInButton &&
      !this.showPickupButton
    ) {
      if (this.preorderDeliveryDays && this.preorderDeliveryDays.length) {
        this.preorderDeliveryMethod = "delivery";

        if (this.preorderDeliveryDays.length === 1) {
          this.selectedDate = _.cloneDeep(this.preorderDeliveryDays[0].day);
          //this.selectDate();
        }
        console.log("selecteddddd day", this.selectedDate);
      }
    } else if (
      !this.showDeliveryButton &&
      this.showDineInButton &&
      !this.showPickupButton
    ) {
      if (this.preorderDineinDays && this.preorderDineinDays.length) {
        this.preorderDeliveryMethod = "dinein";
      }
    } else if (
      !this.showDeliveryButton &&
      !this.showDineInButton &&
      this.showPickupButton
    ) {
      if (this.preorderPickupDays && this.preorderPickupDays.length) {
        this.preorderDeliveryMethod = "takeaway";
        if (this.preorderPickupDays.length === 1) {
          this.selectedDate = _.cloneDeep(this.preorderPickupDays[0].day);
          // this.selectDate();
        }
      }
    } else {
      console.log("inside else delivery take away");
      if (orderPickup == false && dineIn == false) {
        console.log("inside  delivery", this.preorderDeliveryDays);
        if (this.preorderDeliveryDays && this.preorderDeliveryDays.length) {
          this.preorderDeliveryMethod = "delivery";
          if (this.preorderDeliveryDays.length === 1) {
            this.selectedDate = _.cloneDeep(this.preorderDeliveryDays[0].day);
            //this.selectDate();
            //console.log("selecteddddd day", this.selectedDate);
          }
        }
      } else if (orderPickup == true && dineIn == false) {
        console.log("inside  take away", this.preorderPickupDays);
        if (this.preorderPickupDays && this.preorderPickupDays.length) {
          this.preorderDeliveryMethod = "takeaway";
          if (this.preorderPickupDays.length === 1) {
            this.selectedDate = _.cloneDeep(this.preorderPickupDays[0].day);
            // this.selectDate();
            // console.log("selecteddddd day", this.selectedDate);
          }
        }
      } else if (orderPickup == false && dineIn == true) {
        //console.log("inside  dine in", this.preorderDineinDays);
        if (this.preorderDineinDays && this.preorderDineinDays.length) {
          this.preorderDeliveryMethod = "dinein";
        }
      } else if (orderPickup == null && dineIn == null) {
        if (
          this.preorderDeliveryDays &&
          this.preorderDeliveryDays.length &&
          this.selectedDate &&
          this.selectedHour &&
          this.stores &&
          this.stores.length > 1
        ) {
          this.preorderDeliveryMethod = "delivery";
        }
      }
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }

  hourSelection(hour) {
    // console.log("set selected hour");
    this.selectedHour = _.cloneDeep(hour);
  }

  onClickContinue() {
    //chekc if date is now and hour is as soon as posible
    if (!this.preorderMode) {
      this.selectedDate = null;
      this.selectedHour = null;
      this.preorderDeliveryMethod = null;
      this.preorderSetActions();
    } else if (
      (!this.selectedDate || !this.selectedHour) &&
      this.preorderMode
    ) {
    } else if (this.selectedDate && this.selectedHour && this.preorderMode) {
      if (this.stores && this.stores.length === 1) {
        if (this.orderPickup != null && this.dineIn != null) {
          if (
            this.preorderDeliveryMethod === "delivery" &&
            this.orderPickup == true &&
            this.dineIn == false
          ) {
            this.presentChangeDeliveryMethodAlert(this.preorderDeliveryMethod);
          } else if (
            this.preorderDeliveryMethod === "delivery" &&
            this.orderPickup == false &&
            this.dineIn == true
          ) {
            //from dine in to delivery
            this.presentChangeDeliveryMethodAlert(this.preorderDeliveryMethod);
          } else if (
            this.preorderDeliveryMethod === "takeaway" &&
            this.orderPickup == false &&
            this.dineIn == false
          ) {
            //from take delivery to take away
            this.presentChangeDeliveryMethodAlert(this.preorderDeliveryMethod);
          } else if (
            this.preorderDeliveryMethod === "takeaway" &&
            this.orderPickup == false &&
            this.dineIn == true
          ) {
            //from take dinein to take away
            this.presentChangeDeliveryMethodAlert(this.preorderDeliveryMethod);
          } else if (
            this.preorderDeliveryMethod === "dinein" &&
            this.orderPickup == false &&
            this.dineIn == false
          ) {
            //from take delivery to dine in
            this.presentChangeDeliveryMethodAlert(this.preorderDeliveryMethod);
          } else if (
            this.preorderDeliveryMethod === "dinein" &&
            this.orderPickup == true &&
            this.dineIn == false
          ) {
            //from take take away to dine in
            this.presentChangeDeliveryMethodAlert(this.preorderDeliveryMethod);
          } else {
            this.preorderSetActions();
          }
        } else if (this.orderPickup == null && this.dineIn == null) {
          //this.presentOrderNowModal(this.preorderDeliveryMethod);
          this.changeDeliveryMethod(this.preorderDeliveryMethod);
        }
      } else if (this.stores && this.stores.length > 1) {
        if (this.orderPickup != null && this.dineIn != null) {
          if (
            this.preorderDeliveryMethod === "delivery" &&
            this.orderPickup == true &&
            this.dineIn == false
          ) {
            //from take away to delivery
            this.presentChangeDeliveryMethodAlert(this.preorderDeliveryMethod);
          } else if (
            this.preorderDeliveryMethod === "delivery" &&
            this.orderPickup == false &&
            this.dineIn == true
          ) {
            //from dine in to delivery
            this.presentChangeDeliveryMethodAlert(this.preorderDeliveryMethod);
          } else if (
            this.preorderDeliveryMethod === "takeaway" &&
            this.orderPickup == false &&
            this.dineIn == false
          ) {
            //from take delivery to take away
            this.presentChangeDeliveryMethodAlert(this.preorderDeliveryMethod);
          } else if (
            this.preorderDeliveryMethod === "takeaway" &&
            this.orderPickup == false &&
            this.dineIn == true
          ) {
            //from take dinein to take away
            this.presentChangeDeliveryMethodAlert(this.preorderDeliveryMethod);
          } else if (
            this.preorderDeliveryMethod === "dinein" &&
            this.orderPickup == false &&
            this.dineIn == false
          ) {
            //from take delivery to dine in
            this.presentChangeDeliveryMethodAlert(this.preorderDeliveryMethod);
          } else if (
            this.preorderDeliveryMethod === "dinein" &&
            this.orderPickup == true &&
            this.dineIn == false
          ) {
            //from take take away to dine in
            this.presentChangeDeliveryMethodAlert(this.preorderDeliveryMethod);
          } else {
            this.preorderSetActions();
          }
        } else if (this.orderPickup == null && this.dineIn == null) {
          // this.presentChangeDeliveryMethodAlert(this.preorderDeliveryMethod);
          this.changeDeliveryMethod(this.preorderDeliveryMethod);
        }
      }
    }
  }

  async presentChangeDeliveryMethodAlert(method) {
    this.changeDeliveryMethodAlert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: this.translateService.instant("alert"),
      message:
        this.translateService.instant(
          "popups.pre-order-popup.you-are-about-to-change-your-delivery-method-to"
        ) +
        " " +
        this.translateService.instant(method) +
        " " +
        this.translateService.instant(
          "popups.pre-order-popup.do-you-want-to-continue"
        ),
      buttons: [
        {
          text: this.translateService.instant("no"),
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: this.translateService.instant("yes"),
          handler: () => {
            this.changeDeliveryMethod(method);
          },
        },
      ],
    });

    await this.changeDeliveryMethodAlert.present();
    this.changeDeliveryMethodAlert.onDidDismiss().then(() => {
      this.changeDeliveryMethodAlert = false;
    });
  }

  changeDeliveryMethod(method) {
    if (this.stores && this.stores.length === 1) {
      this.presentOrderNowModal(method);
    } else if (this.stores && this.stores.length > 1) {
      if (method === "takeaway") {
        this.store.dispatch(
          new DeliveryMethodAddressActions.setSelectedAddress(null)
        );
        this.store.dispatch(
          new DeliveryMethodAddressActions.setOrderPickup(true)
        );
        this.store.dispatch(
          new DeliveryMethodAddressActions.setValidDeliveryAreas(null)
        );

        this.store.dispatch(
          new DeliveryMethodAddressActions.setCarTakeawayPlateNumber(null)
        );
        this.store.dispatch(
          new DeliveryMethodAddressActions.setCarTakewayComments(null)
        );

        this.analyticsService.dmAnalyticsMessage(
          "takeaway_method",
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null
        );

        this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(false));
        this.store.dispatch(
          new DeliveryMethodAddressActions.setSelectedDineInTable(
            _.cloneDeep(null)
          )
        );

        if (navigator.cookieEnabled) {
          let deliveryMethodAddressObj: any = localStorage.getItem(
            "deliveryMethodAddress"
          );
          if (deliveryMethodAddressObj) {
            deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
            if (deliveryMethodAddressObj) {
              deliveryMethodAddressObj.orderPickup = true;
              deliveryMethodAddressObj.dineIn = false;
              deliveryMethodAddressObj.selectedDineInTable = null;

              deliveryMethodAddressObj.carTakeAwayPlateNumber = null;
              deliveryMethodAddressObj.carTakeAwayComments = null;
            } else {
              deliveryMethodAddressObj = {
                orderPickup: true,
                dineIn: false,
              };
            }
          } else {
            deliveryMethodAddressObj = {
              orderPickup: true,
              dineIn: false,
            };
          }

          window.localStorage.setItem(
            "deliveryMethodAddress",
            JSON.stringify(deliveryMethodAddressObj)
          );
        }
        this.preorderSetActions();
      } else if (method === "delivery") {
        this.presentOrderNowModal(method);
        // this.store.dispatch(
        //   new DeliveryMethodAddressActions.setDineIn(false)
        // );
        // this.store.dispatch(
        //   new DeliveryMethodAddressActions.setOrderPickup(false)
        // );
        // if (navigator.cookieEnabled) {
        //   let deliveryMethodAddressObj: any = localStorage.getItem(
        //     "deliveryMethodAddress"
        //   );
        //   if (deliveryMethodAddressObj) {
        //     deliveryMethodAddressObj = JSON.parse(
        //       deliveryMethodAddressObj
        //     );
        //     if (deliveryMethodAddressObj) {
        //       deliveryMethodAddressObj.orderPickup = false;
        //       deliveryMethodAddressObj.dineIn = false;
        //     } else {
        //       deliveryMethodAddressObj = {
        //         orderPickup: null,
        //         dineIn: null,
        //       };
        //     }
        //   } else {
        //     deliveryMethodAddressObj = {
        //       orderPickup: null,
        //       dineIn: null,
        //     };
        //   }

        //   window.localStorage.setItem(
        //     "deliveryMethodAddress",
        //     JSON.stringify(deliveryMethodAddressObj)
        //   );
        // }

        this.preorderSetActions();
      } else if (method === "dinein") {
        //TO_DO take cases for dine in
      }
    }
  }

  async presentOrderNowModal(mode) {
    // console.log("present order now modal", mode);
    let navigationOnDeliveryMethodChange = false;
    // this.store.dispatch(
    //   new DeliveryMethodAddressActions.SetNavigationOnDeliveryMethodChanged(
    //     true
    //   )
    // );
    this.store
      .select("auth")
      .pipe(first())
      .subscribe((authState) => {
        if (authState) {
          this.store
            .select("currentStore")
            .pipe(first())
            .subscribe((currentStore) => {
              if (currentStore && currentStore.currentStoreId) {
                this.store
                  .select("deliveryMethodAddress")
                  .pipe(first())
                  .subscribe((deliveryMethodAddress) => {
                    if (deliveryMethodAddress) {
                      console.log(
                        "insidededed",
                        mode,
                        this.showPickupButton,
                        this.showDeliveryButton,
                        this.showDineInButton
                      );
                      if (
                        (this.showPickupButton && mode === "takeaway") ||
                        (this.showDeliveryButton && mode === "delivery") ||
                        (this.showDineInButton && mode === "dinein")
                      ) {
                        if (mode === "takeaway") {
                          mode = "take-away";
                        }
                        if (mode === "dinein") {
                          mode = "dine-in";
                        }
                        if (
                          this.stores &&
                          this.stores.length === 1 &&
                          (mode === "dine-in" || mode === "take-away")
                        ) {
                          this.preorderSetActions();
                        } else if (
                          this.stores &&
                          this.stores.length === 1 &&
                          mode === "delivery"
                        ) {
                          // this.waitForAddressToCloseModal = true;
                          // console.log("wait for address to close");
                          this.preorderSetActions();
                        }
                        this.orderNowModalPopupsService.checkAndOpenSettingsModal(
                          mode,
                          authState.user,
                          deliveryMethodAddress.selectedAddress,
                          this.stores,
                          currentStore.currentStoreId,
                          navigationOnDeliveryMethodChange,
                          false
                        );
                      }
                    }
                  })
                  .unsubscribe();
              }
            })
            .unsubscribe();
        }
      })
      .unsubscribe();
  }

  preorderSetActions() {
    if (this.preorderMode) {
      if (navigator.cookieEnabled) {
        let deliveryMethodAddress: any = window.localStorage.getItem(
          "deliveryMethodAddress"
        );

        if (deliveryMethodAddress) {
          deliveryMethodAddress = JSON.parse(deliveryMethodAddress);
          if (deliveryMethodAddress) {
            deliveryMethodAddress.selectedPreorderDate = {
              date: _.cloneDeep(this.selectedDate),
              hour: _.cloneDeep(this.selectedHour),
              selectedMethod: _.cloneDeep(this.preorderDeliveryMethod),
              preorderAcceptDisableDeliveryTime: _.cloneDeep(
                this.preorderAcceptDisableDeliveryTime
              ),
              preorderAcceptDisablePickupTime: _.cloneDeep(
                this.preorderAcceptDisablePickupTime
              ),
            };
          } else {
            deliveryMethodAddress = {
              selectedPreorderDate: {
                date: _.cloneDeep(this.selectedDate),
                hour: _.cloneDeep(this.selectedHour),
                selectedMethod: _.cloneDeep(this.preorderDeliveryMethod),
                preorderAcceptDisableDeliveryTime: _.cloneDeep(
                  this.preorderAcceptDisableDeliveryTime
                ),
                preorderAcceptDisablePickupTime: _.cloneDeep(
                  this.preorderAcceptDisablePickupTime
                ),
              },
            };
          }
        } else {
          deliveryMethodAddress = {
            selectedPreorderDate: {
              date: _.cloneDeep(this.selectedDate),
              hour: _.cloneDeep(this.selectedHour),
              selectedMethod: _.cloneDeep(this.preorderDeliveryMethod),
              preorderAcceptDisableDeliveryTime: _.cloneDeep(
                this.preorderAcceptDisableDeliveryTime
              ),
              preorderAcceptDisablePickupTime: _.cloneDeep(
                this.preorderAcceptDisablePickupTime
              ),
            },
          };
        }
        window.localStorage.setItem(
          "deliveryMethodAddress",
          JSON.stringify(deliveryMethodAddress)
        );
      }

      this.store.dispatch(
        new DeliveryMethodAddressActions.setSelectedPreorderDate({
          date: _.cloneDeep(this.selectedDate),
          hour: _.cloneDeep(this.selectedHour),
          selectedMethod: _.cloneDeep(this.preorderDeliveryMethod),
          preorderAcceptDisableDeliveryTime: _.cloneDeep(
            this.preorderAcceptDisableDeliveryTime
          ),
          preorderAcceptDisablePickupTime: _.cloneDeep(
            this.preorderAcceptDisablePickupTime
          ),
        })
      );
      this.analyticsService.dmAnalyticsMessage(
        "set_preorder_date_time",
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
    } else if (!this.preorderMode) {
      if (navigator.cookieEnabled) {
        let deliveryMethodAddress: any = window.localStorage.getItem(
          "deliveryMethodAddress"
        );

        if (deliveryMethodAddress) {
          deliveryMethodAddress = JSON.parse(deliveryMethodAddress);
          if (deliveryMethodAddress) {
            deliveryMethodAddress.selectedPreorderDate = null;
            window.localStorage.setItem(
              "deliveryMethodAddress",
              JSON.stringify(deliveryMethodAddress)
            );
          }
        }
      }
      this.store.dispatch(
        new DeliveryMethodAddressActions.setSelectedPreorderDate(null)
      );
    }

    this.modalController.dismiss();
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscription && this.subscription.length > 0) {
      this.subscription.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscription = [];
  }
}
