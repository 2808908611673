<ion-card *ngIf="cartMode">
  <ion-list
    id="delivery-method-address"
    class="ion-no-padding"
    [class]="
      orderPickup == null && dineIn == null && cartMode ? 'ion-text-center' : ''
    "
  >
    <ion-item-divider
      *ngIf="cartMode"
      [color]="valid ? '' : 'danger'"
      mode="ios"
    >
      {{ "delivery-method-address.delivery-method" | translate }}
    </ion-item-divider>

    <ion-grid
      [class]="
        store_info && store_info.vatBasedCustomerProductsActive
          ? 'no-padding-top'
          : ''
      "
    >
      <ion-grid
        *ngIf="
          !showDeliveryButton &&
          !showDineInButton &&
          orderPickup === true &&
          dineIn === false
        "
      >
        <ion-row style="width: 100%">
          <ion-col size="6" class="ion-no-padding">
            <ion-button
              (click)="openDeliveryInfoModal()"
              color="light"
              mode="ios"
              size="small"
              style="width: 100%"
            >
              <ion-text style="font-weight: bold"> Delivery </ion-text>
            </ion-button>
          </ion-col>

          <ion-col size="6" class="ion-no-padding">
            <ion-button
              color="primary"
              mode="ios"
              size="small"
              style="width: 100%"
            >
              <ion-text style="font-weight: bold"> Take Away </ion-text>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-item
        detail="true"
        color="deliveryMethodAddressButtons"
        lines="none"
        *ngIf="orderPickup == null && dineIn == null && !cartMode"
        (click)="openOrderNowModal()"
        button
      >
        <ion-label class="ion-text-wrap">
          <ion-text style="font-size: small">
            {{
              "catalog.cart-catalog.you-must-choose-a-way-of-delivery"
                | translate
            }}
          </ion-text>
        </ion-label>
      </ion-item>
      <ion-button
        [class]="cartMode ? 'marginsOrderNowButton' : ''"
        *ngIf="orderPickup == null && dineIn == null && cartMode"
        (click)="openOrderNowModal()"
      >
        <ion-text>
          {{
            "catalog.cart-catalog.you-must-choose-a-way-of-delivery-btn"
              | translate
          }}
        </ion-text>

        <!-- <ion-icon
    color="dark"
    slot="end"
    src="assets/ionicons/chevron-forward-outline.svg"
  >
  </ion-icon> -->
      </ion-button>

      <ion-item
        detail="true"
        (click)="openOrderNowModal()"
        *ngIf="
          (orderPickup != null || dineIn != null) &&
          ((showDeliveryButton && showPickupButton) ||
            (showDeliveryButton && showDineInButton) ||
            (showDineInButton && showPickupButton))
        "
        lines="none"
        color="deliveryMethodAddressButtons"
        button
      >
        <ion-label
          class="ion-no-margin"
          *ngIf="
            orderPickup == true &&
            !(carTakeAwayPlateNumber && showCarPickupButton)
          "
        >
          {{ "catalog.cart-catalog.take-away" | translate }}
        </ion-label>

        <ion-label
          class="ion-no-margin"
          *ngIf="
            orderPickup == true && carTakeAwayPlateNumber && showCarPickupButton
          "
        >
          {{ "catalog.cart-catalog.car-take-away" | translate }}
        </ion-label>
        <ion-label
          class="ion-no-margin"
          *ngIf="orderPickup == false && dineIn == true"
        >
          {{ "catalog.cart-catalog.dine-in" | translate }}
        </ion-label>
        <ion-label
          class="ion-no-margin"
          *ngIf="orderPickup == false && dineIn == false"
        >
          {{ "catalog.cart-catalog.delivery" | translate }}
        </ion-label>
        <ion-label
          class="ion-no-margin"
          *ngIf="orderPickup == null && dineIn == null"
        >
          {{ "catalog.cart-catalog.select-delivery-method" | translate }}
        </ion-label>
      </ion-item>

      <ion-item
        lines="none"
        *ngIf="
          orderPickup == false &&
          dineIn == false &&
          stores &&
          validStores &&
          validStores.length &&
          validStores.length > 1 &&
          store_info &&
          selectedLangugage
        "
      >
        <ion-label style="font-size: small" class="ion-text-wrap">
          {{
            store_info.store_name_mobileorder &&
            store_info.store_name_mobileorder[selectedLangugage]
              ? store_info.store_name_mobileorder[selectedLangugage]
              : store_info.store_name_langs &&
                store_info.store_name_langs[selectedLangugage]
              ? store_info.store_name_langs[selectedLangugage]
              : store_info.store_name_langs && store_info.store_name_langs.el
              ? store_info.store_name_langs.el
              : store_info.store_name
              ? store_info.store_name
              : ""
          }}
        </ion-label>
        <ion-text
          (click)="presentSelectStoreModal()"
          color="primary"
          style="
            font-size: small;
            font-weight: bold;
            cursor: pointer;
            margin-right: 5px;
            margin-left: 5px;
          "
          slot="end"
        >
          {{ "catalog.cart-catalog.change-address" | translate }}</ion-text
        >
      </ion-item>

      <ion-item
        lines="none"
        *ngIf="
          selectedAddress &&
          selectedAddress.formatted_address &&
          selectedAddress.fixed_address_id &&
          selectedAddress.fixed_address_type &&
          selectedAddress.fixed_address_type === 'meeting_point'
        "
        (click)="showOnMap(selectedAddress.userLat, selectedAddress.userLng)"
      >
        <ion-icon
          color="primary"
          style="
            padding: 3px;
            margin-right: 5px;
            font-size: 25px;
            cursor: pointer;
          "
          slot="start"
          src="assets/ionicons/location.svg"
        >
        </ion-icon>
        <ion-label class="ion-text-wrap">
          <!-- <ion-icon slot="start" size="small" src="assets/flaticons/location.svg"></ion-icon> -->
          <div style="font-size: small; font-weight: bold">
            {{ "delivery-spot" | translate }}
          </div>

          <ion-label *ngIf="selectedAddress.fixed_address_name">
            <ion-text style="font-size: small">{{
              selectedAddress.fixed_address_name
            }}</ion-text>
          </ion-label>
          <ion-label>
            <ion-text style="font-size: small">{{
              selectedAddress.formatted_address
            }}</ion-text></ion-label
          >
        </ion-label>
        <ion-text
          (click)="orderNowSettingsModal('delivery')"
          color="primary"
          style="
            font-size: small;
            font-weight: bold;
            cursor: pointer;
            margin-right: 5px;
            margin-left: 5px;
          "
          slot="end"
        >
          {{ "catalog.cart-catalog.change-address" | translate }}</ion-text
        >
      </ion-item>

      <ion-item
        lines="none"
        *ngIf="
          selectedAddress &&
          selectedAddress.formatted_address &&
          (!selectedAddress.fixed_address_id ||
            !selectedAddress.fixed_address_type ||
            selectedAddress.fixed_address_type !== 'meeting_point')
        "
      >
        <ion-icon
          style="padding: 3px; margin-right: 5px"
          size="small"
          slot="start"
          src="assets/ionicons/location.svg"
        >
        </ion-icon>
        <!-- <ion-icon slot="start" size="small" src="assets/flaticons/location.svg"></ion-icon> -->
        <ion-label class="ion-text-wrap">
          <ion-text style="font-size: small">{{
            selectedAddress.formatted_address
          }}</ion-text>
        </ion-label>
        <ion-text
          (click)="orderNowSettingsModal('delivery')"
          color="primary"
          style="
            font-size: small;
            font-weight: bold;
            cursor: pointer;
            margin-right: 5px;
            margin-left: 5px;
          "
          slot="end"
        >
          {{ "catalog.cart-catalog.change-address" | translate }}</ion-text
        >
      </ion-item>

      <ion-item
        lines="none"
        *ngIf="
          orderPickup == true &&
          dineIn == false &&
          selectedLangugage &&
          store_info &&
          !store_info.doNotShowStoreNameOrderPickup
        "
      >
        <ion-label class="ion-text-wrap" style="font-size: small">
          <div>
            <ion-text style="font-weight: bold">
              {{
                store_info.store_name_mobileorder &&
                store_info.store_name_mobileorder[selectedLangugage]
                  ? store_info.store_name_mobileorder[selectedLangugage]
                  : store_info.store_name_langs &&
                    store_info.store_name_langs[selectedLangugage]
                  ? store_info.store_name_langs[selectedLangugage]
                  : store_info.store_name_langs &&
                    store_info.store_name_langs.el
                  ? store_info.store_name_langs.el
                  : store_info.store_name
                  ? store_info.store_name
                  : ""
              }}
            </ion-text>
          </div>
          <div *ngIf="store_info && store_info.address">
            {{ store_info.address }}
          </div>
        </ion-label>

        <ion-icon
          *ngIf="
            stores &&
            stores.length &&
            stores.length === 1 &&
            stores[0].mapCenterStoreLat &&
            stores[0].mapCenterStoreLng
          "
          (click)="googleMaps(stores[0])"
          style="font-size: 25px; margin-left: 0; cursor: pointer"
          slot="end"
          color="primary"
          src="assets/ionicons/location.svg"
        ></ion-icon>
        <ion-text
          *ngIf="stores && stores.length && stores.length > 1"
          (click)="orderNowSettingsModal('take-away')"
          color="primary"
          style="
            font-size: small;
            font-weight: bold;
            cursor: pointer;
            margin-right: 5px;
            margin-left: 5px;
          "
          slot="end"
        >
          {{ "catalog.cart-catalog.change-address" | translate }}</ion-text
        >
      </ion-item>

      <ion-item
        lines="none"
        *ngIf="
          orderPickup == true &&
          dineIn == false &&
          selectedLangugage &&
          store_info &&
          carTakeAwayPlateNumber
        "
      >
        <ion-label class="ion-text-wrap">
          <div style="font-size: small">
            {{ "delivery-method-address.car-plate-number" | translate }}:
            <strong> {{ carTakeAwayPlateNumber }}</strong>
          </div>
          <div style="font-size: small" *ngIf="carTakeAwayComments">
            {{ carTakeAwayComments }}
          </div>
        </ion-label>

        <ion-text
          (click)="changePlateNumbers()"
          color="primary"
          style="
            font-size: small;
            font-weight: bold;
            cursor: pointer;
            margin-right: 5px;
            margin-left: 5px;
          "
          slot="end"
        >
          {{ "catalog.cart-catalog.change-address" | translate }}</ion-text
        >
      </ion-item>

      <ion-item
        lines="none"
        *ngIf="orderPickup == false && dineIn == true && selectedLangugage"
      >
        <ion-label class="ion-text-wrap" style="font-size: small">
          <div>
            <ion-text style="font-weight: bold">
              {{
                store_info.store_name_mobileorder &&
                store_info.store_name_mobileorder[selectedLangugage]
                  ? store_info.store_name_mobileorder[selectedLangugage]
                  : store_info.store_name_langs &&
                    store_info.store_name_langs[selectedLangugage]
                  ? store_info.store_name_langs[selectedLangugage]
                  : store_info.store_name_langs &&
                    store_info.store_name_langs.el
                  ? store_info.store_name_langs.el
                  : store_info.store_name
                  ? store_info.store_name
                  : ""
              }}
            </ion-text>
          </div>
          <div *ngIf="store_info && selectedTableDineIn.name">
            <strong>{{ "popups.dine-in-modal.table" | translate }}:</strong>
            {{ selectedTableDineIn.name }}
          </div>
        </ion-label>
        <ion-text
          (click)="orderNowSettingsModal('dine-in')"
          color="primary"
          style="
            font-size: small;
            font-weight: bold;
            cursor: pointer;
            margin-right: 5px;
            margin-left: 5px;
          "
          slot="end"
        >
          {{ "catalog.cart-catalog.change-address" | translate }}</ion-text
        >
      </ion-item>

      <ion-item
        style="padding-top: 5px; padding-bottom: 5px"
        color="deliveryMethodAddressButtons"
        button
        detail="true"
        (click)="presentPreorderModal(false)"
        lines="none"
        *ngIf="
          !cartMode &&
          store_info &&
          store_info.preorderActive &&
          !(orderPickup == false && dineIn == true)
        "
      >
        <ion-spinner *ngIf="!checkStoreOnlineInfo"></ion-spinner>
        <ion-label
          *ngIf="
            checkStoreOnlineInfo &&
            ((orderPickup == null && dineIn == null) ||
              (orderPickup != null &&
                !checkStoreOnlineInfo.deliveryTime &&
                !checkStoreOnlineInfo.pickupTime))
          "
          style="font-size: small"
          >{{
            orderPickup == false && dineIn == false
              ? ("delivery-method-address.preorder-delivered" | translate)
              : orderPickup == true && dineIn == false
              ? ("delivery-method-address.preorder-pickup" | translate)
              : ("delivery-method-address.preorder-delivered" | translate)
          }}:
          <strong>
            {{
              selectedPreorderDate &&
              selectedPreorderDate.hour &&
              selectedPreorderDate.date
                ? selectedPreorderDate.date +
                  (!selectedPreorderDate.selectedMethod ||
                  (selectedPreorderDate.selectedMethod === "delivery" &&
                    !selectedPreorderDate.preorderAcceptDisableDeliveryTime) ||
                  (selectedPreorderDate.selectedMethod === "takeaway" &&
                    !selectedPreorderDate.preorderAcceptDisablePickupTime)
                    ? " " + selectedPreorderDate.hour
                    : "")
                : ("delivery-method-address.now" | translate)
            }}
          </strong>
        </ion-label>
        <ion-label
          class="ion-text-wrap"
          *ngIf="
            (checkStoreOnlineInfo &&
              orderPickup == false &&
              checkStoreOnlineInfo.deliveryTime) ||
            (orderPickup == true &&
              checkStoreOnlineInfo &&
              checkStoreOnlineInfo.pickupDeliveryTime)
          "
          style="font-size: small"
          >{{
            orderPickup == false && dineIn == false
              ? ("delivery-method-address.preorder-delivered" | translate)
              : orderPickup == true && dineIn == false
              ? ("delivery-method-address.preorder-pickup" | translate)
              : ("delivery-method-address.preorder-delivered" | translate)
          }}:
          <strong>
            {{
              selectedPreorderDate &&
              selectedPreorderDate.hour &&
              selectedPreorderDate.date
                ? selectedPreorderDate.date +
                  (selectedPreorderDate.hour &&
                  ((orderPickup == false &&
                    dineIn == false &&
                    !selectedPreorderDate.preorderAcceptDisableDeliveryTime) ||
                    (orderPickup == true &&
                      dineIn == false &&
                      !selectedPreorderDate.preorderAcceptDisablePickupTime) ||
                    (orderPickup == false && dineIn == true))
                    ? " " + selectedPreorderDate.hour
                    : "")
                : orderPickup == false && checkStoreOnlineInfo.deliveryTime
                ? !deliveryTimeIsNumber
                  ? checkStoreOnlineInfo.deliveryTime
                  : checkStoreOnlineInfo.deliveryTime +
                    " " +
                    ("minutes" | translate)
                : orderPickup == true && checkStoreOnlineInfo.pickupDeliveryTime
                ? !pickUpTimeIsNumber
                  ? checkStoreOnlineInfo.pickupDeliveryTime
                  : checkStoreOnlineInfo.pickupDeliveryTime +
                    " " +
                    ("minutes" | translate)
                : ""
            }}
          </strong>
        </ion-label>
      </ion-item>
    </ion-grid>
  </ion-list>
</ion-card>

<div *ngIf="!cartMode">
  <ion-list
    id="delivery-method-address"
    class="ion-no-padding"
    [class]="
      orderPickup == null && dineIn == null && cartMode ? 'ion-text-center' : ''
    "
  >
    <ion-item-divider
      *ngIf="cartMode"
      [color]="valid ? '' : 'danger'"
      mode="ios"
    >
      {{ "delivery-method-address.delivery-method" | translate }}
    </ion-item-divider>

    <ion-grid
      [class]="
        store_info && store_info.vatBasedCustomerProductsActive
          ? 'no-padding-top'
          : ''
      "
    >
      <ion-grid
        *ngIf="
          !showDeliveryButton &&
          !showDineInButton &&
          orderPickup === true &&
          dineIn === false
        "
      >
        <ion-row style="width: 100%">
          <ion-col size="6" class="ion-no-padding">
            <ion-button
              (click)="openDeliveryInfoModal()"
              color="light"
              mode="ios"
              size="small"
              style="width: 100%"
            >
              <ion-text style="font-weight: bold"> Delivery </ion-text>
            </ion-button>
          </ion-col>

          <ion-col size="6" class="ion-no-padding">
            <ion-button
              color="primary"
              mode="ios"
              size="small"
              style="width: 100%"
            >
              <ion-text style="font-weight: bold"> Take Away </ion-text>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-item
        detail="true"
        color="deliveryMethodAddressButtons"
        lines="none"
        *ngIf="orderPickup == null && dineIn == null && !cartMode"
        (click)="openOrderNowModal()"
        button
      >
        <ion-label class="ion-text-wrap">
          <ion-text style="font-size: small">
            {{
              "catalog.cart-catalog.you-must-choose-a-way-of-delivery"
                | translate
            }}
          </ion-text>
        </ion-label>
      </ion-item>
      <ion-button
        [class]="cartMode ? 'marginsOrderNowButton' : ''"
        *ngIf="orderPickup == null && dineIn == null && cartMode"
        (click)="openOrderNowModal()"
      >
        <ion-text>
          {{
            "catalog.cart-catalog.you-must-choose-a-way-of-delivery-btn"
              | translate
          }}
        </ion-text>

        <!-- <ion-icon
    color="dark"
    slot="end"
    src="assets/ionicons/chevron-forward-outline.svg"
  >
  </ion-icon> -->
      </ion-button>

      <ion-item
        detail="true"
        (click)="openOrderNowModal()"
        *ngIf="
          (orderPickup != null || dineIn != null) &&
          ((showDeliveryButton && showPickupButton) ||
            (showDeliveryButton && showDineInButton) ||
            (showDineInButton && showPickupButton))
        "
        lines="none"
        color="deliveryMethodAddressButtons"
        button
      >
        <ion-label
          class="ion-no-margin"
          *ngIf="
            orderPickup == true &&
            !(carTakeAwayPlateNumber && showCarPickupButton)
          "
        >
          {{ "catalog.cart-catalog.take-away" | translate }}
        </ion-label>

        <ion-label
          class="ion-no-margin"
          *ngIf="
            orderPickup == true && carTakeAwayPlateNumber && showCarPickupButton
          "
        >
          {{ "catalog.cart-catalog.car-take-away" | translate }}
        </ion-label>
        <ion-label
          class="ion-no-margin"
          *ngIf="orderPickup == false && dineIn == true"
        >
          {{ "catalog.cart-catalog.dine-in" | translate }}
        </ion-label>
        <ion-label
          class="ion-no-margin"
          *ngIf="orderPickup == false && dineIn == false"
        >
          {{ "catalog.cart-catalog.delivery" | translate }}
        </ion-label>
        <ion-label
          class="ion-no-margin"
          *ngIf="orderPickup == null && dineIn == null"
        >
          {{ "catalog.cart-catalog.select-delivery-method" | translate }}
        </ion-label>
      </ion-item>

      <ion-item
        lines="none"
        *ngIf="
          orderPickup == false &&
          dineIn == false &&
          stores &&
          validStores &&
          validStores.length &&
          validStores.length > 1 &&
          store_info &&
          selectedLangugage
        "
      >
        <ion-label style="font-size: small" class="ion-text-wrap">
          {{
            store_info.store_name_mobileorder &&
            store_info.store_name_mobileorder[selectedLangugage]
              ? store_info.store_name_mobileorder[selectedLangugage]
              : store_info.store_name_langs &&
                store_info.store_name_langs[selectedLangugage]
              ? store_info.store_name_langs[selectedLangugage]
              : store_info.store_name_langs && store_info.store_name_langs.el
              ? store_info.store_name_langs.el
              : store_info.store_name
              ? store_info.store_name
              : ""
          }}
        </ion-label>
        <ion-text
          (click)="presentSelectStoreModal()"
          color="primary"
          style="
            font-size: small;
            font-weight: bold;
            cursor: pointer;
            margin-right: 5px;
            margin-left: 5px;
          "
          slot="end"
        >
          {{ "catalog.cart-catalog.change-address" | translate }}</ion-text
        >
      </ion-item>

      <ion-item
        lines="none"
        *ngIf="
          selectedAddress &&
          selectedAddress.formatted_address &&
          selectedAddress.fixed_address_id &&
          selectedAddress.fixed_address_type &&
          selectedAddress.fixed_address_type === 'meeting_point'
        "
        (click)="showOnMap(selectedAddress.userLat, selectedAddress.userLng)"
      >
        <ion-icon
          style="
            padding: 3px;
            margin-right: 5px;
            font-size: 25px;
            cursor: pointer;
          "
          color="primary"
          slot="start"
          src="assets/ionicons/location.svg"
        >
        </ion-icon>
        <ion-label class="ion-text-wrap">
          <!-- <ion-icon slot="start" size="small" src="assets/flaticons/location.svg"></ion-icon> -->
          <div style="font-size: small; font-weight: bold">
            {{ "delivery-spot" | translate }}
          </div>

          <ion-label *ngIf="selectedAddress.fixed_address_name">
            <ion-text style="font-size: small">{{
              selectedAddress.fixed_address_name
            }}</ion-text>
          </ion-label>
          <ion-label>
            <ion-text style="font-size: small">{{
              selectedAddress.formatted_address
            }}</ion-text></ion-label
          >
        </ion-label>
        <ion-text
          (click)="orderNowSettingsModal('delivery')"
          color="primary"
          style="
            font-size: small;
            font-weight: bold;
            cursor: pointer;
            margin-right: 5px;
            margin-left: 5px;
          "
          slot="end"
        >
          {{ "catalog.cart-catalog.change-address" | translate }}</ion-text
        >
      </ion-item>

      <ion-item
        lines="none"
        *ngIf="
          selectedAddress &&
          selectedAddress.formatted_address &&
          (!selectedAddress.fixed_address_id ||
            !selectedAddress.fixed_address_type ||
            selectedAddress.fixed_address_type !== 'meeting_point')
        "
      >
        <ion-icon
          style="padding: 3px; margin-right: 5px"
          size="small"
          slot="start"
          src="assets/ionicons/location.svg"
        >
        </ion-icon>
        <!-- <ion-icon slot="start" size="small" src="assets/flaticons/location.svg"></ion-icon> -->
        <ion-label class="ion-text-wrap">
          <ion-text style="font-size: small">{{
            selectedAddress.formatted_address
          }}</ion-text>
        </ion-label>
        <ion-text
          (click)="orderNowSettingsModal('delivery')"
          color="primary"
          style="
            font-size: small;
            font-weight: bold;
            cursor: pointer;
            margin-right: 5px;
            margin-left: 5px;
          "
          slot="end"
        >
          {{ "catalog.cart-catalog.change-address" | translate }}</ion-text
        >
      </ion-item>

      <ion-item
        lines="none"
        *ngIf="
          orderPickup == true &&
          dineIn == false &&
          selectedLangugage &&
          store_info &&
          !store_info.doNotShowStoreNameOrderPickup
        "
      >
        <ion-label class="ion-text-wrap" style="font-size: small">
          <div>
            <ion-text style="font-weight: bold">
              {{
                store_info.store_name_mobileorder &&
                store_info.store_name_mobileorder[selectedLangugage]
                  ? store_info.store_name_mobileorder[selectedLangugage]
                  : store_info.store_name_langs &&
                    store_info.store_name_langs[selectedLangugage]
                  ? store_info.store_name_langs[selectedLangugage]
                  : store_info.store_name_langs &&
                    store_info.store_name_langs.el
                  ? store_info.store_name_langs.el
                  : store_info.store_name
                  ? store_info.store_name
                  : ""
              }}
            </ion-text>
          </div>
          <div *ngIf="store_info && store_info.address">
            {{ store_info.address }}
          </div>
        </ion-label>
        <ion-icon
          *ngIf="
            stores &&
            stores.length &&
            stores.length === 1 &&
            stores[0].mapCenterStoreLat &&
            stores[0].mapCenterStoreLng
          "
          (click)="googleMaps(stores[0])"
          style="font-size: 25px; margin-left: 0; cursor: pointer"
          slot="end"
          color="primary"
          src="assets/ionicons/location.svg"
        ></ion-icon>
        <ion-text
          *ngIf="stores && stores.length && stores.length > 1"
          (click)="orderNowSettingsModal('take-away')"
          color="primary"
          style="
            font-size: small;
            font-weight: bold;
            cursor: pointer;
            margin-right: 5px;
            margin-left: 5px;
          "
          slot="end"
        >
          {{ "catalog.cart-catalog.change-address" | translate }}</ion-text
        >
      </ion-item>

      <ion-item
        lines="none"
        *ngIf="
          orderPickup == true &&
          dineIn == false &&
          selectedLangugage &&
          store_info &&
          carTakeAwayPlateNumber
        "
      >
        <ion-label class="ion-text-wrap">
          <div style="font-size: small">
            {{ "delivery-method-address.car-plate-number" | translate }}:
            <strong> {{ carTakeAwayPlateNumber }}</strong>
          </div>
          <div style="font-size: small" *ngIf="carTakeAwayComments">
            {{ carTakeAwayComments }}
          </div>
        </ion-label>

        <ion-text
          (click)="changePlateNumbers()"
          color="primary"
          style="
            font-size: small;
            font-weight: bold;
            cursor: pointer;
            margin-right: 5px;
            margin-left: 5px;
          "
          slot="end"
        >
          {{ "catalog.cart-catalog.change-address" | translate }}</ion-text
        >
      </ion-item>

      <ion-item
        lines="none"
        *ngIf="orderPickup == false && dineIn == true && selectedLangugage"
      >
        <ion-label class="ion-text-wrap" style="font-size: small">
          <div>
            <ion-text style="font-weight: bold">
              {{
                store_info.store_name_mobileorder &&
                store_info.store_name_mobileorder[selectedLangugage]
                  ? store_info.store_name_mobileorder[selectedLangugage]
                  : store_info.store_name_langs &&
                    store_info.store_name_langs[selectedLangugage]
                  ? store_info.store_name_langs[selectedLangugage]
                  : store_info.store_name_langs &&
                    store_info.store_name_langs.el
                  ? store_info.store_name_langs.el
                  : store_info.store_name
                  ? store_info.store_name
                  : ""
              }}
            </ion-text>
          </div>
          <div *ngIf="store_info && selectedTableDineIn.name">
            <strong>{{ "popups.dine-in-modal.table" | translate }}:</strong>
            {{ selectedTableDineIn.name }}
          </div>
        </ion-label>
        <ion-text
          (click)="orderNowSettingsModal('dine-in')"
          color="primary"
          style="
            font-size: small;
            font-weight: bold;
            cursor: pointer;
            margin-right: 5px;
            margin-left: 5px;
          "
          slot="end"
        >
          {{ "catalog.cart-catalog.change-address" | translate }}</ion-text
        >
      </ion-item>

      <ion-item
        style="padding-top: 5px; padding-bottom: 5px"
        color="deliveryMethodAddressButtons"
        button
        detail="true"
        (click)="presentPreorderModal(false)"
        lines="none"
        *ngIf="
          !cartMode &&
          store_info &&
          store_info.preorderActive &&
          !(orderPickup == false && dineIn == true)
        "
      >
        <ion-spinner *ngIf="!checkStoreOnlineInfo"></ion-spinner>
        <ion-label
          *ngIf="
            checkStoreOnlineInfo &&
            ((orderPickup == null && dineIn == null) ||
              (orderPickup != null &&
                !checkStoreOnlineInfo.deliveryTime &&
                !checkStoreOnlineInfo.pickupTime))
          "
          style="font-size: small"
          >{{
            orderPickup == false && dineIn == false
              ? ("delivery-method-address.preorder-delivered" | translate)
              : orderPickup == true && dineIn == false
              ? ("delivery-method-address.preorder-pickup" | translate)
              : ("delivery-method-address.preorder-delivered" | translate)
          }}:
          <strong>
            {{
              selectedPreorderDate &&
              selectedPreorderDate.hour &&
              selectedPreorderDate.date
                ? selectedPreorderDate.date +
                  (!selectedPreorderDate.selectedMethod ||
                  (selectedPreorderDate.selectedMethod === "delivery" &&
                    !selectedPreorderDate.preorderAcceptDisableDeliveryTime) ||
                  (selectedPreorderDate.selectedMethod === "takeaway" &&
                    !selectedPreorderDate.preorderAcceptDisablePickupTime)
                    ? " " + selectedPreorderDate.hour
                    : "")
                : ("delivery-method-address.now" | translate)
            }}
          </strong>
        </ion-label>
        <ion-label
          class="ion-text-wrap"
          *ngIf="
            (checkStoreOnlineInfo &&
              orderPickup == false &&
              checkStoreOnlineInfo.deliveryTime) ||
            (orderPickup == true &&
              checkStoreOnlineInfo &&
              checkStoreOnlineInfo.pickupDeliveryTime)
          "
          style="font-size: small"
          >{{
            orderPickup == false && dineIn == false
              ? ("delivery-method-address.preorder-delivered" | translate)
              : orderPickup == true && dineIn == false
              ? ("delivery-method-address.preorder-pickup" | translate)
              : ("delivery-method-address.preorder-delivered" | translate)
          }}:
          <strong>
            {{
              selectedPreorderDate &&
              selectedPreorderDate.hour &&
              selectedPreorderDate.date
                ? selectedPreorderDate.date +
                  (selectedPreorderDate.hour &&
                  ((orderPickup == false &&
                    dineIn == false &&
                    !selectedPreorderDate.preorderAcceptDisableDeliveryTime) ||
                    (orderPickup == true &&
                      dineIn == false &&
                      !selectedPreorderDate.preorderAcceptDisablePickupTime) ||
                    (orderPickup == false && dineIn == true))
                    ? " " + selectedPreorderDate.hour
                    : "")
                : orderPickup == false && checkStoreOnlineInfo.deliveryTime
                ? !deliveryTimeIsNumber
                  ? checkStoreOnlineInfo.deliveryTime
                  : checkStoreOnlineInfo.deliveryTime +
                    " " +
                    ("minutes" | translate)
                : orderPickup == true && checkStoreOnlineInfo.pickupDeliveryTime
                ? !pickUpTimeIsNumber
                  ? checkStoreOnlineInfo.pickupDeliveryTime
                  : checkStoreOnlineInfo.pickupDeliveryTime +
                    " " +
                    ("minutes" | translate)
                : ""
            }}
          </strong>
        </ion-label>
      </ion-item>
    </ion-grid>
  </ion-list>
</div>
