<ion-item
  *ngIf="renderedOrder && storeInfo"
  [ngClass]="renderedOrder.status + '_border'"
>
  <ion-button
    *ngIf="
      order &&
      order.status !== 'processing' &&
      order.status !== 'pending' &&
      order.status !== 'pendingPayment' &&
      order.status !== 'processingPayment'
    "
    (click)="askForStoreChanging()"
    color="primary"
    fill="clear"
    style="position: absolute; margin: 0; top: 2px; right: 2px"
  >
    <ion-icon
      slot="icon-only"
      src="assets/flatIcons/reorderFromOrder.svg"
    ></ion-icon>
  </ion-button>
  <ion-progress-bar
    style="position: absolute; top: 0; height: 1.5px"
    *ngIf="renderedOrder.status === 'pending'"
    color="danger"
    type="indeterminate"
  ></ion-progress-bar>

  <ion-row>
    <ion-col size="12">
      <ion-label class="ion-text-wrap">
        <ion-badge
          *ngIf="order && order.status === 'processing'"
          color="processing"
          lines="none"
          style="margin-right: 20px"
        >
          <ion-text style="font-size: 1.2em">
            {{ "orders.order-item.order-number" | translate }}:
            <strong>
              <ion-text
                *ngIf="
                  !(storeInfo.shortOrderIdInitMinutes && order.short_order_id)
                "
                >#{{ order.order_id }}
              </ion-text>
              <ion-text
                *ngIf="
                  storeInfo.shortOrderIdInitMinutes && order.short_order_id
                "
              >
                {{ order.short_order_id }}
              </ion-text>
            </strong></ion-text
          >
        </ion-badge>
        <p style="margin-right: 26px">
          <ion-text
            ><strong>
              <ion-text
                *ngIf="
                  order.status !== 'processing' ||
                  (storeInfo.shortOrderIdInitMinutes && order.short_order_id)
                "
                >#{{ order.order_id }}
              </ion-text>
              <ion-text
                *ngIf="
                  storeInfo.shortOrderIdInitMinutes &&
                  order.short_order_id &&
                  order.status !== 'processing'
                "
              >
                ({{ order.short_order_id }})
              </ion-text>

              {{
                (storeInfo.shortOrderIdInitMinutes &&
                  order.short_order_id &&
                  order.status !== "processing") ||
                order.status !== "processing" ||
                (storeInfo.shortOrderIdInitMinutes && order.short_order_id)
                  ? "|"
                  : ""
              }}
            </strong></ion-text
          >

          <ion-text *ngIf="renderedOrder.timestamp"
            ><strong>
              {{ renderedOrder.timestamp | date : "dd/MM/yyyy HH:mm" }}
            </strong>
          </ion-text>

          <ion-text
            *ngIf="
              renderedOrder.grand_total &&
              groupApp &&
              (!groupApp.design_settings ||
                !groupApp.design_settings.hideZeroPrices ||
                (groupApp.design_settings &&
                  groupApp.design_settings.hideZeroPrices &&
                  renderedOrder.grand_total &&
                  renderedOrder.grand_total !== '0.00'))
            "
            ><strong>| {{ renderedOrder.grand_total }}€ </strong></ion-text
          >
        </p>

        <p *ngIf="renderedOrder.status === 'pendingPayment'">
          <ion-text color="pending"
            >{{ "orders.order-item.order-not-send-yet" | translate }}
          </ion-text>
        </p>
        <p *ngIf="renderedOrder.status === 'processingPayment'">
          <ion-text color="processingPayment"
            >{{
              "orders.order-item.processing-your-payment" | translate
            }}
            &nbsp; {{ "via" | translate }} &nbsp;
            {{ renderedOrder.selectedPaymentMethodID.toString().toUpperCase() }}
          </ion-text>
        </p>
        <p *ngIf="renderedOrder.status === 'processing'">
          <ion-text color="danger"
            >{{ "orders.order-item.your-order-is-proccessing" | translate }}
          </ion-text>
        </p>

        <p
          *ngIf="
            renderedOrder.status === 'canceled' && renderedOrder.rejectionReason
          "
        >
          <ion-text color="canceled">{{
            renderedOrder.rejectionReason
          }}</ion-text>
        </p>
        <p
          *ngIf="
            renderedOrder.firstName ||
            renderedOrder.lastName ||
            renderedOrder.mobile ||
            renderedOrder.doorbellname
          "
        >
          <ion-text
            *ngIf="
              !renderedOrder.pickup &&
              !renderedOrder.dinein &&
              renderedOrder.doorbellname &&
              renderedOrder.mobile &&
              (!renderedOrder.fixed_address_id ||
                !renderedOrder.fixed_address_type ||
                renderedOrder.fixed_address_type !== 'meeting_point')
            "
            >{{ renderedOrder.doorbellname }} {{ renderedOrder.mobile }}
          </ion-text>
          <ion-text
            *ngIf="
              !renderedOrder.pickup &&
              !renderedOrder.dinein &&
              renderedOrder.doorbellname &&
              renderedOrder.mobile &&
              renderedOrder.fixed_address_id &&
              renderedOrder.fixed_address_type &&
              renderedOrder.fixed_address_type === 'meeting_point'
            "
            >{{ renderedOrder.firstName + " " + renderedOrder.lastName }}
            {{ renderedOrder.mobile }}
          </ion-text>
          <ion-text
            *ngIf="
              ((renderedOrder.pickup && !renderedOrder.dinein) ||
                (!renderedOrder.pickup && renderedOrder.dinein)) &&
              renderedOrder.firstName &&
              renderedOrder.lastName &&
              renderedOrder.mobile
            "
            >{{ renderedOrder.firstName + " " + renderedOrder.lastName }}
            {{ renderedOrder.mobile }}
          </ion-text>
        </p>
        <p
          *ngIf="
            !renderedOrder.pickup &&
            renderedOrder.fixed_address_id &&
            renderedOrder.fixed_address_type &&
            renderedOrder.fixed_address_type === 'meeting_point'
          "
          style="font-weight: bold; display: flex"
        >
          <ion-icon
            (click)="showOnMap(renderedOrder.userLat, renderedOrder.userLng)"
            color="primary"
            style="font-size: 20px; cursor: pointer; margin-right: 5px"
            src="assets/ionicons/location.svg"
          >
          </ion-icon>
          {{ "delivery-spot" | translate }}
        </p>

        <p
          *ngIf="
            renderedOrder.formatted_address &&
            !renderedOrder.pickup &&
            renderedOrder.fixed_address_id &&
            renderedOrder.fixed_address_name &&
            renderedOrder.fixed_address_type &&
            renderedOrder.fixed_address_type === 'meeting_point'
          "
        >
          <ion-text>{{ order.fixed_address_name }}</ion-text>
        </p>
        <p
          *ngIf="
            !renderedOrder.pickup &&
            !renderedOrder.dinein &&
            renderedOrder.formatted_address &&
            !(
              renderedOrder.fixed_address_id &&
              renderedOrder.fixed_address_name &&
              renderedOrder.fixed_address_type &&
              renderedOrder.fixed_address_type === 'meeting_point'
            )
          "
        >
          <ion-text>
            {{ renderedOrder.formatted_address }}
          </ion-text>
        </p>
        <p
          *ngIf="
            (order.status === 'complete' || order.status === 'processing') &&
            renderedOrder.deliveryTime &&
            !renderedOrder.preorder &&
            isNum(renderedOrder.deliveryTime) &&
            !(
              order.ds_status &&
              ds_status_color &&
              (order.ds_status === 'order_delivered' ||
                order.ds_status === 'order_received' ||
                order.ds_status === 'order_picked_up')
            )
          "
        >
          <ion-text color="primary"
            >{{ "orders.estimated-delivery-time" | translate }}:
            {{ renderedOrder.deliveryTime }}'
          </ion-text>
        </p>
        <p
          *ngIf="
            (order.status === 'complete' || order.status === 'processing') &&
            renderedOrder.deliveryTime &&
            !renderedOrder.preorder &&
            !isNum(renderedOrder.deliveryTime) &&
            !(
              order.ds_status &&
              ds_status_color &&
              (order.ds_status === 'order_delivered' ||
                order.ds_status === 'order_received' ||
                order.ds_status === 'order_picked_up')
            )
          "
        >
          <ion-text color="primary"
            >{{ "orders.estimated-delivery-time" | translate }}:
            {{ renderedOrder.deliveryTime }}
          </ion-text>
        </p>
        <p
          *ngIf="
            (order.status === 'complete' || order.status === 'processing') &&
            renderedOrder.preorder &&
            renderedOrder.preorder_day &&
            renderedOrder.preorder_time
          "
        >
          <ion-text color="primary"
            >{{ "orders.estimated-delivery-time" | translate }}:
            {{ renderedOrder.preorder_day }}
            {{
              !renderedOrder.preorderAcceptDisableDeliveryTime &&
              !renderedOrder.pickup &&
              !renderedOrder.dinein
                ? renderedOrder.preorder_time
                : !renderedOrder.preorderAcceptDisablePickupTime &&
                  renderedOrder.pickup &&
                  !renderedOrder.dinein
                ? renderedOrder.preorder_time
                : ""
            }}
          </ion-text>
        </p>
        <p
          *ngIf="
            stores &&
            stores.length > 1 &&
            storesByStoreId[renderedOrder.store_id] &&
            selectedLanguage
          "
        >
          <ion-text
            ><strong>
              {{
                storesByStoreId[renderedOrder.store_id]
                  .store_name_mobileorder &&
                storesByStoreId[renderedOrder.store_id].store_name_mobileorder[
                  selectedLanguage
                ]
                  ? storesByStoreId[renderedOrder.store_id]
                      .store_name_mobileorder[selectedLanguage]
                  : storesByStoreId[renderedOrder.store_id].store_name_langs &&
                    storesByStoreId[renderedOrder.store_id].store_name_langs[
                      selectedLanguage
                    ]
                  ? storesByStoreId[renderedOrder.store_id].store_name_langs[
                      selectedLanguage
                    ]
                  : storesByStoreId[renderedOrder.store_id].store_name_langs &&
                    storesByStoreId[renderedOrder.store_id].store_name_langs.el
                  ? storesByStoreId[renderedOrder.store_id].store_name_langs.el
                  : storesByStoreId[renderedOrder.store_id].store_name
                  ? storesByStoreId[renderedOrder.store_id].store_name
                  : ""
              }}
            </strong></ion-text
          >
        </p>
        <p
          *ngIf="
            renderedOrder.dinein &&
            renderedOrder.dinein_table_id &&
            renderedOrder.dinein_table_name
          "
        >
          <ion-text>{{ "orders.order-item.table" | translate }}: </ion-text>
          <ion-text>{{ renderedOrder.dinein_table_name }}</ion-text>
        </p>
        <p *ngIf="renderedOrder.company">
          <ion-text>{{ "orders.order-item.company" | translate }}: </ion-text>
          <ion-text>{{ renderedOrder.company }}</ion-text>
        </p>
        <p *ngIf="renderedOrder.altPhone">
          <ion-text
            >{{ "orders.order-item.alternative-phone" | translate }}:
            {{ renderedOrder.altPhone }}
          </ion-text>
        </p>
        <p
          *ngIf="renderedOrder.coupons && renderedOrder.coupons.length > 0"
          style="font-weight: bold"
          class="warningColor"
        >
          <ion-text *ngFor="let coupon of renderedOrder.coupons">
            <span
              *ngIf="
                coupon.discount &&
                coupon.discountType &&
                (coupon.discountType === 'percent' ||
                  coupon.discountType === 'fixed')
              "
              class="warningColor"
            >
              -{{
                coupon.discount +
                  (coupon.discountType === "percent" ? "%" : "€")
              }}
            </span>

            <span
              *ngIf="
                coupon.discount_percent &&
                coupon.discount_fixed &&
                coupon.discountType === 'combined'
              "
              color="danger"
            >
              -{{ coupon.discount_percent }}% & -{{
                coupon.discount_fixed
              }}€</span
            >
            {{ coupon.description }}
          </ion-text>
        </p>

        <p
          *ngIf="
            renderedOrder.selectedPaymentMethodID !== 'cash' &&
            renderedOrder.selectedPaymentMethodID !== 'mobilePOS' &&
            renderedOrder.selectedPaymentMethodID !== 'pre_cash' &&
            renderedOrder.selectedPaymentMethodID !== 'pre_mobilePOS' &&
            renderedOrder.payment_timestamp
          "
        >
          <ion-text
            ><strong>{{ "orders.order-item.payment" | translate }}:</strong>
            {{ renderedOrder.payment_timestamp | date : "dd/MM/yyyy HH:mm" }}
          </ion-text>
        </p>

        <p *ngIf="renderedOrder.refund && renderedOrder.refund.refund_id">
          <ion-text color="danger"
            ><strong>
              {{ renderedOrder.refund.amount
              }}{{ "orders.order-item.refunded" | translate }}
            </strong></ion-text
          >
        </p>

        <p
          *ngIf="
            storesByStoreId &&
            renderedOrder.time_sent &&
            storesByStoreId[renderedOrder.store_id] &&
            storesByStoreId[renderedOrder.store_id].orderProcessing
          "
        >
          <ion-text
            ><strong>{{ "orders.order-item.sent" | translate }}:</strong>
            {{ renderedOrder.time_sent | date : "dd/MM/yyyy HH:mm" }}
          </ion-text>
        </p>
      </ion-label>
    </ion-col>
    <ion-col size="12">
      <ion-badge
        class="badge-margin"
        *ngIf="
          renderedOrder.pickup &&
          renderedOrder.carTakeAwayPlateNumber &&
          !renderedOrder.dinein
        "
      >
        <ion-text
          ><strong>{{
            "orders.order-item.car-take-away" | translate
          }}</strong></ion-text
        >
      </ion-badge>

      <ion-badge
        class="badge-margin"
        *ngIf="
          renderedOrder.pickup &&
          !renderedOrder.carTakeAwayPlateNumber &&
          !renderedOrder.dinein
        "
      >
        <ion-text
          ><strong>{{
            "orders.order-item.take-away" | translate
          }}</strong></ion-text
        >
      </ion-badge>
      <ion-badge
        class="badge-margin"
        *ngIf="!renderedOrder.pickup && !renderedOrder.dinein"
      >
        <ion-text
          ><strong>{{
            "orders.order-item.delivery" | translate
          }}</strong></ion-text
        >
      </ion-badge>
      <ion-badge
        class="badge-margin"
        *ngIf="!renderedOrder.pickup && renderedOrder.dinein"
      >
        <ion-text
          ><strong>{{
            "orders.order-item.dine-in" | translate
          }}</strong></ion-text
        >
      </ion-badge>

      <ion-badge
        class="badge-margin"
        *ngIf="
          renderedOrder.preorder &&
          renderedOrder.preorder_day &&
          renderedOrder.preorder_time
        "
      >
        <ion-text
          ><strong>{{
            "orders.order-item.preorder" | translate
          }}</strong></ion-text
        >
      </ion-badge>

      <ion-badge
        *ngIf="renderedOrder.status"
        class="badge-margin"
        [color]="renderedOrder.status"
      >
        <ion-text *ngIf="renderedOrder.status === 'pendingPayment'">{{
          "orders.order-item.pendingPayment" | translate
        }}</ion-text>
        <ion-text *ngIf="renderedOrder.status === 'processingPayment'"
          >{{ "orders.order-item.processingPayment" | translate }}
        </ion-text>
        <ion-text *ngIf="renderedOrder.status === 'pending'">{{
          "orders.order-item.pending" | translate
        }}</ion-text>
        <span *ngIf="renderedOrder.status === 'complete' && groupApp">
          <ion-text
            *ngIf="
              !groupApp.design_settings ||
              !groupApp.design_settings.completeOrderLabelCustomText ||
              (groupApp.design_settings &&
                groupApp.design_settings.completeOrderLabelCustomText &&
                !groupApp.design_settings.completeOrderLabelCustomText[
                  selectedLanguage
                ])
            "
          >
            {{ "orders.order-item.complete" | translate }}
          </ion-text>

          <ion-text
            *ngIf="
              groupApp &&
              groupApp.design_settings &&
              groupApp.design_settings.completeOrderLabelCustomText &&
              selectedLanguage &&
              groupApp.design_settings.completeOrderLabelCustomText[
                selectedLanguage
              ]
            "
          >
            {{
              groupApp.design_settings.completeOrderLabelCustomText[
                selectedLanguage
              ]
            }}
          </ion-text>
        </span>
        <ion-text *ngIf="renderedOrder.status === 'canceled'">{{
          "orders.order-item.canceled" | translate
        }}</ion-text>
        <ion-text *ngIf="renderedOrder.status === 'processing'">{{
          "orders.order-item.processing" | translate
        }}</ion-text>
      </ion-badge>

      <span
        *ngIf="
          renderedOrder.status !== 'pendingPayment' &&
          renderedOrder.status !== 'processingPayment'
        "
      >
        <ion-badge
          class="badge-margin"
          *ngIf="
            renderedOrder.selectedPaymentMethodID &&
            renderedOrder.selectedPaymentMethodID !== 'cash' &&
            renderedOrder.selectedPaymentMethodID !== 'mobilePOS' &&
            renderedOrder.selectedPaymentMethodID !== 'pre_cash' &&
            renderedOrder.selectedPaymentMethodID !== 'pre_mobilePOS' &&
            renderedOrder.status !== 'canceled'
          "
        >
          <ion-text
            >{{ "orders.order-item.paid-online" | translate }}
            {{ "orders.order-item.via" | translate }}
            {{
              renderedOrder.selectedPaymentMethodID.toString().toUpperCase()
            }}</ion-text
          >
        </ion-badge>
        <ion-badge
          class="badge-margin"
          *ngIf="
            renderedOrder.selectedPaymentMethodID &&
            (renderedOrder.selectedPaymentMethodID === 'mobilePOS' ||
              renderedOrder.selectedPaymentMethodID === 'pre_mobilePOS')
          "
        >
          <span *ngIf="renderedOrder.selectedPaymentMethodID === 'mobilePOS'">
            <ion-text *ngIf="renderedOrder.pickup && !renderedOrder.dinein">
              {{ "orders.order-item.mobilePOS-takeaway" | translate }}
            </ion-text>
            <ion-text *ngIf="!renderedOrder.pickup && !renderedOrder.dinein">
              {{ "orders.order-item.mobilePOS-delivery" | translate }}
            </ion-text>
            <ion-text *ngIf="!renderedOrder.pickup && renderedOrder.dinein">
              {{ "orders.order-item.mobilePOS-dinein" | translate }}
            </ion-text></span
          >

          <ion-text
            *ngIf="renderedOrder.selectedPaymentMethodID === 'pre_mobilePOS'"
            >{{
              "orders.order-item.pay-via-pre-mobile-pos" | translate
            }}</ion-text
          >
        </ion-badge>
      </span>
    </ion-col>

    <ion-col
      size="12"
      class="ion-text-center ion-no-padding"
      *ngIf="
        order &&
        order.ds_status &&
        ds_status_color &&
        (order.ds_status === 'order_delivered' ||
          order.ds_status === 'order_received' ||
          order.ds_status === 'order_picked_up')
      "
    >
      <ion-badge [color]="ds_status_color">
        <div *ngIf="order.ds_status === 'order_received'">
          {{ "orders.delivery-status" | translate }}:
        </div>
        <div
          *ngIf="order.ds_status === 'order_received'"
          style="font-size: small"
        >
          <ion-text *ngIf="order.ds_status === 'order_received'">
            {{ "orders." + order.ds_status.toLowerCase() | translate }} ~{{
              order.ds_info?.pickup_time_eta | date : "HH:mm"
            }}
          </ion-text>
          <ion-text *ngIf="order.ds_status === 'order_picked_up'">
            {{ "orders." + order.ds_status.toLowerCase() | translate }} ~{{
              order.ds_info?.dropoff_time_eta | date : "HH:mm"
            }}
          </ion-text>
          <ion-text
            *ngIf="
              order.ds_status !== 'order_picked_up' &&
              order.ds_status !== 'order_received'
            "
          >
            {{ "orders." + order.ds_status.toLowerCase() | translate }}
          </ion-text>
        </div>

        <div *ngIf="order.ds_status !== 'order_received'">
          <ion-text *ngIf="order.ds_status === 'order_received'">
            {{ "orders.delivery-status" | translate }}:
            {{ "orders." + order.ds_status.toLowerCase() | translate }} ~{{
              order.ds_info?.pickup_time_eta | date : "HH:mm"
            }}
          </ion-text>
          <ion-text *ngIf="order.ds_status === 'order_picked_up'">
            {{ "orders.delivery-status" | translate }}:
            {{ "orders." + order.ds_status.toLowerCase() | translate }} ~{{
              order.ds_info?.dropoff_time_eta | date : "HH:mm"
            }}
          </ion-text>
          <ion-text
            *ngIf="
              order.ds_status !== 'order_picked_up' &&
              order.ds_status !== 'order_received'
            "
          >
            {{ "orders.delivery-status" | translate }}:
            {{ "orders." + order.ds_status.toLowerCase() | translate }}
          </ion-text>
        </div>
      </ion-badge>
    </ion-col>

    <ion-col
      size="12"
      [class]="
        (storesByStoreId &&
          storesByStoreId[renderedOrder.store_id] &&
          storesByStoreId[renderedOrder.store_id].reviewSystemActivated &&
          !renderedOrder.oldOrder &&
          !renderedOrder.rated &&
          renderedOrder.status !== 'pendingPayment' &&
          renderedOrder.status !== 'processingPayment' &&
          (renderedOrder.status === 'complete' ||
            renderedOrder.status === 'canceled')) ||
        renderedOrder.status !== 'pendingPayment'
          ? 'ion-text-right'
          : renderedOrder.status === 'pendingPayment'
          ? 'ion-text-center'
          : ''
      "
    >
      <ion-button
        (click)="presentReviewOrderModal(order)"
        color="danger"
        size="small"
        *ngIf="
          storesByStoreId &&
          storesByStoreId[renderedOrder.store_id] &&
          storesByStoreId[renderedOrder.store_id].reviewSystemActivated &&
          !renderedOrder.oldOrder &&
          !renderedOrder.rated &&
          renderedOrder.status !== 'pendingPayment' &&
          renderedOrder.status !== 'processingPayment' &&
          (renderedOrder.status === 'complete' ||
            renderedOrder.status === 'canceled')
        "
        >{{ "orders.order-item.rate-order" | translate }}</ion-button
      >
      <!-- <ion-button
        fill="clear"
        style="font-weight: 600"
        *ngIf="
          storesByStoreId &&
          storesByStoreId[renderedOrder.store_id] &&
          storesByStoreId[renderedOrder.store_id].reviewSystemActivated &&
          renderedOrder.rated &&
          renderedOrder.status !== 'pendingPayment' &&
          renderedOrder.status !== 'processingPayment'
        "
        >{{ "orders.order-item.see-review" | translate }}</ion-button
      > -->
      <ion-button
        color="danger"
        style="font-weight: 600"
        (click)="openSelectPaymentMethodPopup(order)"
        [disabled]="piraeusPendingPaymentOrder"
        *ngIf="
          renderedOrder.status === 'pendingPayment' &&
          !renderedOrder.orderMustBeCanceled
        "
      >
        <ion-text *ngIf="!piraeusPendingPaymentOrder">
          {{ "orders.order-item.complete-payment" | translate }}</ion-text
        >

        <ion-spinner *ngIf="piraeusPendingPaymentOrder"></ion-spinner>
      </ion-button>
      <ion-button
        size="small"
        (click)="presentOrderDetailsModal(order)"
        *ngIf="renderedOrder.status !== 'pendingPayment'"
        >{{ "orders.order-item.order-details" | translate }}</ion-button
      >
    </ion-col>
  </ion-row>
</ion-item>
