import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { distinctUntilChanged, Subscription } from "rxjs";
import * as fromApp from "../store/app.reducer";
import * as selectors from "../store/app.selectors";
import * as GroupAppActions from "../store/group-app/groupApp.actions";
import * as _ from "lodash";
import { AnalyticsServiceService } from "../services/analitycsService/analytics-service.service";
import { AnimationOptions } from "ngx-lottie";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";

declare let window: any;

@Component({
  selector: "app-menu-footer",
  templateUrl: "./menu-footer.component.html",
  styleUrls: ["./menu-footer.component.scss"],
})
export class MenuFooterComponent implements OnInit, OnDestroy {
  @Input() version;
  @Input() appChannel: any;

  public currentStore: any;
  public selectedLangugage: any;
  public darkTheme;
  public selectedTheme: any;
  public prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
  public groupApp: any;
  public animationPlaying: boolean = false;
  public startAnimation;
  public windowDimensions;
  public stores: any;
  public showWhiteLogoDeliveryManager;

  private subscriptions: Subscription[] = [];

  private animationDeliveryManagerInstance: any;
  animationDeliveryManagerOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/deliverymanager-lottie.json",
  };

  constructor(
    private store: Store<fromApp.AppState>,
    private analyticsService: AnalyticsServiceService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {

    if(this.appChannel && this.appChannel==='masterCapacitor'){
      //
    }

    this.showWhiteLogoDeliveryManager =
      !!document.documentElement.style.getPropertyValue(
        "--showWhiteLogoDeliveryManager"
      );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((currentStore) => {
          if (currentStore && !_.isEqual(this.currentStore, currentStore)) {
            this.currentStore = _.cloneDeep(currentStore);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(this.stores, state.stores)) {
            this.stores = _.cloneDeep(state.stores);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLangugage, state.selectedLangugage)
          ) {
            this.selectedLangugage = _.cloneDeep(state.selectedLangugage);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
          if (
            state &&
            state.selectedTheme &&
            !_.isEqual(this.selectedTheme, state.selectedTheme)
          ) {
            this.selectedTheme = _.cloneDeep(state.selectedTheme);
            if (
              this.selectedTheme &&
              this.selectedTheme === "light" &&
              !this.showWhiteLogoDeliveryManager
            ) {
              //console.log("lllight");

              this.animationDeliveryManagerOptions = {
                ...this.animationDeliveryManagerOptions,
                path: "https://data.deliverymanager.gr/animations/deliverymanager-lottie.json",
              };
              this.startAnimation = true;
            } else if (
              (this.selectedTheme && this.selectedTheme === "dark") ||
              this.showWhiteLogoDeliveryManager
            ) {
              //console.log("dddark");
              this.animationDeliveryManagerOptions = {
                ...this.animationDeliveryManagerOptions,
                path: "https://data.deliverymanager.gr/animations/deliverymanager-lottie-white.json",
              };
              this.startAnimation = true;
            }
            if (
              this.selectedTheme &&
              this.selectedTheme === "light" &&
              this.darkMode
            ) {
              this.darkTheme = false;
            } else if (
              this.selectedTheme &&
              this.selectedTheme === "dark" &&
              !this.darkTheme
            ) {
              this.darkTheme = true;
            }
          }
        })
    );
    if (navigator.cookieEnabled) {
      let darkThemeLocalStorage = localStorage.getItem("darkTheme");
      if (darkThemeLocalStorage && darkThemeLocalStorage === "yes") {
        this.darkTheme = true;
        this.store.dispatch(new GroupAppActions.SetSelectedTheme("dark"));
      } else {
        this.darkTheme = false;
        this.store.dispatch(new GroupAppActions.SetSelectedTheme("light"));
      }
    }
  }

  animationCreated(ev) {
    this.animationDeliveryManagerInstance = ev;
  }

  animStartPlaying() {
    this.animationPlaying = true;
  }

  animationLoopCompleted(ev) {
    this.animationPlaying = false;
    this.animationDeliveryManagerInstance.stop();
    this.startAnimation = false;

    this.changeDetector.detectChanges();
  }

  animationError(ev) {
    this.animationPlaying = false;
  }

  // darkMode(ev) {
  //   console.log("event", ev);
  //   document.body.classList.toggle("dark", ev.detail.checked);
  // }

  darkMode() {
    if (this.groupApp) {
      this.darkTheme = !this.darkTheme;
      this.analyticsService.dmAnalyticsMessage(
        "dark_mode",
        _.cloneDeep(this.darkTheme),
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
      if (this.darkTheme) {
        if (this.groupApp) {
          this.store.dispatch(new GroupAppActions.SetSelectedTheme("dark"));
          //document.body.classList.toggle(this.groupApp.group, false);
          if (this.groupApp.group === "battlenet") {
            document.body.classList.toggle("battlenet-dark", true);
          } else {
            document.body.classList.toggle("dark", true);
          }

          if (navigator.cookieEnabled) {
            localStorage.setItem("darkTheme", "yes");
          }
        }
      } else {
        if (this.groupApp) {
          this.store.dispatch(new GroupAppActions.SetSelectedTheme("light"));

          if (this.groupApp.group === "battlenet") {
            document.body.classList.toggle("battlenet-dark", false);
          } else {
            document.body.classList.toggle("dark", false);
          }

          // document.body.classList.toggle(this.groupApp.group, true);
          if (navigator.cookieEnabled) {
            localStorage.setItem("darkTheme", "no");
          }
        }
      }
    }
  }

  openLink = async (link) => {
    //open link external on new page
    if (Capacitor.isNativePlatform()) {
      window.open(link, "_system");
    } else {
      window.open(link, "_blank");
    }
  };

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });

      this.subscriptions = [];
    }
  }
}
