import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import {
  AlertController,
  MenuController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { Capacitor } from "@capacitor/core";

import { auditTime, distinctUntilChanged, first } from "rxjs/operators";
import { DataStorageService } from "../../services/data-storage/data-storage.service";
import * as fromApp from "../../store/app.reducer";
import * as CatalogActions from "../../catalog/store/catalog.actions";
import * as selectors from "../../store/app.selectors";
import * as CategoriesSelectionActions from "../../catalog/categories/store/categoriesSelection.actions";
import { ActivatedRoute, Router } from "@angular/router";
import * as CurrentStoreActions from "../../store/current-store/current-store.actions";
import { TranslateService } from "@ngx-translate/core";
import * as HyperCategoriesActions from "../../store/hyperCategories/hyperCategories.actions";
import { AnalyticsServiceService } from "../../services/analitycsService/analytics-service.service";
import { CalculateFinalPriceService } from "../../services/calculateFinalPrice/calculate-final-price.service";
import { SelectedAddressService } from "../../services/selectedAddress/selected-address.service";
import { LoginPopupsServiceService } from "../../services/login-popups-service/login-popups-service.service";
import { PaymentGatewaysService } from "../../services/payment-gateways/payment-gateways.service";
import { OrderNowModalPopupsService } from "../../services/orderNowModalPopupsService/orderNowModalPopups.service";
import * as GroupAppActions from "../../store/group-app/groupApp.actions";
import * as storesActions from "../../store/stores/stores.actions";
import { AnimationOptions } from "ngx-lottie";
import { InformationMessagesService } from "../../services/informationMessages/information-messages.service";
import { InfoMessageModalComponent } from "../../popups/info-message-modal/info-message-modal.component";
import * as currentStoreActions from "../../store/current-store/current-store.actions";
import moment from "moment-timezone";
import { InvoiceListModalComponent } from "../../cart/cart-components/invoice-list-modal/invoice-list-modal.component";
import * as FavoriteProductsActions from "../../store/favorite-products/favoriteProducts.actions";
import * as WindowInfoActions from "../../store/window-info/windowInfo.actions";
import { ReservationSelectArtistPage } from "../reservation-select-artist/reservation-select-artist.page";
import * as CartActions from "../../cart/store/cart.actions";
import { AppLoadingService } from "../app-loading.service";
import { StoreSchedulePage } from "src/app/store-schedule/store-schedule.page";
import { ReservationLocationPreviewPage } from "../reservation-location-preview/reservation-location-preview.page";
import * as CartActiveCouponsActions from "../../cart/cart-components/cart-active-coupons/store/cartActiveCoupons.actions";
import { ReservationSystemModalsService } from "../reservation-system-modals.service";
declare let window: any;

@Component({
  selector: "app-reservation-catalog",
  templateUrl: "./reservation-catalog.page.html",
  styleUrls: ["./reservation-catalog.page.scss"],
})
export class ReservationCatalogPage implements OnInit, OnDestroy {
  public currentStoreId: string;
  public languagePath = "el";
  public categories: any;
  public currentView: string;
  public windowDimensions: any;
  public groupApp: any;
  public rootDir: any;
  public webP: any;
  public store_info: any;
  public subscriptions: Subscription[] = [];
  public subCategoriesById: any;
  public customerPricelist: any;
  public hyperCategories: any;
  public selectedHyperCategory: string;
  public selectedPreorderDate: any;
  public swipeInfoAnimation: boolean;
  public swipeInfoAnimationInstance: any;
  public catalogInfoSwipeCheckbox = true;
  public selectedTheme: any;
  public loginState: any;
  public stores: any;
  public infoMessagesStore: any = {
    alert: null,
    inline: null,
    modal: null,
  };
  public homePageInfoMessageLink: any;
  public motoCardsProgressValues = {};
  public mo_base: any;
  public scrollFromBottomCatalogLg;
  public virtualSelectedHyperCategoryId: any;
  private currentStoreSub: Subscription;
  private pricelistsSub: Subscription;
  public catalogEmpty: boolean;
  public noMigrationCustomerIdInvoiceData: boolean;
  public customerOrders: any;
  public customerOrdersFiltered: any;
  public cartProducts: any;
  public selectedStoreId: string;
  public disableChangeStoreSegments: boolean;
  public migration_customer_id: any;
  public selectedInvoice: any;
  public currentUser: any;
  public showCategoriesSlider = true;
  public deliveryManagerPath = "https://images.deliverymanager.gr/";
  public allCategoriesMarketCardsDesign;
  public tabBarElement;
  public selectedLanguage;
  public rerenderProductsItemsTimestamp;

  public options: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/swipe-animation.json",
  };
  public homepageInfoMessagesStore: any = {
    alert: null,
    inline: null,
    modal: null,
  };
  public homepageInfoMessagesGroup: any = {
    alert: null,
    inline: null,
    modal: null,
  };

  public animationNoProductsOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/admin-person-animation.json",
  };
  public infoMessagesAnimations = {};
  public infoMessagesCardsHide = {};

  private checkStoreOnlineAlert: any;
  private autoDismissInfoMessageIntervals: any = {};
  private autoDismissTimeOuts: any = {};
  private tempGroupSub: any;
  private infoMessagesModal: any = {};
  private swipeAnimationChecked: boolean = false;
  private catalog: any;
  private selectedInvoiceModal: any;
  private browserPlatform: any = "xxx";
  private windowInfoSubscription: Subscription;
  private timeouts: any = [];
  private unsubscribeBackEvent: Subscription;
  private migrationCustomerIdSub: Subscription;
  private tempStoreSub: Subscription;
  private authSub: Subscription;
  private selectArtistModal;
  private locationModal;
  private storeInfoModal;

  constructor(
    private store: Store<fromApp.AppState>,
    private dataStorageService: DataStorageService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private analyticsService: AnalyticsServiceService,
    private toastController: ToastController,
    private calculateFinalPriceService: CalculateFinalPriceService,
    private alertController: AlertController,
    private addressService: SelectedAddressService,
    private loginPopupsService: LoginPopupsServiceService,
    private paymentGatewaysService: PaymentGatewaysService,
    private platform: Platform,
    private router: Router,
    private modalCtrl: ModalController,
    private orderNowModalPopupsService: OrderNowModalPopupsService,
    private infoMessagesService: InformationMessagesService,
    private changeDetector: ChangeDetectorRef,
    private appLoadingService: AppLoadingService,
    private reservationsSystemModalsService: ReservationSystemModalsService
  ) {}

  animationCreated(ev) {
    this.swipeInfoAnimationInstance = ev;
    this.swipeInfoAnimationInstance.setSpeed(1.2);
  }

  checkAndShowSwipeAnimation(groupApp) {
    if (groupApp.appSuspended) {
      this.swipeInfoAnimation = false;
    } else {
      if (navigator.cookieEnabled) {
        let pageInfoObject: any;
        if (
          localStorage.getItem("pageInfoAnimations") &&
          localStorage.getItem("pageInfoAnimations") !== ""
        ) {
          pageInfoObject = _.cloneDeep(
            JSON.parse(localStorage.getItem("pageInfoAnimations"))
          );
        }
        if (!pageInfoObject || !pageInfoObject.catalog_showed) {
          this.swipeInfoAnimation = true;
        }
      } else {
        this.swipeInfoAnimation = true;
      }
    }
  }

  ionViewDidEnter() {
    this.tabBarElement = document.getElementById("reservationSystemTabBar");

    if (this.tabBarElement) {
      this.tabBarElement.style.display = "none";
    }

    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      100,
      () => {
        if (
          this.swipeInfoAnimation &&
          (this.currentView === "mobileCatalog" ||
            this.currentView === "browserCatalogMd")
        ) {
          this.swipeInfoAnimation = false;
        } else {
          this.navigateToHome();
        }
      }
    );

    this.store
      .select("stores")
      .pipe(first())
      .subscribe((state) => {
        //console.log("stores sub catalog", state);
        this.activatedRoute.paramMap
          .subscribe((params) => {
            // console.log("paramss", params);

            // console.log("stores sub stores", state.stores);
            if (state && state.stores) {
              let params_store_id = params.get("store_id");
              if (
                params &&
                params_store_id &&
                _.find(state.stores, { store_id: params_store_id })
              ) {
                console.log("set store id catalog");
                //straight navigation
                this.store.dispatch(
                  new CurrentStoreActions.SetCurrentStoreId(
                    _.cloneDeep(params_store_id)
                  )
                );

                //  if (navigator.cookieEnabled) {
                //    window.localStorage.setItem("store_id", params_store_id);
                //  }
              }
            }

            // console.log("paramsss", params);

            //TO_DO PRESENT ERROR ALERT IF IS NOT SHOING ANY
          })
          .unsubscribe();
      })
      .unsubscribe();

    this.activatedRoute.queryParams
      .subscribe((params) => {
        if (params && params.showCouponsModal) {
          console.log("show coupons modal");
          setTimeout(() => {
            this.reservationsSystemModalsService.presentReservationCouponsSelectionModal();
          }, 1000);
        }
      })
      .unsubscribe();

    this.analyticsService.fbqTrackPageView();
    this.analyticsService.setCurrentScreenFirebase("catalog");
    this.analyticsService.tikTokPixelPageView("catalog");

    // console.log("did enter");
    this.tempStoreSub = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((storeInfo) => {
        // console.log("did enter store info", storeInfo);
        if (storeInfo && storeInfo.showOnlyFavoritesCatalog) {
          console.log("did enter store info showOnlyFavs catalog");
          if (this.authSub) {
            this.authSub.unsubscribe();
          }
          this.authSub = this.store
            .select("auth")
            .pipe(distinctUntilChanged())
            .subscribe((state) => {
              if (
                state &&
                state.loginState &&
                !_.isEqual(this.loginState, state.loginLoading)
              ) {
                this.loginState = _.cloneDeep(state.loginState);
                if (this.loginState === "no_login" && _.isEmpty(state.user)) {
                  this.presentErrorToast(
                    this.translateService.instant(
                      "catalog.you-must-be-logged-in-to-see-catalog"
                    ),
                    "danger"
                  );
                  this.router.navigateByUrl("/homepage", {
                    replaceUrl: true,
                  });
                } else if (this.loginState === "login_completed") {
                  //console.log("Prpspsps");

                  this.noMigrationCustomerIdInvoiceData = false;

                  if (storeInfo.vatBasedCustomerProductsActive) {
                    if (state.user && state.user.invoiceData) {
                      let counter = 0;
                      _.each(state.user.invoiceData, (invoice) => {
                        if (invoice.migration_customer_id) {
                          counter = counter + 1;
                        }
                      });

                      if (!counter) {
                        this.noMigrationCustomerIdInvoiceData = true;
                        this.catalogEmpty = true;
                        this.presentErrorToast(
                          this.translateService.instant(
                            "there-are-no-invoices-with-migration-customer-id"
                          ),
                          "danger"
                        );
                        // this.router.navigateByUrl("/homepage", {
                        //   replaceUrl: true,
                        // });
                      }
                    } else if (state.user && !state.user.invoiceData) {
                      this.noMigrationCustomerIdInvoiceData = true;
                      this.catalogEmpty = true;
                      this.presentErrorToast(
                        this.translateService.instant(
                          "there-are-no-invoices-with-migration-customer-id"
                        ),
                        "danger"
                      );
                      // this.router.navigateByUrl("/homepage", {
                      //   replaceUrl: true,
                      // });
                    }
                  }

                  this.initActions();
                }
              }
            });
        } else if (storeInfo && !storeInfo.showOnlyFavoritesCatalog) {
          console.log("did enter store info init actions");
          if (this.authSub) {
            this.authSub.unsubscribe();
          }
          this.authSub = this.store
            .select("auth")
            .pipe(distinctUntilChanged())
            .subscribe((state) => {
              if (
                state &&
                state.loginState &&
                !_.isEqual(this.loginState, state.loginLoading)
              ) {
                this.loginState = _.cloneDeep(state.loginState);

                if (this.loginState && this.loginState === "no_login") {
                  this.initActions();
                } else if (
                  this.loginState &&
                  this.loginState === "login_completed"
                ) {
                  this.noMigrationCustomerIdInvoiceData = false;

                  if (storeInfo.vatBasedCustomerProductsActive) {
                    if (state.user && state.user.invoiceData) {
                      let counter = 0;
                      _.each(state.user.invoiceData, (invoice) => {
                        if (invoice.migration_customer_id) {
                          counter = counter + 1;
                        }
                      });

                      if (!counter) {
                        this.noMigrationCustomerIdInvoiceData = true;
                        this.catalogEmpty = true;
                        this.presentErrorToast(
                          this.translateService.instant(
                            "there-are-no-invoices-with-migration-customer-id"
                          ),
                          "danger"
                        );
                        // this.router.navigateByUrl("/homepage", {
                        //   replaceUrl: true,
                        // });
                      }
                    } else if (state.user && !state.user.invoiceData) {
                      this.noMigrationCustomerIdInvoiceData = true;
                      this.catalogEmpty = true;
                      this.presentErrorToast(
                        this.translateService.instant(
                          "there-are-no-invoices-with-migration-customer-id"
                        ),
                        "danger"
                      );
                      // this.router.navigateByUrl("/homepage", {
                      //   replaceUrl: true,
                      // });
                    }
                  }
                  this.initActions();
                }
              }
            });
        }
      });
  }

  initActions() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    if (this.loginState) {
      this.loginState = null;
    }

    if (this.tempStoreSub) {
      this.tempStoreSub.unsubscribe();
    }
    console.log("init actions", this.loginState);
    this.subscriptions.push(
      this.store
        .select("hyperCategories")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.currentHyperCategory &&
            !_.isEqual(
              this.selectedHyperCategory,
              state.currentHyperCategory
            ) &&
            state.allCategories &&
            state.allCategories.length > 0
          ) {
            this.selectedHyperCategory = _.cloneDeep(
              state.currentHyperCategory
            );
            this.virtualSelectedHyperCategoryId = _.cloneDeep(
              state.currentHyperCategory
            );
            this.hyperCategoriesCalculations(state.allCategories);
          }
        })
    );
    console.log("will enter called", this.currentStoreId, this.languagePath);
    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);

            this.mo_base = _.cloneDeep(
              _.find(this.groupApp.languages, {
                mo_base: true,
              })
            );
            if (!this.mo_base) {
              this.mo_base = "en";
            } else {
              this.mo_base = this.mo_base.code;
            }
          }
          if (
            state &&
            state.rootDir &&
            !_.isEqual(this.rootDir, state.rootDir)
          ) {
            this.rootDir = _.cloneDeep(state.rootDir);
          }
          if (state && state.webp && !_.isEqual(this.webP, state.webp)) {
            this.webP = _.cloneDeep(state.webp);
          }
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.languagePath, state.selectedLangugage)
          ) {
            this.languagePath = _.cloneDeep(state.selectedLangugage);
          }
          if (
            state &&
            state.selectedTheme &&
            !_.isEqual(this.selectedTheme, state.selectedTheme)
          ) {
            this.selectedTheme = _.cloneDeep(state.selectedTheme);
            //console.log("selected theme", this.selectedTheme);
          }

          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLanguage, state.selectedLangugage)
          ) {
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
            //console.log("selected theme", this.selectedTheme);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("selectedInvoice")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.selectedInvoiceModal, state.selectedInvoice)
          ) {
            this.selectedInvoice = _.cloneDeep(state.selectedInvoice);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((store_info) => {
          if (store_info && !_.isEqual(store_info, this.store_info)) {
            if (this.store_info && this.groupApp) {
              this.calculateCatalogInfoMessagesAndStoreOnlineDebounced(
                _.cloneDeep(this.groupApp),
                _.cloneDeep(this.customerOrdersFiltered)
              );
            }
            this.store_info = _.cloneDeep(store_info);
            this.selectedStoreId = _.cloneDeep(store_info.store_id);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((auth) => {
          if (auth && !_.isEqual(this.currentUser, auth.user)) {
            this.currentUser = _.cloneDeep(auth.user);
          }
          if (
            auth &&
            auth.loginState &&
            !_.isEqual(this.loginState, auth.loginState)
          ) {
            this.loginState = _.cloneDeep(auth.loginState);
            // console.log("loginState", this.loginState);
            if (this.loginState && this.loginState === "no_login") {
              this.checkForCatalogInfoMessages();
            }

            if (
              this.loginState === "login_completed" ||
              this.loginState === "no_login"
            ) {
              if (this.currentStoreSub) {
                this.currentStoreSub.unsubscribe();
                this.currentStoreId = null;
              }

              this.currentStoreSub = this.store
                .select("currentStore")
                .pipe(distinctUntilChanged())
                .pipe(auditTime(100))
                .subscribe((state) => {
                  if (
                    state &&
                    state.currentStoreId &&
                    !_.isEqual(this.currentStoreId, state.currentStoreId)
                  ) {
                    this.currentStoreId = _.cloneDeep(state.currentStoreId);
                    // console.log("currentStore id changed", this.currentStoreId);
                    this.store
                      .select(selectors.getStoreInfo)
                      .pipe(first())
                      .subscribe((storeInfo) => {
                        //console.log("strtttr info", storeInfo);
                        if (
                          storeInfo &&
                          storeInfo.enable_customer_pricelists_mobileorder &&
                          storeInfo.enable_customer_pricelists &&
                          this.loginState === "login_completed"
                        ) {
                          //in this case we have customer pricelists
                          if (
                            storeInfo &&
                            storeInfo.vatBasedCustomerProductsActive
                          ) {
                            this.subscribeToMigrationCustomerId(true);
                          } else {
                            //console.log("storeInfo", storeInfo);
                            if (
                              storeInfo &&
                              storeInfo.showOnlyFavoritesCatalog
                            ) {
                              this.fetchCustomerFavoriteProducts(
                                storeInfo.store_id,
                                true,
                                false,
                                storeInfo.showOnlyFavoritesCatalog,
                                null
                              );
                            } else {
                              //console.log("storeInfo else");

                              this.subscribeToCatalogForPricelist(false, null);
                            }
                          }
                        } else {
                          if (
                            storeInfo &&
                            storeInfo.vatBasedCustomerProductsActive
                          ) {
                            if (
                              storeInfo &&
                              storeInfo.showOnlyFavoritesCatalog
                            ) {
                              this.subscribeToMigrationCustomerId(false);
                            } else {
                              //console.log("storeInfo else");
                              if (
                                storeInfo &&
                                storeInfo.enable_customer_pricelists_mobileorder &&
                                storeInfo.enable_customer_pricelists &&
                                this.loginState === "login_completed"
                              ) {
                                this.subscribeToMigrationCustomerId(true);
                              } else {
                                this.fetchCatalog(false, false, false, null);
                              }
                            }
                          } else {
                            if (
                              storeInfo &&
                              storeInfo.showOnlyFavoritesCatalog
                            ) {
                              this.fetchCustomerFavoriteProducts(
                                storeInfo.store_id,
                                false,
                                false,
                                storeInfo.showOnlyFavoritesCatalog,
                                null
                              );
                            } else {
                              //console.log("storeInfo else");
                              if (this.loginState === "login_completed") {
                                this.subscribeToCatalogForPricelist(
                                  false,
                                  null
                                );
                              } else {
                                this.fetchCatalog(false, false, false, null);
                              }
                            }
                          }
                        }
                      });
                  }
                });
            }
          }

          if (auth && !_.isEqual(auth.customerOrders, this.customerOrders)) {
            this.customerOrders = _.cloneDeep(auth.customerOrders);
            this.customerOrdersFiltered = _.cloneDeep(
              _.filter(_.cloneDeep(auth.customerOrders) || [], (order) => {
                return order.status === "complete";
              })
            );
            if (
              this.customerOrdersFiltered &&
              auth.loginState &&
              auth.loginState === "login_completed"
            ) {
              this.checkForCatalogInfoMessages();
            }

            // console.log("customersOrders", this.customerOrders);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.selectedPreorderDate, this.selectedPreorderDate)
          ) {
            this.selectedPreorderDate = _.cloneDeep(state.selectedPreorderDate);
            if (
              this.selectedPreorderDate &&
              this.selectedPreorderDate.date &&
              this.selectedPreorderDate.hour
            ) {
              //TO_DO CHECK FOR CUSTOMERS PRICELISTS
              //TO_DO CHECK IF WE ARE LOGGED IN

              this.store
                .select(selectors.getStoreInfo)
                .pipe(first())
                .subscribe((storeInfo) => {
                  if (storeInfo && storeInfo.showOnlyFavoritesCatalog) {
                    if (storeInfo.vatBasedCustomerProductsActive) {
                      this.store
                        .select("auth")
                        .pipe(first())
                        .subscribe((authState) => {
                          if (authState.migration_customer_id) {
                            if (
                              storeInfo.enable_customer_pricelists_mobileorder &&
                              storeInfo.enable_customer_pricelists
                            ) {
                              //TO_DO CHECK THIS
                              // this.fetchCustomerPricelistsForMigrationCustomerIdForPreorder(
                              //   authState.mo_token,
                              //   storeInfo.store_id,
                              //   authState.migration_customer_id
                              // );
                            } else {
                              this.fetchCustomerFavoriteProducts(
                                storeInfo.store_id,
                                false,
                                true,
                                storeInfo.showOnlyFavoritesCatalog,
                                authState.migration_customer_id
                              );
                            }
                          } else {
                            console.log("error: no migration customer id");
                          }
                        })
                        .unsubscribe();
                    } else {
                      this.fetchCustomerFavoriteProducts(
                        storeInfo.store_id,
                        false,
                        true,
                        storeInfo.showOnlyFavoritesCatalog,
                        null
                      );
                    }
                  } else {
                    this.fetchCatalog(true, false, false, null);
                  }
                })
                .unsubscribe();
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(this.stores, state.stores)) {
            this.stores = _.cloneDeep(state.stores);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.products &&
            !_.isEqual(this.cartProducts, state.products)
          ) {
            this.cartProducts = _.cloneDeep(state.products);
            if (
              this.cartProducts &&
              this.cartProducts.length &&
              this.cartProducts.length === 1
            ) {
              const lastProduct: any = _.maxBy(this.cartProducts, "cart_id");
              console.log("lastProduct", lastProduct);
              if (lastProduct) {
                this.reservationsSystemModalsService.closeReservationCouponsSelectionModal();
                this.openSelectArtistModal(lastProduct.product_id);
              }
            } else if (
              this.cartProducts &&
              this.cartProducts.length &&
              this.cartProducts.length > 1
            ) {
              this.store.dispatch(
                new CartActions.SetCartProducts(
                  _.cloneDeep([_.maxBy(this.cartProducts, "cart_id")])
                )
              );
            }
          }
        })
    );

    this.analyticsService.dmAnalyticsMessage(
      "catalog_loaded",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }

  async openSelectArtistModal(product_id) {
    await this.appLoadingService.hideLoading();

    if (!this.selectArtistModal) {
      this.selectArtistModal = true;
      this.selectArtistModal = await this.modalCtrl.create({
        component: ReservationSelectArtistPage,
        cssClass: "artistModalCss",
        animated: true,
        componentProps: {
          product_id: _.cloneDeep(product_id),
        },
        backdropDismiss: false,
      });

      await this.selectArtistModal.present();
      await this.selectArtistModal.onDidDismiss().then((data) => {
        this.selectArtistModal = null;

        if (data && data.data && data.data === "back") {
          this.store
            .select("cartActiveCoupons")
            .pipe(first())
            .subscribe((state) => {
              if (state && state.activeCoupons && state.activeCoupons.length) {
                const cartCoupons = _.cloneDeep(state.activeCoupons);
                _.each(this.cartProducts, (cartProduct) => {
                  _.each(_.cloneDeep(cartCoupons), (coupon, index) => {
                    if (
                      coupon.couponCheckbox &&
                      coupon.product_id &&
                      cartProduct.product_id === coupon.product_id
                    ) {
                      cartCoupons[index].couponCheckbox = false;
                    }
                  });
                });

                this.store.dispatch(
                  new CartActiveCouponsActions.SetActiveCoupons(
                    _.cloneDeep(cartCoupons)
                  )
                );
              }

              this.store.dispatch(new CartActions.ClearCart());
              this.appLoadingService.presentLoading();
              //this is in order to calculate start
              setTimeout(async () => {
                await this.appLoadingService.hideLoading();
              }, 610);
              if (navigator.cookieEnabled) {
                window.localStorage.removeItem("cart");
              }
            })
            .unsubscribe();
        }
      });
    }
  }

  fetchCustomerPricelistsForMigrationCustomerId(
    mo_token,
    store,
    migration_customer_id,
    fetchFavorites,
    subscribeToPricelists,
    forPreorder,
    onlyFavorites,
    currentUser
  ) {
    console.log("curreaskdj", currentUser);
    if (
      ((store && store.catalogZeroPricesMode) ||
        (currentUser && currentUser.catalogZeroPricesMode)) &&
      !!!currentUser?.skipCatalogZeroPricesMode
    ) {
      this.store.dispatch(new CatalogActions.SetCustomerPricelist([]));

      if (fetchFavorites) {
        this.fetchCustomerFavoriteProducts(
          store.store_id,
          true,
          forPreorder,
          onlyFavorites,
          migration_customer_id
        );
      } else if (subscribeToPricelists) {
        this.subscribeToCatalogForPricelist(false, null);
      }

      return;
    }
    this.dataStorageService
      .getUserPricelist(mo_token, store.store_id, migration_customer_id)
      .subscribe({
        next: async (res: any) => {
          if (!res || !res.success) {
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message:
                res && res.comment_id
                  ? this.translateService.instant(res.comment_id)
                  : this.translateService.instant(
                      "errorMessages.general_error"
                    ),
              backdropDismiss: false,
              buttons: ["OK"],
            });

            await alert.present();
          } else {
            if (!_.isEqual(this.customerPricelist, res.products)) {
              this.rerenderProductsItemsTimestamp = Date.now().toString();

              if (!this.customerPricelist) {
                setTimeout(() => {
                  this.rerenderProductsItemsTimestamp = Date.now().toString();
                }, 300);
              }
            }

            this.store.dispatch(
              new CatalogActions.SetCustomerPricelist(res.products)
            );
            if (fetchFavorites) {
              this.fetchCustomerFavoriteProducts(
                store.store_id,
                true,
                forPreorder,
                onlyFavorites,
                migration_customer_id
              );
            } else if (subscribeToPricelists) {
              this.subscribeToCatalogForPricelist(false, null);
            }
          }
        },
        error: async (err) => {
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.translateService.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          return err;
        },
      });
  }

  subscribeToCatalogForPricelist(onlyFavorites, favoriteProducts) {
    if (this.pricelistsSub) {
      this.pricelistsSub.unsubscribe();
    }
    this.customerPricelist = null;
    this.pricelistsSub = this.store
      .select("catalog")
      .pipe(distinctUntilChanged())
      .subscribe((catalogState) => {
        if (
          catalogState &&
          !_.isEqual(catalogState.customerPricelist, this.customerPricelist)
        ) {
          this.customerPricelist = _.cloneDeep(catalogState.customerPricelist);
          // console.log("catalog pricelists called", this.customerPricelist);

          if (
            this.customerPricelist &&
            this.customerPricelist &&
            this.customerPricelist.length > 0
          ) {
            //console.log("pricelists length > 0");
            this.fetchCatalog(false, true, onlyFavorites, favoriteProducts);
          } else if (
            this.customerPricelist &&
            this.customerPricelist &&
            this.customerPricelist.length === 0
          ) {
            // console.log("ffffffetch catalog called");
            this.fetchCatalog(false, false, onlyFavorites, favoriteProducts);
          }
        }
      });
  }

  ngOnInit() {
    //console.log("On init Called");
  }

  fetchCatalog(
    fetchForPreorder,
    customerPricelist,
    onlyFavorites,
    favoriteProducts
  ) {
    console.log(
      "fetch catalog called",
      fetchForPreorder,
      onlyFavorites,
      favoriteProducts
    );

    if (!fetchForPreorder) {
      this.store
        .select("groupApp")
        .pipe(first())
        .subscribe((groupState) => {
          this.dataStorageService
            .getCatalog(
              this.currentStoreId,
              groupState.selectedLangugage || "el"
            )
            .subscribe({
              next: (res: any) => {
                if (res && res.success && res.catalog) {
                  //console.log("get catalog", res.catalog);
                  const unfilteredCatalogWithoutProducts = _.map(
                    _.cloneDeep(res.catalog),
                    (cat) => {
                      return _.omit(cat, ["products"]);
                    }
                  );
                  this.store.dispatch(
                    new CatalogActions.SetCatalogUnfiltered(
                      unfilteredCatalogWithoutProducts
                    )
                  );
                  this.catalog = res.catalog;
                  this.hyperCategories = res.hyper_categories;
                  this.windowInfo(groupState.groupApp);

                  if (onlyFavorites) {
                    this.catalog = this.filterOnlyFavorites(
                      _.cloneDeep(this.catalog),
                      favoriteProducts
                    );
                    this.catalogEmpty = false;
                    if (this.catalog && this.catalog === "catalog_empty") {
                      this.catalogEmpty = true;
                      this.catalog = null;
                    }
                  }

                  this.filterCatalogProducts(customerPricelist);

                  if (this.hyperCategories && this.hyperCategories.length > 0) {
                    this.hyperCategories = _.filter(
                      this.hyperCategories,
                      (hyperCategory) => {
                        if (
                          _.find(this.catalog, (category) => {
                            if (
                              category.hyper_category_id ===
                                hyperCategory.hyper_category_id &&
                              category.activeTotal &&
                              category.products &&
                              category.products.length > 0
                            ) {
                              return category;
                            }
                          })
                        ) {
                          return hyperCategory;
                        }
                      }
                    );

                    let createOthersHyperCategory = false;
                    _.each(this.catalog, (category) => {
                      if (
                        !_.find(this.hyperCategories, {
                          hyper_category_id: category.hyper_category_id,
                        })
                      ) {
                        if (
                          category &&
                          category.activeTotal &&
                          category.products &&
                          category.products.length > 0
                        ) {
                          category.hyper_category_id = "others";
                          createOthersHyperCategory = true;
                        }
                      }
                    });
                    if (createOthersHyperCategory) {
                      this.hyperCategories.push({
                        name: "others",
                        hyper_category_id: "others",
                      });
                    }

                    _.each(this.hyperCategories, (hyperCategory) => {
                      _.each(this.catalog, (category) => {
                        if (
                          category.hyper_category_id ===
                          hyperCategory.hyper_category_id
                        ) {
                          if (hyperCategory.num_of_categories) {
                            hyperCategory.num_of_categories =
                              hyperCategory.num_of_categories + 1;
                          } else {
                            hyperCategory.num_of_categories = 1;
                          }
                        }
                      });
                    });
                  }

                  this.store.dispatch(
                    new HyperCategoriesActions.SetHyperCategories(
                      _.cloneDeep(
                        this.hyperCategories ? this.hyperCategories : []
                      )
                    )
                  );
                  this.getCatalogSuccessFunction(customerPricelist);
                } else {
                  //TO_DO ERROR HANDLING
                  console.log("error");
                }
              },
              error: (err) => {
                let errMsg = this.translateService.instant(
                  "errorMessages.problem_reaching_server"
                );

                this.presentErrorToast(errMsg, "danger");
              },
            });
        })
        .unsubscribe();
    } else {
      if (this.hyperCategories && this.hyperCategories.length === 0) {
        this.store
          .select("catalog")
          .pipe(first())
          .subscribe((state) => {
            if (state && state.catalog) {
              let catalog = _.cloneDeep(state.catalog);
              if (catalog && catalog.preorderNeedsCalculations) {
                //TO_DO FETCH FROM PREORDER API products and categories needs calculations
              } else {
                //this.fetchCatalog(false);
              }
            }
          })
          .unsubscribe();
      } else if (this.hyperCategories && this.hyperCategories.length > 0) {
        this.store
          .select("hyperCategories")
          .pipe(first())
          .subscribe((state) => {
            if (state && state.allCategories) {
              let catalog = _.cloneDeep(state.allCategories);
              if (catalog && catalog.preorderNeedsCalculations) {
                //TO_DO FETCH FROM PREORDER API products and categories needs calculations
              } else {
                // this.fetchCatalog(false);
              }
            }
          })
          .unsubscribe();
      } else {
      }
    }
  }

  checkForCatalogInfoMessages() {
    this.tempGroupSub = this.store
      .select("groupApp")

      .subscribe((groupState) => {
        if (groupState && groupState.groupApp) {
          // console.log("no login calculation for info messages");
          this.calculateCatalogInfoMessagesAndStoreOnlineDebounced(
            _.cloneDeep(groupState.groupApp),
            _.cloneDeep(this.customerOrdersFiltered)
          );
        }
      });
  }

  subscribeToMigrationCustomerId(withCustomerPricelists) {
    if (this.migrationCustomerIdSub) {
      this.migrationCustomerIdSub.unsubscribe();
      this.migration_customer_id = null;
    }

    this.migrationCustomerIdSub = this.store
      .select("auth")
      .pipe(distinctUntilChanged())
      .subscribe((authState) => {
        if (
          authState &&
          authState.migration_customer_id &&
          !_.isEqual(
            this.migration_customer_id,
            authState.migration_customer_id
          )
        ) {
          this.migration_customer_id = _.cloneDeep(
            authState.migration_customer_id
          );

          this.store
            .select(selectors.getStoreInfo)
            .pipe(first())
            .subscribe((storeInfo) => {
              if (withCustomerPricelists) {
                this.fetchCustomerPricelistsForMigrationCustomerId(
                  authState.mo_token,
                  storeInfo,
                  this.migration_customer_id,
                  true,
                  true,
                  false,
                  storeInfo.showOnlyFavoritesCatalog,
                  authState.user
                );
              } else {
                this.fetchCustomerFavoriteProducts(
                  storeInfo.store_id,
                  false,
                  false,
                  storeInfo.showOnlyFavoritesCatalog,
                  this.migration_customer_id
                );
              }
            })
            .unsubscribe();
        }
      });

    if (this.noMigrationCustomerIdInvoiceData) {
      this.subscribeToCatalogForPricelist(false, null);
    }
  }

  fetchCustomerFavoriteProducts(
    currentStoreId,
    customerPriceList,
    forPreorder,
    favoritesOnly,
    migration_customer_id
  ) {
    this.store
      .select("auth")
      .pipe(first())
      .subscribe((auth) => {
        if (auth && auth.mo_token) {
          this.dataStorageService
            .fetchCustomerFavoriteProducts(
              currentStoreId,
              auth.mo_token,
              migration_customer_id
            )

            .subscribe({
              next: async (res: any) => {
                if (res && res.success) {
                  this.store.dispatch(
                    new FavoriteProductsActions.SetFavoriteProducts(
                      res.products
                    )
                  );
                  let favoriteProducts = res.products;
                  if (customerPriceList) {
                    this.subscribeToCatalogForPricelist(
                      favoritesOnly,
                      favoriteProducts
                    );
                  } else {
                    this.fetchCatalog(
                      forPreorder,
                      customerPriceList,
                      true,
                      favoriteProducts
                    );
                  }
                } else if (!res || !res.success) {
                }
              },
              error: async (error) => {
                return error;
              },
            });
        }
      })
      .unsubscribe();
  }

  private navigateToHome() {
    if (this.platform.is("android")) {
      this.router.navigateByUrl("/reservationSystem/reservation-home", {
        replaceUrl: true,
      });
    }
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            //console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  async handleInfoMessagesModal(infoMessage) {
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe(async (state) => {
        if (state && !state.catalogPageInfoMessagesDisplayed) {
          this.infoMessagesModal[infoMessage.id] = await this.modalCtrl.create({
            component: InfoMessageModalComponent,
            cssClass: "infoMessagesModal",
            componentProps: {
              infoMessage: infoMessage,
            },
          });
          await this.infoMessagesModal[infoMessage.id].present();

          this.infoMessagesModal[infoMessage.id].onDidDismiss().then(() => {
            this.infoMessagesModal[infoMessage.id] = null;
          });

          this.store.dispatch(
            new GroupAppActions.SetCatalogPageInfoMessagesDisplayed(true)
          );
        }
      })
      .unsubscribe();
  }

  closeInfoCard(id) {
    this.infoMessagesCardsHide[id] = true;
    if (navigator.cookieEnabled) {
      const infoMessageObj = {
        id: id,
        closing_timestamp: Date.now().toString(),
      };
      let infoMessagesClosingArray: any = localStorage.getItem(
        "infoMessagesClosingArray"
      );
      // console.log("infoMessagesClosingArray", infoMessagesClosingArray);
      if (infoMessagesClosingArray && infoMessagesClosingArray !== "{}") {
        infoMessagesClosingArray = JSON.parse(infoMessagesClosingArray);
      }
      if (!infoMessagesClosingArray) {
        //  console.log("!infoMessagesClosingArray", infoMessagesClosingArray);
        infoMessagesClosingArray = [];
        infoMessagesClosingArray.push(infoMessageObj);
      } else {
        const index = _.findIndex(infoMessagesClosingArray, {
          id: id,
        });
        if (index !== -1) {
          infoMessagesClosingArray.splice(index, 1);
          infoMessagesClosingArray.push(infoMessageObj);
        } else {
          infoMessagesClosingArray.push(infoMessageObj);
        }
      }
      localStorage.setItem(
        "infoMessagesClosingArray",
        JSON.stringify(infoMessagesClosingArray)
      );
    }
  }

  checkIfShowInfoMessage(infoMessage) {
    if (navigator.cookieEnabled) {
      let infoMessagesClosingArray: any = localStorage.getItem(
        "infoMessagesClosingArray"
      );
      if (infoMessagesClosingArray) {
        infoMessagesClosingArray = JSON.parse(infoMessagesClosingArray);

        let infoMessageObj = _.find(infoMessagesClosingArray, {
          id: infoMessage.id,
        });

        if (infoMessageObj && infoMessageObj.closing_timestamp) {
          if (
            Math.abs(
              moment(
                parseInt(infoMessageObj.closing_timestamp.toString())
              ).diff(moment(), "hours")
            ) < 23
          ) {
            return false;
          }
        }
      }
    }

    return true;
  }

  private calculateCatalogInfoMessagesAndStoreOnlineDebounced = _.debounce(
    (groupApp, customerOrders) => {
      this.calculateCatalogInfoMessagesAndStoreOnline(groupApp, customerOrders);
    },
    500
  );

  calculateCatalogInfoMessagesAndStoreOnline(groupApp, customerOrders) {
    if (this.tempGroupSub) {
      this.tempGroupSub.unsubscribe();
    }
    for (let property in this.infoMessagesCardsHide) {
      this.infoMessagesCardsHide[property] = false;
    }

    for (let property in this.autoDismissInfoMessageIntervals) {
      clearInterval(this.autoDismissInfoMessageIntervals[property]);
    }

    for (let property in this.autoDismissTimeOuts) {
      clearInterval(this.autoDismissTimeOuts[property]);
    }
    this.autoDismissInfoMessageIntervals = {};
    this.autoDismissTimeOuts = {};
    this.infoMessagesStore = {
      alert: null,
      inline: null,
      modal: null,
    };
    this.store
      .select(selectors.getStoreInfo)
      .pipe(first())
      .subscribe((storeInfo) => {
        if (storeInfo) {
          if (groupApp) {
            // console.log(
            //   "call calculateHomepageInfoMessagesAndStoreOnline()",
            //   groupApp,
            //   customerOrders,
            //   storeInfo.store_name
            // );
            let catalogInfoMessagesGroup = _.cloneDeep(
              this.infoMessagesService.checkForMobileOrderInfoMessagesGroupApp(
                _.cloneDeep(groupApp),
                _.cloneDeep(storeInfo),
                "catalog"
              )
            );
            let catalogInfoMessagesStore = _.cloneDeep(
              this.infoMessagesService.checkForMobileOrderInfoMessagesStore(
                _.cloneDeep(storeInfo),
                "catalog"
              )
            );

            if (
              customerOrders &&
              customerOrders.length &&
              customerOrders.length > 0
            ) {
              //fitler the info messages arrays if the message is only for a new customer
              catalogInfoMessagesGroup = _.cloneDeep(
                _.filter(
                  _.cloneDeep(catalogInfoMessagesGroup),
                  (infoMessage) => {
                    if (infoMessage && !infoMessage.showOnlyNewCustomers) {
                      return infoMessage;
                    }
                  }
                )
              );

              catalogInfoMessagesStore = _.cloneDeep(
                _.filter(
                  _.cloneDeep(catalogInfoMessagesStore),
                  (infoMessage) => {
                    if (infoMessage && !infoMessage.showOnlyNewCustomers) {
                      return infoMessage;
                    }
                  }
                )
              );
            }

            //filter info messages if closed recently
            catalogInfoMessagesGroup = _.cloneDeep(
              _.filter(_.cloneDeep(catalogInfoMessagesGroup), (infoMessage) => {
                if (infoMessage && this.checkIfShowInfoMessage(infoMessage)) {
                  return infoMessage;
                }
              })
            );

            catalogInfoMessagesStore = _.cloneDeep(
              _.filter(_.cloneDeep(catalogInfoMessagesStore), (infoMessage) => {
                if (infoMessage && this.checkIfShowInfoMessage(infoMessage)) {
                  return infoMessage;
                }
              })
            );

            this.infoMessagesStore.alert = _.cloneDeep(
              _.filter(catalogInfoMessagesStore, (infoMessage) => {
                if (
                  infoMessage &&
                  infoMessage.view_type &&
                  _.includes(infoMessage.view_type, "alert")
                ) {
                  return infoMessage;
                }
              })
            );

            this.infoMessagesStore.inline = _.cloneDeep(
              _.filter(catalogInfoMessagesStore, (infoMessage) => {
                if (
                  infoMessage &&
                  infoMessage.view_type &&
                  _.includes(infoMessage.view_type, "inline")
                ) {
                  return infoMessage;
                }
              })
            );

            this.infoMessagesStore.modal = _.cloneDeep(
              _.filter(catalogInfoMessagesStore, (infoMessage) => {
                if (
                  infoMessage &&
                  infoMessage.view_type &&
                  _.includes(infoMessage.view_type, "modal")
                ) {
                  return infoMessage;
                }
              })
            );

            //console.log("infoMessagesStore catalog", this.infoMessagesStore);

            _.each(this.infoMessagesStore.alert, (infoMessage) => {
              this.playAnimationsFloatingInfoMessagesCard(infoMessage.id);
              if (
                infoMessage &&
                infoMessage.auto_dismiss &&
                infoMessage.auto_dismiss_seconds
              ) {
                this.CloseAnimationsFloatingInfoMessagesCard(
                  infoMessage.id,
                  infoMessage.auto_dismiss_seconds * 1000
                );
              }
            });

            let dont_show_other_messages: any;
            _.each(this.infoMessagesStore.modal, (infoMessage, index) => {
              if (infoMessage.dont_show_other_messages) {
                dont_show_other_messages = true;
              }
              if (
                infoMessage &&
                infoMessage.only_browser &&
                !Capacitor.isNativePlatform()
              ) {
                this.handleInfoMessagesModal(infoMessage);
              } else if (
                infoMessage &&
                infoMessage.only_app &&
                Capacitor.isNativePlatform()
              ) {
                this.handleInfoMessagesModal(infoMessage);
              } else if (!infoMessage.only_app && !infoMessage.only_browser) {
                this.handleInfoMessagesModal(infoMessage);
              }
            });
          }
        }
      });
  }
  playAnimationsFloatingInfoMessagesCard(id) {
    this.infoMessagesAnimations[id] = true;
    this.infoMessagesCardsHide[id] = false;
  }

  CloseAnimationsFloatingInfoMessagesCard(id, dismiss_seconds) {
    const seconds = parseFloat(dismiss_seconds);

    this.motoCardsProgressValues[id] = 0.0;
    this.autoDismissInfoMessageIntervals[id] = setInterval(() => {
      // console.log("interval called for", id, seconds / 10000000);
      this.motoCardsProgressValues[id] =
        this.motoCardsProgressValues[id] + 1 / (seconds / 150);
    }, 150);

    this.autoDismissTimeOuts[id] = setTimeout(() => {
      this.infoMessagesAnimations[id] = false;
      setTimeout(() => {
        this.infoMessagesCardsHide[id] = true;
        clearInterval(this.autoDismissInfoMessageIntervals[id]);
      }, 2000);
    }, dismiss_seconds);
  }

  closeCatalogSwipeInfo() {
    if (navigator.cookieEnabled && this.catalogInfoSwipeCheckbox) {
      let pageInfoAnimationsObj: any =
        localStorage.getItem("pageInfoAnimations");
      if (pageInfoAnimationsObj && pageInfoAnimationsObj !== "") {
        pageInfoAnimationsObj = JSON.parse(_.cloneDeep(pageInfoAnimationsObj));
        pageInfoAnimationsObj.catalog_showed = true;
        localStorage.setItem(
          "pageInfoAnimations",
          JSON.stringify(pageInfoAnimationsObj)
        );
      } else {
        const pageInfoObject = {
          catalog_showed: true,
        };
        localStorage.setItem(
          "pageInfoAnimations",
          JSON.stringify(pageInfoObject)
        );
      }
    }

    this.swipeInfoAnimation = false;
  }

  async selectedStoreChangedFromSegment(ev) {
    if (this.cartProducts && this.cartProducts.length > 0) {
      const alert = await this.alertController.create({
        cssClass: "my-custom-class",
        header: this.translateService.instant("alert"),
        message: this.translateService.instant(
          "catalog.if-you-change-store-your-cart-products-will-be-deleted-do-you-want-to-continue"
        ),
        buttons: [
          {
            text: this.translateService.instant("cancel"),
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              this.selectedStoreId = _.cloneDeep(this.store_info.store_id);
            },
          },
          {
            text: this.translateService.instant("yes"),
            handler: () => {
              console.log("set store id deliveryMethod address");
              this.showCategoriesSlider = false;
              setTimeout(() => {
                this.showCategoriesSlider = true;
              }, 900);
              this.store.dispatch(
                new currentStoreActions.SetCurrentStoreId(
                  _.cloneDeep(this.selectedStoreId)
                )
              );
            },
          },
        ],
      });

      await alert.present();
    } else {
      console.log("set store id catalog segment changed");
      this.showCategoriesSlider = false;
      setTimeout(() => {
        this.showCategoriesSlider = true;
      }, 900);
      this.store.dispatch(
        new currentStoreActions.SetCurrentStoreId(
          _.cloneDeep(this.selectedStoreId)
        )
      );
    }
    this.disableChangeStoreSegments = true;

    setTimeout(() => {
      this.disableChangeStoreSegments = false;
    }, 1000);
  }

  selectHyperCategoryChangeFromSegment(ev) {
    if (this.virtualSelectedHyperCategoryId) {
      this.store.dispatch(
        new HyperCategoriesActions.SetCurrentHyperCategory(
          _.cloneDeep(this.virtualSelectedHyperCategoryId)
        )
      );
    }
  }

  windowInfo(groupApp) {
    if (this.windowInfoSubscription) {
      this.windowInfoSubscription.unsubscribe();
      this.windowInfoSubscription = null;
    }
    if (!this.windowInfoSubscription) {
      if (this.windowDimensions) {
        this.store
          .select(selectors.getStoreInfo)
          .pipe(first())
          .subscribe((storeInfo) => {
            this.store
              .select("stores")
              .pipe(first())
              .subscribe((storesState) => {
                this.calculateGridDimensions(
                  _.cloneDeep(groupApp),
                  storesState.stores,
                  storeInfo
                );
              })
              .unsubscribe();
          })
          .unsubscribe();
      }
      this.windowInfoSubscription = this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowDimensions, state.windowDimensions) &&
            this.hyperCategories
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
            this.store
              .select(selectors.getStoreInfo)
              .pipe(first())
              .subscribe((storeInfo) => {
                this.store
                  .select("stores")
                  .pipe(first())
                  .subscribe((storesState) => {
                    this.calculateGridDimensions(
                      _.cloneDeep(groupApp),
                      storesState.stores,
                      storeInfo
                    );
                  })
                  .unsubscribe();
              })
              .unsubscribe();
          }
        });
    }
  }

  calculateGridDimensions(groupApp: any, stores: any, store_info: any) {
    if (this.hyperCategories && !this.hyperCategories.length) {
      document.documentElement.style.setProperty(
        "--menuMinWidthToShow",
        "1600"
      );

      if (
        this.windowDimensions &&
        this.windowDimensions.height &&
        this.windowDimensions.width
      ) {
        let height = this.windowDimensions.height;
        let width = this.windowDimensions.width;

        document.documentElement.style.setProperty(
          "--catalogGridMaxWidth",
          "100%"
        );

        document.documentElement.style.setProperty(
          "--catalogScrollHeight",
          (height - 175).toString() + "px"
        );
      }
    } else if (this.hyperCategories && this.hyperCategories.length > 0) {
      //console.log("calculate grid with hypercategories");

      document.documentElement.style.setProperty(
        "--menuMinWidthToShow",
        "1600"
      );

      if (
        this.windowDimensions &&
        this.windowDimensions.height &&
        this.windowDimensions.width
      ) {
        let height = this.windowDimensions.height;
        let width = this.windowDimensions.width;

        document.documentElement.style.setProperty(
          "--catalogGridMaxWidth",
          "100%"
        );

        document.documentElement.style.setProperty(
          "--catalogScrollHeight",
          (height - 175).toString() + "px"
        );
      }
    }
  }

  getCatalogSuccessFunction(thereAreCustomerPricelist) {
    //console.log("thereAreCustomerPricelist", thereAreCustomerPricelist);

    this.subCategoriesById = {};
    if (
      this.catalog &&
      (!this.hyperCategories ||
        (this.hyperCategories && this.hyperCategories.length === 0))
    ) {
      this.store.dispatch(
        new HyperCategoriesActions.SetCurrentHyperCategory(null)
      );
      this.selectedHyperCategory = null;
      this.filterCatalogProducts(thereAreCustomerPricelist);

      this.categories = _.filter(this.catalog, (category) => {
        if (
          category.activeTotal &&
          !category.hidden &&
          !category.showOnlyInQRCatalog &&
          !_.isEmpty(category.products) &&
          (!Capacitor.isNativePlatform() ||
            (Capacitor.isNativePlatform() && !this.platform.is("ios")) ||
            (Capacitor.isNativePlatform() &&
              this.platform.is("ios") &&
              !category.ios_hide_category))
        ) {
          return category;
        }
      });

      this.allCategoriesMarketCardsDesign = true;
      _.each(this.categories, (category, k) => {
        if (
          category.subCategoriesActive &&
          category.subCategories &&
          !_.isEmpty(category.subCategories)
        ) {
          _.each(category.subCategories, (subCategory) => {
            this.subCategoriesById[subCategory.subCategoryId] = subCategory;
          });
        }
        if (category && !category.marketCardsDesign) {
          this.allCategoriesMarketCardsDesign = false;
        }

        if (
          category &&
          category.promotions &&
          category.hideCategoryPromotions
        ) {
          category.promotions = [];
        }
      });
      // console.log("subcategories", this.subCategoriesById);

      this.store.dispatch(
        new CatalogActions.SetAllCategoriesMarketCardsDesign(
          _.cloneDeep(this.allCategoriesMarketCardsDesign)
        )
      );

      if (!_.isEmpty(this.categories)) {
        // if (navigator.cookieEnabled) {
        //   let selectedCategoryId = localStorage.getItem("selectedCategory");
        //   if (
        //     selectedCategoryId &&
        //     _.find(this.categories, {
        //       category_id: selectedCategoryId,
        //     })
        //   ) {
        //     this.store.dispatch(
        //       new CategoriesSelectionActions.SetSelectedCategory(
        //         _.cloneDeep(selectedCategoryId)
        //       )
        //     );
        //   } else {
        //     if (selectedCategoryId) {
        //       localStorage.removeItem("selectedCategory");
        //     }
        //     this.store.dispatch(
        //       new CategoriesSelectionActions.SetSelectedCategory(
        //         _.cloneDeep(this.categories[0].category_id)
        //       )
        //     );
        //   }
        // } else {
        this.store.dispatch(
          new CategoriesSelectionActions.SetSelectedCategory(
            _.cloneDeep(this.categories[0].category_id)
          )
        );
      }
      this.writeCategoriesToStateForRender(this.categories, false);
    } else if (
      this.catalog &&
      this.hyperCategories &&
      this.hyperCategories.length > 0
    ) {
      //console.log("hyper categories calculations");
      this.hyperCategoriesCatalog(thereAreCustomerPricelist);
    }
    if (!this.swipeAnimationChecked) {
      this.swipeAnimationChecked = true;
      if (
        (this.platform && this.platform.is("android")) ||
        this.platform.is("ios")
      ) {
        this.store
          .select("groupApp")
          .pipe(first())
          .subscribe((groupState) => {
            if (groupState && groupState.groupApp) {
              this.checkAndShowSwipeAnimation(groupState.groupApp);
            }
          })
          .unsubscribe();
      }
    }
  }

  writeCategoriesToStateForRender(categories, hyperCategoriesExist) {
    this.store
      .select(selectors.getStoreInfo)
      .pipe(first())
      .subscribe((store_info) => {
        this.store
          .select("catalog")
          .pipe(first())
          .subscribe((state) => {
            if (state && state.catalog && state.catalog.length) {
              this.store.dispatch(
                new CatalogActions.SetAllCategories(_.cloneDeep(categories))
              );
              this.store.dispatch(
                new CatalogActions.SetCatalog(_.cloneDeep(categories))
              );
            } else {
              this.store.dispatch(
                new CatalogActions.SetAllCategories(_.cloneDeep(categories))
              );

              if (
                !(
                  store_info &&
                  store_info.design_settings &&
                  store_info.design_settings.showOnlySelectedCategoryProducts
                )
              ) {
                let categoriesProducts = [];
                let categoriesClone = [];
                categoriesClone = _.cloneDeep(categories);
                _.forEach(categoriesClone, (category, key) => {
                  if (key !== 0 && key !== 1 && key !== 2) {
                    categoriesProducts.push(
                      _.cloneDeep({
                        category_id: category.category_id,
                        products: category.products,
                      })
                    );
                    category.products = null;
                  }
                });
                this.store.dispatch(
                  new CatalogActions.SetCatalog(_.cloneDeep(categoriesClone))
                );
                let delayTime = 300;
                if (hyperCategoriesExist) {
                  delayTime = 300;
                }
                _.each(categoriesProducts, (categoryProducts, key) => {
                  let timeOut = setTimeout(() => {
                    this.store.dispatch(
                      new CatalogActions.SetCategoryProducts({
                        category_id: categoryProducts.category_id,
                        products: categoryProducts.products,
                      })
                    );
                  }, delayTime);
                  if (!hyperCategoriesExist) {
                    delayTime =
                      delayTime +
                      (categoryProducts.products &&
                      categoryProducts.products.length
                        ? categoryProducts.products.length * 35 + 300
                        : 300);
                  } else {
                    delayTime = delayTime + 200;
                  }

                  this.timeouts.push(timeOut);
                });
              } else {
                this.store.dispatch(
                  new CatalogActions.SetCatalog(_.cloneDeep(categories))
                );
              }
            }
          })
          .unsubscribe();
      })
      .unsubscribe();
  }

  filterCatalogProducts(thereAreCustomerPricelist) {
    this.store
      .select(selectors.getStoreInfo)
      .pipe(first())
      .subscribe((store_info) => {
        if (thereAreCustomerPricelist) {
          this.store
            .select("catalog")
            .pipe(first())
            .subscribe((state) => {
              if (state && state.customerPricelist) {
                this.filterCatalogProductsActions(
                  _.cloneDeep(state.customerPricelist),
                  store_info
                );
              }
            });
        } else {
          this.filterCatalogProductsActions(null, store_info);
        }
      })
      .unsubscribe();
  }

  filterCatalogProductsActions(customerPricelistProducts, store_info) {
    //console.log("customerPricelistProducts", customerPricelistProducts);
    _.each(this.catalog, (cat, c, l) => {
      this.catalog[c].products = _.filter(cat.products, (product, key) => {
        //TODO this is to be deleted as the subCategoryId will not exist in the product if the subCategories of the category
        //This was found in Prunas
        //This problem exists when the product is a copy

        if (
          product.subCategoryId &&
          (!cat.subCategoriesActive ||
            !_.find(cat.subCategories, {
              subCategoryId: product.subCategoryId,
            }))
        ) {
          //console.log("product with an invalid subCategoryId", product);
          cat.products[key].subCategoryId = null;
        }
        if (
          product.image &&
          product.image.true &&
          product.image.true["4x3"] &&
          product.image.true["4x3"].path
        ) {
          product.thumb = true;
        }

        this.checkProductsCategoriesAvailabilityActions(product, cat);

        if (
          !(
            cat.availableTotal &&
            !product.availableTotal &&
            product.available &&
            product.avpc
          )
        ) {
          cat.avpc = null;
        }
        if (
          (!product.available && !product.availableTotal) ||
          !cat.availableTotal
        ) {
          product.lineThrough = true;
        }
        if (
          !product.availableTotal ||
          !cat.availableTotal ||
          product.initial_price
        ) {
          product.priceWidthUnavailable = true;
        }
        if (
          product.availableTotal &&
          cat.availableTotal &&
          !product.initial_price
        ) {
          product.priceWidthAvailable = true;
        }

        //all the products that has avalabilityForPreorder

        //this is for the preorder only category for user to have the ability to order this products only with preorder
        if (
          product &&
          !product.availableTotal &&
          store_info.preorderActive &&
          product.tags &&
          product.tags.availableForPreorder &&
          cat &&
          cat.promoCategory &&
          cat.availableTotal &&
          cat.activeTotal &&
          cat.tags &&
          cat.tags.availableForPreorder
        ) {
          product.availableTotal = true;
          product.avpc = null;
          product.hideAvailableForPreorderTag = false;
        } else if (
          product &&
          product.tags &&
          product.tags.availableForPreorder &&
          product.availableTotal &&
          cat &&
          (!cat.promoCategory ||
            (cat.promoCategory &&
              (!cat.tags || !cat.tags.availableForPreorder)))
        ) {
          product.hideAvailableForPreorderTag = true;
        } else if (
          product &&
          product.tags &&
          product.tags.availableForPreorder &&
          !product.availableTotal &&
          !cat.promoCategory
        ) {
          let foundProductInPromoCategory;
          _.each(this.catalog, (categ) => {
            if (
              categ &&
              categ.promoCategory &&
              categ.availableTotal &&
              categ.activeTotal &&
              categ.tags &&
              categ.tags.availableForPreorder
            ) {
              _.each(categ.products, (categ_product) => {
                if (categ_product.product_id === product.product_id) {
                  foundProductInPromoCategory = true;
                }
              });
            }
          });

          if (!foundProductInPromoCategory) {
            product.hideAvailableForPreorderTag = true;
          }
        }

        if (
          product.product_categories_active_check &&
          product.product_categories_availability_check &&
          (!cat.promoCategory || !cat.tags || !cat.tags.availableForPreorder) &&
          //TO-DO check the above condition
          // (!cat.promoCategory ||
          //   (cat.promoCategory &&
          //     !cat.check_promo_category_products_availability)) &&
          //
          !product.availableTotal &&
          !product.activePeriod &&
          !product.availablePeriod &&
          product.availableInitializationActive &&
          product.availableInitializationTime &&
          (!product.preorder_availability_stock_enabled ||
            product.preorder_availability_stock > 0) &&
          store_info &&
          store_info.preorderActive &&
          (((!store_info.preorderDaysStart ||
            store_info.preorderDaysStart === 0) &&
            store_info.preorderDaysAhead > 1) ||
            store_info.preorderDaysStart > 0) &&
          (!product.tags || !product.tags.availableForPreorder)
        ) {
          if (!product.tags) {
            product.tags = {};
          }
          product.tags.initializationActivePreorder = true;
          product.availableTotal = true;
        }

        if (customerPricelistProducts && customerPricelistProducts.length) {
          _.each(customerPricelistProducts, (customerProduct) => {
            if (customerProduct.product_id === product.product_id) {
              if (customerProduct.price) {
                let price = _.cloneDeep(
                  parseFloat(customerProduct.price.toString()).toFixed(
                    store_info && store_info.decimalPlacesPrices
                      ? store_info.decimalPlacesPrices
                      : 2
                  )
                );
                product.price = price.toString() + "€";
              }

              if (customerProduct.price_takeaway) {
                let price_takeaway = _.cloneDeep(
                  parseFloat(customerProduct.price_takeaway.toString()).toFixed(
                    store_info && store_info.decimalPlacesPrices
                      ? store_info.decimalPlacesPrices
                      : 2
                  )
                );
                price_takeaway = price_takeaway.toString() + "€";

                product.price_takeaway = price_takeaway;
              }
            }
          });
        }

        return (
          product.activeTotal &&
          !product.hidden &&
          !product.showOnlyInQRCatalog &&
          product.product_categories_active_check
        );
      });
    });
  }

  checkProductsCategoriesAvailabilityActions(product, cat) {
    if (product && product.options) {
      _.each(product.options, (opt) => {
        if (opt && opt.choices) {
          _.each(opt.choices, (ch) => {
            if (ch.bundle === true && ch.bundled_product) {
              this.checkProductsCategoriesAvailabilityActions(
                ch.bundled_product,
                cat
              );
            }
          });
        }
      });
    }

    this.checkProductCategoriesAvailabilityActions(product, cat);
  }

  checkProductCategoriesAvailabilityActions(product, cat) {
    //check for categories copy products availability and active
    if (product.categories && product.categories.length) {
      const original_category = _.find(this.catalog, {
        category_id: product.category_id,
      });

      if (
        original_category &&
        (!original_category.availableTotal || !original_category.activeTotal)
      ) {
        if (
          cat.promoCategory &&
          cat.check_promo_category_products_availability
        ) {
          const productCategories = _.filter(product.categories, (ct) => {
            return ct.category_id !== cat.category_id;
          });
          let productCategoryActiveTotal, productCategoryAvailableTotal;
          _.each(productCategories, (product_category) => {
            const pr_cat = _.find(this.catalog, {
              category_id: product_category.category_id,
            });

            if (pr_cat.availableTotal) {
              productCategoryAvailableTotal = true;
            }

            if (pr_cat.activeTotal) {
              productCategoryActiveTotal = true;
              console.log("pr categoruyasd", pr_cat);
            }
          });
          if (productCategoryActiveTotal) {
            product.product_categories_active_check = true;
          }

          if (productCategoryAvailableTotal) {
            product.product_categories_availability_check = true;
          }
        } else if (
          cat.promoCategory &&
          !cat.check_promo_category_products_availability
        ) {
          let productCategoryActiveTotal, productCategoryAvailableTotal;

          _.each(product.categories, (product_category) => {
            const pr_cat = _.find(this.catalog, {
              category_id: product_category.category_id,
            });

            if (pr_cat.availableTotal) {
              productCategoryAvailableTotal = true;
            }

            if (pr_cat.activeTotal) {
              productCategoryActiveTotal = true;
            }
          });
          if (productCategoryActiveTotal) {
            product.product_categories_active_check = true;
          }

          if (productCategoryAvailableTotal) {
            product.product_categories_availability_check = true;
          }
        } else if (!cat.promoCategory) {
          let productCategoryActiveTotal, productCategoryAvailableTotal;
          if (cat.availableTotal) {
            productCategoryAvailableTotal = true;
          }

          if (cat.activeTotal) {
            productCategoryActiveTotal = true;
          }

          if (!cat.availableTotal || !cat.activeTotal) {
            _.each(product.categories, (product_category) => {
              const pr_cat = _.find(this.catalog, {
                category_id: product_category.category_id,
              });

              if (pr_cat.availableTotal) {
                productCategoryAvailableTotal = true;
              }

              if (pr_cat.activeTotal) {
                productCategoryActiveTotal = true;
              }
            });
          }

          if (productCategoryActiveTotal) {
            product.product_categories_active_check = true;
          }

          if (productCategoryAvailableTotal) {
            product.product_categories_availability_check = true;
          }
        }
      } else {
        product.product_categories_availability_check = _.cloneDeep(
          original_category.availableTotal
        );
        product.product_categories_active_check = _.cloneDeep(
          original_category.activeTotal
        );
      }
    } else {
      product.product_categories_availability_check = _.cloneDeep(
        cat.availableTotal
      );
      product.product_categories_active_check = _.cloneDeep(cat.activeTotal);
    }
  }

  hyperCategoriesCatalog(thereAreCustomerPricelist) {
    if (!this.categories || !_.isArray(this.categories)) {
      this.categories = [];
    }

    this.filterCatalogProducts(thereAreCustomerPricelist);

    this.categories = _.filter(this.catalog, (category) => {
      if (
        category.activeTotal &&
        !category.hidden &&
        !category.showOnlyInQRCatalog &&
        !_.isEmpty(category.products) &&
        (!Capacitor.isNativePlatform() ||
          (Capacitor.isNativePlatform() && !this.platform.is("ios")) ||
          (Capacitor.isNativePlatform() &&
            this.platform.is("ios") &&
            !category.ios_hide_category))
      ) {
        return category;
      }
    });

    this.allCategoriesMarketCardsDesign = true;

    _.each(this.categories, (category, k) => {
      if (
        category.subCategoriesActive &&
        category.subCategories &&
        !_.isEmpty(category.subCategories)
      ) {
        _.each(category.subCategories, (subCategory) => {
          this.subCategoriesById[subCategory.subCategoryId] = subCategory;
        });
      }
      if (category && !category.marketCardsDesign) {
        this.allCategoriesMarketCardsDesign = false;
      }
    });

    this.store.dispatch(
      new CatalogActions.SetAllCategoriesMarketCardsDesign(
        _.cloneDeep(this.allCategoriesMarketCardsDesign)
      )
    );
    // console.log("subcategories", this.subCategoriesById);

    this.store.dispatch(
      new HyperCategoriesActions.SetAllCategories(_.cloneDeep(this.categories))
    );

    if (thereAreCustomerPricelist) {
      this.hyperCategoriesCalculations(this.categories);
    }

    if (navigator.cookieEnabled) {
      let selectedHyperCateogryId = localStorage.getItem(
        "selectedHyperCategory"
      );
      if (
        selectedHyperCateogryId &&
        _.find(this.hyperCategories, {
          hyper_category_id: selectedHyperCateogryId,
        })
      ) {
        this.store.dispatch(
          new HyperCategoriesActions.SetCurrentHyperCategory(
            _.cloneDeep(selectedHyperCateogryId)
          )
        );
      } else {
        if (selectedHyperCateogryId) {
          localStorage.removeItem("selectedHyperCategory");
        }
        this.store.dispatch(
          new HyperCategoriesActions.SetCurrentHyperCategory(
            this.hyperCategories[0].hyper_category_id
          )
        );
      }
    } else {
      this.store.dispatch(
        new HyperCategoriesActions.SetCurrentHyperCategory(
          this.hyperCategories[0].hyper_category_id
        )
      );
    }
  }

  hyperCategoriesCalculations(allCategories) {
    // console.log("first hyper category", this.selectedHyperCategory);
    let categories: any = [];
    // console.log("hyper categories calculations", categories, allCategories);
    this.store.dispatch(new CatalogActions.SetCatalog(_.cloneDeep(null)));
    _.each(allCategories, (category) => {
      if (category.hyper_category_id === this.selectedHyperCategory) {
        //console.log("push category", category);
        categories.push(category);
      }
    });
    if (!_.isEmpty(categories)) {
      this.store.dispatch(
        new CategoriesSelectionActions.SetSelectedCategory(
          _.cloneDeep(categories[0].category_id)
        )
      );
    }
    // console.log("after each loop hyper categories", categories);

    this.writeCategoriesToStateForRender(categories, true);
  }

  filterOnlyFavorites(catalog, favoriteProducts) {
    // console.log("only favorites called", catalog, favoriteProducts);
    if (catalog && catalog.length) {
      _.each(catalog, (category) => {
        if (category.products) {
          let newProducts = [];
          _.each(category.products, (product, index) => {
            if (
              _.find(favoriteProducts, { product_id: product.product_id }) &&
              !_.find(newProducts, { product_id: product.product_id })
            ) {
              newProducts.push(product);
            }
          });

          category.products = newProducts;
        }
      });
      const returedCatalog = _.filter(catalog, (category) => {
        if (category.products && category.products.length) {
          return category;
        }
      });

      if (!returedCatalog || !returedCatalog.length) {
        return "catalog_empty";
      } else {
        return returedCatalog;
      }
    } else {
      return catalog;
    }
  }

  goBack() {
    this.router.navigateByUrl("/reservationSystem", {
      replaceUrl: true,
    });
  }

  checkIfIShouldShowSchedulePopup(response, orderPickup) {
    if (
      response.__reason === "stopStoreActive" ||
      response.__reason === "offSchedule"
    ) {
      console.log(
        "Store is not online based on the period or the schedule of both pickup and delivery"
      );
      return true;

      //This is the case where we need to check if the user selected pickup or not so that I can show the popup if needed
    } else if (
      (!response.delivery_status && orderPickup == false) ||
      (!response.pickup_status && orderPickup)
    ) {
      return true;
    } else {
      return false;
    }

    //This is the case that we need to overide the delivery_status and pickup_status as period overrules/
  }

  scrollFromBottomEventCatalogLg(ev) {
    // console.log("scroll botttttom event", ev);
    this.scrollFromBottomCatalogLg = ev;
    this.changeDetector.detectChanges();
  }

  navigateToCart() {
    this.router.navigateByUrl("/cart", {
      replaceUrl: true,
    });
  }

  async presentSelectInvoiceModal() {
    this.selectedInvoiceModal = await this.modalCtrl.create({
      component: InvoiceListModalComponent,
      cssClass: "invoiceListModal",
      backdropDismiss: false,
    });

    await this.selectedInvoiceModal.present();
    this.selectedInvoiceModal.onDidDismiss().then((invoice) => {
      console.log("on dismiss", invoice);
      this.selectedInvoiceModal = null;
    });
  }

  ionViewDidLeave() {
    if (this.tabBarElement) {
      this.tabBarElement.style.display = "flex";
    }
    this.paymentGatewaysService.closeAllModals();
    this.loginPopupsService.closeFirebaseModalLogin();
    this.loginPopupsService.closeConfirmationLoginModal();
    this.loginPopupsService.closeMobileMagicLoginAlert();
    this.addressService.closeAddressModals();
    this.orderNowModalPopupsService.closeFirebaseRecaptchaModal();
    this.orderNowModalPopupsService.closeNewAddressModal();
    this.orderNowModalPopupsService.closeSettingsModal();
    this.calculateFinalPriceService.closeProductDetailsModal();
    if (this.selectArtistModal) {
      this.selectArtistModal.dismiss();
      this.selectArtistModal = null;
    }
    if (this.selectedInvoiceModal) {
      this.selectedInvoiceModal.dismiss();
      this.selectedInvoiceModal = null;
    }

    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.pricelistsSub) {
      this.pricelistsSub.unsubscribe();
    }
    if (this.currentStoreSub) {
      this.currentStoreSub.unsubscribe();
    }
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    if (this.tempStoreSub) {
      this.tempStoreSub.unsubscribe();
    }

    if (this.tempGroupSub) {
      this.tempGroupSub.unsubscribe();
    }
    if (this.migrationCustomerIdSub) {
      this.migrationCustomerIdSub.unsubscribe();
    }
    this.currentStoreId = null;
    this.languagePath = null;
    this.currentStoreId = null;
    this.languagePath = null;
    this.swipeAnimationChecked = false;
    this.swipeInfoAnimation = null;
    this.swipeInfoAnimationInstance = null;
    this.catalog = null;
    this.categories = null;
    this.loginState = null;

    this.store.dispatch(new CatalogActions.ClearCatalog());
    this.store.dispatch(new CatalogActions.SetAllCategories(null));
    this.store.dispatch(new HyperCategoriesActions.ClearAllCategories());
    this.store.dispatch(new HyperCategoriesActions.ClearHyperCategories());
    this.store.dispatch(
      new HyperCategoriesActions.SetCurrentHyperCategory(null)
    );

    console.log("catalog destroyed");
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.timeouts && this.timeouts.length) {
      this.timeouts.forEach((timeout) => {
        clearTimeout(timeout);
      });
    }
    this.timeouts = [];
  }

  async openLocation() {
    this.locationModal = await this.modalCtrl.create({
      component: ReservationLocationPreviewPage,
      animated: true,
      backdropDismiss: false,
      componentProps: {
        selectedStore: this.store_info,
      },
      cssClass: "fullScreenModal",
    });
    await this.locationModal.present();

    await this.locationModal.onDidDismiss().then((data) => {
      this.locationModal = null;
    });
  }

  async openInfo() {
    this.storeInfoModal = await this.modalCtrl.create({
      component: StoreSchedulePage,
      animated: true,
      backdropDismiss: false,
      componentProps: {
        reservationAppStore: this.store_info,
      },
      cssClass: "fullScreenModal",
    });
    await this.storeInfoModal.present();
    await this.storeInfoModal.onDidDismiss().then(() => {
      this.storeInfoModal = null;
    });
  }

  ngOnDestroy() {
    this.paymentGatewaysService.closeAllModals();
    this.loginPopupsService.closeFirebaseModalLogin();
    this.loginPopupsService.closeConfirmationLoginModal();
    this.loginPopupsService.closeMobileMagicLoginAlert();
    this.addressService.closeAddressModals();
    this.orderNowModalPopupsService.closeFirebaseRecaptchaModal();
    this.orderNowModalPopupsService.closeNewAddressModal();
    this.orderNowModalPopupsService.closeSettingsModal();
    this.calculateFinalPriceService.closeProductDetailsModal();

    if (this.selectArtistModal) {
      this.selectArtistModal.dismiss();
      this.selectArtistModal = null;
    }
    if (this.selectedInvoiceModal) {
      this.selectedInvoiceModal.dismiss();
      this.selectedInvoiceModal = null;
    }

    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.pricelistsSub) {
      this.pricelistsSub.unsubscribe();
    }
    if (this.currentStoreSub) {
      this.currentStoreSub.unsubscribe();
    }
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    if (this.tempStoreSub) {
      this.tempStoreSub.unsubscribe();
    }

    if (this.tempGroupSub) {
      this.tempGroupSub.unsubscribe();
    }
    if (this.migrationCustomerIdSub) {
      this.migrationCustomerIdSub.unsubscribe();
    }

    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.migrationCustomerIdSub) {
      this.migrationCustomerIdSub.unsubscribe();
    }
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];

    if (this.locationModal) {
      this.locationModal.dismiss();
      this.locationModal = null;
    }

    if (this.storeInfoModal) {
      this.storeInfoModal.dismiss();
      this.storeInfoModal = null;
    }
  }
}
