import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  auditTime,
  debounceTime,
  distinctUntilChanged,
  first,
} from "rxjs/operators";
import * as _ from "lodash";
import * as CompleteOrderValidationActions from "../../../store/complete-order-validation/completeOrderValidation.actions";

import * as fromApp from "../../../store/app.reducer";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import {
  AlertController,
  ModalController,
  ToastController,
} from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import * as CartActions from "../../store/cart.actions";
import { LoginPopupsServiceService } from "src/app/services/login-popups-service/login-popups-service.service";
import { OrderNowPopupComponent } from "src/app/popups/order-now-popup/order-now-popup.component";

import { CalculateFinalPriceService } from "src/app/services/calculateFinalPrice/calculate-final-price.service";
import { SelectedAddressService } from "src/app/services/selectedAddress/selected-address.service";
import * as manuallyTriggerFinalPriceActions from "../../../store/manuallyTriggerFinalPriceCheckAddress/manuallyTriggerFinalPrice.actions";
import { FirebaseBrowserService } from "src/app/services/firebaseBrowser/firebase-browser.service";
import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";
import * as SelectedInvoiceActions from "../invoice/store/selectedInvoice.actions";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as DeliveryMethodAddressActions from "../../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import * as selectors from "../../../store/app.selectors";
import { InformationMessagesService } from "src/app/services/informationMessages/information-messages.service";
import { CartPageAlertsService } from "src/app/services/cartPageAlerts/cart-page-alerts.service";
import { AllergenModalComponent } from "src/app/popups/allergen-modal/allergen-modal.component";
import { NoLocationDineInModalComponent } from "src/app/popups/no-location-dine-in-modal/no-location-dine-in-modal.component";
import { CheckLocationDistanceService } from "src/app/services/checkLocationDistance/check-location-distance.service";
import moment from "moment-timezone";
import { OrderNowModalPopupsService } from "src/app/services/orderNowModalPopupsService/orderNowModalPopups.service";
import { ProductDetailsOperationsService } from "src/app/product-details/product-details-operations.service";
import { Capacitor } from "@capacitor/core";

declare let window: any;
@Component({
  selector: "app-cart-list",
  templateUrl: "./cart-list.component.html",
  styleUrls: ["./cart-list.component.scss"],
})
export class CartListComponent implements OnInit, OnDestroy {
  public cartProducts: any;
  public finalPriceLoading: boolean;
  public amountToMinimumOrder: any;
  public amountToMinimumOrderProgressValue: any;
  public cartPrices: any;
  public groupApp: any;
  public completeOrderValidations: any;
  public manualFinalPriceLoading: boolean = false;
  public checkAddressLoading: boolean = false;
  public preorderDaysLoading: boolean = false;
  public checkLocationDineInLoading: boolean = false;
  public cartInfoMessagesStore: any = {
    alert: null,
    inline: null,
    modal: null,
  };
  public cartInfoMessagesGroup: any = {
    alert: null,
    inline: null,
    modal: null,
  };
  public selectedAddress: any;
  public selectedLang: any;
  public storeInfo: any;
  public mo_base: any;
  public onlyAvailableForPreorderProductsById: any;
  private pendingOrders: any;
  private orderNowModal: any;
  private lastValidTimestamp: any = null;
  private activeCoupons: any;
  private subscriptions: Subscription[] = [];
  private couponAlertDisplayed = false;
  private finalPriceSuccess: boolean = false;
  private checkAddressSuccess: boolean = false;
  private infoShowDeliveryButton;
  private selectedPaymentMethodId: any;
  private newCustomer: any;
  private allergensModal: any;
  private currentUser: any;
  private showDeliveryButton: boolean;
  private showPickupButton: boolean;
  private showDineInButton: boolean;
  private stores: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private alertController: AlertController,
    private translateService: TranslateService,
    private loginPopupService: LoginPopupsServiceService,
    private modalController: ModalController,
    private toastController: ToastController,
    private firebaseBrowserService: FirebaseBrowserService,
    private analyticsService: AnalyticsServiceService,
    private dataStorageService: DataStorageService,
    private infoMessagesService: InformationMessagesService,
    private cartAlertsService: CartPageAlertsService,
    private checkLocationDistanceService: CheckLocationDistanceService,
    private orderNowModalPopupsService: OrderNowModalPopupsService,
    private productDetailsOperationsService: ProductDetailsOperationsService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (state && !_.isEqual(this.newCustomer, state.newCustomer)) {
            this.newCustomer = _.cloneDeep(state.newCustomer);
          }
          if (state && !_.isEqual(this.currentUser, state.user)) {
            this.currentUser = _.cloneDeep(state.user);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("cartPrices")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (
            state &&
            state.cartPrices &&
            !_.isEqual(this.cartPrices, state.cartPrices)
          ) {
            this.cartPrices = _.cloneDeep(state.cartPrices);
            if (this.cartPrices && this.cartPrices.amountToMinimumOrder) {
              this.amountToMinimumOrder = this.cartPrices.amountToMinimumOrder;
              this.calculateAmountToMinOrderProgressValue();
            } else if (
              this.cartPrices &&
              !this.cartPrices.amountToMinimumOrder
            ) {
              this.amountToMinimumOrder = 0;
              this.amountToMinimumOrderProgressValue = 0;
            }
            // console.log(this.cartPrices);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (
            state &&
            state.products &&
            !_.isEqual(this.cartProducts, state.products)
          ) {
            this.cartProducts = _.cloneDeep(state.products);
            this.calculateOnlyAvailableForPreorderProductsDebounced();
            // console.log(this.cartProducts);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
            this.checkCartInfoMessages();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cartActiveCoupons")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (state && !_.isEqual(this.activeCoupons, state.activeCoupons)) {
            this.activeCoupons = _.cloneDeep(state.activeCoupons);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
            this.mo_base = _.cloneDeep(
              _.find(state.groupApp.languages, {
                mo_base: true,
              })
            );

            if (!this.mo_base) {
              this.mo_base = "en";
            } else {
              this.mo_base = this.mo_base.code;
            }
            this.getPreorderDays(this.groupApp, state.selectedLangugage);
            // console.log(this.cartProducts);
          }
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLang, state.selectedLangugage)
          ) {
            this.selectedLang = _.cloneDeep(state.selectedLangugage);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.selectedAddress, this.selectedAddress)
          ) {
            this.selectedAddress = _.cloneDeep(state.selectedAddress);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("selectedPaymentMethodID")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.selectedPaymentMethodID &&
            !_.isEqual(
              state.selectedPaymentMethodID,
              this.selectedPaymentMethodId
            )
          ) {
            this.selectedPaymentMethodId = _.cloneDeep(
              state.selectedPaymentMethodID
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("pendingOrders")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (
            state &&
            state.pendingOrders &&
            !_.isEqual(state.pendingOrders, this.pendingOrders)
          ) {
            //filter orders that is in panding payment status for 30 minutes because there is a bug that not canceling order sometimes!
            const pendingOrdersFilter = _.filter(
              _.cloneDeep(state.pendingOrders) || [],
              (order) => {
                if (
                  !(
                    order &&
                    order.status === "pendingPayment" &&
                    order.timestamp &&
                    moment().diff(
                      moment(parseInt(order.timestamp.toString())),
                      "minutes"
                    ) > 35
                  )
                ) {
                  return order;
                }
              }
            );
            this.pendingOrders = pendingOrdersFilter;
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("disableFinalPrice")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
          ) {
            this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
            if (this.cartProducts) {
              this.calculateOnlyAvailableForPreorderProductsDebounced();
            }

            console.log(
              "final price loading from cart-list",
              this.finalPriceLoading
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("completeOrderValidation")
        .pipe(debounceTime(300))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.lastValidTimestamp, state.lastValidTimestamp)
          ) {
            this.lastValidTimestamp = _.cloneDeep(state.lastValidTimestamp);
            this.completeOrderValidations = _.cloneDeep(
              state.completeOrderValidations
            );

            this.store
              .select(selectors.getStoreInfo)
              .pipe(first())
              .subscribe((store) => {
                if (store && store.dineinGpsCheck) {
                  this.store
                    .select("deliveryMethodAddress")
                    .pipe(first())
                    .subscribe((state) => {
                      if (state && state.dineIn && !state.orderPickup) {
                        this.completeOrderValidationActions(true, store);
                      } else {
                        this.completeOrderValidationActions(null, null);
                      }
                    })
                    .unsubscribe();
                } else {
                  this.completeOrderValidationActions(null, null);
                }
              })
              .unsubscribe();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe(async (state) => {
          if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
            this.stores = _.cloneDeep(state.stores);
            this.showDeliveryButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (!store.disableOrderDelivery) {
                  return store;
                }
              })
            );

            if (!this.showDeliveryButton) {
              this.infoShowDeliveryButton =
                await this.orderNowModalPopupsService.checkToShowInfoDeliveryButton();

              this.infoShowDeliveryButton = !!this.infoShowDeliveryButton;
            }

            this.showPickupButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (!store.disableOrderPickup) {
                  return store;
                }
              })
            );
            console.log("pickup button", this.showPickupButton);

            this.showDineInButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (store.dinein) {
                  return store;
                }
              })
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("manuallyTriggerFinalPriceCheckAddress")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            (!_.isEqual(state.finalPriceSuccess, this.finalPriceSuccess) ||
              !_.isEqual(state.checkAddressSuccess, this.checkAddressSuccess))
          ) {
            // console.log("manuall trigger first if");
            this.finalPriceSuccess = _.cloneDeep(state.finalPriceSuccess);
            this.checkAddressSuccess = _.cloneDeep(state.checkAddressSuccess);
            if (this.finalPriceSuccess && this.checkAddressSuccess) {
              //console.log("manuall trigger second if");
              this.startValidationWritingState();
            }
          }
          if (
            state &&
            !_.isEqual(state.finalPriceLoading, this.manualFinalPriceLoading)
          ) {
            this.manualFinalPriceLoading = _.cloneDeep(state.finalPriceLoading);
          }
          if (
            state &&
            !_.isEqual(state.checkAddressLoading, this.checkAddressLoading)
          ) {
            this.checkAddressLoading = _.cloneDeep(state.checkAddressLoading);
          }
        })
    );
  }

  openLink = async (link) => {
    //open link external on new page
    if (Capacitor.isNativePlatform()) {
      window.open(link, "_system");
    } else {
      window.open(link, "_blank");
    }
  };

  showAllergensModal() {
    if (this.storeInfo.staticPagesAllergensActive) {
      if (this.allergensModal) {
        this.allergensModal.dismiss();
        this.allergensModal = null;
      }
      this.openAllergensModal();
    } else if (
      this.storeInfo.allergensDownloadFileActive &&
      this.groupApp &&
      this.storeInfo
    ) {
      this.openLink(
        `https://data.deliverymanager.gr/${this.groupApp.group}/${this.storeInfo.store_id}/allergens.pdf`
      );
    }
  }

  async openAllergensModal() {
    this.allergensModal = await this.modalController.create({
      component: AllergenModalComponent,
      cssClass: "allergensModal",
      backdropDismiss: false,
    });

    await this.allergensModal.present();

    this.allergensModal.onDidDismiss().then(() => {
      this.allergensModal = null;
    });
  }

  calculateAmountToMinOrderProgressValue() {
    this.store
      .select("deliveryMethodAddress")
      .pipe(first())
      .subscribe((state) => {
        if (state && state.validStores) {
          this.store
            .select(selectors.getStoreInfo)
            .pipe(first())
            .subscribe((storeInfo) => {
              if (storeInfo) {
                const findSelectedStore = _.find(state.validStores, {
                  store_id: storeInfo.store_id,
                });

                if (findSelectedStore) {
                  this.amountToMinimumOrderProgressValue =
                    100 -
                    (100 * this.amountToMinimumOrder) /
                      findSelectedStore.minOrder;
                } else {
                  this.amountToMinimumOrderProgressValue = null;
                }
              }
            });
        }
      });
  }

  completeOrderValidationActions(checkLocationForDineIn, storeInfo) {
    if (
      this.completeOrderValidations &&
      this.completeOrderValidations.length > 0
    ) {
      let noCartProducts = _.find(this.completeOrderValidations, {
        valid: false,
        id: "cart",
      });
      if (noCartProducts) {
        this.analyticsService.dmAnalyticsMessage(
          "complete_order_invalid_data",
          "no_cart_products",
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null
        );
        if (noCartProducts && noCartProducts.errorMessages) {
          this.presentErrorToast(noCartProducts.errorMessages[0], "danger");
        }
      } else {
        let noUser = _.find(this.completeOrderValidations, {
          valid: false,
          id: "customer-info",
          modal: "loginModal",
        });
        if (noUser) {
          this.analyticsService.dmAnalyticsMessage(
            "complete_order_invalid_data",
            "no_logged_in_user",
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null
          );
          console.log("no user");
          let element = document.getElementById("customer-info");
          if (element) {
            element.scrollIntoView({ behavior: "auto" });
          }

          if (
            this.groupApp &&
            this.groupApp.firebaseVerificationEnabled &&
            this.groupApp.webFirebase &&
            this.groupApp.webFirebase.apiKey &&
            this.firebaseBrowserService.getFireBaseApp() &&
            !Capacitor.isNativePlatform()
          ) {
            this.loginPopupService.closeConfirmationLoginModal();
            this.loginPopupService.closeFirebaseModalLogin();
            this.loginPopupService.presentFirebaseModalLogin(null, null);
            if (noUser.errorMessages && noUser.errorMessages.length > 0) {
              this.presentErrorToast(noUser.errorMessages[0], "danger");
            }
          } else if (
            this.groupApp &&
            this.groupApp.firebaseVerificationEnabled &&
            Capacitor.isNativePlatform()
          ) {
            this.loginPopupService.closeMobileMagicLoginAlert();
            this.loginPopupService.presentMobileMagicLoginAlert(
              this.newCustomer,
              this.groupApp
            );

            if (noUser.errorMessages && noUser.errorMessages.length > 0) {
              this.presentErrorToast(noUser.errorMessages[0], "danger");
            }
          }
        } else {
          console.log("else no user");
          let noDeliveryAddressMethods = _.find(this.completeOrderValidations, {
            valid: false,
            id: "delivery-method-address",
            modal: "selectDeliveryMethodModal",
          });
          if (noDeliveryAddressMethods) {
            this.analyticsService.dmAnalyticsMessage(
              "complete_order_invalid_data",
              "no_delivery_method_selected",
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null
            );
            console.log("no delivery");
            let element = document.getElementById("delivery-method-address");
            if (element) {
              element.scrollIntoView({ behavior: "auto" });
            }
            this.loginPopupService.closeConfirmationLoginModal();
            this.loginPopupService.closeFirebaseModalLogin();
            this.openOrderNowModal();
            if (
              noDeliveryAddressMethods.errorMessages &&
              noDeliveryAddressMethods.errorMessages.length > 0
            ) {
              this.presentErrorToast(
                noDeliveryAddressMethods.errorMessages[0],
                "danger"
              );
            }
          } else if (checkLocationForDineIn && storeInfo) {
            this.checkForLocationDineIn(storeInfo);
          } else {
            this.completeOrderSecondPartValidationActions();
          }
        }
      }
    }
  }

  async checkForLocationDineIn(storeInfo) {
    let distance: any;
    this.checkLocationDineInLoading = true;
    const distance_object: any =
      await this.checkLocationDistanceService.calculateDistanceFromGps(
        {
          lat: storeInfo.mapCenterStoreLat,
          lng: storeInfo.mapCenterStoreLng,
        },
        false
      );
    this.checkLocationDineInLoading = false;
    console.log("distance_object", distance_object);
    if (distance_object && distance_object.distance) {
      distance = distance_object.distance;
    }

    if (distance) {
      if (distance <= 100) {
        this.completeOrderSecondPartValidationActions();
      } else {
        this.openNoLocationDineInModal(
          "popups.no-location-dine-in-modal.you-are-out-of-store"
        );
      }
    } else {
      this.openNoLocationDineInModal(
        this.translateService.instant(
          "popups.no-location-dine-in-modal.we-have-to-access-your-location-to-continue-for-dine-in"
        )
      );
    }
  }

  async openNoLocationDineInModal(message) {
    const modal = await this.modalController.create({
      component: NoLocationDineInModalComponent,
      cssClass: "no-location-dine-in-modal",
      animated: true,
      componentProps: {
        orderNowModal: false,
        completeOrder: true,
        message: message,
      },
      backdropDismiss: false,
    });

    await modal.present();
    modal.onDidDismiss().then((data) => {
      if (data && data.data === "success_complete_order") {
        this.completeOrderSecondPartValidationActions();
      }
    });
  }

  completeOrderSecondPartValidationActions() {
    let requiredInvoice = _.find(this.completeOrderValidations, {
      valid: false,
      id: "invoice",
      isRequired: true,
    });
    if (requiredInvoice) {
      this.analyticsService.dmAnalyticsMessage(
        "complete_order_invalid_data",
        "no_invoice_selected",
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
      console.log("required invoice");
      let element = document.getElementById("invoice-cart");
      if (element) {
        element.scrollIntoView({ behavior: "auto" });
      }
      this.presentErrorToast(requiredInvoice.errorMessages[0], "danger");
      this.store.dispatch(
        new SelectedInvoiceActions.SetInvoiceRequiredOpenModal(false)
      );
      setTimeout(() => {
        this.store.dispatch(
          new SelectedInvoiceActions.SetInvoiceRequiredOpenModal(true)
        );
      }, 300);
    } else {
      let requiredCustomOptionsObject = _.find(this.completeOrderValidations, {
        valid: false,
        id: "cart-custom-options",
      });

      console.log("requiredCustomOptionsObject", requiredCustomOptionsObject);

      if (requiredCustomOptionsObject) {
        if (
          requiredCustomOptionsObject &&
          requiredCustomOptionsObject.errorMessages &&
          requiredCustomOptionsObject.errorMessages.length > 0 &&
          ((requiredCustomOptionsObject.isRequiredOptions &&
            requiredCustomOptionsObject.isRequiredOptions.length) ||
            (requiredCustomOptionsObject.minOrderOptions &&
              requiredCustomOptionsObject.minOrderOptions.length))
        ) {
          let element = document.getElementById(
            "cart-custom-options-no-quantity"
          );
          if (element) {
            element.scrollIntoView({ behavior: "auto" });
          }

          const notValidOption =
            requiredCustomOptionsObject.isRequiredOptions &&
            requiredCustomOptionsObject.isRequiredOptions[0]
              ? requiredCustomOptionsObject.isRequiredOptions[0]
              : requiredCustomOptionsObject.minOrderOptions[0];
          this.presentErrorToast(
            "*" +
              (notValidOption.title &&
              (!notValidOption.translations ||
                (notValidOption.translations &&
                  !notValidOption.translations.title) ||
                (notValidOption.translations &&
                  notValidOption.translations.title &&
                  !notValidOption.translations.title[this.selectedLang]))
                ? notValidOption.title
                : notValidOption.translations &&
                  notValidOption.translations.title &&
                  notValidOption.translations.title[this.selectedLang]
                ? notValidOption.translations.title[this.selectedLang]
                : "") +
              ": " +
              this.translateService.instant(
                requiredCustomOptionsObject.errorMessages[0]
              ),
            "danger"
          );
        }
      } else {
        let otherValidationObject = _.find(this.completeOrderValidations, {
          valid: false,
        });
        if (
          otherValidationObject &&
          otherValidationObject.errorMessages &&
          otherValidationObject.errorMessages.length > 0
        ) {
          this.analyticsService.dmAnalyticsMessage(
            "complete_order_invalid_data",
            otherValidationObject.errorMessages[0],
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null
          );
          //Other validations
          if (otherValidationObject.id === "delivery-info") {
            let element = document.getElementById("delivery-info");
            if (element) {
              element.scrollIntoView({ behavior: "auto" });
            }
          }

          this.presentErrorToast(
            otherValidationObject.errorMessages[0],
            "danger"
          );
        } else {
          let activeCouponsValidation = _.find(this.completeOrderValidations, {
            id: "active-coupons",
          });
          if (
            activeCouponsValidation &&
            activeCouponsValidation.openActiveCouponAlert &&
            !this.couponAlertDisplayed &&
            !this.allProductsArePromos()
          ) {
            this.activeCouponAlert();
            this.couponAlertDisplayed = true;
          } else {
            if (
              this.pendingOrders &&
              this.pendingOrders.length &&
              this.pendingOrders.length > 0
            ) {
              this.pendingOrderAlert();
            } else {
              if (this.selectedPaymentMethodId) {
                if (
                  this.selectedPaymentMethodId === "cash" ||
                  this.selectedPaymentMethodId === "mobilePOS"
                ) {
                  this.cartAlertsService.presentSendOrderModal();
                } else {
                  this.cartAlertsService.presentSendOrderAndPayModal(
                    this.currentUser
                  );
                }
              }
            }
          }
        }
      }
    }
  }

  allProductsArePromos() {
    const foundProductWithoutPromo = _.find(this.cartProducts, (product) => {
      if (!product.promo) {
        return product;
      }
    });

    if (!foundProductWithoutPromo) {
      return true;
    } else {
      return false;
    }
  }

  checkCartInfoMessages() {
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe((state) => {
        if (state && state.groupApp) {
          const cartInfoMessagesGroup = _.cloneDeep(
            this.infoMessagesService.checkForMobileOrderInfoMessagesGroupApp(
              _.cloneDeep(state.groupApp),
              _.cloneDeep(this.storeInfo),
              "cart"
            )
          );
          const cartInfoMessagesStore = _.cloneDeep(
            this.infoMessagesService.checkForMobileOrderInfoMessagesStore(
              _.cloneDeep(this.storeInfo),
              "cart"
            )
          );

          this.cartInfoMessagesGroup.alert = _.cloneDeep(
            _.filter(cartInfoMessagesGroup, (infoMessage) => {
              if (
                infoMessage &&
                infoMessage.view_type &&
                _.includes(infoMessage.view_type, "alert")
              ) {
                return infoMessage;
              }
            })
          );
          this.cartInfoMessagesStore.alert = _.cloneDeep(
            _.filter(cartInfoMessagesStore, (infoMessage) => {
              if (
                infoMessage &&
                infoMessage.view_type &&
                _.includes(infoMessage.view_type, "alert")
              ) {
                return infoMessage;
              }
            })
          );

          this.cartInfoMessagesGroup.inline = _.cloneDeep(
            _.filter(cartInfoMessagesGroup, (infoMessage) => {
              if (
                infoMessage &&
                infoMessage.view_type &&
                _.includes(infoMessage.view_type, "inline")
              ) {
                return infoMessage;
              }
            })
          );
          this.cartInfoMessagesStore.inline = _.cloneDeep(
            _.filter(cartInfoMessagesStore, (infoMessage) => {
              if (
                infoMessage &&
                infoMessage.view_type &&
                _.includes(infoMessage.view_type, "inline")
              ) {
                return infoMessage;
              }
            })
          );

          this.cartInfoMessagesGroup.modal = _.cloneDeep(
            _.filter(cartInfoMessagesGroup, (infoMessage) => {
              if (
                infoMessage &&
                infoMessage.view_type &&
                _.includes(infoMessage.view_type, "modal")
              ) {
                return infoMessage;
              }
            })
          );
          this.cartInfoMessagesStore.modal = _.cloneDeep(
            _.filter(cartInfoMessagesStore, (infoMessage) => {
              if (
                infoMessage &&
                infoMessage.view_type &&
                _.includes(infoMessage.view_type, "modal")
              ) {
                return infoMessage;
              }
            })
          );

          let mo_base = _.cloneDeep(
            _.find(state.groupApp.languages, {
              mo_base: true,
            })
          );

          if (!mo_base) {
            mo_base = "en";
          }

          _.each(this.cartInfoMessagesStore.alert, (infoMessage) => {
            this.showInfoMessageAlert(
              infoMessage,
              state.selectedLangugage,
              mo_base
            );
          });

          _.each(this.cartInfoMessagesGroup.alert, (infoMessage) => {
            this.showInfoMessageAlert(
              infoMessage,
              state.selectedLangugage,
              mo_base
            );
          });

          //TO_DO HANDLE MODAL TYPE MESSAGES
        }
      })
      .unsubscribe();
  }

  showInfoMessageAlert(infoMessage, selectedLangugage, mo_base) {
    this.infoMessageAlert(infoMessage, selectedLangugage, mo_base);
  }

  async infoMessageAlert(infoMessage, selectedLangugage, mo_base) {
    let alert = await this.alertController.create({
      message:
        (infoMessage && infoMessage.title
          ? infoMessage.title[selectedLangugage]
            ? infoMessage.title[selectedLangugage]
            : infoMessage.title[mo_base]
            ? infoMessage.title[mo_base]
            : infoMessage.title["en"]
          : null) +
        (infoMessage && infoMessage.description
          ? infoMessage.description[selectedLangugage]
            ? infoMessage.description[selectedLangugage]
            : infoMessage.description[mo_base]
            ? infoMessage.description[mo_base]
            : infoMessage.description["en"]
          : null),
      cssClass: "info-message-alert",
      buttons: ["OK"],
    });

    if (
      infoMessage &&
      infoMessage.auto_dismiss &&
      infoMessage.auto_dismiss_seconds
    ) {
      setTimeout(() => {
        if (alert) {
          alert.dismiss();
        }
      }, infoMessage.auto_dismiss_seconds * 1000);
    }

    await alert.present();

    await alert.onDidDismiss().then(() => {
      alert = null;
    });
  }

  activeCouponAlert() {
    this.cartAlertsService.openActivateCouponAlert(
      this.selectedPaymentMethodId,
      this.activeCoupons,
      this.pendingOrders,
      this.currentUser
    );
  }

  pendingOrderAlert() {
    this.cartAlertsService.openPendingOrderAlert(
      this.selectedPaymentMethodId,
      this.pendingOrders[0],
      false,
      this.currentUser
    );
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: this.translateService.instant(message),
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  async openOrderNowModal() {
    if (!this.showPickupButton && !this.showDineInButton) {
      this.orderNowModalPopupsService.checkAndOpenSettingsModal(
        "delivery",
        this.currentUser,
        this.selectedAddress,
        this.stores,
        this.storeInfo.store_id,
        false,
        false
      );
    } else if (!this.showPickupButton && !this.showDeliveryButton) {
      this.orderNowModalPopupsService.checkAndOpenSettingsModal(
        "dine-in",
        this.currentUser,
        this.selectedAddress,
        this.stores,
        this.storeInfo.store_id,
        false,
        false
      );
    } else if (!this.showDineInButton && !this.showDeliveryButton) {
      this.orderNowModalPopupsService.checkAndOpenSettingsModal(
        "take-away",
        this.currentUser,
        this.selectedAddress,
        this.stores,
        this.storeInfo.store_id,
        false,
        false
      );
    } else {
      this.orderNowModal = await this.modalController.create({
        component: OrderNowPopupComponent,
        cssClass: "orderNowPopupCss",
        backdropDismiss: true,
      });

      await this.orderNowModal.present();
      this.orderNowModal.onDidDismiss().then(() => {
        this.orderNowModal = null;
      });
    }
  }

  trackFnc(index, item) {
    return item.cart_id;
  }

  async clearCart() {
    const alert = await this.alertController.create({
      header: this.translateService.instant("alert"),

      message: this.translateService.instant(
        "cart.cart-components.cart-list.do-you-want-to-delete-cart"
      ),
      buttons: [
        {
          text: this.translateService.instant("cancel"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: this.translateService.instant("yes"),
          handler: () => {
            this.store.dispatch(new CartActions.ClearCart());
            if (navigator.cookieEnabled) {
              window.localStorage.removeItem("cart");
            }
          },
        },
      ],
    });

    await alert.present();
  }

  getPreorderDays(groupApp, selectedLanguage) {
    this.store
      .select(selectors.getStoreInfo)
      .pipe(first())
      .subscribe((store_info) => {
        if (store_info && store_info.preorderActive) {
          this.preorderDaysLoading = true;
          this.dataStorageService
            .getPreorderDays(
              store_info.store_id,
              groupApp.group,
              selectedLanguage
            )
            .subscribe({
              next: async (res: any) => {
                this.preorderDaysLoading = false;
                if (!res || !res.success) {
                  const alert = await this.alertController.create({
                    header: this.translateService.instant("alert"),
                    message:
                      res && res.comment_id
                        ? this.translateService.instant(res.comment_id)
                        : this.translateService.instant(
                            "errorMessages.general_error"
                          ),
                    backdropDismiss: false,
                    buttons: ["OK"],
                  });

                  await alert.present();
                } else {
                  this.store.dispatch(
                    new DeliveryMethodAddressActions.setPreorderResponse({
                      preorder_delivery_days: res.preorder_delivery_days,
                      preorder_pickup_days: res.preorder_pickup_days,
                    })
                  );
                }
              },
              error: async (error) => {
                this.preorderDaysLoading = false;
                const alert = await this.alertController.create({
                  header: this.translateService.instant("alert"),
                  message: this.translateService.instant(
                    "errorMessages.problem_reaching_server"
                  ),

                  buttons: ["OK"],
                });
                await alert.present();
                return error;
              },
            });
        }
      })
      .unsubscribe();
  }

  completeOrder() {
    console.log("complete order button pressed");
    this.analyticsService.dmAnalyticsMessage(
      "complete_order_button",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );

    this.store.dispatch(
      new manuallyTriggerFinalPriceActions.SetTriggerFinalPriceTimestamp(
        Date.now().toString()
      )
    );
    if (this.selectedAddress) {
      this.store.dispatch(
        new manuallyTriggerFinalPriceActions.SetTriggerCheckAddressTimestamp(
          Date.now().toString()
        )
      );
    } else {
      this.store.dispatch(
        new manuallyTriggerFinalPriceActions.SetCheckAddressSuccess(true)
      );
    }
  }

  startValidationWritingState() {
    this.store.dispatch(
      new CompleteOrderValidationActions.ClearCompleteOrderValidation()
    );
    this.store.dispatch(
      new CompleteOrderValidationActions.SetCompleteOrderValidation({
        lastValidTimestamp: Date.now(),
        completeOrderValidations: "",
      })
    );
  }

  private calculateOnlyAvailableForPreorderProductsDebounced = _.debounce(
    () => {
      this.calculateOnlyAvailableForPreorderProducts();
    },
    400
  );

  calculateOnlyAvailableForPreorderProducts() {
    if (this.cartProducts && this.cartProducts.length) {
      this.store
        .select("deliveryMethodAddress")
        .pipe(first())
        .subscribe(async (state) => {
          if (state) {
            const allCartProducts: any =
              await this.productDetailsOperationsService.calculateonlyForPreorderForCartProducts(
                _.cloneDeep(this.cartProducts),
                state.selectedPreorderDate
              );

            if (allCartProducts && allCartProducts.length) {
              const onlyAvailableForPreorderProductsById = {};
              _.each(allCartProducts, (cartPrd) => {
                this.onlyAvailableForPreorderActions(
                  cartPrd,
                  onlyAvailableForPreorderProductsById
                );
              });

              this.store.dispatch(
                new CartActions.SetOnlyAvailableForPreorderCartProducts(
                  _.cloneDeep(onlyAvailableForPreorderProductsById)
                )
              );
            }
          }
        })
        .unsubscribe();
    }
  }

  onlyAvailableForPreorderActions(
    cart_product,
    onlyAvailableForPreorderProductsById
  ) {
    console.log("onlyAvailableForPreorderActions", cart_product);
    if (cart_product.onlyForPreorder) {
      onlyAvailableForPreorderProductsById[cart_product.product_id] = true;
    } else {
      onlyAvailableForPreorderProductsById[cart_product.product_id] = false;
    }
    if (cart_product && cart_product.options) {
      _.each(cart_product.options, (option) => {
        if (option.bundle && option.choices) {
          _.each(option.choices, (choice) => {
            if (
              choice &&
              choice.bundle === true &&
              choice.bundled_with_product_id &&
              choice.bundled_product
            ) {
              this.onlyAvailableForPreorderActions(
                choice.bundled_product,
                onlyAvailableForPreorderProductsById
              );
            }
          });
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.orderNowModal) {
      this.orderNowModal.dismiss();
      this.orderNowModal = null;
    }

    if (this.allergensModal) {
      this.allergensModal.dismiss();
      this.allergensModal = null;
    }

    this.cartAlertsService.closeSendOrderAndPayModal();
    this.cartAlertsService.closeSendOrderModal();
    this.cartAlertsService.closeActivateCouponAlert();

    this.store.dispatch(
      new manuallyTriggerFinalPriceActions.SetTriggerFinalPriceTimestamp(null)
    );
    this.store.dispatch(
      new manuallyTriggerFinalPriceActions.SetFinalPriceSuccess(false)
    );
    this.store.dispatch(
      new manuallyTriggerFinalPriceActions.SetCheckAddressSuccess(false)
    );
    this.store.dispatch(
      new CompleteOrderValidationActions.ClearCompleteOrderValidation()
    );
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
