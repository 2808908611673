<div
  *ngIf="
    option &&
    (!option.bundle_options ||
      !option.bundle_options.displayBundledProductsByPosition ||
      (option.bundle_options &&
        option.bundle_options.load_category_choice_products &&
        option.bundle_options.choiceCategoriesProducts &&
        option.bundle_options.choiceCategoriesProducts.length)) &&
    option.choices &&
    option.choices.length
  "
>
  <span
    *ngIf="
      categoriesByCategoryIdWithProducts &&
      (categoriesByCategoryIdWithProducts | json) != '{}'
    "
  >
    <div
      *ngFor="
        let category of categoriesByCategoryIdWithProductsArray;
        trackBy: trackCategories
      "
    >
      <ion-item
        class="categoryItem"
        *ngIf="
          category &&
          categoriesByCategoryIdWithProductsArray.length > 1 &&
          option &&
          option.choices &&
          option.choices.length >= 10 &&
          (!option.bundle_options ||
            !option.bundle_options.do_not_show_bundle_products_category_names)
        "
        lines="full"
      >
        <ion-row style="width: 100%">
          <ion-col size="12">
            <ion-text color="primary">
              <strong style="font-size: medium">{{ category.name }}</strong>
            </ion-text>
            <div>
              <app-description
                [description]="category.description"
                [three_lines_description]="false"
                [imageBrowser]="false"
                [noReadMoreButton]="true"
              ></app-description>
            </div>
          </ion-col>
        </ion-row>
      </ion-item>
      <span *ngFor="let choice of category.choices; trackBy: trackFn">
        <app-bundle-radio-choice
          *ngIf="!checkForImage(choice)"
          [option_id]="option_id"
          [choice_id]="choice.choice_id"
          [bundleMode]="bundleMode"
          [selectedProductId]="selectedProductId"
          [selectedChoiceId]="selectedChoiceId"
          [groupStoreData]="groupStoreData"
          [autoSelectChoice]="autoSelectChoice"
          [choicePreselectedId]="choicePreselectedId"
          (selectChoiceEv)="onChoiceSelectedChange($event)"
        ></app-bundle-radio-choice>
      </span>
    </div>
  </span>
</div>

<div
  *ngIf="
    option &&
    option.bundle_options &&
    option.bundle_options.displayBundledProductsByPosition &&
    (!option.bundle_options ||
      !option.bundle_options.load_category_choice_products ||
      !option.bundle_options.choiceCategoriesProducts ||
      !option.bundle_options.choiceCategoriesProducts.length) &&
    option.choices &&
    option.choices.length
  "
>
  <div *ngFor="let choice of option.choices; trackBy: trackFn">
    <app-bundle-radio-choice
      *ngIf="!checkForImage(choice)"
      [option_id]="option_id"
      [choice_id]="choice.choice_id"
      [bundleMode]="bundleMode"
      [selectedProductId]="selectedProductId"
      [selectedChoiceId]="selectedChoiceId"
      [groupStoreData]="groupStoreData"
      [autoSelectChoice]="autoSelectChoice"
      [choicePreselectedId]="choicePreselectedId"
      (selectChoiceEv)="onChoiceSelectedChange($event)"
    ></app-bundle-radio-choice>
  </div>
</div>
