<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      <ion-text style="font-size: medium">
        {{ "popups.send-order-popup.order-info" | translate }}</ion-text
      >
    </ion-title>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content
  scrollEvents="false"
  scrollX="false"
  [scrollY]="createOrderModalInfoMessage ? true : false"
>
  <ion-grid>
    <ion-item lines="none" *ngIf="dineIn || orderPickup || selectedAddress">
      <ion-label class="ion-text-wrap">
        <div *ngIf="orderPickup == true && dineIn == false && selectedLanguage">
          <strong>
            {{ "popups.send-order-popup.store-pickup-take-away" | translate }}
          </strong>
          <div
            style="font-size: small; font-weight: bold"
            *ngIf="store_info && !store_info.doNotShowStoreNameOrderPickup"
          >
            <ion-text>
              {{
                store_info.store_name_mobileorder &&
                store_info.store_name_mobileorder[selectedLanguage]
                  ? store_info.store_name_mobileorder[selectedLanguage]
                  : store_info.store_name_langs &&
                    store_info.store_name_langs[selectedLanguage]
                  ? store_info.store_name_langs[selectedLanguage]
                  : store_info.store_name_langs &&
                    store_info.store_name_langs.el
                  ? store_info.store_name_langs.el
                  : store_info.store_name
                  ? store_info.store_name
                  : ""
              }}
            </ion-text>
          </div>
          <div
            style="font-size: small"
            *ngIf="
              store_info &&
              store_info.address &&
              store_info &&
              !store_info.doNotShowStoreNameOrderPickup
            "
          >
            {{ store_info.address }}
          </div>

          <div *ngIf="carTakeAwayPlateNumber">
            {{
              "popups.send-order-popup.pick-up-with-car-plate-number"
                | translate
            }}: <strong>{{ carTakeAwayPlateNumber }}</strong>

            <div *ngIf="carTakeAwayComments">
              {{ carTakeAwayComments }}
            </div>
          </div>
        </div>
        <div *ngIf="orderPickup == false && dineIn == false && selectedAddress">
          <strong>
            {{ "popups.send-order-popup.delivery" | translate }}
          </strong>

          <div
            *ngIf="
              selectedAddress.fixed_address_id &&
              selectedAddress.fixed_address_type &&
              selectedAddress.fixed_address_type === 'meeting_point'
            "
          >
            <div style="font-weight: bold">
              {{ "delivery-spot" | translate }}
            </div>

            <div *ngIf="selectedAddress.fixed_address_name">
              <ion-text>{{ selectedAddress.fixed_address_name }}</ion-text>
            </div>
          </div>

          <p>
            {{ selectedAddress.formatted_address }}
          </p>
        </div>
        <div *ngIf="orderPickup == false && dineIn == true">
          <strong>
            {{ "popups.send-order-popup.dine-in" | translate }}
          </strong>
        </div>
        <div
          *ngIf="orderPickup == false && dineIn == true && selectedDineInTable"
        >
          <strong> {{ "popups.send-order-popup.table" | translate }}: </strong>
          {{ selectedDineInTable.name }}
        </div>

        <div
          style="line-height: 1.2"
          *ngIf="
            store_info &&
            store_info.preorderActive &&
            !selectedPreorderDate &&
            !dineIn
          "
        >
          <strong
            *ngIf="
              (orderPickup == null && dineIn == null) ||
              (orderPickup == false && dineIn == false)
            "
          >
            {{ "popups.send-order-popup.delivery-preorder" | translate }}:
          </strong>
          <strong
            *ngIf="
              (orderPickup == null && dineIn == null) ||
              (orderPickup == true && dineIn == false)
            "
          >
            {{ "popups.send-order-popup.takeaway-preorder" | translate }}:
          </strong>
          <ion-text style="font-size: small">
            {{
              selectedPreorderDate &&
              selectedPreorderDate.hour &&
              selectedPreorderDate.date
                ? selectedPreorderDate.date +
                  (selectedPreorderDate.hour &&
                  ((orderPickup == false &&
                    dineIn == false &&
                    !selectedPreorderDate.preorderAcceptDisableDeliveryTime) ||
                    (orderPickup == true &&
                      dineIn == false &&
                      !selectedPreorderDate.preorderAcceptDisablePickupTime) ||
                    (orderPickup == false && dineIn == true))
                    ? " " + selectedPreorderDate.hour
                    : "")
                : orderPickup == false && checkStoreOnlineInfo.deliveryTime
                ? !deliveryTimeIsNumber
                  ? checkStoreOnlineInfo.deliveryTime
                  : checkStoreOnlineInfo.deliveryTime +
                    " " +
                    ("minutes" | translate)
                : orderPickup == true && checkStoreOnlineInfo.pickupDeliveryTime
                ? !pickUpTimeIsNumber
                  ? checkStoreOnlineInfo.pickupDeliveryTime
                  : checkStoreOnlineInfo.pickupDeliveryTime +
                    " " +
                    ("minutes" | translate)
                : ""
            }}
          </ion-text>

          <ion-text
            *ngIf="
              checkStoreOnlineInfo &&
              ((orderPickup == null && dineIn == null) ||
                (orderPickup != null && !checkStoreOnlineInfo.deliveryTime))
            "
          >
            {{ "delivery-method-address.now" | translate }}
          </ion-text>
        </div>
        <div
          *ngIf="
            selectedPreorderDate &&
            selectedPreorderDate.date &&
            selectedPreorderDate.hour &&
            orderPickup == false &&
            dineIn == false &&
            selectedAddress
          "
        >
          <strong>
            {{ "popups.send-order-popup.delivery-preorder" | translate }}:
          </strong>

          {{ selectedPreorderDate.date }}
          {{
            selectedPreorderDate.hour &&
            ((orderPickup == false &&
              dineIn == false &&
              !selectedPreorderDate.preorderAcceptDisableDeliveryTime) ||
              (orderPickup == true &&
                dineIn == false &&
                !selectedPreorderDate.preorderAcceptDisablePickupTime) ||
              (orderPickup == false && dineIn == true))
              ? " " + selectedPreorderDate.hour
              : ""
          }}
        </div>

        <div
          *ngIf="
            selectedPreorderDate &&
            selectedPreorderDate.date &&
            selectedPreorderDate.hour &&
            orderPickup == true &&
            dineIn == false
          "
        >
          <strong>
            {{ "popups.send-order-popup.takeaway-preorder" | translate }}:
          </strong>

          {{ selectedPreorderDate.date }}
          {{
            selectedPreorderDate.hour &&
            ((orderPickup == false &&
              dineIn == false &&
              !selectedPreorderDate.preorderAcceptDisableDeliveryTime) ||
              (orderPickup == true &&
                dineIn == false &&
                !selectedPreorderDate.preorderAcceptDisablePickupTime) ||
              (orderPickup == false && dineIn == true))
              ? " " + selectedPreorderDate.hour
              : ""
          }}
        </div>

        <div
          style="font-size: small; line-height: 1.4"
          *ngFor="let coupon of selectedCoupons"
        >
          <strong
            >{{ coupon.description
            }}{{
              coupon.discount ||
              (coupon.discount_percent && coupon.discount_fixed)
                ? ":"
                : ""
            }}</strong
          >
          <span
            *ngIf="
              coupon.discountType !== 'percent' &&
              coupon.discountType !== 'combined' &&
              coupon.discount
            "
          >
            -{{ coupon.discount }}€
          </span>
          <span *ngIf="coupon.discountType === 'percent' && coupon.discount">
            -{{ coupon.discount }}%
          </span>
          <span
            *ngIf="
              coupon.discountType === 'combined' &&
              coupon.discount_percent &&
              coupon.discount_fixed
            "
          >
            -{{ coupon.discount_percent }}% & -{{ coupon.discount_fixed }}€
          </span>
          <div
            *ngIf="
              cartPrices.discount_amount &&
              coupon &&
              (coupon.discount || coupon.discount_fixed) &&
              coupon.discountType !== 'percent' &&
              coupon.discountType &&
              toFloat(coupon.discount || coupon.discount_fixed) >
                toFloat(cartPrices.discount_amount)
            "
          >
            <ion-text color="danger" style="font-weight: bold">
              {{
                "popups.send-order-popup.you-have-not-fully-used-you-coupon"
                  | translate
              }}
            </ion-text>
          </div>
        </div>

        <div *ngIf="cartPrices">
          <strong>
            {{ "popups.send-order-popup.total-cost" | translate }}:
          </strong>

          {{ cartPrices.grand_total }}€
        </div>

        <div
          style="margin-top: 3px"
          *ngIf="
            ((groupApp && groupApp.pointsSystem) ||
              (currentUser && currentUser.admin)) &&
            currentOrderPoints
          "
        >
          <ion-badge>
            {{ "popups.send-order-popup.you-earn" | translate }}:
            {{ currentOrderPoints }}
            {{ "popups.send-order-popup.points" | translate }}
          </ion-badge>
        </div>
      </ion-label>
    </ion-item>

    <div *ngIf="createOrderModalInfoMessage">
      <ion-grid class="ion-text-center ion-no-padding">
        <img
          style="width: 100%"
          src="https://s3-eu-west-1.amazonaws.com/storegroupseditedbgimages/{{
            groupApp.group
          }}/info_messages/{{ createOrderModalInfoMessage.image }}"
        />
      </ion-grid>
      <ion-item
        class="infoMessageItem"
        lines="none"
        *ngIf="
          createOrderModalInfoMessage.title &&
          (createOrderModalInfoMessage.title[selectedLanguage] ||
            createOrderModalInfoMessage.title[mo_base] ||
            createOrderModalInfoMessage.title['en'])
        "
      >
        <ion-label class="ion-text-wrap">
          <div
            [innerHTML]="
              createOrderModalInfoMessage.title[selectedLanguage]
                ? createOrderModalInfoMessage.title[selectedLanguage]
                : createOrderModalInfoMessage.title[mo_base]
                ? createOrderModalInfoMessage.title[mo_base]
                : createOrderModalInfoMessage.title['en']
            "
          ></div>
        </ion-label>
      </ion-item>
    </div>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-button (click)="continueToPayment()">
          {{ "popups.send-order-popup.continue-to-payment" | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
