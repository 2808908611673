<ion-card
  color="deliveryMethodAddressButtons"
  (click)="selectChoice(choice.choice_id)"
  [disabled]="
    !choice.bundled_product.availableTotal ||
    !option.availableTotal ||
    !choice.availableTotal ||
    !(
      choice.bundled_product.product_categories_availability_check &&
      choice.bundled_product.availableTotal &&
      !disableForDeliveryMethodAddress
    ) ||
    (option.dependenciesVariable &&
      option.dependenciesVariable.length > 0 &&
      !option.dependentHidden &&
      !option.showDependent)
  "
  button="true"
  [ngClass]="{
    'line-through': !!(!option.availableTotal || !choice.availableTotal),
    'selected-item': selectedChoiceId === choice.choice_id
  }"
  *ngIf="choice && choice.bundled_product"
>
  <ion-item
    color="deliveryMethodAddressButtons"
    lines="none"
    [ngClass]="{
      'line-through': !!(!option.availableTotal || !choice.availableTotal)
    }"
  >
    <ion-label
      [class]="
        !choice.bundled_product.availableTotal ||
        !option.availableTotal ||
        !choice.availableTotal ||
        !(
          choice.bundled_product.product_categories_availability_check &&
          choice.bundled_product.availableTotal &&
          !disableForDeliveryMethodAddress
        ) ||
        (option.dependenciesVariable &&
          option.dependenciesVariable.length > 0 &&
          !option.dependentHidden &&
          !option.showDependent)
          ? 'ion-text-wrap opacityDisabled'
          : 'ion-text-wrap'
      "
      style="line-height: 1.2"
    >
      <div style="font-weight: bold">{{ choice.bundled_product.name }}</div>

      <div
        *ngIf="
          choice.bundled_product &&
          (choice.bundled_product.description ||
            choice.bundled_product.showOptionInDescription)
        "
        style="line-height: initial; padding-left: 0px"
      >
        <span class="fontSizeSmaller">
          <app-description
            *ngIf="
              (orderPickup == null && dineIn == null) ||
              (orderPickup == false && dineIn == false) ||
              (orderPickup == true &&
                dineIn == false &&
                !choice.bundled_product.showOptionInDescription_takeaway) ||
              (orderPickup == false &&
                dineIn == true &&
                !choice.bundled_product.showOptionInDescription_dinein)
            "
            [show_option_description]="
              choice.bundled_product.showOptionInDescription
            "
            [description]="choice.bundled_product.description"
            [three_lines_description]="
              (choice.bundled_product &&
                choice.bundled_product.options &&
                choice.bundled_product.options.length <= 1) ||
              option?.bundle_options?.selectBundledProductInstant
                ? true
                : false
            "
            [imageBrowser]="false"
            [noReadMoreButton]="true"
          >
          </app-description>

          <app-description
            *ngIf="
              orderPickup == true &&
              dineIn == false &&
              choice.bundled_product.showOptionInDescription_takeaway
            "
            [show_option_description]="
              choice.bundled_product.showOptionInDescription_takeaway
            "
            [description]="choice.bundled_product.description"
            [three_lines_description]="
              (choice.bundled_product &&
                choice.bundled_product.options &&
                choice.bundled_product.options.length <= 1) ||
              option?.bundle_options?.selectBundledProductInstant
                ? true
                : false
            "
            [imageBrowser]="false"
            [noReadMoreButton]="true"
          >
          </app-description>

          <app-description
            *ngIf="
              orderPickup == false &&
              dineIn == true &&
              choice.bundled_product.showOptionInDescription_dinein
            "
            [show_option_description]="
              choice.bundled_product.showOptionInDescription_dinein
            "
            [description]="choice.bundled_product.description"
            [three_lines_description]="
              (choice.bundled_product &&
                choice.bundled_product.options &&
                choice.bundled_product.options.length <= 1) ||
              option?.bundle_options?.selectBundledProductInstant
                ? true
                : false
            "
            [imageBrowser]="false"
            [noReadMoreButton]="true"
          >
          </app-description>
        </span>
      </div>

      <div>
        <ion-text
          class="item-text-wrap"
          *ngIf="choice && choice.bundled_product"
        >
          <span
            [ngClass]="{
              disabled: !!(
                !choice.bundled_product.availableTotal ||
                !option.availableTotal ||
                !choice.availableTotal ||
                !(
                  choice.bundled_product
                    .product_categories_availability_check &&
                  choice.bundled_product.availableTotal &&
                  !disableForDeliveryMethodAddress
                ) ||
                (option.dependenciesVariable &&
                  option.dependenciesVariable.length > 0 &&
                  !option.dependentHidden &&
                  !option.showDependent)
              ),
              'line-through ': !!(
                !option.availableTotal ||
                !choice.availableTotal ||
                !choice.bundled_product.availableTotal
              )
            }"
            ><ion-text color="primary" style="font-weight: bold">
              {{
                (choice.bundled_product.productTotal
                  .toString()
                  .trim()
                  .replace(",", ".") &&
                choice.bundled_product.productTotal
                  .toString()
                  .trim()
                  .replace(",", ".") !== "0.00"
                  ? choice.bundled_product.productTotal
                      .toString()
                      .trim()
                      .replace(",", ".")
                  : ""
                ) | number : "1.2-2"
              }}
              {{
                choice.bundled_product.productTotal
                  .toString()
                  .trim()
                  .replace(",", ".") &&
                choice.bundled_product.productTotal
                  .toString()
                  .trim()
                  .replace(",", ".") !== "0.00"
                  ? "€"
                  : ""
              }}
            </ion-text></span
          >
        </ion-text>
      </div>

      <div>
        <app-product-choice-tags
          [choiceItem]="choice"
          [optionItem]="option"
          [selectedProductPromo]="selectedProductPromo"
          [selectedPreorderDate]="selectedPreorderDate"
          [groupStoreData]="groupStoreData"
        >
        </app-product-choice-tags>
        <app-product-item-order-type-tags
          *ngIf="choice"
          [productItem]="choice.bundled_product"
          [orderPickup]="orderPickup"
          [dineIn]="dineIn"
        >
        </app-product-item-order-type-tags>
      </div>
    </ion-label>

    <!-- <ion-radio [value]="choice.choice_id"></ion-radio> -->
    <ion-thumbnail
      slot="end"
      *ngIf="
        image &&
        image.path &&
        choice &&
        choice.bundled_product &&
        !(
          choice.bundled_product.squareImageDesign &&
          !choice.bundled_product.noSquareImageDesignOnCatalog
        )
      "
      class="standardIonThumbnail"
    >
      <img
        style="border-radius: 10px"
        [class]="
          !choice.bundled_product.availableTotal ||
          !option.availableTotal ||
          !choice.availableTotal ||
          !(
            choice.bundled_product.product_categories_availability_check &&
            choice.bundled_product.availableTotal &&
            !disableForDeliveryMethodAddress
          ) ||
          (option.dependenciesVariable &&
            option.dependenciesVariable.length > 0 &&
            !option.dependentHidden &&
            !option.showDependent)
            ? 'opacityDisabled'
            : ''
        "
        [alt]="choice.bundled_product.name"
        loading="lazy"
        *ngIf="image"
        [src]="image.path"
      />
    </ion-thumbnail>

    <ion-thumbnail
      *ngIf="
        image &&
        image.path &&
        choice &&
        choice.bundled_product &&
        choice.bundled_product.squareImageDesign &&
        !choice.bundled_product.noSquareImageDesignOnCatalog
      "
      slot="end"
      [class]="
        groupStoreData &&
        groupStoreData.store_info &&
        groupStoreData.store_info.design_settings &&
        groupStoreData.store_info.design_settings.mobileorder_show_img_borders
          ? 'product-image-border squareIonThumbnail'
          : 'squareIonThumbnail'
      "
      style="z-index: 1"
    >
      <img style="border-radius: 7px" loading="lazy" [src]="image.path" />
    </ion-thumbnail>
  </ion-item>
</ion-card>
