import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { PreorderPopupComponent } from "src/app/popups/preorder-popup/preorder-popup.component";
import * as fromApp from "../../store/app.reducer";
import * as DeliveryMethodAddressActions from "../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import * as SelectedProductActions from "../../product-details/store/selectedProduct.actions";

@Injectable({
  providedIn: "root",
})
export class PreorderValidationServiceService {
  private preorderModal: any;
  constructor(
    private store: Store<fromApp.AppState>,
    private modalCtrl: ModalController
  ) {}

  preorderValidCalculationsChangeStore(
    preorderDayResponse,
    pickup,
    dineIn,
    selectedPreorderDate
  ) {
    console.log("preorder valid calculations change store");
    if (preorderDayResponse) {
      if (pickup == false && dineIn == false) {
        //changed to delivery
        console.log(
          "changed to delivery",
          selectedPreorderDate,
          preorderDayResponse.preorder_delivery_days
        );
        let selectedDateObject = _.find(
          preorderDayResponse.preorder_delivery_days,
          {
            day: selectedPreorderDate.date,
          }
        );
        console.log("find selected date", selectedDateObject);
        if (
          selectedDateObject &&
          selectedDateObject.time_slots &&
          selectedDateObject.time_slots.length
        ) {
          let findHour = _.includes(
            selectedDateObject.time_slots,
            selectedPreorderDate.hour
          );
          console.log("find selected hour", findHour);

          if (!findHour) {
            //delete selected preorder date
            this.store.dispatch(
              new DeliveryMethodAddressActions.setSelectedPreorderDate(null)
            );
            this.store.dispatch(
              new DeliveryMethodAddressActions.setPreorderResponse(null)
            );

            if (navigator.cookieEnabled) {
              let deliveryMethodAddressObj: any = localStorage.getItem(
                "deliveryMethodAddress"
              );
              if (deliveryMethodAddressObj) {
                deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
                deliveryMethodAddressObj.selectedPreorderDate = null;

                localStorage.setItem(
                  "deliveryMethodAddress",
                  JSON.stringify(deliveryMethodAddressObj)
                );
              }
            }
            this.presentPreorderModal(true);
          }
        } else {
          //delete selected preorder date
          this.store.dispatch(
            new DeliveryMethodAddressActions.setSelectedPreorderDate(null)
          );
          this.store.dispatch(
            new DeliveryMethodAddressActions.setPreorderResponse(null)
          );

          if (navigator.cookieEnabled) {
            let deliveryMethodAddressObj: any = localStorage.getItem(
              "deliveryMethodAddress"
            );
            if (deliveryMethodAddressObj) {
              deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
              deliveryMethodAddressObj.selectedPreorderDate = null;

              localStorage.setItem(
                "deliveryMethodAddress",
                JSON.stringify(deliveryMethodAddressObj)
              );
            }
          }
          this.presentPreorderModal(true);
        }
      } else if (pickup == true && dineIn == false) {
        //changed to take away
        let selectedDateObject = _.find(
          preorderDayResponse.preorder_pickup_days,
          {
            day: selectedPreorderDate.date,
          }
        );
        if (selectedDateObject && selectedDateObject.time_slots) {
          let findHour = _.includes(
            selectedDateObject.time_slots,
            selectedPreorderDate.hour
          );
          if (!findHour) {
            //delete selected preorder date
            this.store.dispatch(
              new DeliveryMethodAddressActions.setSelectedPreorderDate(null)
            );
            this.store.dispatch(
              new DeliveryMethodAddressActions.setPreorderResponse(null)
            );

            if (navigator.cookieEnabled) {
              let deliveryMethodAddressObj: any = localStorage.getItem(
                "deliveryMethodAddress"
              );
              if (deliveryMethodAddressObj) {
                deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
                deliveryMethodAddressObj.selectedPreorderDate = null;

                localStorage.setItem(
                  "deliveryMethodAddress",
                  JSON.stringify(deliveryMethodAddressObj)
                );
              }
            }
            this.presentPreorderModal(true);
          }
        } else {
          //delete selected preorder date
          this.store.dispatch(
            new DeliveryMethodAddressActions.setSelectedPreorderDate(null)
          );
          this.store.dispatch(
            new DeliveryMethodAddressActions.setPreorderResponse(null)
          );

          if (navigator.cookieEnabled) {
            let deliveryMethodAddressObj: any = localStorage.getItem(
              "deliveryMethodAddress"
            );
            if (deliveryMethodAddressObj) {
              deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
              deliveryMethodAddressObj.selectedPreorderDate = null;

              localStorage.setItem(
                "deliveryMethodAddress",
                JSON.stringify(deliveryMethodAddressObj)
              );
            }
          }
          this.presentPreorderModal(true);
        }
      } else if (pickup == false && dineIn == true) {
        //TO_DO IMPLEMENT FROM DINE
        //changed to dine in
      }
    }
  }

  preorderValidCalculations(
    pickup,
    dineIn,
    preorder_delivery_days,
    preorder_pickup_days,
    selectedPreorderDate
  ) {
    console.log("preorder valid calculations called");
    if (pickup == false && dineIn == false) {
      //changed to delivery
      console.log(
        "changed to delivery",
        selectedPreorderDate,
        preorder_delivery_days
      );
      let selectedDateObject = _.find(preorder_delivery_days, {
        day: selectedPreorderDate.date,
      });
      console.log("find selected date", selectedDateObject);
      if (
        selectedDateObject &&
        selectedDateObject.time_slots &&
        selectedDateObject.time_slots.length
      ) {
        let findHour = _.includes(
          selectedDateObject.time_slots,
          selectedPreorderDate.hour
        );
        console.log("find selected hour", findHour);

        if (!findHour) {
          //delete selected preorder date
          this.store.dispatch(
            new DeliveryMethodAddressActions.setSelectedPreorderDate(null)
          );
          this.store.dispatch(
            new DeliveryMethodAddressActions.setPreorderResponse(null)
          );

          if (navigator.cookieEnabled) {
            let deliveryMethodAddressObj: any = localStorage.getItem(
              "deliveryMethodAddress"
            );
            if (deliveryMethodAddressObj) {
              deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
              deliveryMethodAddressObj.selectedPreorderDate = null;

              localStorage.setItem(
                "deliveryMethodAddress",
                JSON.stringify(deliveryMethodAddressObj)
              );
            }
          }
          this.presentPreorderModal(true);
        }
      } else {
        //delete selected preorder date
        this.store.dispatch(
          new DeliveryMethodAddressActions.setSelectedPreorderDate(null)
        );
        this.store.dispatch(
          new DeliveryMethodAddressActions.setPreorderResponse(null)
        );

        if (navigator.cookieEnabled) {
          let deliveryMethodAddressObj: any = localStorage.getItem(
            "deliveryMethodAddress"
          );
          if (deliveryMethodAddressObj) {
            deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
            deliveryMethodAddressObj.selectedPreorderDate = null;

            localStorage.setItem(
              "deliveryMethodAddress",
              JSON.stringify(deliveryMethodAddressObj)
            );
          }
        }
        this.presentPreorderModal(true);
      }
    } else if (pickup == true && dineIn == false) {
      //changed to take away
      let selectedDateObject = _.find(preorder_pickup_days, {
        day: selectedPreorderDate.date,
      });
      if (selectedDateObject && selectedDateObject.time_slots) {
        let findHour = _.includes(
          selectedDateObject.time_slots,
          selectedPreorderDate.hour
        );
        if (!findHour) {
          //delete selected preorder date
          this.store.dispatch(
            new DeliveryMethodAddressActions.setSelectedPreorderDate(null)
          );
          this.store.dispatch(
            new DeliveryMethodAddressActions.setPreorderResponse(null)
          );

          if (navigator.cookieEnabled) {
            let deliveryMethodAddressObj: any = localStorage.getItem(
              "deliveryMethodAddress"
            );
            if (deliveryMethodAddressObj) {
              deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
              deliveryMethodAddressObj.selectedPreorderDate = null;

              localStorage.setItem(
                "deliveryMethodAddress",
                JSON.stringify(deliveryMethodAddressObj)
              );
            }
          }
          this.presentPreorderModal(true);
        }
      } else {
        //delete selected preorder date
        this.store.dispatch(
          new DeliveryMethodAddressActions.setSelectedPreorderDate(null)
        );
        this.store.dispatch(
          new DeliveryMethodAddressActions.setPreorderResponse(null)
        );

        if (navigator.cookieEnabled) {
          let deliveryMethodAddressObj: any = localStorage.getItem(
            "deliveryMethodAddress"
          );
          if (deliveryMethodAddressObj) {
            deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
            deliveryMethodAddressObj.selectedPreorderDate = null;

            localStorage.setItem(
              "deliveryMethodAddress",
              JSON.stringify(deliveryMethodAddressObj)
            );
          }
        }
        this.presentPreorderModal(true);
      }
    } else if (pickup == true && dineIn == true) {
      //TO_DO IMPLEMENT FROM DINE
      //changed to dine in
    }
  }

  presentPreorderModalFromCartButtons(fromCartButton) {
    if (!this.preorderModal) {
      this.preorderModal = true;
      this.showPreorderModal(false, false, fromCartButton);
    }
  }

  presentPreorderModal(showNotValidDateToast) {
    if (!this.preorderModal) {
      this.preorderModal = true;
      this.showPreorderModal(showNotValidDateToast, false, false);
    }
  }

  presentPreorderModalFromProductDetails() {
    if (!this.preorderModal) {
      this.preorderModal = true;
      this.showPreorderModal(false, true, false);
    }
  }

  async showPreorderModal(
    showNotValidDateToast,
    fromProductDetails,
    fromCartButtons
  ) {
    this.preorderModal = await this.modalCtrl.create({
      component: PreorderPopupComponent,
      cssClass: "preorderPopup",
      backdropDismiss: false,
      componentProps: {
        showNotValidDateToast: showNotValidDateToast,
        fromCartButtons: fromCartButtons,
        fromProductDetails: fromProductDetails,
      },
    });

    await this.preorderModal.present();
    this.preorderModal.onDidDismiss().then(() => {
      this.preorderModal = null;
      if (fromProductDetails) {
        //write to recalculate preorder product availability timestamp
        this.store.dispatch(
          new SelectedProductActions.SetRecalculateProductPreorderAvailability(
            Date.now().toString()
          )
        );
      }
    });
  }

  preorderModalIsOpen() {
    if (this.preorderModal) {
      return true;
    } else {
      return false;
    }
  }

  closePreorderModal() {
    if (this.preorderModal) {
      this.preorderModal.dismiss();
      this.preorderModal = null;
    }
  }
}
