<ion-grid style="height: 100%; width: 320px">
  <!-- <ion-row style="width: 100%"> </ion-row> -->

  <ion-row
    style="width: 100%; height: 100%"
    class="ion-align-items-center ion-justify-content-center"
  >
    <ion-col class="ion-align-self-center ion-text-center">
      <ion-list class="ion-no-margin ion-no-padding">
        <ion-grid
          class="ion-no-padding"
          style="margin-bottom: 25px"
          [ngClass]="{
            whiteIconBackground: !flattenedIconBackgroundFlag,
            flattenedIconBackground: flattenedIconBackgroundFlag,
            browserSizeLogo: windowDimensions && windowDimensions.width > 650,
            smallMobileSizeLogo:
              windowDimensions && windowDimensions.width < 350,
            mobileSizeLogo:
              windowDimensions &&
              windowDimensions.width <= 650 &&
              windowDimensions.width >= 350
          }"
        >
          <ion-img
            src="https://images.deliverymanager.gr/{{
              groupApp.group
            }}/appicon/{{ storeImage }}"
          ></ion-img
        ></ion-grid>
        <ion-grid
          *ngIf="
            groupApp &&
            groupApp.design_settings &&
            groupApp.design_settings.animation_loader
          "
          style="width: 140px; padding: 0; margin-bottom: 20px"
        >
          <ng-lottie [options]="animationOptions"></ng-lottie>
        </ion-grid>
        <ion-grid
          *ngIf="
            groupApp &&
            (!groupApp.design_settings ||
              !groupApp.design_settings.animation_loader)
          "
          style="height: 180px; padding: 0; margin-bottom: 20px"
        >
          <ion-row
            class="ion-align-items-center justify-content-center"
            style="width: 100%"
          >
            <ion-col size="12" class="ion-align-self-center">
              <ion-grid style="width: 120px">
                <ng-lottie [options]="animationOptions"></ng-lottie> </ion-grid
            ></ion-col>
          </ion-row>
        </ion-grid>

        <ion-progress-bar
          color="black"
          class="progressBar"
          [value]="progress ? progress / 100 : 0"
        >
        </ion-progress-bar>
        <!-- <p>
          <ion-text color="black">{{ progress ? progress : 0 }}%</ion-text>
        </p> -->
      </ion-list>
    </ion-col>
  </ion-row>
</ion-grid>

<ion-grid style="position: absolute; bottom: 10px; width: 100%">
  <ion-row style="width: 100%">
    <ion-col
      *ngIf="!groupApp?.active_reservation_app"
      class="ion-text-center ion-no-padding"
    >
      <!-- <ion-icon style="font-size: 80px;" slot="start" src="assets/deliveryManagerLogo/deliveryManager.svg">
    
      </ion-icon>
      <ion-thumbnail slot="start">
     
      </ion-thumbnail> -->

      <ion-grid style="max-width: 40px" class="ion-text-center ion-no-padding">
        <ion-thumbnail>
          <ng-lottie
            style="z-index: 100"
            (animationCreated)="animationCreated($event)"
            [options]="animationDeliveryManagerOptions"
          >
          </ng-lottie>
        </ion-thumbnail>
      </ion-grid>

      <ion-text style="font-size: small" color="black"
        >Powered by deliverymanager.gr</ion-text
      >
      <p style="margin: 0; font-size: small">
        <ion-text color="poweredByTextColor"> © DM SOFT IKE</ion-text>
      </p>
    </ion-col>

    <ion-col
      *ngIf="groupApp?.active_reservation_app"
      class="ion-text-center ion-no-padding"
    >
      <ion-text style="font-size: small" color="black"
        >Powered by kioskpos.gr</ion-text
      >
      <p style="margin: 0; font-size: small">
        <ion-text color="poweredByTextColor"> © DM SOFT IKE</ion-text>
      </p>
    </ion-col>
  </ion-row>
</ion-grid>
