import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import * as AuthActions from "../../auth/store/auth.actions";
import * as _ from "lodash";
import { catchError, retry, tap, timeout } from "rxjs/operators";
import * as CatalogActions from "../../catalog/store/catalog.actions";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DataStorageService {
  private developerMode: boolean;
  private stagePath: string;
  constructor(
    private http: HttpClient,
    private store: Store<fromApp.AppState>
  ) {
    this.store.select("developerMode").subscribe((state) => {
      if (state && !_.isEqual(this.developerMode, state.developerMode)) {
        this.developerMode = _.cloneDeep(state.developerMode);
        if (this.developerMode) {
          this.stagePath = "mov5dev";
        } else {
          this.stagePath = "mov5";
        }
      }
    });
  }

  fetchGroupApp(group) {
    console.log("group from fetch", group);
    return this.http
      .get("https://data.deliverymanager.gr/" + group + "/groupapp.json")
      .pipe(timeout(10000))
      .pipe(retry(2));
  }

  fetchTheme(group) {
    return this.http
      .get(
        "https://data.deliverymanager.gr/mobileorderthemes/" + group + ".json"
      )
      .pipe(timeout(10000))
      .pipe(retry(2));
  }

  fetchAllStores(group) {
    return this.http
      .get("https://data.deliverymanager.gr/" + group + "/stores.json")
      .pipe(timeout(10000))
      .pipe(retry(2));
  }

  checkStoreOnline(data) {
    return this.http
      .post(
        "https://n3jchx7l3wz62ay73snrjuoone0zqwsy.lambda-url.eu-west-1.on.aws",
        JSON.stringify(data)
      )
      .pipe(timeout(10000))
      .pipe(retry(2));
  }

  //Static Pages

  fetchStaticPages(store_id, group) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-get-static-pages",
        { store_id: store_id, group: group },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(2));
  }

  //Address APIS

  fetchFixedAddressData(fixed_address_id, group) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-get-fixed-address-data",
        { fixed_address_id: fixed_address_id, group: group },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        }
      )
      .pipe(timeout(10000));
  }

  fetchFixedPointsAddresses(group) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-fetch-fixed-addresses",
        { group: group },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        }
      )
      .pipe(timeout(10000));
  }

  // loyalty system
  fetchUserLoyaltyProducts(mo_token, data) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-loyaltyapp-fetch-customer-products",
        data,
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000));
  }

  fetchHallOfFame(mo_token) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-fetch-loyaltyapp-ranking",
        {},
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000));
  }

  createLoyaltyCoupon(loyalty_product_rule_id, use_type, mo_token, store_id) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-loyaltyapp-create-coupon",
        {
          loyalty_product_rule_id,
          use_type,
          store_id,
        },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000));
  }

  fetchGroupContests(group) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-fetch-contests",
        { group },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        }
      )
      .pipe(timeout(10000));
  }

  fetchCustomerDraws(mo_token, contest_id, winners_fileName, status) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-fetch-customer-draws",
        { contest_id, winners_fileName, status },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000));
  }

  handleImageUpload(fileName, directory) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-customer-upload-profile-image",
        {
          fileName: fileName,
          directory: directory,
        }
      )
      .pipe(retry(1));
  }

  createOrder(params, mo_token) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" + this.stagePath + "/createorder",
        JSON.stringify(params),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(20000));
  }

  changeOrderPaymentMethod(data, mo_token) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/changeOrderPaymentMethod",
        JSON.stringify(data),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000));
  }

  checkAutoCompleteAddres(
    street,
    street_number,
    postal_code,
    userLat,
    userLng,
    googleLat,
    googleLng,
    languageCode,
    googleMapsCountryCode,
    group
  ) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-check-autocomplete-address",
        JSON.stringify({
          street,
          street_number,
          postal_code,
          userLat,
          userLng,
          googleLat,
          googleLng,
          languageCode,
          googleMapsCountryCode,
          group,
        }),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(1));
  }

  getAddressFromCoordinates(
    gpsLat,
    gpsLng,
    languageCode,
    googleMapsCountryCode,
    group,
    postCodeForCheck
  ) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-get-address-from-coordinates",
        JSON.stringify({
          gpsLat,
          gpsLng,
          languageCode,
          googleMapsCountryCode,
          group,
          postCodeForCheck,
        }),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(1));
  }

  checkMarker(data) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-check-marker",
        JSON.stringify(data),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(1));
  }

  fetchCustomerFavoriteProducts(store_id, mo_token, migration_customer_id) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-fetch-customer-favorite-products",
        JSON.stringify({
          store_id,
          migration_customer_id,
        }),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(1));
  }

  fetchCustomerPreviousOrders(
    store_id,
    selectedLanguage,
    groupAppLanguages,
    mo_token,
    pickup,
    dinein,
    preorderData,
    dinein_table_id,
    vatBasedCustomerProductsActive,
    migration_customer_id
  ) {
    let baseLanguage: string = "";
    const langs = _.cloneDeep(groupAppLanguages);
    const moBaseLanguage = _.find(langs, { mo_base: true });
    if (moBaseLanguage && moBaseLanguage.code) {
      baseLanguage = moBaseLanguage.code;
    }
    let preorder = null;
    let preorder_day = null;
    let preorder_time = null;
    if (
      preorderData &&
      preorderData.preorder &&
      preorderData.preorder_day &&
      preorderData.preorder_time
    ) {
      preorder = true;
      preorder_day = preorderData.preorder_day;
      preorder_time = preorderData.preorder_time;
    }

    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-fetch-previous-orders-products",
        JSON.stringify({
          store_id,
          selectedLanguage,
          baseLanguage,
          pickup,
          dinein,
          preorder,
          preorder_day,
          preorder_time,
          dinein_table_id,
          vatBasedCustomerProductsActive,
          migration_customer_id,
        }),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(1));
  }

  handleFavoriteProduct(
    store_id,
    product_id,
    type,
    mo_token,
    migration_customer_id
  ) {
    console.log("params", store_id, product_id, mo_token, type);
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-handle-favorite-product",
        JSON.stringify({
          store_id,
          product_id,
          type,
          mo_token,
          migration_customer_id,
        }),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(retry(1));
  }

  checkFormattedAddress(
    group,
    userLat,
    userLng,
    languageCode,
    customer_id,
    address_id
  ) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-check-formatted-address",
        JSON.stringify({
          group,
          userLat,
          userLng,
          languageCode,
          customer_id,
          address_id,
        }),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(1));
  }

  moCheckCouponRuleId(obj) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mo_check_coupon_rule_id",

        JSON.stringify(obj),

        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(1));
  }

  moCheckAffiliateCouponCode(obj) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mo-check-affiliate-coupon-code",

        JSON.stringify(obj),

        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(1));
  }

  moCheckCouponCode(obj) {
    console.log("obj inside check coupon code");
    if (obj.coupon_rule_id) {
      return this.moCheckCouponRuleId(obj);
    } else if (obj.affiliate_coupon_code) {
      return this.moCheckAffiliateCouponCode(obj);
    } else {
      return this.http
        .post(
          "https://api.deliverymanager.gr/" +
            this.stagePath +
            "/mo-check-coupon-code",

          JSON.stringify(obj),

          {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
            }),
          }
        )
        .pipe(timeout(10000));
    }
  }

  sendAffiliateEmail(email_to, store_id, mo_token) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-affiliate-email-sharing",
        { email_to, store_id },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe();
  }

  //get the whole catalog (retries 3 times if failed)
  getCatalog(store_id, languagePath) {
    console.log(
      "https://cdn.deliverymanager.gr/" +
        store_id +
        "/" +
        languagePath +
        "/catalog.json"
    );
    return this.http
      .get(
        "https://cdn.deliverymanager.gr/" +
          store_id +
          "/" +
          languagePath +
          "/catalog.json"
      )

      .pipe(timeout(20000))
      .pipe(retry(2));
  }

  searchCatalog(selectedLanguage, store_id, search_term, search_categories) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-search-items",
        {
          selectedLanguage: selectedLanguage,
          store_id: store_id,
          search_term: search_term,
          search_categories: search_categories,
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(2));
  }

  getProductDetails(store_id, product_id, languagePath) {
    return this.http
      .get(
        " https://cdn.deliverymanager.gr/" +
          store_id +
          "/" +
          languagePath +
          "/" +
          product_id +
          ".json"
      )
      .pipe(timeout(10000))
      .pipe(retry(2));
  }

  fetchProductDetailsUpdatedData(
    store_id,
    group,
    product_id,
    language_code,
    orderPickup,
    dinein,
    preorderData
  ) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-fetch-product-updated-data",
        JSON.stringify({
          store_id,
          group,
          product_id,
          language_code,
          orderPickup,
          dinein,
          preorderData,
        }),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
          }),
        }
      )
      .pipe(timeout(20000))
      .pipe(retry(2));
  }

  getAllProductDetails(
    store_id,
    group,
    bundle_option_id,
    language_code,
    orderPickup,
    dineIn,
    root_product_id,
    enable_customer_pricelists,
    enable_customer_pricelists_mobileorder,
    customerPricelist,
    dinein_table_id,
    preorderData,
    parent_bundle_choice_of_root_product,
    selectedAddress
  ) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-fetch-option-bundle-choice-products",
        JSON.stringify({
          store_id,
          bundle_option_id,
          language_code,
          orderPickup,
          dineIn,
          root_product_id,
          enable_customer_pricelists,
          enable_customer_pricelists_mobileorder,
          customerPricelist,
          group,
          dinein_table_id,
          preorderData,
          parent_bundle_choice_of_root_product,
          selectedAddress,
        }),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
          }),
        }
      )
      .pipe(timeout(20000))
      .pipe(retry(2));
  }

  updateCustomerData(obj, mo_token) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/updateCustomerData",
        JSON.stringify(obj),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(20000))
      .pipe(retry(2));
  }

  get_user_data(
    mo_token,
    active_loyalty_app,
    customerDraws,
    migrating_points_from_group
  ) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/get-customer-data",
        { active_loyalty_app, customerDraws, migrating_points_from_group },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(20000))
      .pipe(retry(2));
  }

  getUserPricelist(mo_token, store_id, migration_customer_id) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-fetch-customer-prices",
        { store_id, migration_customer_id },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(2));
  }

  getUserOrders(mo_token, vatBasedCustomerProductsActive) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" + this.stagePath + "/getUserOrders",
        JSON.stringify({
          vatBasedCustomerProductsActive: vatBasedCustomerProductsActive,
        }),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(2));
  }

  fetchPreviousOrderProducts(
    store_id,
    order_id,
    selectedLanguage,
    groupAppLanguages,
    mo_token,
    pickup,
    dinein,
    preorderData,
    dinein_table_id,
    vatBasedCustomerProductsActive,
    migration_customer_id
  ) {
    let baseLanguage: string = "";
    const langs = _.cloneDeep(groupAppLanguages);
    const moBaseLanguage = _.find(langs, { mo_base: true });
    if (moBaseLanguage && moBaseLanguage.code) {
      baseLanguage = moBaseLanguage.code;
    }
    let preorder;
    let preorder_day;
    let preorder_time;
    if (
      preorderData &&
      preorderData.preorder &&
      preorderData.preorder_day &&
      preorderData.preorder_time
    ) {
      preorder = true;
      preorder_day = preorderData.preorder_day;
      preorder_time = preorderData.preorder_time;
    }
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-fetch-previous-order-products",
        JSON.stringify({
          store_id,
          order_id,
          selectedLanguage,
          baseLanguage,
          pickup,
          dinein,
          preorder,
          preorder_day,
          preorder_time,
          dinein_table_id,
          vatBasedCustomerProductsActive,
          migration_customer_id,
        }),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(1));
  }

  getUserOrderDetails(mo_token, order_id, store_id) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/getUserOrderDetails",
        JSON.stringify({ order_id: order_id, store_id: store_id }),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(2));
  }

  getDineInTables(store_id) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-fetch-dinein-tables",
        { store_id: store_id.toString() },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
          }),
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(2));
  }

  getPreorderDays(store_id, group, language_code) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-get-preorder-days",
        { store_id, group, language_code },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
          }),
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(2));
  }

  fetchCustomerPoint(mo_token, loyaltyCardLevelsActive) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/fetchcustomerpoints",
        JSON.stringify({ loyaltyCardLevelsActive: loyaltyCardLevelsActive }),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(2))
      .pipe(
        tap((resData: any) => {
          if (
            loyaltyCardLevelsActive &&
            resData &&
            resData.data &&
            resData.data.updatedCustomer &&
            !_.isEmpty(resData.data.updatedCustomer)
          ) {
            this.store.dispatch(
              new AuthActions.SetUser(_.cloneDeep(resData.data.updatedCustomer))
            );
          }
        })
      );
  }

  useCouponRule(obj, mo_token) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-use-coupon-rule",
        JSON.stringify(obj),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000));
  }

  usePoints(obj, mo_token) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" + this.stagePath + "/usepoints",
        JSON.stringify(obj),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000));
  }

  getCustomerCoupons(
    mo_token,
    generate_kiosk_passwords,
    fetchAlsoKioskCoupons
  ) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/get_customer_coupons",
        JSON.stringify({
          generate_kiosk_passwords: generate_kiosk_passwords,
          fetchAlsoKioskCoupons: fetchAlsoKioskCoupons,
        }),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(2));
  }

  getCustomerKioskCoupons(mo_token) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/get_customer_coupons",
        JSON.stringify({
          kioskCoupons: true,
          generate_kiosk_passwords: true,
        }),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(2));
  }

  getNoUserKioskCouponRulesRewards(store_id, group) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-fetch-coupon-rules",
        JSON.stringify({
          store_id: store_id,
          group: group,
        }),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
          }),
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(2));
  }

  getCustomerKioskCouponRulesRewards(mo_token, store_id) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-fetch-customer-coupon-rules",
        JSON.stringify({
          store_id: store_id,
        }),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(2));
  }

  getLoyaltyAppCustomerCouponRules(mo_token, store_id) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-fetch-customer-coupon-rules",
        JSON.stringify({
          store_id: store_id,
          loyalty_app: true,
        }),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(2));
  }

  getNoUserLoyaltyAppCouponRules(store_id, group) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-fetch-coupon-rules",
        JSON.stringify({
          store_id: store_id,
          group: group,
          loyalty_app: true,
        }),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
          }),
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(2));
  }

  getReviews(mo_token) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" + this.stagePath + "/getReviews",
        JSON.stringify({}),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(2));
  }

  sendReview(mo_token, order_id, store_id, platform, questions) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" + this.stagePath + "/sendReview",
        JSON.stringify({
          order_id,
          store_id,
          platform,
          questions,
        }),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000));
  }

  createCardRecurringTransaction(data, mo_token) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/createCardRecurringTransaction",
        JSON.stringify(data),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(20000));
  }

  getPiraeusHtml3ds(data, mo_token) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-get-piraeus-html-3ds",
        JSON.stringify(data),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(3000));
  }

  piraeusCheckoutRecurring3ds(data, mo_token) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/piraeus-checkout-recurring-3ds",
        JSON.stringify(data),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(20000));
  }

  verifyBillingInfo(data, mo_token) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/verify-bank-format-billing-address",
        JSON.stringify(data),
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(20000));
  }

  triggerDeleteAccount(mo_token) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-trigger-delete-customer",
        {},
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000));
  }

  triggerRestoreAccount(group, mobile, language_code) {
    let url =
      "https://api.deliverymanager.gr/" +
      this.stagePath +
      "/mobileorder-trigger-restore-customer";
    return this.http
      .post(url, { group, mobile, language_code })
      .pipe(timeout(10000));
  }

  checkStoreSchedule(store_id, group, languageCode) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-loyaltyapp-check-store-schedule",
        { store_id, group, languageCode },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
          }),
        }
      )
      .pipe(timeout(10000))
      .pipe(retry(2));
  }

  fetchReceiversCoupons(mo_token, affiliate_coupon_code) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-affiliate-fetch-receivers-coupons",
        { affiliate_coupon_code },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000));
  }

  createSendersCoupon(mo_token, coupon, store_id) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-affiliate-sender-create-coupon",
        { coupon, store_id },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000));
  }

  migrateCustomerPoints(mo_token, memberNumberPhone) {
    return this.http.post(
      "https://api.deliverymanager.gr/" +
        this.stagePath +
        "/mobileorder-manually-migrate-points",
      { memberNumberPhone },
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json; charset=utf-8",
          Authorization: mo_token,
        }),
      }
    );
  }

  fetchReservationTables(group, store_id, lang) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-fetch-reservation-tables",
        { group, store_id, language_code: lang },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            // Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000));
  }

  fetchReservations(mo_token, store_id, active_reservation_app) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-fetch-reservations",
        { store_id, active_reservation_app },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000));
  }

  createReservation(mo_token, reservation) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-handle-reservation",
        { reservation },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000));
  }

  handleReservation(mo_token, reservation: any, type) {
    const res_id = reservation.res_id;
    const store_id = reservation.store_id;
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-handle-reservation",
        { res_id, store_id, type },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000));
  }

  fetchArtistTables(group, store_id, product_id) {
    return this.http
      .post(
        "https://api.deliverymanager.gr/" +
          this.stagePath +
          "/mobileorder-fetch-reservation-tables",
        { group, product_id, store_id },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            // Authorization: mo_token,
          }),
        }
      )
      .pipe(timeout(10000));
  }

  triggerReconnectSocket() {
    this.store.dispatch(
      new AuthActions.SetReconnectSocketTimestamp(Date.now().toString())
    );
  }
  changeBitlyToRealUrlForRedirect(bitlyUrl) {
    return this.http.post(
      "https://api.deliverymanager.gr/" +
        this.stagePath +
        "/mobileorder-check-short-url",
      { bitlyUrl },
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json; charset=utf-8",
        }),
      }
    );
  }
}
